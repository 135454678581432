<br>
<div class="content">
    <section class="arr-buttons">
        <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
        <a class="next round"><i class="fa-solid fa-chevron-right"></i></a> &nbsp;
        <span class="title">&nbsp; Add Survey</span>
    </section>
</div>
<br>
<div class="container">
    <br>
      <form [formGroup]="survey_form" class="example-container">

    <mat-card>
        <!-- <mat-card-title>Templates</mat-card-title>  -->

        <section class="two-buttons">
           
            <mat-form-field appearance="outline" class="size-select">
                <mat-label>Enter Survey Name</mat-label>
                <input matInput placeholder="" formControlName="surveyName">
              </mat-form-field>
              
              <mat-form-field appearance="outline" class="size-select">
                <mat-label>Select State</mat-label>
                <mat-select formControlName="module" (selectionChange)="onStateSelect($event)">
                    <mat-option *ngFor="let module of moduleData" [value]="module.name"> {{ module.name }} </mat-option>
                </mat-select>
            </mat-form-field>
             
        </section>

        <section>
            <table mat-table [dataSource]="templateData" class="list">
                <!-- Item Column -->
                <ng-container matColumnDef="templates">
                  <th mat-header-cell *matHeaderCellDef> Template Names </th>
                  <td mat-cell *matCellDef="let template" > {{template.templateName}} </td>
                </ng-container>
              
                <!-- Cost Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let template">
                    <section class="example-section">
                        <mat-slide-toggle class="example-margin" (change)="updateFunc($event)" [checked]="checked">
                        </mat-slide-toggle>
                    </section>
                </td>
                </ng-container>
            
              
                <!-- The table will render two header rows, one data row per data object, and two footer rows. -->
                <tr mat-header-row  *matHeaderRowDef="displayedColumns">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></tr>
              </table>
        </section>
        <br>
        <mat-card-actions class="style">
            <button class="active-stroke-lrg" style="width: 10em;padding: 8px 4px;" (click)="goTo()">Preview</button>
        </mat-card-actions>
    </mat-card>
  </form>
</div>

