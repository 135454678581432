import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseComponent } from '@app/database/database.component';
import { DbOpMaturityComponent } from '@app/database/db-op-maturity/db-op-maturity.component';
import { DbAvaComponent } from '@app/database/db-ava/db-ava.component';
import { DbEvaComponent } from '@app/database/db-eva/db-eva.component';
import { SharedModule } from '@app/shared/shared.module';
import { DatabaseRoutingModule } from './database-routing.module';
import { DbManagerInterviewComponent } from './db-manager-interview/db-manager-interview.component';
import { DbFocusGroupComponent } from './db-focus-group/db-focus-group.component';
import { DbPoiComponent } from './db-poi/db-poi.component';


@NgModule({
  declarations: [
    DatabaseComponent,
    DbOpMaturityComponent,
    DbAvaComponent,
    DbEvaComponent,
    DbManagerInterviewComponent,
    DbFocusGroupComponent,
    DbPoiComponent
  ],
  imports: [
    DatabaseRoutingModule,
    CommonModule,
    SharedModule
  ]
})
export class DatabaseModule { }
