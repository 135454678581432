import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class FocusGroupsService {
  clientURL: string = environment.CLIENT_API_URL;
  surveyURL: string = environment.SURVEY_API_URL;
  staticURL: string = environment.STATIC_API_URL;
  constructor(private http: HttpClient) {}

  getFocusGroup(clientId: string) {
    let url = `${this.clientURL}focusGroup/${clientId}?size=100&pageNumber=0`;
    return this.http.get<any>(url, { observe: "response" });
  }

  createFocusGroup(payload: any) {
    let url = `${this.clientURL}focusGroup`;
    return this.http.post<any>(url, payload, { observe: "response" });
  }

  editFocusGroup(focusGroupId: string, payload: any) {
    let url = `${this.clientURL}focusGroup/${focusGroupId}`;
    return this.http.put<any>(url, payload, { observe: "response" });
  }

  deleteFocusGroup(focusGroupId: string) {
    let url = `${this.clientURL}focusGroup/${focusGroupId}`;
    return this.http.delete<any>(url, { observe: "response" });
  }

  getFG(module: string, clientId: string = "", surveyDetailId?: string) {
    let url: string = `${this.surveyURL}survey/surveyDetail?module=${module}`;
    if (surveyDetailId) {
      url = `${url}&surveyDetailId=${surveyDetailId}`;
    }
    if (clientId) url = `${url}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }

  getOutput(module: any, clientId: any) {
    let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }

  getFgOutput(payload: any) {
    let url = `${this.surveyURL}surveyResponse/fgsurveyResults`;
    return this.http.post<any>(url, payload);
  }

  getOutput1(module: any, clientId: any, focusGroupId: any) {
    let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}&focusGroupId=${focusGroupId}`;
    return this.http.get<any>(url);
  }

  getPOIOutputs(module: any, clientId: any) {
    let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }
}
