import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  public selectedClient = new BehaviorSubject("empty");
  constructor(private httpClient: HttpClient, private router: Router) {}
  clientUrl: string = environment.CLIENT_API_URL;
  authUrl: string = environment.AUTH_API_URL;

  getClientId() {
    return this.httpClient.get<any>(`${this.authUrl}auth/clientDetails`);
  }

  getClients(id: string = "") {
    let url = `${this.clientUrl}client`;
    if (id) url = `${url}?id=${id}`;

    return this.httpClient.get<any>(url);
  }

  updateClient(clientId: any, payload: any) {
    return this.httpClient.put<any>(
      `${this.clientUrl}client/${clientId}`,
      payload
    );
  }

  getAllClients() {
    return this.httpClient.get<any>(
      `${this.clientUrl}client?fullSearch=false&size=1000&pageNumber=0`
    );
  }

  sendClient(clientId: string) {
    return this.selectedClient.next(clientId);
  }

  receiveClient() {
    return this.selectedClient.asObservable();
  }
}
