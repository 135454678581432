import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "@app/core/layout/layout.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@app/shared/shared.module";
import { UserDetailTabComponent } from "@app/core/user-detail-tab/user-detail-tab.component";
import { HorizontalTimelineComponent } from "./horizontal-timeline/horizontal-timeline.component";
import { ChartComponents } from "./chart/chart.component";
import { FteCountsComponent } from "./fte-counts/fte-counts.component";
import { CircleChartComponent } from "./circle-chart/circle-chart.component";
import { ChartTypesComponent } from "./chart-types/chart-types.component";
import { RatingTimeline } from "./rating-timeline/rating-timeline.component";
import { ResponseEditComponent } from "./response-edit/response-edit.component";
import { ResponseSelectComponent } from './response-select/response-select.component';

@NgModule({
  declarations: [
    LayoutComponent,
    UserDetailTabComponent,
    HorizontalTimelineComponent,
    ChartComponents,
    FteCountsComponent,
    CircleChartComponent,
    ChartTypesComponent,
    RatingTimeline,
    ResponseEditComponent,
    ResponseSelectComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    LayoutComponent,
    ChartComponents,
    FteCountsComponent,
    CircleChartComponent,
    ChartTypesComponent,
    ResponseEditComponent,
  ],
})
export class CoreModule {}
