<div style="display: flex; flex-direction: row;">


    <section class="database-data" style="width: 65% !important; margin-right: 5px !important;">
        <mat-card style="height: 65px;">
            <div class="row" style="padding: 0.5em;">
                <mat-form-field appearance="outline" class="w-50" style="font-size: 12.5px;width: 18.5vw !important;">
                    <mat-label>Search</mat-label>
                    <input matInput #input />
                </mat-form-field>
    
                <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                    <mat-label>Select Category</mat-label>
                    <mat-select >
                        <mat-option *ngFor="let category of categoryList" [value]="category.name"> {{ category.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                    <mat-label>Select Sub Category</mat-label>
                    <mat-select >
                        <mat-option *ngFor="let category of categoryList" [value]="category.name"> {{ category.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                    <mat-label>Select Lens</mat-label>
                    <mat-select >
                        <!-- <mat-option *ngFor="let state of questionStates" [value]="state.name"> {{ state.name }} </mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card>
    </section>
    
    <section class="database-data-action" style="width: 25% !important;">
        <mat-card style="height: 65px; display:flex; justify-content: center;">
            <button mat-button class="active-stroke-sml w" style="font-size: 13px;" (click)="openDialog()"> <i class="fa-solid fa-plus"></i> &nbsp; Add New  </button>
            <button mat-button class="active-stroke-sml w" style="font-size: 13px;" > Remove  </button>
            <button mat-button class="active-stroke-sml w" style="font-size: 13px;" > Amend  </button>
        </mat-card>
    </section>
    </div>
    
    <div class="example-container mat-elevation-z8 m-12" style="margin-top: 20px;">
    
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
            
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>
    
            <ng-container matColumnDef="subCategory">
                <th mat-header-cell *matHeaderCellDef> Sub Category </th>
                <td mat-cell *matCellDef="let element"> {{element.subCategory}} </td>
            </ng-container>
    
            <ng-container matColumnDef="lens">
                <th mat-header-cell *matHeaderCellDef> Lens </th>
                <td mat-cell *matCellDef="let element"> {{element.lens}} </td>
            </ng-container>
    
            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef> Question </th>
                <td mat-cell *matCellDef="let element"> {{element.question}} </td>
            </ng-container>
    
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon  class="icon bulb" >delete</mat-icon>
                    <mat-icon   class="icon playPause">mode_edit</mat-icon>
                </td>
            </ng-container>
    
            <ng-container  matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
                        {{expandedElement === element ? 'expand_less' : 'expand_more'}}
                    </mat-icon>
                </td>
            </ng-container>
    
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    
                        <mat-card class="mat-elevation-z8">
                            <mat-card-content style="display: flex;gap: 3em;margin-left: 3em;">
                                <div>
                                    <p class="project-info">Category</p>
                                    <p class="project-info">Sub Category</p>
                                    <p class="project-info">Lens</p>
                                    <p class="project-info">Question</p>
                                    <p class="project-info">Not in place</p>
                                    <p class="project-info">Designed, yet not in place OR in place yet unused</p>
                                    <p class="project-info">In place, yet not adding value</p>
                                    <p class="project-info">In place and adding value</p>
                                    <p class="project-info">In place, adding value and continuously reviewed and improved</p>
                                </div>
                                <div>
                                    <p class="project-info">: {{element.category}}</p>
                                    <p class="project-info">: {{element.subCategory}}</p>
                                    <p class="project-info">: {{element.lens}}</p>
                                    <p class="project-info">: {{element.question}}</p>
                                    <p class="project-info">: {{element.notInPlace}}</p>
                                    <p class="project-info">: {{element.designedYetNotInPlace}}</p>
                                    <p class="project-info">: {{element.inPlaceAndNotAddingValue}}</p>
                                    <p class="project-info">: {{element.inPlaceAndAddingValue}}</p>
                                    <p class="project-info">: {{element.InPlaceAddingValueAndContinuouslyReviewed}}</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
    
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator 
            (page)="getPageEvent($event)"
            [length]="totalLength"
            [pageIndex]="(questionPagination.page || 1) - 1"
            [pageSize]="questionPagination.perPage"
            [pageSizeOptions]="questionPagination.paginationSizes"
            showFirstLastButtons 
            aria-label="Select page">
        </mat-paginator>
      </div>