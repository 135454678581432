<div class="circle-chart-container">
    <mat-progress-spinner
      class="circle-chart"
      [ngClass]="{ 'hide-progress-bar': hideProgressBar }"
      mode="determinate"
      [value]="chartValue"
    >
    </mat-progress-spinner>
    <div class="circle-chart-text">{{ chartText }}</div>
  </div>
