import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.checkToken(route);
  }
  canLoad(route: Route, next: ActivatedRouteSnapshot): boolean {
    return this.checkToken(next);
  }

  checkToken(route: ActivatedRouteSnapshot) {

    const roleValue = localStorage.getItem("encodedRole");
    let decodedValue = "";
    if(roleValue) {
      decodedValue = atob(roleValue);
      return true;
    } else {
      localStorage.clear();
      this.router.navigateByUrl("");
      return false;
    }
    // console.log(decodedCookie);

    // const helper = new JwtHelperService();

    // const token = localStorage.getItem("token");

    // const decodedToken = helper.decodeToken(`${token}`);
    // localStorage.setItem("role", decodedToken.roles[0]);
    // const expirationDate = helper.getTokenExpirationDate(`${token}`);
    // const isExpired = helper.isTokenExpired(`${token}`);

    // if (!token || isExpired) {
    //   localStorage.clear();
    //   this.router.navigateByUrl("");
    //   return false;
    // } else {
    //   return true;
    // }
  }

  private getCookie(cookieName : string): string {
    const cookie = document.cookie.split(';').find((item) => item.trim().startsWith(`${cookieName}`));
    if(cookie) {
      return cookie.split('=')[1];
    }
    return '';
  }
}
