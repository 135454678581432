     <!-- <mat-tab-group (selectedIndexChange)="Output_TabChange($event)" >
         <mat-tab label="AVA">
             <app-ava-outputs></app-ava-outputs>
         </mat-tab>
         <mat-tab label="Engagement Survey">
             <app-eva-outputs></app-eva-outputs>
         </mat-tab>
     </mat-tab-group> -->

<div class="va">
    <mat-tab-group (selectedIndexChange)="Output_TabChange($event)">
        <mat-tab label="AVA"></mat-tab>
        <mat-tab label="Engagement Survey"></mat-tab>
    </mat-tab-group>
    <br>
</div>


     <app-ava-outputs *ngIf="ava"></app-ava-outputs>
     <app-eva-outputs *ngIf="!ava"></app-eva-outputs>
