import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUrl = environment.AUTH_API_URL;
  userManager: UserManager;
  private currentUserObject : BehaviorSubject<User | null>;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {  
    const settings = {
    authority: environment.AUTHORITY,
    client_id: environment.CLIENT_ID,
    redirect_uri: window.location.origin + "/signin-callback",
    response_type: "code",
    scope: "openid profile email",
    client_secret : environment.CLIENT_SECRET,
    metadata:{
      issuer: environment.AUTHORITY,
      token_endpoint: environment.TOKEN_ENDPOINT,
      authorization_endpoint: environment.AUTHORIZATION_ENDPOINT,
      userinfo_endpoint: environment.USERINFO_ENDPOINT,
      end_session_endpoint: `${environment.END_SESSION_ENDPOINT}?returnTo=${encodeURIComponent(window.location.origin)}`
    }
  };
  this.userManager = new UserManager(settings);
  this.currentUserObject = new BehaviorSubject<User | null>(null);
  this.userManager.getUser().then(user => {
    this.currentUserObject.next(user);
  })
}

  getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  getCurrentUserValue() : User | null {
    return this.currentUserObject.value;
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
  
  authorize(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    // headers.append("Access-Control-Expose-Headers","Set-Cookie");
    // headers.append('Set-Cookie',"");
    return this.http
      .post<any>(`${this.authUrl}auth/authorize`, {} ,{headers, observe : 'response' as 'body'});
  }

  decodeUser(_token: string): string {
    return JSON.parse(window.atob(_token.split('.')[1]));    
  }

  checkAdminRights(_token: string) : boolean {
      const decoded : any = window.atob(_token); 
      return decoded == 'ADMIN';
  }

  checkRole(_token: string) : boolean {
    const decoded : any = window.atob(_token); 
    return decoded == 'CLIENT';
}
}
