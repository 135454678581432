<div class="content">
    <div class="">
        <section class="arr-buttons">
            <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
            <a class="next round"><i class="fa-solid fa-chevron-right"></i></a> &nbsp;
            <span class="title">&nbsp; Master Data</span>
        </section>
        <br><br>
    
        <section class="master-data">
            <mat-card>
                <div class="row" style="padding: 0.5em;">
                    <!-- <div class="col-sm-9"> -->
                        <mat-form-field appearance="outline" class="w-50" style="font-size: 12.5px;width: 18.5vw !important;">
                            <mat-label>Search</mat-label>
                            <input matInput #input [formControl]="search" (input)="onSearchChange($event)"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                            <mat-label>Select Module</mat-label>
                            <mat-select [formControl]="searchModule" (selectionChange)="onModuleSelect($event)">
                              
                              
                              <mat-option *ngFor="let module of moduleList" [value]="module.name"> {{ module.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                            <mat-label>Select Category</mat-label>
                            <mat-select [formControl]="searchCategory" (selectionChange)="onCategorySelect($event)">
                                <mat-option *ngFor="let category of categoryList" [value]="category.name"> {{ category.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                            <mat-label>Select State</mat-label>
                            <mat-select [formControl]="searchState" (selectionChange)="onStateSelect($event)">
                                <mat-option *ngFor="let state of questionStates" [value]="state.name"> {{ state.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>

                      
                    <!-- </div> -->
                    <!-- <div class="col-sm-3 add"> -->
                        <button class="active-stroke-sml w"
                        style="font-size: 12.5px;margin-left: 1em;margin-bottom: 1em;"
                         (click)="openDialog()">Add New &nbsp; <i class="fa-solid fa-plus"></i> </button>
                    <!-- </div> -->
                </div>

            </mat-card>
            <br>
            <table mat-table [dataSource]="dataSource" class="w-100">
             
                <!-- Column -->
                <ng-container matColumnDef="question">
                  <th mat-header-cell *matHeaderCellDef>Question</th>
                  <td mat-cell *matCellDef="let element" style="display: flex;margin-top: 1em;
                  justify-content: center !important;">{{element?.question}}</td>
                </ng-container>
              
                <!-- action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;gap: 1em;justify-content: center;margin-top: 1em;">
                        <!-- <i class="fa-solid fa-pencil"></i> -->
                        <i [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </i>
                        <!-- <i mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </i> -->
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                                <mat-icon>dialpad</mat-icon>
                                <span>View</span>
                            </button>
                            <button mat-menu-item>
                                <mat-icon>voicemail</mat-icon>
                                <span>Edit</span>
                            </button>
                            <button mat-menu-item>
                                <mat-icon>voicemail</mat-icon>
                                <span>Version History</span>
                            </button>
                            <button mat-menu-item>
                                <mat-icon>notifications_off</mat-icon>
                                <span>Activate/Deactivate</span>
                            </button>
                        </mat-menu>
                    </div>
                </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                ></tr>
              </table>
            <mat-paginator 
                (page)="getPageEvent($event)"
                [length]="totalLength"
                [pageIndex]="(clientsPagination.page || 1) - 1"
                [pageSize]="clientsPagination.perPage"
                [pageSizeOptions]="clientsPagination.paginationSizes"
                showFirstLastButtons 
                aria-label="Select page">
            </mat-paginator>
        </section>
        <br><br>
    </div>

</div>
