import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataComponent } from '@app/masterdata/data/data.component';
import { ApprovalComponent } from '@app/masterdata/approval/approval.component';
import { MasterdataComponent } from '@app/masterdata/masterdata.component';
import { SharedModule } from '@app/shared/shared.module';
import { QuestionAddDialogComponent } from '@app/masterdata/question-add-dialog/question-add-dialog.component';
import { TemplateListComponent } from './template-list/template-list.component';
import { RouterModule } from '@angular/router';
import { ReqHistoryComponent } from './req-history/req-history.component';

@NgModule({
  declarations: [
    DataComponent,
    ApprovalComponent,
    MasterdataComponent,
    QuestionAddDialogComponent,
    TemplateListComponent,
    ReqHistoryComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ]
})
export class MasterdataModule { }
