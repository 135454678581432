import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexOptions,
  ApexChart,
  ChartComponent,
} from "ng-apexcharts";

import { managerInterview as data } from "@app/projects-v2/ava-eva/avaData";
import { ManagerInterviewService } from "../manager-interview.service";
import { CustomService } from "@app/core/services/custom.service";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { DatabaseService } from "@app/database/database.service";
import { SurveyService } from "@app/survey/survey.service";
import { _isNumberValue } from "@angular/cdk/coercion";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: "app-outputs",
  templateUrl: "./outputs.component.html",
  styleUrls: ["./outputs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputsComponent implements OnInit {
  teamFilterOption = "All";
  displayedColumns: string[] = [
    "Team",
    "Function",
    "FTE",
    "Total FLC",
    "Maturity Assessment Score",
  ];
  dataSource: any = [];
  highlightedHeaders: any[] = ["Lens", "Average Score"];

  clickedRows: any;

  @ViewChild("chart")
  chart: ChartComponent;
  public radialChartOptions: ChartOptions | any;

  filters: any[] = [{ Value: "All" }];

  // @ViewChild("single")
  // Singlechart: ChartComponent;
  // public singleChartOptions: ChartOptions | any;

  @ViewChild("chart2")
  chart2: ChartComponent;
  public chartOptions: ChartOptions | any;
  role: string;
  dataTable: any[];
  saveTable: any;
  isSaved: boolean = false;
  isClicked: boolean;
  data: any = data;
  grouping: string = "team";
  clientId: any = sessionStorage.getItem("clientId") || "";
  categories: any;
  average: any;
  averageChart: any;
  possiblePoints = 5;
  selectedRows: any = [];
  selectedOptions: any[] = [];
  selectAllChecked = false;
  selectedTab: number = 0;
  render: boolean;

  chartCategoryScore: any = new Map();
  subCatTable: any[] = [];
  subCatTable2: any[] = [];
  teamMap = new Map();
  functionMap = new Map();
  subCategoryButtons: any[] = ["All"];

  colors: any = ["#c1e6e3", "#dffcdc", "#d7e3d5", "#18eddf", "#17e8da"];

  constructor(
    private cdr: ChangeDetectorRef,
    private interviewService: ManagerInterviewService,
    private customService: CustomService,
    private snackbar: MatSnackBar,
    private databaseService: DatabaseService,
    private surveyService: SurveyService
  ) {
    //this.getMIOutput();
    this.processTableData();
    this.appendFilters();
    this.processData();
  }

  getAllCategories() {
    this.databaseService.getCategoriesByType("CATEGORY").subscribe(
      (res: any) => {
        let populateInitData = false;
        if (this.chartDataArray.length == 0) {
          populateInitData = true;
        }

        for (let cat of res) {
          this.chartCategoryScore.set(cat.category, 0);
          const originalObject: any = {
            series: "",
            name: "",
          };
          if (populateInitData) {
            originalObject.series = 0;
            originalObject.name = cat.category;
            this.chartDataArray.push(originalObject);
          }
        }
        this.cdr.markForCheck();
        this.categories = [];
        this.categories.push("All");
        this.categories.push(...this.chartCategoryScore.keys());
        this.clientId = sessionStorage.getItem("clientId") || "";
        console.log("MOCK DATA _>", this.data);
        this.overAllCounts();
        this.appendFilters();
        this.selectedOption = "option2";

        this.initiateSingleChart();
      },
      (error) => {
        console.log("Error from Categories API : ", error);
      }
    );
  }

  appendFilters() {
    this.filters = [];
    this.filters.push({ Value: "All" });
    const teams = new Set();
    for (let d of this.data) {
      teams.add(d.teamName);
    }
    for (let team of teams) {
      this.filters.push(...[{ Value: team }]);
    }
  }

  selectedOption: string;
  options: any[] = [
    { value: "option1", label: "Option 1", selected: false },
    { value: "option2", label: "Option 2", selected: false },
    { value: "option3", label: "Option 3", selected: false },
    // Add more options as needed
  ];

  // New Data

  functions: any[] = [];
  teams: any[] = [];

  selectedFunction: String = "All";
  selectedTeam: String = "All";

  selectedFunctions: any[] = ["All"];
  selectedTeams: any[] = ["All"];
  previousFunctions: any[] = ["All"];
  previousTeams: any[] = ["All"];
  selectedChartValues: any = [];

  onOptionChange(event: any) {
    // Handle option change event if needed
    console.log(event);
  }

  tabChange(index: any) {
    this.selectedTab = index;
    if (this.selectedTab == 0) {
      this.cdr.markForCheck();
    } else if (this.selectedTab == 1) {
      this.render = true;
      // this.chartDataArray.forEach((element:any) => {
      //   console.log(element);
      // });

      this.cdr.markForCheck();
    }
  }

  ngOnInit(): void {
    this.getAllCategories();
  }

  getMIOutput() {
    this.interviewService
      .getOutput("managerInterviews", this.clientId)
      .subscribe({
        next: (res: any) => {
          console.log("MI Output :----->", res);
          //console.log('MOCK DATA _>',this.data);

          this.data = res;

          // Modifying API DATA
          this.data.forEach((ele: any) => {
            let Format: any = [];
            ele.managerInterviews.forEach((ele: any) => {
              Format.push(ele);
            });

            delete ele.managerInterviews;
            let obj = [];
            obj = ele;
            obj.managerInterviews = { questions: Format };
            ele = obj;
          });
          // Modifying API DATA complete
          console.log("modified mi output ->", this.data);
          this.cdr.markForCheck();
          // this.getCategories();

          this.processTableData();
          this.appendFilters();
          this.getFunctionsAndTeams();
          this.isSaved = true;
          this.processMiData();
          // this.processData();
        },
        error: (error: any) => {
          console.log("get MI error", error);
        },
      });
  }

  renerLensChart(seriesData: any, seriesCategories: any) {
    this.chartOptions = {
      series: [
        {
          name: "basic",
          data: seriesData,
        },
      ],
      chart: {
        type: "bar",
        height: 600,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: seriesCategories,
      },
    };
  }

  renderCategoryChart(series: any, labels: any) {
    console.log("CHECKING C -><", series, labels);

    this.radialChartOptions = {
      series: series,
      chart: {
        height: 400,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 6,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#DC143C", "#FF2400", "#E0115F", "#800020", "#800000"],
      labels: labels,
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        fontFamily: "Hanken Grotesk",
        position: "left",
        left: 10,
        offsetX: 50,
        offsetY: 10,
        labels: {
          useSeriesColors: true,
        },
        formatter: function (seriesName: any, opts: any) {
          return (
            seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + " %"
          );
        },
        itemMargin: {
          horizontal: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    };
  }

  getRecord(data: any) {
    console.log(data);
    //this.changeTeam(data.name)
  }

  teamFilter(data: any) {
    console.log(data);

    this.teamFilterOption = data;
    this.processData(data);
  }

  processTableData() {
    const tableData: any[] = [];

    const functionTeamCount = new Map();
    const resultMap = new Map();

    //Can have both subcategory and lens
    const rating1Map = new Map();
    const rating2Map = new Map();
    const rating3Map = new Map();
    const rating4Map = new Map();
    const rating5Map = new Map();

    let processData = true;

    for (let x of this.data) {
      if (processData) {
        let value = 0;
        let qCount = 0;

        for (let q of x.managerInterviews.questions) {
          value = value + q.answer * x.total;
          qCount = qCount + 1;

          const rating = q.ratingCount;
          if (rating1Map.has(x.teamName)) {
            rating1Map.set(x.teamName, rating1Map.get(x.teamName) + rating.one);
          } else {
            rating1Map.set(x.teamName, rating.one);
          }

          if (rating2Map.has(x.teamName)) {
            rating2Map.set(x.teamName, rating2Map.get(x.teamName) + rating.two);
          } else {
            rating2Map.set(x.teamName, rating.two);
          }

          if (rating3Map.has(x.teamName)) {
            rating3Map.set(
              x.teamName,
              rating3Map.get(x.teamName) + rating.three
            );
          } else {
            rating3Map.set(x.teamName, rating.three);
          }

          if (rating4Map.has(x.teamName)) {
            rating4Map.set(
              x.teamName,
              rating4Map.get(x.teamName) + rating.four
            );
          } else {
            rating4Map.set(x.teamName, rating.four);
          }

          if (rating5Map.has(x.teamName)) {
            rating5Map.set(
              x.teamName,
              rating5Map.get(x.teamName) + rating.five
            );
          } else {
            rating5Map.set(x.teamName, rating.five);
          }
        }

        if (resultMap.has(x.teamName)) {
          functionTeamCount.set(
            x.teamName,
            x.total + functionTeamCount.get(x.teamName)
          );
          resultMap.set(x.teamName, value / qCount + resultMap.get(x.teamName));
        } else {
          functionTeamCount.set(x.teamName, x.total);
          resultMap.set(x.teamName, value / qCount);
        }
      }
    }
    for (let key of resultMap.keys()) {
      tableData.push({
        team: key,
        score: (resultMap.get(key) / functionTeamCount.get(key)).toFixed(1),
      });
      console.log("1", resultMap.get(key), "2", functionTeamCount.get(key));
    }

    //this.dataTable = tableData;
  }

  processData(teamFilter: String = "All") {
    const lensMap = new Map();
    const catMap = new Map();
    const subCatMap = new Map();

    let processData = false;

    let totalCount = 0;

    for (let x of this.data) {
      console.log("TT", x);

      if (x.teamName === teamFilter) {
        processData = true;
      }
      if (teamFilter === "All") {
        processData = true;
      }

      if (processData) {
        console.log("processing " + x.teamName);
        totalCount = totalCount + x.total;

        for (let q of x.managerInterviews.questions) {
          let value = q.answer * x.total;

          if (lensMap.has(q.lens)) {
            let obj = lensMap.get(q.lens);
            obj.count = obj.count + 1;
            obj.value = value + parseFloat(obj.value);
            lensMap.set(q.lens, obj);
          } else {
            let obj: any = {};
            obj.count = 1;
            obj.value = value;
            lensMap.set(q.lens, obj);
          }

          if (catMap.has(q.category)) {
            let obj = catMap.get(q.category);
            obj.count = obj.count + 1;
            obj.value = value + parseFloat(obj.value);
            catMap.set(q.category, obj);
          } else {
            let obj: any = {};
            obj.count = 1;
            obj.value = value;
            catMap.set(q.category, obj);
          }

          if (subCatMap.has(q.subCategory)) {
            let obj = subCatMap.get(q.subCategory);
            obj.count = obj.count + 1;
            obj.value = value + parseFloat(obj.value);
            subCatMap.set(q.subCategory, obj);
          } else {
            let obj: any = {};
            obj.count = 1;
            obj.value = value;
            subCatMap.set(q.subCategory, obj);
          }
        }
      }
      processData = false;
    }

    const catResult = new Map();
    let totalValue = 0;

    for (let key of catMap.keys()) {
      let obj = catMap.get(key);
      totalValue = obj.value + totalValue;
    }

    for (let key of catMap.keys()) {
      let obj = catMap.get(key);
      catResult.set(key, ((obj.value / totalValue) * 100).toFixed(1));
      console.log("key" + key);
      console.log(catResult.get(key));
    }

    const catResultSub = new Map();
    let totalValueSub = 0;

    for (let key of subCatMap.keys()) {
      let obj = subCatMap.get(key);
      totalValueSub = obj.value + totalValueSub;
    }

    for (let key of subCatMap.keys()) {
      let obj = subCatMap.get(key);
      catResultSub.set(key, ((obj.value / totalValueSub) * 100).toFixed(1));
      console.log("key" + key);
      console.log(catResultSub.get(key));
    }

    // OverallCategories

    this.overallCategories = Array.from(catResult.keys());
    this.overallCategoryScore = Array.from(catResult.values());

    this.initiateSingleChart();
    this.cdr.markForCheck();

    let resulttt: any = [];

    for (let key of this.chartCategoryScore.keys()) {
      let catObj: any = {};
      catObj.name = key;
      if (catResult.has(key)) {
        catObj.value = catResult.get(key);
      } else {
        catObj.value = 0;
      }
      this.chartCategoryScore.set(key, catObj.value);
    }

    this.overallCategories = Array.from(this.chartCategoryScore.keys());
    this.overallCategoryScore = Array.from(this.chartCategoryScore.values());

    this.overallCategoryScore.forEach((ele: never, index: any) => {
      console.log(ele);

      const originalObject = {
        series: [],
        chart: {
          height: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: "17px",
                fontFamily: "Hanken Grotesk",
                fontWeight: 600,
                textAlign: "center",
              },
              value: {
                formatter: function (val: any) {
                  return val.replace(/%/g, "");
                },
                show: true,
              },
            },
          },
        },
        colors: ["#5C2344"],
        name: "",
      };
      //console.log(originalObject);

      originalObject.series.push(ele);

      this.overallCategories.forEach((cat: any, targetIndex: any) => {
        if (index === targetIndex) {
          originalObject.name = cat;
        }
      });

      resulttt.push(originalObject);
      //console.log('CCCCCC||',resulttt)
    });

    //To get team,function,member,manager overall counts

    const teamsCount = this.data.map((obj: any) => obj.teamName);
    this.teamsCount = teamsCount.length;
    const functionCount = this.data.map((obj: any) => obj.clientFunction);
    this.functionsCount = functionCount.length;
    const managerCount = this.data.map((obj: any) => obj.isManager);
    let counts: any = 0;
    let countsMember: any = 0;
    managerCount.forEach((count: any) => {
      if (count === true) counts = counts + 1;
      if (count === false) countsMember = countsMember + 1;
    });
    this.managerCount = counts;
    this.memberCount = countsMember;

    this.renerLensChart(
      Array.from(
        [...lensMap.values()].map((x) => (x.value / x.count).toFixed(1))
      ),
      Array.from(lensMap.keys())
    );

    this.renderCategoryChart(
      Array.from(catResult.values()),
      Array.from(catResult.keys())
    );

    // const mergedArray = Array.from(catResultSub.keys()).map((key, index) => ({ value: Array.from(catResultSub.values())[index] }));
    const mergedArrayMap = Array.from(catResultSub.keys()).map(
      (name, index) => ({
        name,
        value: Array.from(catResultSub.values())[index],
      })
    );

    console.log(mergedArrayMap);

    // this.initiateRadar(
    //   Array.from(catResultSub.values()),
    //   Array.from(catResultSub.keys())
    // );
    console.log(
      "Counts--<>",
      this.overallCategoryScore,
      teamsCount,
      this.functionsCount,
      this.memberCount,
      managerCount
    );
    console.log("CCCCCC||", resulttt);
    // this.chartDataArray = resulttt;
    //this.lensTable = mergedArrayMap;
    this.cdr.markForCheck();
  }

  getCategories() {
    let category: any = [];

    function getUniqueValuesByKey(array: any, key: any) {
      const uniqueValues = array.reduce((values: any, obj: any) => {
        if (obj.hasOwnProperty(key)) {
          values.add(obj[key]);
        }
        return values;
      }, new Set());

      return Array.from(uniqueValues);
    }

    this.data.forEach(function (ele: any, i: any) {
      category.push(ele.managerInterviews.questions);
    });

    let mergedArray: any = [];
    category.forEach((ele: any) => {
      let uniqueValues: any = [];
      uniqueValues = getUniqueValuesByKey(ele, "category");
      mergedArray = uniqueValues.concat(ele);
    });
    category = mergedArray.filter((item: any) => typeof item === "string"); // Got Category Labels

    let finalData: any = [];
    this.data.forEach(function (ele: any, i: any) {
      //let questions = Question_Array;
      ele.managerInterviews.questions.forEach((question: any) => {
        let obj = { category: "", sum: 0, iterate: 0 };
        let dataIndex = finalData.findIndex(
          (data: any) => data["category"] == question.category
        );

        if (dataIndex != -1) {
          let newData = finalData[dataIndex];
          newData.sum = newData.sum + Number(question.answer);
          newData.iterate += 1;
          finalData[dataIndex] = newData;
        } else {
          obj.category = question.category;
          obj.sum = Number(question.answer);
          obj.iterate = 1;
          finalData.push(obj);
        }
      });
    });
    console.log(finalData);
    let actualData: any = [];
    let finalAvg: any = [];
    let totalAvgSum = 0;
    finalData.forEach((ans: any) => {
      const result = ans.sum / ans.iterate;
      totalAvgSum = totalAvgSum + result;
      actualData.push(result);
    });
    console.log(totalAvgSum.toFixed(1));
    console.log(actualData); //Got Category series

    actualData.forEach((ele: any) => {
      let toPush = (ele * 100) / totalAvgSum;
      finalAvg.push(toPush.toFixed(1));
    });
    console.log("Final", finalAvg);

    this.cdr.markForCheck();

    this.renderCategoryChart(finalAvg, category);
    this.cdr.markForCheck();
  }

  //New

  @ViewChild("single") single: ChartComponent;
  public SinglechartOptions: any;

  public Radar: any;

  managerCount: any;
  memberCount: any;
  functionsCount: any;
  teamsCount: any;
  overallCategoryScore: any;
  overallCategories: any;

  lensTable: any = new MatTableDataSource<any>([]);
  lensTable2: any = new MatTableDataSource<any>([]);

  lensColumns: any[] = [];
  columnsToDisplay: any[] = this.lensColumns.slice();

  public chartDataArray: any = [
    {
      series: [],
      chart: {
        height: 200,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "17px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 600,
              textAlign: "center",
            },
          },
        },
      },
      colors: ["#5C2344"],
      name: "Sample",
    },
  ];

  initiateSingleChart() {
    let avgScore = 0;
    if (_isNumberValue(this.average)) {
      avgScore = this.average;
    }
    this.SinglechartOptions = {
      series: [(avgScore / this.possiblePoints) * 100],
      chart: {
        height: 300,
        type: "radialBar",
        // marginLeft: 10,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: false,
            name: {
              show: false,
              fontSize: "17px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 600,
              textAlign: "center",
            },
            value: {
              formatter: function (val: any) {
                return val;
                // return val.replace(/%/g, '');
              },
              show: true,
            },
          },
        },
      },
      // dataLabels: {
      //   enabled: false // <--- HERE
      // },
      colors: ["#0C0209"],
      name: avgScore,
    };
    this.cdr.markForCheck();
  }

  initiateRadar(series: any, cat: any) {
    this.Radar = {
      series: series,
      chart: {
        height: 680,
        type: "radar",
      },
      title: {
        text: "Basic Radar Chart",
      },
      xaxis: {
        categories: cat,
      },
      yaxis: {
        min: 0,
        max: 5,
        tickAmount: 5,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(1);
          },
        },
      },
      tooltip: {
        x: {
          show: false, // Hide the x-axis tooltip (series-1 label)
        },
        y: {
          formatter: function (
            value: any,
            { seriesIndex, dataPointIndex, w }: any
          ) {
            let seriesName = w.config.series[seriesIndex].name;
            // Replace "series-1" with "average"
            if (seriesName === "series-1") {
              seriesName = "average";
            }
            return seriesName + ": " + value;
          },
        },
      },
    };

    this.cdr.markForCheck();
  }

  isrowClicked: boolean;
  toggleSelection(row: any) {
    //this.saveTable = this.dataTable;
    row.selected = !row.selected;
    this.selectedRows.find((x: any) => x == row)
      ? (this.selectedRows = this.selectedRows.filter((x: any) => x != row))
      : this.selectedRows.push(row);
    console.log("SELECTED ROWS", this.selectedRows);
    let array: any = [];
    this.selectedRows.forEach((ele: any) => {
      array.push(ele.name);
    });
    console.log("ARR", array);
    this.isrowClicked = true;
    this.changeTable(array);
  }

  changeLensTable(data: any) {
    const outputResult = this.lensTable.map((item: any) => {
      const newItem = { ...item };
      item.teams.forEach((team: any) => {
        newItem[team.name] = team.value;
      });
      return newItem;
    });

    const elementsToRemove = ["category", "teams"];
    let keysArray = outputResult.map((obj: any) => Object.keys(obj));

    const filteredColumns = keysArray[0].filter(
      (element: any) => !elementsToRemove.includes(element)
    );

    this.columnsToDisplay = [];
    this.lensTable = outputResult;
    this.lensColumns = filteredColumns;
    this.lensColumns.forEach((ele: any) => {
      this.columnsToDisplay.push(ele);
    });
    this.isrowClicked = false;
    this.isrowClicked = true;

    console.log("LensTable", this.lensTable, this.lensColumns);
    this.cdr.markForCheck();
  }

  addNewColumn() {
    const newColumnName = "newColumn";
    this.lensColumns.push(newColumnName); // Add the new column to displayedColumns
    this.lensTable.forEach((row: any) => {
      row[newColumnName] = "New Data"; // Add data for the new column in each row
    });

    this.lensTable = new MatTableDataSource(this.lensTable);
    this.cdr.markForCheck();
  }

  getColorClass(str: string): string {
    //console.log(str,"Chars");

    switch (str) {
      case "Customer & Purpose":
        return "customer";
      case "Performance":
        return "perform";
      case "Intelligent Automation and Process":
        return "intel";
      case "Problem Solving":
        return "problem";
      case "People & Capability":
        return "people";
      default:
        return "def";
    }
  }

  // New charts
  getFunctionsAndTeams() {
    this.functions = [];
    if (!this.functions.includes("All")) {
      this.functions.push("All");
    }
    if (!this.teams.includes("All")) {
      this.teams.push("All");
    }
    for (let d of this.data) {
      if (!this.functions.includes(d.clientFunction)) {
        this.functions.push(d.clientFunction);
      }
      if (!this.teams.includes(d.teamName)) {
        this.teams.push(d.teamName);
      }
    }
  }

  getRowColor(rating: any) {
    if (typeof rating === "string") {
      rating = parseFloat(rating);
      if (rating) {
        if (rating >= 1.0 && rating < 2.0) {
          return "red";
        } else if (rating >= 2.0 && rating < 3.0) {
          return "brown";
        } else if (rating >= 3.0 && rating < 4.0) {
          return "black";
        } else if (rating >= 4.0 && rating < 5.0) {
          return "green";
        } else if (rating >= 5.0) {
          return "#90EE90";
        }
      }
    }
    return "black";
  }

  changeFunction(event: any) {
    console.log("----changeFunction ------", event);
    this.selectedFunctions = [];

    if (!event.length) {
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;

    this.processMiData();
  }

  changeTeam(event: any) {
    console.log("----changeTeam ------", event);
    this.selectedTeams = [];

    if (!event.length) {
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.processMiData();
  }

  changeTable(event: any) {
    console.log("----<< Inside changeTable >>------", event);
    this.isClicked = true;
    this.selectedTeams = [];
    for (let e of event) {
      this.selectedTeams.push(e);
    }
    this.processMiData();
  }

  @ViewChild("select") select: MatSelect;
  @ViewChild("select2") select2: MatSelect;

  allSelected_function = false;
  allSelected_team = false;
  toggleAllSelection() {
    if (this.allSelected_function) {
      this.select.options.forEach((item: any) => item.select());
    } else {
      this.select.options.forEach((item: any) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_function = newStatus;
  }

  toggleAllSelectionTeam() {
    if (this.allSelected_team) {
      this.select2.options.forEach((item: any) => item.select());
    } else {
      this.select2.options.forEach((item: any) => item.deselect());
    }
  }
  optionClickTeam() {
    let newStatus = true;
    this.select2.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_team = newStatus;
  }

  clearFilters() {
    // clear the filters
    this.getMIOutput();
    this.selectedFunction = "All";
    this.selectedTeam = "All";
    this.getFunctionsAndTeams();
    this.cdr.markForCheck();
  }

  overAllCounts() {
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        this.managerCount = 0;
        this.memberCount = 0;
        for (let r of res) {
          let fnObject: any;
          if (this.functionMap.has(r.functionName)) {
            fnObject = this.functionMap.get(r.functionName);
          } else {
            fnObject = {};
            fnObject.fte = 0;
            fnObject.ftc = 0;
            fnObject.teams = [];
          }
          for (let x of r.teams) {
            let object: any = {};
            object.functionName = r.functionName;
            object.teamName = x.teamName;
            object.fte = x.fteMembers + x.fteManagers;
            this.managerCount = this.managerCount + x.fteManagers;
            this.memberCount = this.memberCount + x.fteMembers;
            object.ftc =
              x.fteMembers * x.fteMemberCost + x.fteManagers * x.fteManagerCost;
            fnObject.fte = fnObject.fte + object.fte;
            fnObject.ftc = fnObject.ftc + object.ftc;

            fnObject.teams.push(x.teamName);
            this.teamMap.set(object.teamName, object);
          }
          this.functionMap.set(r.functionName, fnObject);
        }

        this.functionsCount = [...this.functionMap.keys()].length;
        this.teamsCount = [...this.teamMap.keys()].length;
        this.getMIOutput();
      },
      error: (error: any) => {
        console.log("get counts error", error);
      },
    });
  }

  processMiData() {
    console.log("----New output ------");
    let avgMi = 0;
    let avgTotal = 0;

    let categoryMap = new Map();
    let subCategoryMap = new Map();
    let lensMap = new Map();
    let tableMap = new Map();

    for (let d of this.data) {
      let funProcessThis = false;

      if (
        (this.selectedFunctions.includes(d.clientFunction) ||
          this.selectedFunctions.includes("All")) &&
        (this.selectedTeams.includes(d.teamName) ||
          this.selectedTeams.includes("All"))
      ) {
        funProcessThis = true;
      }

      if (funProcessThis) {
        for (let q of d.managerInterviews.questions) {
          // Overall Avg

          avgMi = avgMi + parseFloat(q.answer) * parseFloat(d.total);
          avgTotal = avgTotal + d.total;

          //Process Category
          let object: any = {};
          if (categoryMap.has(q.category)) {
            object = categoryMap.get(q.category);
            object.count = object.count + d.total;
            object.value =
              parseFloat(object.value) +
              parseFloat(q.answer) * parseFloat(d.total);
          } else {
            object.count = d.total;
            object.value = parseFloat(q.answer) * parseFloat(d.total);
          }
          categoryMap.set(q.category, object);

          //Process Sub-Category
          let teamMap = new Map();
          object = {};
          if (subCategoryMap.has(q.subCategory)) {
            teamMap = subCategoryMap.get(q.subCategory);
            if (teamMap.has(d.teamName)) {
              object = teamMap.get(d.teamName);
              object.count = object.count + d.total;
              object.value =
                parseFloat(object.value) +
                parseFloat(q.answer) * parseFloat(d.total);
            } else {
              object.count = d.total;
              object.value = parseFloat(q.answer) * parseFloat(d.total);
            }
            teamMap.set(d.teamName, object);
          } else {
            object.count = d.total;
            object.value = parseFloat(q.answer) * parseFloat(d.total);
            teamMap.set(d.teamName, object);
          }

          subCategoryMap.set(q.subCategory, teamMap);

          //Process Lens
          teamMap = new Map();
          object = {};

          if (lensMap.has(q.lens)) {
            teamMap = lensMap.get(q.lens);
            if (teamMap.has(d.teamName)) {
              object = teamMap.get(d.teamName);
              object.category = q.category;
              object.count = object.count + d.total;
              object.value =
                parseFloat(object.value) +
                parseFloat(q.answer) * parseFloat(d.total);
            } else {
              object.count = d.total;
              object.category = q.category;
              object.value = parseFloat(q.answer) * parseFloat(d.total);
            }
            teamMap.set(d.teamName, object);
          } else {
            object.count = d.total;
            object.category = q.category;
            object.value = parseFloat(q.answer) * parseFloat(d.total);
            teamMap.set(d.teamName, object);
          }

          lensMap.set(q.lens, teamMap);

          //Process teams table
          object = {};
          if (tableMap.has(d.teamName)) {
            object = tableMap.get(d.teamName);
            object.count = object.count + d.total;
            object.value =
              parseFloat(object.value) +
              parseFloat(q.answer) * parseFloat(d.total);
          } else {
            object.count = d.total;
            object.value = parseFloat(q.answer) * parseFloat(d.total);
          }

          tableMap.set(d.teamName, object);
        }
      }
    }

    this.average = (avgMi / avgTotal).toFixed(1);

    //Mapping all outputs

    //Team table

    //teamTable

    let loopTeams = [];

    if (this.selectedTeams.includes("All")) {
      loopTeams = [...this.teamMap.keys()];
    } else {
      loopTeams = this.selectedTeams;
    }
    this.dataTable = [];

    for (let team of loopTeams) {
      let obj: any = {};
      let dataObj = this.teamMap.get(team);
      obj["Team"] = team;
      obj["Function"] = dataObj.functionName;
      obj["FTE"] = dataObj.fte;
      obj["Total FLC"] = dataObj.ftc;

      let value = 0;
      let count = 0;
      for (let key of subCategoryMap.keys()) {
        let catObj = subCategoryMap.get(key);
        let xTeamObj = catObj.get(team);
        console.log(xTeamObj);
        if (!xTeamObj) {
          obj[key] = 0;
        } else {
          value = xTeamObj.value + value;
          count = count + xTeamObj.count;
          obj[key] = xTeamObj.value / xTeamObj.count;
        }
      }

      if (value / count) {
        obj["Maturity Assessment Score"] = (value / count).toFixed(1);
        this.dataTable.push(obj);
      }
    }

    console.log("tableofteams", this.dataTable);

    let categories = [];
    // for (let key of categoryMap.keys()) {
    //   let obj: any = {};
    //   let dat = categoryMap.get(key);
    //   obj.name = key;
    //   obj.value = dat.value / dat.count;
    //   categories.push(obj);
    // }

    for (let key of this.chartCategoryScore.keys()) {
      let catObj: any = {};
      catObj.name = key;
      if (categoryMap.has(key)) {
        let dat = categoryMap.get(key);
        catObj.value = dat.value / dat.count;
        categories.push(catObj);
      } else {
        catObj.value = 0;
        categories.push(catObj);
      }
      this.chartCategoryScore.set(key, catObj.value);
    }

    //Sub categories

    this.subCatTable = [];

    console.log("SubcategoryMap,", subCategoryMap);

    for (let subCat of subCategoryMap.keys()) {
      let subObj: any = {};
      subObj.subCategory = subCat;
      let cSum = 0;
      let cTot = 0;
      let teamData = subCategoryMap.get(subCat);
      for (let t of teamData.keys()) {
        let tDat = teamData.get(t);
        cSum = cSum + tDat.value;
        cTot = cTot + tDat.count;
        subObj[t] = (tDat.value / tDat.count).toFixed(1);
      }
      subObj.value = (cSum / cTot).toFixed(1);
      this.subCatTable.push(subObj);
    }

    let keyData: any = [];
    let valueData: any = [];
    this.subCatTable.forEach((res) => {
      keyData.push(res.subCategory);
      valueData.push(res.value);
    });

    console.log("KV", keyData, valueData);

    this.initiateRadar([{ name: "All", data: valueData }], keyData);
    this.cdr.markForCheck();

    this.lensColumns = [];
    this.lensColumns.push("Lens");
    this.lensColumns.push(...this.teamMap.keys());
    this.lensColumns.push("Average Score");

    this.lensTable = [];

    for (let lens of lensMap.keys()) {
      let subObj: any = {};
      subObj["Lens"] = lens;
      let cSum = 0;
      let cTot = 0;
      let teamData = lensMap.get(lens);
      for (let t of teamData.keys()) {
        let tDat = teamData.get(t);
        cSum = cSum + tDat.value;
        cTot = cTot + tDat.count;
        subObj[t] = (tDat.value / tDat.count).toFixed(1);
      }
      subObj["Average Score"] = (cSum / cTot).toFixed(1);
      this.lensTable.push(subObj);
    }

    this.lensTable2 = this.lensTable;
    this.initiateSingleChart();
    this.cdr.markForCheck();

    this.categories = categories;

    // Assigning categories to circle chart below

    let resulttt: any = [];

    this.categories.forEach((ele: any, index: any) => {
      console.log(ele);

      const originalObject: any = {
        series: "",
        name: "",
      };
      //console.log(originalObject);

      originalObject.series = ele.value.toFixed(1);
      originalObject.name = ele.name;

      resulttt.push(originalObject);
      //console.log('CCCCCC||',resulttt)
    });
    this.chartDataArray = resulttt;
    console.log("CCCCCC||", this.chartDataArray);

    //   this.selectedChartValues.push(valueData)

    //   this.selectedTeams.forEach((ele:any) => {
    //     chartValues = [];
    //     this.selectedChartValues.forEach((cv:any) => {
    //     chartValues.push({name:ele,data:cv})
    //   });
    // });

    this.cdr.markForCheck();
  }

  getClientDatas(tableEntries: any) {
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        console.log("getClientDatas :----->", res);

        let teamMap = new Map();

        for (let fn of res) {
          for (let tm of fn.teams) {
            let obj: any = {};
            obj.function = fn.functionName;
            obj.fte = parseInt(tm.fteManagers) + parseInt(tm.fteMembers);
            obj.ftc =
              parseInt(tm.fteManagers) * parseInt(tm.fteManagerCost) +
              parseInt(tm.fteMembers) * parseInt(tm.fteMemberCost);
            teamMap.set(tm.teamName, obj);
          }
        }
        let newTableEntries = [];
        for (let entry of tableEntries) {
          if (teamMap.has(entry.name)) {
            let obj = teamMap.get(entry.name);
            obj.avg = entry.avg;
            obj.name = entry.name;
            newTableEntries.push(obj);
          }
        }
        console.log("tb:", newTableEntries);
        this.dataTable = newTableEntries;

        if (this.isSaved) this.saveTable = this.dataTable;
        if (this.isClicked) this.dataTable = this.saveTable;
        console.log("ST", this.saveTable);
        this.isSaved = false;
        this.cdr.markForCheck();

        // // dataTable currently having only 2 fields team & ma-score
        // //  & res having total flc

        // res.forEach((ele: any) => {
        //   console.log(ele);

        //   this.dataTable.forEach((ele2: any) => {
        //     if (ele.clientFunction === ele2.clientFunction) {
        //       ele.teams.forEach((data: any) => {
        //         ele["totalFlcManage"] = data.fteMemberCost * data.fteMembers;
        //         ele["totalFlcMember"] = data.fteManagerCost * data.fteManagers;
        //         //Find totalFTE
        //       });
        //     }
        //   });
        // });

        // res.forEach((final: any) => {
        //   final["totalFLC"] = final.totalFlcManage + final.totalFlcMember;
        // });

        // console.log("RES>>>>>>>", res, this.dataTable);

        // merge res & dataTable to get the table data..!!

        this.cdr.markForCheck();
      },
      error: (error: any) => {
        console.log("get counts error", error);
      },
    });
  }

  //New way

  getColor(cat: any) {
    let index = this.subCategoryButtons.indexOf(cat);
    let len = this.colors.length;
    if (index > len) {
      index = index / len;
    }
    return this.colors[index];
  }

  lensCheck(header: any): any {
    if (this.highlightedHeaders.includes(header)) {
      return true;
    } else {
      return false;
    }
  }

  isRowSelected(row: any) {
    let key = row.Team ? row.Team : row.Function;
    return this.highlightedHeaders.includes(key);
  }

  onRowClick(row: any) {
    let key = row.Team;
    if (this.highlightedHeaders.includes(key)) {
      this.highlightedHeaders = this.highlightedHeaders.filter((x) => x != key);
    } else {
      this.highlightedHeaders = this.highlightedHeaders.filter(
        (x) => x != "Average Score"
      );
      this.highlightedHeaders.push(key);
      this.highlightedHeaders.push("Average Score");
    }
    this.renderCharts();
    this.renderLensTable();
  }

  renderCharts() {
    if (this.highlightedHeaders.length == 2) {
      let keyData: any = [];
      let valueData: any = [];
      this.subCatTable.forEach((res) => {
        keyData.push(res.subCategory);
        valueData.push(res.value);
      });

      console.log("KV", keyData, valueData);

      this.initiateRadar([{ name: "All", data: valueData }], keyData);
      this.cdr.markForCheck();
    } else {
      let series: any = [];
      let keyData: any = [];

      console.log(this.subCatTable);

      this.subCatTable.forEach((res) => {
        keyData.push(res.subCategory);
      });

      for (let head of this.highlightedHeaders) {
        let obj: any = {};
        if (this.teamMap.has(head)) {
          obj.name = head;
          let data: any = [];
          let val = 0;
          let tot = 0;
          for (let c of keyData) {
            for (let sc of this.subCatTable) {
              if (sc[head] && c == sc.subCategory) {
                val = val + parseFloat(sc[head]);
                tot = tot + 1;
              }
            }
            if (tot > 0) {
              data.push(val / tot);
            } else {
              data.push(undefined);
            }
            obj.data = data;
          }
          series.push(obj);
        }

        console.log(series);

        this.initiateRadar(series, keyData);
        this.cdr.markForCheck();
      }
    }
  }

  getBarColor(value: number): string {
    const fillValue = Math.min(value / 5, 1) * 100;
    return `linear-gradient(90deg, black ${fillValue}%, white ${
      100 - fillValue
    }%)`;
  }

  functionSwitch = false;
  renderLensTable() {
    if (this.highlightedHeaders.length == 2) {
      this.lensColumns = [];
      this.lensColumns.push("Lens");
      this.lensColumns.push("Average Score");
      this.lensTable = this.lensTable2;
    } else {
      this.lensColumns = [];
      let data: any = [];
      this.lensColumns = [];
      // this.lensColumns.push("Lens");
      console.log(this.highlightedHeaders);
      this.lensColumns.push(...this.highlightedHeaders);

      // if (this.functionSwitch) {
      //   this.lensColumns.push(...this.functionMap.keys());
      //   this.lensColumns.push("Average Score");
      // } else {
      //   this.lensColumns.push(...this.teamMap.keys());
      //   this.lensColumns.push("Average Score");
      // }

      // let lensAvgMag = new Map();

      // console.log("ssdsd");
      // for (let len of this.lensTable2) {
      //   console.log(len);
      // }

      // for (let len of this.lensTable2) {
      //   let obj: any = {};
      //   obj["Lens"] = len["Lens"];
      //   let val = 0;
      //   let tot = 0;

      //   for (let head of this.highlightedHeaders) {
      //     if (this.functionSwitch) {
      //       if (this.functionMap.has(head)) {
      //         let fn = this.functionMap.get(head);
      //         let tVal = 0;
      //         let tTot = 0;
      //         for (let t of fn.teams) {
      //           if (len[t]) {
      //             tVal = tVal + parseFloat(len[t]);
      //             tTot = tTot + 1;
      //           }
      //         }
      //         val = val + tVal;
      //         tot = tot + tTot;
      //         obj[head] = tTot > 0 ? (tVal / tTot).toFixed(1) : undefined;
      //       }
      //     } else {
      //       if (this.teamMap.has(head)) {
      //         if (len[head]) {
      //           obj[head] = len[head];
      //           val = val + parseFloat(len[head]);
      //           tot = tot + 1;
      //         }
      //       }
      //     }
      //   }
      //   obj["Average Score"] = tot > 0 ? (val / tot).toFixed(1) : undefined;
      //   data.push(obj);
      // }
      this.lensTable = this.lensTable2;
    }
  }

  downloadData() {
    this.surveyService
      .downloadData(this.clientId, "managerInterviews")
      .subscribe(
        (data: Blob) => {
          console.log("--- surveyResponse output ---");
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = "output.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error: any) => {
          console.log("Error while getting survey Outputs: ", error);
        }
      );
  }
}
