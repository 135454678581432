<!-- <div class="search">
    <mat-form-field>
        <mat-label>Select Questions to Add</mat-label>
        <input matInput [formControl]="searchInputText" #autocompleteTrigger="matAutocompleteTrigger"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" showPanel="true"
            (optionsScroll)="onScroll()">
            <mat-option *ngFor="let item of allRefQuestion; let i = index" [value]="item">
                <mat-checkbox color="primary" (click)="checkbox(i,item)" [checked]="item.selected"> </mat-checkbox>
                {{item.question}}

            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<div class="selected" *ngIf="questions.length > 0">
    <mat-label>Selected Questions {{questions.length}} </mat-label>
    <div *ngFor="let item of questions; let i = index">
        <span *ngIf="item.selected">
            {{i + 1}}.{{item.question}}
        </span>
    </div>

</div> -->



<div class="search">
    <section class="scroll" *ngIf="dataSource.length >0">
        <table mat-table [dataSource]="dataSource" class="bfd">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    Select
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="selectRow(row)" [checked]="isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="activity">
                <span *ngIf="data === 'AVA'">
                    <th mat-header-cell *matHeaderCellDef> Activity </th>
                </span>
                <span *ngIf="data !== 'AVA'">
                    <th mat-header-cell *matHeaderCellDef> Question </th>
                </span>        
                <th mat-header-cell *matHeaderCellDef> Activity </th>
                <td mat-cell *matCellDef="let element"> {{element?.question ?? '-'}} </td>
            </ng-container>
    
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>Category </th>
                <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </section>
    <mat-card *ngIf="dataSource.length === 0" class="textCenter">
        <span><mat-icon>check_circle_outline</mat-icon> &nbsp; All the questions has been added the template</span>
    </mat-card>
</div>
<br>
<section class="foot row" *ngIf="dataSource.length >0">
    <button class="active-solid-lrg white fit" color="warn" (click)="cancel()">Cancel</button>
    <button class="active-solid-lrg fit" color="primary" (click)="loadQuestions()">Load</button>
</section>