import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = localStorage.getItem("id_token");
    var role = localStorage.getItem("encodedRole");

          request = request.clone({
        withCredentials : true,
        setHeaders: {
          // "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, OPTIONS, PUT, PATCH, DELETE",
          "Access-Control-Allow-Headers": "X-Requested-With,content-type",
          "X-Access-Token": `${token}`,
          "X-Access-Role" : `${role}`
        },
      });
  
      return next.handle(request);
  }
}
