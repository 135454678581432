export const data = [
  {
    team: "Team-A",
    total: 10,
    functionName: "Risk",
    manager: true,
    ava: {
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 15,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 16,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ]
    },
  },
  {
    teamName: "Team-A",
    total: 10,
    clientFunction: "Risk",
    isManager: false,
    ava:{
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 1.0,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 1.5,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 1.6,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ]
    },

  },
  {
    team: "Team-B",
    functionName: "Risk",
    total: 5,
    manager: true,
    ava:{
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 15,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 16,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ]
    },
  },
  {
    team: "Team-B",
    functionName: "Risk",
    total: 15,
    manager: false,
    ava: { 
      questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ]}
   ,
  },
  {
    team: "Team-C",
    total: 5,
    functionName: "Center",
    manager: true,
    ava: { 
      questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ]},
   
  },
  {
    team: "Team-C",
    total: 15,
    functionName: "Center",
    manager: false,
    ava:{
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 15,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 16,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ]
    },
  
  },
  {
    team: "Team-D",
    functionName: "Back",
    total: 5,
    manager: true,
    ava:{
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 15,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 16,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ]
    }
 
  },
  {
    team: "Team-D",
    functionName: "Back",
    total: 15,
    manager: false,
    ava:{
      questions: [
        {
          Activity: "Complaince",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "First Aid",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 10,
        },
        {
          Activity: "Following Standard Processes",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 15,
        },
        {
          Activity: "Core work",
          Type: "Core",
          TimeCategory: "Core Activity",
          Answer: 16,
        },
        {
          Activity:
            "Escalations (e.g. Any problems that require escalating for support)",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 5,
        },
        {
          Activity: "Archiving",
          Type: "Non Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 15,
        },
        {
          Activity: "Archiving",
          Type: "Meetings",
          TimeCategory: "Value Add",
          Answer: 10,
        },
        {
          Activity: "Archiving",
          Type: "Reports",
          TimeCategory: "Value Add",
          Answer: 15,
        },
        {
          Activity: "Team Manager Support - Cover",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 4,
        },
        {
          Activity: "Team Manager Support - Other",
          Type: "Value Add",
          TimeCategory: "Non Core Activity",
          Answer: 13,
        },
      ],
    },
 
  },
];

export const avaDataCopy = [
  [
    {
        teamName: "Team Jun 22",
        clientFunction: "Administrative/Clerical",
        isManager: true,
        total: 10,
        count: 2,
        ava: {
            questions: [
                {
                    Answer: 14,
                    Activity: "Compliance",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 20,
                    Activity: "First Aid",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 3,
                    Activity: "Following Standard Processes",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 6.5,
                    Activity: "Core work",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Escalations (e.g. Any problems that require escalating for support)",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 5,
                    Activity: "Archiving",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 1.5,
                    Activity: "Meetings",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Reports",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Team Manager Support - Cover",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Team Manager Support - Other",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Work Allocation",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Chasing/ Rework / Fixing Issues",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Stepping In To Execute Activities Assigned To Your Team",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Covering For Other Managers",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Emails / Admin",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "IT / Technical Issues",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Reactively Addressing Issues / Firefighting",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Internal / External Audit",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Other Non Core Activities",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Ad Hoc Meetings",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Other",
                    Type: "Non Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Recognition",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Performance 1-2-1 and Development",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Supporting The Workload Of Other Teams Besides Your Home Team",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Process Guide Review / Updating Procedures",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Project - Meetings",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Project - SME",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Project - Testing",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Training (Including training of new starters, formalised, classroom based)",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Problem Solving and Process Improvement Activities",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Personal Development Time For You Rather Than Developing Your Team Members",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Customer Facing Time",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Coaching",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Huddles / Stand-ups (Quick 15-20 min daily meetings with your team)",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Capacity Planning (Daily, weekly, monthly)",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Project Work Outside of Standard Processes",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Team Briefings",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Reading Important Information On Process/ Policy Changes",
                    Type: "Value Add",
                    TimeCategory: "Non Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Core Work",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                },
                {
                    Answer: 0,
                    Activity: "Jun22AVA",
                    Type: "Core",
                    TimeCategory: "Core Activity"
                }
            ]
        },
        // eva: {
        //     questions: [
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Training & development plans",
        //             "questionId": "ES1",
        //             "question": "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 0,
        //                 "1": 2,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Customer & Purpose",
        //             "subCategory": "Value",
        //             "lens": "Understanding customer value",
        //             "questionId": "ES2",
        //             "question": "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
        //             "answer": 1.5,
        //             "ratingCount": {
        //                 "0": 0,
        //                 "1": 1,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Customer & Purpose",
        //             "subCategory": "Value",
        //             "lens": "Understanding customer value",
        //             "questionId": "ES3",
        //             "question": "I am clear about the purpose and value of our work.",
        //             "answer": 2,
        //             "ratingCount": {
        //                 "0": 0,
        //                 "1": 1,
        //                 "2": 0,
        //                 "3": 1,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Customer & Purpose",
        //             "subCategory": "Value",
        //             "lens": "Customer feedback",
        //             "questionId": "ES4",
        //             "question": "We regularly use customer feedback to reflect on how to improve the quality of our work.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Customer & Purpose",
        //             "subCategory": "Value",
        //             "lens": "Understanding customer value",
        //             "questionId": "ES5",
        //             "question": "I understand clearly how my actions impact customer service.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Customer & Purpose",
        //             "subCategory": "Value",
        //             "lens": "Customer feedback",
        //             "questionId": "ES6",
        //             "question": "We resolve customer problems quickly and effectively.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Intelligent Automation and Process",
        //             "subCategory": "Process Flow",
        //             "lens": "Visibility of processes and ownership",
        //             "questionId": "ES7",
        //             "question": "Handovers between our team and other teams are smooth and there is minimal rework required.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Intelligent Automation and Process",
        //             "subCategory": "Process Flow",
        //             "lens": "Systems enablement / workflow",
        //             "questionId": "ES8",
        //             "question": "I am able to use modern digital technology to improve the way I work.",
        //             "answer": 2,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 1,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Demand and capacity management",
        //             "questionId": "ES9",
        //             "question": "Forecasting is accurate and there is a clear feedback loop to better inform resource planning.",
        //             "answer": 2,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 1,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Intelligent Automation and Process",
        //             "subCategory": "Process Flow",
        //             "lens": "Visibility of processes and ownership",
        //             "questionId": "ES10",
        //             "question": "Everyone in my team follows the same steps and methods, completing tasks by following best practice.",
        //             "answer": 1.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 1,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES11",
        //             "question": "I would recommend my employer as a great place to work and would go beyond what is expected to ensure the business' success.",
        //             "answer": 1.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 1,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Successes captured",
        //             "questionId": "ES12",
        //             "question": "I am recognised in a way that motivates me.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Leadership",
        //             "lens": "Role models and behaviors",
        //             "questionId": "ES13",
        //             "question": "Local leadership are visible and good workplace role models.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Coaching / 1-1s",
        //             "questionId": "ES14",
        //             "question": "I receive regular support and coaching that helps me improve my performance.",
        //             "answer": 0.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 1,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Awareness & visibility of skills",
        //             "questionId": "ES15",
        //             "question": "This last year, I have had opportunities at work to learn and grow.",
        //             "answer": 0.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 1,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES16",
        //             "question": "I am totally satisfied with my working life.",
        //             "answer": 2.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 1
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES17",
        //             "question": "I am encouraged to participate in activities that enhance departmental productivity.",
        //             "answer": 2.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 1
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Deskside coaching",
        //             "questionId": "ES18",
        //             "question": "My line manager and I have actively shaped my role so that it contributes to my long term development objectives.",
        //             "answer": 2,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 1,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Training & development plans",
        //             "questionId": "ES19",
        //             "question": "I can see the link between my personal goals and the direction of the business.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Capability",
        //             "lens": "Training & development plans",
        //             "questionId": "ES20",
        //             "question": "I can see the link between my goals and the direction of the business.",
        //             "answer": 1.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 1,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Performance",
        //             "subCategory": "Vision & KPIs",
        //             "lens": "KPIs",
        //             "questionId": "ES21",
        //             "question": "Team performance and feedback is visible to me and looked at regularly.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Working styles",
        //             "questionId": "ES22",
        //             "question": "My team collaborates well with each other, other teams, and are committed to doing quality work.",
        //             "answer": 0.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 1,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Leadership",
        //             "lens": "Senior management visibilty",
        //             "questionId": "ES23",
        //             "question": "My manager effectively balances workload and equips the team with the correct materials to work effectively.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Performance",
        //             "subCategory": "Vision & KPIs",
        //             "lens": "Targets",
        //             "questionId": "ES24",
        //             "question": "In my team, we communicate openly with each other about problems and concerns.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Performance",
        //             "subCategory": "Vision & KPIs",
        //             "lens": "KPIs",
        //             "questionId": "ES25",
        //             "question": "I use metrics that help me understand the performance of the work I deliver.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES26",
        //             "question": "My supervisor, or someone at work, seems to care about me as a person and encourages my development.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Successes captured",
        //             "questionId": "ES27",
        //             "question": "In the last seven days, I have received recognition or praise for doing good work.",
        //             "answer": 0,
        //             "ratingCount": {
        //                 "0": 2,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES28",
        //             "question": "At work, I have the opportunity to do what I do best every day.",
        //             "answer": 0.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 1,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES29",
        //             "question": "I have a best friend at work.",
        //             "answer": 1,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 1,
        //                 "3": 0,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "People & Capability",
        //             "subCategory": "Motivation & Engagement",
        //             "lens": "Colleague engagement",
        //             "questionId": "ES30",
        //             "question": "The mission or purpose of my company makes me feel my job is important.",
        //             "answer": 1.5,
        //             "ratingCount": {
        //                 "0": 1,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 1,
        //                 "4": 0,
        //                 "5": 0
        //             }
        //         },
        //         {
        //             "category": "Intelligent Automation and Process",
        //             "subCategory": "Process Flow",
        //             "lens": "Customer feedback",
        //             "question": "A Eva Question added for testing..??",
        //             "answer": 4.5,
        //             "ratingCount": {
        //                 "0": 0,
        //                 "1": 0,
        //                 "2": 0,
        //                 "3": 0,
        //                 "4": 1,
        //                 "5": 1
        //             }
        //         }
        //     ]
        // }
    }
]
]

export const eva = [
  {
    team: "Team-A",
    total: 10,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: true,
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 2,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 2,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-A",
    total: 20,
    functionName: "Risk",
    ratingCount: {
      one: 2,
      two: 5,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: false,
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-B",
    total: 10,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: true,
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-B",
    total: 20,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: false,
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-C",
    total: 10,
    functionName: "Accounts",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-C",
    total: 20,
    functionName: "Accounts",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-D",
    total: 10,
    functionName: "Accounts",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-D",
    total: 20,
    functionName: "Accounts",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-E",
    total: 10,
    functionName: "Security",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-E",
    total: 20,
    functionName: "Security",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-F",
    total: 10,
    functionName: "Security",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    team: "Team-F",
    total: 20,
    functionName: "Security",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    eva: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
];

export const managerInterview = [
  {
    teamName: "Team-A",
    total: 10,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: true,
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 2,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 2,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-A",
    total: 20,
    functionName: "Risk",
    ratingCount: {
      one: 2,
      two: 5,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: false,
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-B",
    total: 10,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: true,
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-B",
    total: 20,
    functionName: "Risk",
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    manager: false,
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-C",
    total: 10,
    functionName: "Accounts",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-C",
    total: 20,
    functionName: "Accounts",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-D",
    total: 10,
    functionName: "Accounts",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-D",
    total: 20,
    functionName: "Accounts",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-E",
    total: 10,
    functionName: "Security",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-E",
    total: 20,
    functionName: "Security",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-F",
    total: 10,
    functionName: "Security",
    manager: true,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.6,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.8,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 4.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 3.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
  {
    teamName: "Team-F",
    total: 20,
    functionName: "Security",
    manager: false,
    ratingCount: {
      one: 3,
      two: 4,
      three: 1,
      four: 1,
      five: 1,
    },
    managerInterviews: {
      questions: [
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 2.2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "People & Capability",
          subCategory: "Capability",
          lens: "Training & development plans",
          questionId: "ES1",
          question:
            "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
          answer: 2.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.5,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 1.9,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Customer & Purpose",
          subCategory: "Value",
          lens: "Understanding customer value",
          questionId: "ES2",
          question:
            "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
          answer: 3.3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 4.1,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Intelligent Automation and Process",
          subCategory: "Process Flow",
          lens: "Visibility of processes and ownership",
          questionId: "ES7",
          question:
            "Handovers between our team and other teams are smooth and there is minimal rework required.",
          answer: 1.4,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 3,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
        {
          category: "Performance",
          subCategory: "Vision & KPIs",
          lens: "KPIs",
          questionId: "ES21",
          question:
            "Team performance and feedback is visible to me and looked at regularly.",
          answer: 2,
          ratingCount: {
            one: 3,
            two: 4,
            three: 1,
            four: 1,
            five: 1,
          },
        },
      ],
    },
  },
];
