import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponents implements OnInit {

  @Input() chartDataArray: ChartComponent | any;
  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log(this.chartDataArray);
    this.cdr.markForCheck()
    
  }

}
