<br>

<div style="display: flex;
justify-content: end;
margin-right: 6em;">
  <button class="active-stroke-lrg" style="width: 10em;padding: 8px 4px;" (click)="goTo()">Add New +</button>
</div>
<ul class="flex-container">
  <li class="flex-container">

    <section class="section">
      <mat-card class="tag-questions">
        <mat-list>
          <div mat-subheader>
            <h2 class="qtitle" >{{templateDetail.templateName}}</h2>
          </div>
          <br>  
          <div *ngFor="let data of templateDetail.questions">
            <mat-list-item>
              <!-- <div mat-line>{{data.refId}}</div> -->
              <div mat-line>{{data}}</div>
              <mat-icon mat-list-icon mat-ripple (click) ='delete()'>delete_forever</mat-icon>
            </mat-list-item>
            <!-- <mat-divider></mat-divider> -->
          </div>
        </mat-list>
      </mat-card>
    </section>
  </li>
</ul>

  <br>
  <section class="clientab">
    <div class="basic-container">
        <div class="custom-pad">
                <div class="example-container">
                    <div class="three-buttons">
                      <button class="active-solid-lrg" (click)="back()">Back</button>
                        <button class="active-stroke-lrg cancel">Save</button> &nbsp;
                    </div>
                </div>
          </div>
    </div>
  </section>