<mat-card class="scroll-container">
    <mat-card-content>
        <button   *ngFor="let team of teams; let i = index" 
        class="scroll-item" 
        [class.active]="i === activeTeamIndex"
        (click)="handleTeamClick(i)" class="scroll-item">{{ team }}</button>
    </mat-card-content>
  
</mat-card>

<br>
<br>

<mat-card>
      <table class="my-table">
        <tr>
          <ng-container *ngFor="let header of displayedColumns; let i = index">
            <th [style.width]="columnWidths[i]">{{ header }}</th>
          </ng-container>
        </tr>
        <ng-container *ngFor="let row of dataSource2; let i = index">
          <tr>
            <ng-container *ngFor="let header of displayedColumns; let j = index">
              <!-- <td *ngIf="header !== 'PwC Score' && !isMergedCell(i, j); else editableColumn" [attr.rowspan]="getRowspan(i, j)" 
              [class.center-align]="j > 2" [class.full-cat] = " j < 3" [style.background-color]="getColor(row['Category'])"> -->
                <!-- <td *ngIf="header !== 'PwC Score' ; else editableColumn"  [class.center-align]="j > 2">
                  <div class="full-cat" *ngIf="j < 3" [style.background-color]="getColor(row['Category'])">   {{ row[header] }} </div>
                  <div *ngIf="j > 3" >   {{ row[header] }} </div>
               </td> -->
               <td *ngIf="j < 3" [style.background-color]="getColor(row['Category'])" > <div *ngIf="header !== 'PwC Score' ; else editableColumn"  [class.center-align]="j > 2">
                {{ row[header] }}
               </div> </td>
               <td *ngIf="j > 2" [style.background-color]="getValueColor(row[header], header)" [attr.rowspan]="getRowSpan(header)"> <div *ngIf="header !== 'PwC Score'; else editableColumn" [class.center-align]="j > 2">
                {{ row[header] }}
               </div> </td>

              <ng-template #editableColumn >
                <td *ngIf="header === 'PwC Score'" [class.center-align]="j > 2">
                  <!-- <label *ngIf="checkForWarning(row)" style="color: red">Input Score*</label> -->
                  <mat-form-field appearance="outline" style="width: 6vw;height: 2vh;">
                    <mat-label>Input Score*</mat-label> 
                    <input matInput type="number" min="1" max="5" maxlength="1" (ngModelChange)="validateInput($event,row)"
                    [(ngModel)]="row[header]"  >
                  </mat-form-field>
                  <!-- <input class="transparent-input" type="number"  min="0" max="5" [(ngModel)]="row[header]" (ngModelChange)="onEditableColumnEdit($event, row) "  > -->
                </td>
              </ng-template>
            </ng-container>
          </tr>
        </ng-container>
      </table>
</mat-card>
<br>

<div class="button-container">
  <div class="keys">Keys : </div>
  <button class="cat-button" *ngFor="let category of categories.keys()" [style.background-color]="getColor(category)"  
  (click)="onButtonClick(category)">
    {{ category }}
  </button>
</div>

<br>
<br>

<div *ngIf="spinner" class="overlay" style="left: 0em !important;">
  <mat-spinner class="spinner"></mat-spinner>
</div>