import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { of } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { FocusGroupsService } from "@app/projects-v2/focus-groups/focus-groups.service";
import { SurveyService } from "@app/survey/survey.service";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-focus-groups-survey-page",
  templateUrl: "./focus-groups-survey-page.component.html",
  styleUrls: ["./focus-groups-survey-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FocusGroupsSurveyPageComponent implements OnInit {
  selected: any = 0;
  time: boolean;
  submitted: any = false;
  started: boolean = false;
  timeline: any = [1, 2, 3, 4, 5];
  isAdmin: any;
  finalSubmit: boolean;
  adminDataShow: any = false;
  url: string;

  //Hover
  selectedIndex: any = 0;
  selectedRow: any;
  hoverContent: any = [];
  selectedHoverIndex: number = -1;

  show: boolean = true;
  teams: any = [];
  updateId: any = "";
  selectedTeam: any = "";
  selectedFunction: any = "";
  finalIndex: any;
  preview: boolean = true;
  stars: number[] = [1, 2, 3, 4, 5];
  val: string;
  ValueFromEvent: string;
  focusGroups: any = [];
  participantName = new FormControl("", Validators.required);
  over: boolean = false;

  ClientId: any;
  surveyDetailId: any;
  theSurveyId: any;
  TemplateId: any;
  FocusGroupsID: any;
  FocusGroupID: any;
  noResponse: boolean = false;

  //payloads
  createSurveyTemplatePayload: any;
  submitAnswersPayload: any;
  currentDateTime: any;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private focusGroupsService: FocusGroupsService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public datepipe: DatePipe
  ) {
    this.currentDateTime = this.datepipe.transform(new Date(), "dd/MM/yyyy");
    this.url = window.location.href;
  }

  ngOnInit(): void {
    this.countdown = 10; // Initial countdown value
    this.ClientId = sessionStorage.getItem("clientId") || "";
    this.surveyDetailId =
      this.route.snapshot.paramMap.get("surveyDetailId") || "";

    // this.isAdmin =
    this.TemplateId = sessionStorage.getItem("FgTemplateId") || "";
    let ID = sessionStorage.getItem("FocusGroupsID") || "";
    this.FocusGroupsID = ID.split(",");

    if (this.surveyDetailId) {
      this.preview = false;
      //this.getSurveyDetails();
      this.getTeams();
      // this.checkingForNextQuestion();
      this.cdr.markForCheck();
    } else {
      this.getTemplate(this.TemplateId);
      this.preview = true;
      this.getTeams();
      this.cdr.markForCheck();
    }

    // console.log('clientId :--',this.ClientId);
    // console.log('surveyDetailId :--',this.surveyDetailId,'preview>>>',this.preview);
    // console.log('managerInterviewTemplateId :--',this.miTemplateId);
    // console.log('interviewID :--',this.interviewID);

    //setting payload structure
    let futureDay = new Date();
    futureDay.setDate(futureDay.getDate() + 10);

    this.createSurveyTemplatePayload = {
      templates: [
        {
          module: "focusGroup",
          templateId: this.TemplateId,
        },
      ],
      clientId: this.ClientId,
      module: "focusGroup",
      startDate: new Date(),
      endDate: futureDay,
      focusGroupIds: this.FocusGroupsID,
    };
    this.getTeams();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    clearInterval(this.checkinPoint);
    clearInterval(this.dataCheck);
  }

  checkinPoint: any;
  checkingForNextQuestion() {
    this.checkinPoint = setInterval(() => {
      this.getCurrentQuestion(this.surveyDetailId);
    }, 2500);
  }

  //GetAnswersforAdmin
  dataCheck: any;
  answersGetAdmin() {
    this.dataCheck = setInterval(() => {
      this.getFocusGroups(sessionStorage.getItem("fgId"));
    }, 2000);
  }

  //AdminhoverComments
  hover: boolean;

  highlight(index: number, row: any) {
    console.log("highlight row", index, row);
    this.selectedIndex = index;
    this.selectedRow = row;
  }

  isContentVisible: boolean = false;

  showContent(ind: any, answer: any) {
    console.log("parent data", answer);

    this.selectedHoverIndex = ind;
    this.hoverContent = answer;
    this.hoverContent.forEach((element: any) => {
      console.log("element", element);
    });
    console.log("pind,ind", answer, this.hoverContent);
    if (answer.length > 0 || ind == 5) answer.show = true;
    this.isContentVisible = true;
  }

  hideContent(answer: any) {
    this.selectedHoverIndex = -1;
    this.hoverContent = this.selectedRow.answers;
    answer.show = false;
    this.isContentVisible = false;
  }

  getTeams() {
    this.surveyService.getFocusGroupTeams(this.surveyDetailId).subscribe(
      (res) => {
        console.log(res);
        this.teams = res;

        //Below data is for admin, need to get the data from API itself in-future.
        this.teams.push({
          functionName: "PwC Admin",
          fteMemberCost: 11,
          fteManagerCost: 112,
          fteMembers: 4,
          fteManagers: 2,
          teamName: "PwC Admin",
          customName: "PwC Admin",
        });
        //
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("--Error --", error);
      }
    );
  }

  begin() {
    if (this.preview === true) {
      // this.selected = 1;
    } else {
      console.log("Selected team is ", this.selectedTeam);

      if (this.selectedTeam !== "" && this.participantName.valid) {
        this.getSurveyDetails();
        if (this.selectedTeam === "PwC Admin") {
          console.log("Admin activated");
          this.getData();
          this.answersGetAdmin();
        }
        this.cdr.markForCheck();
      } else {
        this.snackBar.open("Kindly enter all the fields", "close", {
          duration: 3500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      }
    }
  }

  //Admin
  getData() {
    let fgID: any;
    this.focusGroupsService
      .getFG("focusGroup", this.ClientId, this.surveyDetailId)
      .subscribe({
        next: (res: any) => {
          console.log(res);
          res.forEach((ele: any) => {
            sessionStorage.setItem("fgId", ele.focusGroupId);
          });
          this.getFocusGroups(fgID);
        },
      });
  }

  getFocusGroups(id: any) {
    this.noResponse = false;
    this.focusGroupsService
      .getOutput1("focusGroup", this.ClientId, id)
      .subscribe({
        next: (res: any) => {
          console.log("Focus G API Output :----->", res);
          // console.log('T----->',this.adminDataShow,this.focusGroups[0]);
          this.adminDataShow = res;
          if (
            this.adminDataShow === "No Responses received" ||
            this.adminDataShow === "Please Setup the Survey for Focus Group"
          ) {
            this.adminDataShow = this.focusGroups;
            this.noResponse = true;
          }
          this.cdr.markForCheck();
          this.setOutput();

          this.cdr.markForCheck();
        },
        error: (error: any) => {
          console.log("get focus group error", error);
        },
      });
  }

  setOutput() {
    this.adminDataShow.forEach((element: any) => {
      if (element.average > 1 && element.average < 2) element.margin = 10;
      if (element.average > 2 && element.average < 3) element.margin = 18;
      if (element.average > 3 && element.average < 4) element.margin = 21;
      if (element.average > 4 && element.average < 5) element.margin = 23;
    });
    console.log("output :----->", this.adminDataShow);
    if (
      this.adminDataShow === "No Responses received" ||
      this.adminDataShow === "Please Setup the Survey for Focus Group"
    ) {
      this.isAdmin = false;
      this.show = false;
      this.selectedTeam = "";
      window.location.reload();
      this.snackBar.open("No Responses received", "close", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    } else {
      this.isAdmin = true;
      this.cdr.markForCheck();
    }

    this.adminDataShow.forEach((ele: any) => {
      let ratings: any = {
        one: [],
        two: [],
        three: [],
        four: [],
        five: [],
        six: [],
      };
      let questionRes: any;
      let Average: number = ele.average;
      console.log(ele);
      if (ele.answers) {
      }
      of(ele).forEach((value: any) => {
        if (value.answers) {
          value.answers.forEach((item: any) => {
            if (item.answer == 1) {
              ratings.one.push(item);
            }
            if (item.answer == 2) {
              ratings.two.push(item);
            }
            if (item.answer == 3) {
              ratings.three.push(item);
            }
            if (item.answer == 4) {
              ratings.four.push(item);
            }
            if (item.answer == 5) {
              ratings.five.push(item);
            }
          });
          let getArray: any = [];
          getArray = Object.values(ratings);
          //  console.log('ratings',ratings)
          //  console.log('assray',assray)

          ele.rating = [...getArray];
        }
      });
    });
    console.log("mockdataTimeline", this.adminDataShow);
    this.selectedRow = this.adminDataShow[this.selected - 1];
    this.hoverContent = this.selectedRow.answers
      ? this.selectedRow.answers
      : [];
    this.isAdmin = true;
  }

  getTemplate(id: any) {
    this.surveyService.getFocusGroupTemplate(id).subscribe(
      (res) => {
        //Assign
        this.focusGroups = res.data[0]["questions"].filter(
          (x: any) => x != null
        );
        console.log(this.focusGroups, this.focusGroups.length);

        this.focusGroups.forEach((elem: any, i: number) => {
          elem.answer = 0;
          elem.comments = "";
          elem.team = this.selectedTeam;
        });

        if (this.surveyDetailId) {
          this.checkingForNextQuestion(); // get index/position
        }

        console.log("questionslength:-", this.focusGroups.length);
        console.log("selected index from api:-", this.selected);
        console.log("selected index from api:-", this.finalIndex);

        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getSurveyDetails() {
    this.surveyService
      .getSurveyDetails("surveyDetailId", this.surveyDetailId, this.ClientId)
      .subscribe(
        (res) => {
          console.log("-- Get surveyDetail --", res, res[0]);
          this.ClientId = res[0].clientId;
          this.theSurveyId = res[0].surveyId;
          this.FocusGroupID = res[0].focusGroupId;
          this.getSurveyTemplate(); // get template ID
          this.cdr.markForCheck();
        },
        (error) => {
          console.log("-- surveyDetails Error --", error);
        }
      );
  }

  getSurveyTemplate() {
    this.surveyService.getSurveyById(this.theSurveyId).subscribe(
      (res) => {
        //console.log('!---->>', res.data[0].templates[0].templateId);
        this.TemplateId = res.data[0].templates[0].templateId;

        this.getTemplate(this.TemplateId);
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("--Error --", error);
      }
    );
  }

  createTemplate() {
    let data = this.createSurveyTemplatePayload;

    this.surveyService.createSurvey(data).subscribe(
      (res) => {
        this.snackBar.open("Survey Created Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.router.navigateByUrl(
          "/project/" + this.ClientId + "/focus-groups/responses"
        );
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submit(exactData: any) {
    let singleResponse: any = [];
    singleResponse.push(exactData);
    console.log("singleResponse>>", singleResponse);

    if (this.updateId.length > 0) {
      this.submitAnswersPayload = {
        surveyDetailId: this.surveyDetailId,
        surveyResponseId: this.updateId,
        clientId: this.ClientId,
        clientFunction: this.selectedFunction,
        teamName: this.selectedTeam,
        name: this.participantName.value,
        dataResponse: this.focusGroups,
        //dataResponse: singleResponse
      };
    } else {
      this.submitAnswersPayload = {
        surveyDetailId: this.surveyDetailId,
        clientId: this.ClientId,
        dataResponse: this.focusGroups,
        //dataResponse: singleResponse
      };
    }
    this.cdr.markForCheck();
    console.log(this.submitAnswersPayload);
    this.submitTemplate(this.submitAnswersPayload);
  }

  back() {
    this.router.navigateByUrl("/project/" + this.ClientId + "/focus-groups");
  }

  submitTemplate(payload: any) {
    console.log(payload, this.focusGroups);

    this.surveyService.submitMiSurvey(payload).subscribe(
      (res) => {
        console.log(this.finalIndex, this.focusGroups.length - 1);
        this.updateId = res;

        this.snackBar.open(
          "Kindly Submit and Wait for Admin to proceed",
          "close",
          {
            duration: 3500,
            horizontalPosition: "center",
            verticalPosition: "top",
          }
        );

        //At end of final question
        if (this.focusGroups.length - 1 === this.finalIndex) {
          //this.submitted = true;
          this.started = false;
          this.cdr.markForCheck();
        }

        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  countStar(question: any, star: any) {
    //console.log('eva star',star, question)
    question.answer = star;
    this.cdr.markForCheck();
  }

  getInput(event: any, item: any) {
    item.comments = event.target.value;
    //console.log(event.target.value);
    this.ValueFromEvent = event.target.value;
  }

  getCurrentQuestion(surveyDetailId: any) {
    this.surveyService.getFGQuestionIndex(surveyDetailId).subscribe(
      (res) => {
        this.selected = res.currentQuestion;
        console.log(res, this.selected);
        this.started = true;
        if (!this.isAdmin) {
          if (this.selected === this.focusGroups.length) {
            this.finalSubmit = true;
          }
        }
        if (this.selected === this.focusGroups.length + 1) {
          if (this.isAdmin) this.over = true;
          if (!this.isAdmin) this.submitted = true;
        }

        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  approveToNextQuestion(type: any) {
    //Increase FG count
    this.hoverContent = [];
    let Payload: any = {
      status: "Complete",
    };
    this.surveyService.increaseQuestionIndex({}, this.surveyDetailId).subscribe(
      (res) => {
        console.log("<><><><>", res);

        if (this.adminDataShow.length === this.selected) this.over = true;

        if (type === "final") {
          this.focusGroupsService
            .editFocusGroup(this.FocusGroupID, Payload)
            .subscribe(
              (res) => {
                console.log(res, "Status completed Success");
              },
              (error) => {
                console.log(error);
              }
            );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  set(type: any, data: any) {
    console.log("CCCCCC", data);
    if (data.answer !== 0) {
      this.finalIndex = this.selected; //set index to set final api call
      this.submit(data);
      this.getCurrentQuestion(this.surveyDetailId);

      if (this.selected === this.focusGroups.length) {
        this.finalSubmit = true;
      }
      this.cdr.markForCheck();

      if (type === "submit") this.submitted = true;
    } else {
      this.snackBar.open("Kindly rate the question..!", "close", {
        duration: 2200,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    }
  }

  assign(event: any) {
    this.selectedTeam = event;
    this.teams.forEach((ele: any) => {
      if (ele.teamName === event) {
        this.selectedFunction = ele.functionName;
      }
    });
    // if (this.selectedTeam === "PwC Admin") {
    //   this.isAdmin = true;
    //   this.over = false;
    // } else {
    //   this.isAdmin = false;
    //   this.over = false;
    // }
    //console.log(this.selectedFunction,this.selectedTeam);
    this.cdr.markForCheck();
  }

  close() {
    window.close();
  }

  goBack() {
    this.router.navigateByUrl(`/project/${this.ClientId}/focus-groups/setup2`);
  }

  //Waiting Countdown

  countdown: number;
  timerRef: any;

  startCountdown() {
    this.timerRef = setInterval(() => {
      this.countdown--;
      this.cdr.markForCheck();
      if (this.countdown === 0) {
        clearInterval(this.timerRef);
        this.countdown = 10;
        this.cdr.markForCheck();
        // Do something when the countdown reaches zero
      }
    }, 1000); // Update countdown every second (1000ms)
  }

  calculateMargin(ans: any) {
    if (ans.length > 0) {
      return (ans[0].answer / 5) * 100 - 15 + "%";
    } else {
      return 0;
    }
  }

  getAnswer(ans: any) {
    console.log("solid: ", ans[0].answer);
    return ans.length;
  }
}
