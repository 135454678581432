import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  constructor(private httpClient: HttpClient, private router: Router) {}
  private subjectClientId = new Subject<any>();

  clientUrl: string = environment.CLIENT_API_URL;
  apiUrl: string = environment.STATIC_API_URL;
  authUrl: string = environment.AUTH_API_URL;

  createClient(payload: any) {
    return this.httpClient.post<any>(`${this.clientUrl}client`, payload);
  }

  createOrganisation(payload: any) {
    return this.httpClient.post<any>(
      `${this.clientUrl}client/function`,
      payload
    );
  }

  getClienByClientId(clientId: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client?id=${clientId}&fullSearch=false&size=100&pageNumber=0`
    );
  }

  getClientByClientName(name: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client?text=${name}&fullSearch=false&size=100&pageNumber=0`
    );
  }

  getAllClients() {
    return this.httpClient.get<any>(
      `${this.clientUrl}client?fullSearch=false&size=1000&pageNumber=0`
    );
  }

  updateClient(id: string, payload: any) {
    return this.httpClient.put<any>(`${this.clientUrl}client/${id}`, payload);
  }

  getOrganisation() {
    return this.httpClient.get<any>(`${this.clientUrl}client/functions`);
  }

  getOrganisationByClientId(clientId: string) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/functions?clientId=${clientId}&size=100&pageNumber=0`
    );
  }

  getClientConfigSectors() {
    // return this.httpClient.get<any>(`${this.clientUrl}clientConfig/sectors`);
    return this.httpClient.get<any>(`${this.clientUrl}clientConfig?type=SECTOR`);
  }

  getClientConfigFunctions() {
    return this.httpClient.get<any>(`${this.clientUrl}clientConfig?type=FUNCTION`);
  }

  getModuleData(type: string = "") {
    return this.httpClient.get<any>(`${this.apiUrl}category?type=${type}`);
  }

  getUsers() {
    return this.httpClient.get<any>(
      `${this.authUrl}auth/users?size=100&pageNumber=0`
    );
  }

  create_sector_or_function(payload:any){
    return this.httpClient.post<any>(
      `${this.clientUrl}clientConfig`,
      payload
    );
  }


  createModule(payload: any) {
    return this.httpClient.post<any>(
      `${this.clientUrl}client/modules`,
      payload
    );
  }
  
  editModule(payload: any, moduleId?: string) {

    let url: string = `${this.clientUrl}client/modules/update`;
    if(moduleId){
      url = `${url}?id=${moduleId}`
    }
    return this.httpClient.put<any>( url, payload );
  }

  getClientSelectedModulesByClientId(clientId: string) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/modules?clientId=${clientId}`
    );
  }

  //Get Client ID via subject

  sendID(value: any) {
    this.subjectClientId.next({ value });
  }

  receiveID() {
    return this.subjectClientId.asObservable();
  }

  updateOrganisation(organisationId: string, payload: any) {
    return this.httpClient.put<any>(
      `${this.clientUrl}client/functions/${organisationId}`,
      payload
    );
  }
}
