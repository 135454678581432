<mat-card>
    <div id="dn" align="center">
        <apx-chart
            [series]="series_dn"
            [chart]="chart_dn"
            [labels]="labels"
            [responsive]="responsive_dn">
        </apx-chart>
    </div>
</mat-card>
<br>
<table mat-table [dataSource]="surveyDetailList" class="mat-elevation-z8 survey-resp">

    <ng-container matColumnDef="function">
    <th mat-header-cell *matHeaderCellDef> Function </th>
    <td mat-cell *matCellDef="let element"> {{element.clientFunction}} </td>
    </ng-container>

    <ng-container matColumnDef="team">
    <th mat-header-cell *matHeaderCellDef> Team </th>
    <td mat-cell *matCellDef="let element"> {{element.teamName}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role </th>
    <!-- <td mat-cell *matCellDef="let element"> {{'Team Member'}} </td> -->
    <td mat-cell *matCellDef="let element"> {{element.isManager ? "Team Manager" : "Team Member"}} </td>
    </ng-container>

    <ng-container matColumnDef="link">
    <th mat-header-cell *matHeaderCellDef> Link </th>
    <td mat-cell *matCellDef="let element"> 
        <i class="fa-regular fa-file-lines survey-lnk" (click)="goToSurveySubmit(element.link)"></i>
    </td>
    </ng-container>

    <ng-container matColumnDef="responses">
    <th mat-header-cell *matHeaderCellDef> Responses </th>
    <td mat-cell *matCellDef="let element"> {{ element?.responseCount }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
</table>