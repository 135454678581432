import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '@app/home/home/home.service';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homeService : HomeService
  ) { }
  moduleName: string = '';

  ngOnInit(): void {
    /* TODO: redirect page routing based on url(Questions for modules.) */
    // this.moduleName = this.route.snapshot.paramMap.get("module") || '';
    // console.log('----- this.moduleName ------', this.moduleName);
    // if(this.moduleName === 'ava' || this.moduleName === 'eva' || this.moduleName === 'op-maturity'){
    //   this.router.navigate([`/database/${this.moduleName}`]);
    // }
   
sessionStorage.setItem('CLIENTID','empty')

  }

}
