<!-- <br> -->
  <mat-card *ngIf="activityShow">
    <form [formGroup]="surveySetupForm">
      <div class="Row">

        <div class="col-sm-4 input-container">
            <mat-label>Survey Name</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="surveyName" type="text" placeholder="Survey Name">
            </mat-form-field>
        </div>

        <div class="col-sm-4 input-container">
            <mat-label>Owner</mat-label>
            <mat-form-field appearance="outline">
                <mat-select placeholder="Name" formControlName="owner">
                    <mat-option *ngFor="let owner of selectedOwners" [value]="owner"> {{ owner}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
  
        <div class="col-sm-4 input-container">
            <mat-label>Start Date</mat-label>
            <mat-form-field appearance="outline">
                <!-- <input matInput [min]="todayDate" formControlName="startDate" type="date" name="start" id=""
                    placeholder="Date"> -->
                    <input matInput [min]="todayDate" formControlName="startDate"  name="start" id=""
                    placeholder="Date" [matDatepicker]="startpicker" placeholder="Date" (dateChange)="handleClick($event)">
                    <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                    <mat-datepicker #startpicker></mat-datepicker>
            </mat-form-field>
        </div>
        
        <div class="col-sm-4 input-container">
            <mat-label>End Date</mat-label>
            <mat-form-field appearance="outline">
                <!-- <input  matInput [min]="endMinDate" formControlName="endDate" type="date" name="" id="end" placeholder="Date" > -->
                <input matInput [min]="endMinDate" formControlName="endDate" name="" id="end"
                placeholder="Date" [matDatepicker]="picker" placeholder="Date" >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        

      
  
      </div>
    </form>
  </mat-card>

  <br *ngIf="activityShow">
<section *ngIf="activityShow">
    <div class="basic-container">
        <div class="example-container">
            <div class="two-butns">
                <button class="active-solid-lrg size" *ngIf="type === 'create'" (click)="createActivity('create')" >Create</button>
                <button class="active-solid-lrg size" *ngIf="type === 'update'"(click)="cancel()" >Cancel</button>
                <button class="active-solid-lrg size" *ngIf="type === 'update'"(click)="editActivity()" >Update</button>
            </div>
        </div>
    </div>
</section>
  <br *ngIf="activityShow">
  <table mat-table [dataSource]="dataSource_survey" class="mat-elevation-z8" *ngIf="activityShow">

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Survey Name </th>
        <td mat-cell *matCellDef="let element"> {{element.surveyName}} </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef> Owner </th>
        <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
    </ng-container>

    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef> Start </th>
        <td mat-cell *matCellDef="let element"> {{element.startDate | date:"dd-MM-YYYY"}} </td>
    </ng-container>

    <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef> End </th>
        <td mat-cell *matCellDef="let element"> {{element.endDate | date:"dd-MM-YYYY"}}  </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element; let i = index">
            <mat-icon class="icon playPause" (click)="patchActivity(element)">mode_edit</mat-icon>
            <mat-icon *ngIf="element.status!='In Progress'" class="icon bulb" (click)="deleteActivity(element)">delete</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns_survey"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns_survey;" 
    (click)="selectRow(row)" [class.selected-row]="row === selectedRow"></tr>
</table>
<br *ngIf="activityShow">
<section *ngIf="activityShow">
    <div class="basic-container">
        <div class="example-container">
            <div class="two-butns">
                <button class="active-stroke-lrg size" (click)="clearForm()">Cancel </button>
                <button class="active-solid-lrg size" [ngClass]="{'val': !isSelect}" (click)="goTo('ava');clear()">Setup Surveys</button>
                <!-- <button class="active-solid-lrg size" [ngClass]="{'val': !isSelect}" (click)="goTo('eva')">Set Up Engagement</button> -->
                <!-- <button class="active-solid-lrg size">Cancel</button> -->
            </div>
        </div>
    </div>
</section>

<mat-tab-group (selectedIndexChange)="change($event)" [selectedIndex]="selectedTab" *ngIf="!activityShow" class="va" >
    <mat-tab label="AVA">
        <!-- ava filter -->
        <br>
        <mat-card style="width: 97%;border-radius: 1em;">
            <div class="Row">
        
                <div class="col-sm-6 input-container">
                    <mat-label>Team Selection</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select multiple [formControl]="teamSelected" 
                        [(ngModel)]="selectedActivity" [compareWith]="comparer"
                        (selectionChange)="setTeamstoActivity($event)">
                            <mat-option *ngFor="let owner of clientFunctions" [value]="owner">
                                {{ owner.teams[0].teamName}} 
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="col-sm-6 input-container">
                    <mat-label>Build from Template</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select [value]="AvaDefaultTemplate.templateName" (selectionChange)="onTemplateSelectAva($event)">
                            <mat-option *ngFor="let template of AvaTemplateList" [value]="template.templateName"> 
                                {{ template.templateName }} 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <br>
        <div class="basic-container">
            <div class="example-container">
                <div class="two-butns">
                    <button class="active-solid-lrg w" 
                    (click)="back()"
                    style="width: 10em;background-color: #ffff;color: black;">Back</button>
                    <button class="active-solid-lrg w" (click)="buildFromDb('AVA')">Build from Database</button>
                    <button class="active-solid-lrg w" (click)="openDialogAva()">Add Question</button>
                    <button class="active-solid-lrg w" (click)="addSurvey()"> Preview</button>
                </div>
            </div>
        </div>
        <br>

        <!-- ava table -->

        <table mat-table [dataSource]="dataSource_ava" class="mat-elevation-z8">
            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef> Activity </th>
                <td mat-cell *matCellDef="let element"> {{element?.question ?? '-'}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element?.type ?? '-'}} </td>
            </ng-container>

            <ng-container matColumnDef="timeCategory">
                <th mat-header-cell *matHeaderCellDef> Time Category </th>
                <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element; let i = index" >
                  <mat-icon class="icon playPause iconleft2em" (click)="editQuestion(element, i, 'ava-template')" *ngIf="!isDefaultTemplateAva">mode_edit</mat-icon>
                  <mat-icon class="icon bulb iconleft2em" *ngIf="!isDefaultTemplateAva " (click)="deletePreview(element,i,'AVA')">delete</mat-icon>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns_ava"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns_ava;"></tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
        <br>
    </mat-tab>
    <mat-tab label="Engagement Survey">
        <br>
        <!-- eva filter -->

        <mat-card style="width: 97%;border-radius: 1em;">
            <div class="Row">
        
                <div class="col-sm-6 input-container">
                    <mat-label>Team Selection</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select multiple [formControl]="teamSelected" 
                        [(ngModel)]="selectedActivity" [compareWith]="comparer"
                        (selectionChange)="setTeamstoActivity($event)">
                            <mat-option *ngFor="let owner of clientFunctions" [value]="owner">
                                {{ owner.teams[0].teamName}} 
                            </mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="col-sm-6 input-container">
                    <mat-label>Build from Template</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select value="{{evaDefaultTemplate.templateName}}"
                        (selectionChange)="onTemplateSelectEva($event)">
                        <mat-option *ngFor="let template of evaTemplateList" [value]="template.templateName"> {{
                            template.templateName }} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        <br>
        <div class="basic-container">
            <div class="example-container">
                <div class="two-butns">
                    <button class="active-solid-lrg w" 
                    (click)="back()"
                    style="width: 10em;background-color: #ffff;color: black;">Back</button>
                    <button class="active-solid-lrg w" (click)="buildFromDb('EVA')">Build from Database</button>
                    <button class="active-solid-lrg w" (click)="openDialogEva()">Add Question</button>
                    <button class="active-solid-lrg w" (click)="addSurvey()"> Preview</button>
                </div>
            </div>
        </div>
        <br>

        <!-- eva-table -->

        <table mat-table [dataSource]="dataSource_eva" class="mat-elevation-z8">
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <ng-container matColumnDef="sub-category">
                <th mat-header-cell *matHeaderCellDef> Sub Category </th>
                <td mat-cell *matCellDef="let element"> {{element.subCategory}} </td>
            </ng-container>

            <ng-container matColumnDef="lens">
                <th mat-header-cell *matHeaderCellDef> Lens </th>
                <td mat-cell *matCellDef="let element"> {{element.lens}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Role </th>
                <td mat-cell *matCellDef="let element"> {{'Manager'}} </td>
            </ng-container>

            <ng-container matColumnDef="subteam">
                <th mat-header-cell *matHeaderCellDef> Sub-team </th>
                <td mat-cell *matCellDef="let element"> {{'Team 1'}} </td>
            </ng-container>

            <ng-container matColumnDef="score">
                <th mat-header-cell *matHeaderCellDef> MA Score </th>
                <td mat-cell *matCellDef="let element"> {{'4'}} </td>
            </ng-container>

            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef> Question </th>
                <td mat-cell *matCellDef="let element"> {{ element.question }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element; let i = index" class="actionbtns">
                    <mat-icon class="icon playPause"  (click)="editQuestion(element, i, 'eva-template')" *ngIf="!isDefaultTemplateEva">mode_edit</mat-icon>
                    <mat-icon class="icon bulb" *ngIf="!isDefaultTemplateEva"
                        (click)="deletePreview(element,i,'EVA')">delete</mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns_eva"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns_eva;"></tr>
        </table>
    </mat-tab>
</mat-tab-group>

<br>

<!-- <mat-card align="center" >
    <button class="active-solid-lrg" style="text-decoration: underline;" (click)="addSurvey()">Survey Preview</button>
</mat-card> -->
