<div class="row space filterr">
  <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
    <mat-form-field appearance="outline">
      <mat-label>Function</mat-label>
      <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
        (selectionChange)="changeFunction($event.value)">
        <mat-option *ngFor="let function of functions" [value]="function">
          {{function}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Team</mat-label>
      <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
        <mat-option *ngFor="let team of teams" [value]="team">
          {{team}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<mat-card>
  <table class="my-table">
    <tr>
      <ng-container *ngFor="let header of reponseHeaders; let i = index">
        <th>{{ header }}</th>
      </ng-container>
    </tr>
    <ng-container *ngFor="let row of responseTable; let i = index">
      <tr>
        <ng-container *ngFor="let header of reponseHeaders; let j = index">
           <td class="cell-with-margin"  [style.background-color]="getBackground(header)" > <div *ngIf="header !== 'Op Maturiy Status'">
            {{ row[header] }}
           </div> <div *ngIf="header === 'Op Maturiy Status'">
            <svg height="30" width="30">
              <circle cx="15" cy="15" r="10" [attr.fill]="getTrafficLightColor(row[header])" />
            </svg>
           </div> </td>
        </ng-container>
      </tr>
    </ng-container>
  </table>
</mat-card>

<br>
<br>

<div class="table-rows">
  <mat-card>
    <table class="my-table">
      <tr>
        <ng-container *ngFor="let header of functionHeaders; let i = index">
          <th>{{ header }}</th>
        </ng-container>
      </tr>
      <ng-container *ngFor="let row of functionTable; let i = index">
        <tr [ngStyle]="{'font-weight': i === functionTable.length - 1 ? 'bold' : 'normal'}" >
          <ng-container *ngFor="let header of functionHeaders; let j = index" >
             <td> <div>
              {{ row[header] }}
             </div> </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </mat-card>

  <mat-card>
    <table class="my-table">
      <tr>
        <ng-container *ngFor="let header of teamHeaders; let i = index">
          <th>{{ header }}</th>
        </ng-container>
      </tr>
      <ng-container *ngFor="let row of teamTable; let i = index">
        <tr [ngStyle]="{'font-weight': i === teamTable.length - 1 ? 'bold' : 'normal'}" >
          <ng-container *ngFor="let header of teamHeaders; let j = index">
             <td> <div>
              {{ row[header] }}
             </div> </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </mat-card>

</div>

<div *ngIf="spinner" class="overlay" style="left: 0em !important;">
  <mat-spinner class="spinner"></mat-spinner>
</div>