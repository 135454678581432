<br>

  <app-fte-counts></app-fte-counts>
  <br>


<!-- <div *ngFor="let data of dataTable">
<span>{{data.surveyName}}</span> 
<span>{{data.owner}}</span> 
<span>{{data.cicks}}</span> 

<span>{{data.surveyData.endDate }}</span> 
</div> -->


<table mat-table [dataSource]="dataTable" class="survey-resp">

    <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Survey Name </th>
    <td mat-cell *matCellDef="let element"> {{element.surveyName}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date Due </th>
    <td mat-cell *matCellDef="let element"> {{element.surveyData.endDate | date:"dd-MM-YYYY"}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Owner </th>
    <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
    </ng-container>

    <ng-container matColumnDef="link">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon (click)="getAllResponse(element)" *ngIf="getStatus(element) ==='Completed'" matTooltip="Get Response">edit</mat-icon>
        <mat-icon *ngIf="getStatus(element) != 'Completed'" (click)="goToSurveySubmit(element.link)" matTooltip="Go to Survey">play_circle_outline</mat-icon>  
        <mat-icon matRipple *ngIf="getStatus(element) != 'Completed'" style="margin-left: 5px;" (click)="copyLink(element.link)" matTooltip="Copy Link">link</mat-icon>  
        <mat-icon matRipple style="margin-left: 5px;" (click)="download(element)" matTooltip="Download Data">download</mat-icon>  
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{getStatus(element)}}  </td>
    </ng-container>  

    <ng-container matColumnDef="responses">
    <th mat-header-cell *matHeaderCellDef> Participants </th>
    <td mat-cell *matCellDef="let element"> &nbsp; {{ element?.clicks }} {{" /"}} {{getTotalCount(element)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
</table>