<br>

<div class="content">
    <!-- <section class="arr-buttons">
        <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
        <a class="next round"><i class="fa-solid fa-chevron-right"></i></a> &nbsp;
        <span class="title" *ngIf="isAddNewClient">&nbsp; Add New Client</span>
        <span class="title" *ngIf="isEditClient">&nbsp; Edit {{singleClientDetails?.clientName}}</span>
        <span class="title" *ngIf="isViewClient">&nbsp; View {{singleClientDetails?.clientName}}</span>
        <span class="title" *ngIf="isAddOrgStructure">&nbsp; Organisation Structure</span>
        <span class="title" *ngIf="isAddModules">&nbsp; Modules</span>
    </section> -->
    <br><br>

    <section class="clientab">
        <div class="basic-container">
            <mat-horizontal-stepper [linear]="isClientDtailsFormEditable" #stepper>
                <mat-step [stepControl]="client_details_form" >
                    <ng-template matStepLabel>Client Details</ng-template>
                    <form [formGroup]="client_details_form" class="example-container">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Client Name</mat-label>
                            <input matInput formControlName="clientName" placeholder="Client Name"
                                readonly="{{ isViewClient }}" type="text" maxlength="100" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Diagnostic Name</mat-label>
                            <input matInput formControlName="diagnosticName" placeholder="Diagnostic Name"
                                readonly="{{ isViewClient }}" type="text" maxlength="100" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Details</mat-label>
                            <input matInput formControlName="details" placeholder="Details"
                                readonly="{{ isViewClient }}" type="text" maxlength="100" />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="example-full-width" *ngIf="!selectedSectors">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="sector"
                             (selectionChange)="change($event)" *ngIf="!isViewClient">
                                <mat-option *ngFor="let sector of clientConfigSectorsData" 
                                [ngClass]="sector.configName === 'Add New Sector' ? 'add-new' : ''"
                                [value]="sector.configName">
                                    {{sector.configName}}</mat-option>
                            </mat-select>
                            <input matInput formControlName="sector" *ngIf="isViewClient" readonly="{{ isViewClient }}" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="example-full-width" style="width: 35vw !important;" *ngIf="selectedSectors">
                            <mat-label>Sector Name</mat-label>
                            <input matInput formControlName="sector" placeholder="Enter Sector Name" type="text" maxlength="100" />
                        </mat-form-field>
                        &nbsp; &nbsp;
                        <span (click)="createSector()" mat-mini-fab *ngIf="selectedSectors" >
                            <mat-icon>add_icon</mat-icon>
                        </span>

                        <mat-form-field *ngIf="!(isViewClient || isEditClient)" appearance="outline" matTooltip="Once set can't be changed in future"
                            [matTooltipPosition]="'below'">
                            <mat-label>Start date</mat-label>
                            <input [readonly]="true" matInput [min]="todayDate" formControlName="startDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"  [disabled]="isViewClient"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field *ngIf="(isViewClient || isEditClient)" appearance="outline" matTooltip="Once set can't be changed in future"
                            [matTooltipPosition]="'below'">
                            <mat-label>Start date</mat-label>
                            <input #stDate matInput formControlName="startDate" 
                                [readonly]="true" [min]="todayDate" >
                            <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="true"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" matTooltip="Can be changed in future"
                            [matTooltipPosition]="'right'">
                            <mat-label>End date</mat-label>
                            <input  matInput formControlName="endDate" [matDatepicker]="picker2"
                                [readonly]="true" [min]="selectedStartDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2" [disabled]="isViewClient"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                       
                        <br>
                        <section class="clientab">
                            <div class="basic-container">
                                <mat-card class="custom-pad">
                                    <mat-card-content>
                                        <div class="example-container">
                                            <div class="three-buttons" style="justify-content: end !important; margin-right: 1.1em;">
                                                <a class="saveDraft" *ngIf="isEditClient" (click)="updateClient('true','patchUpdate')">Update</a> &nbsp;
                                                <button class="active-solid-lrg" 
                                                    (click)="createClientNext()" *ngIf="!(isEditClient || isViewClient)">Create Client</button>
                                                    <button class="active-solid-lrg" 
                                                    (click)="editNext()" *ngIf="(isEditClient || isViewClient)">Next</button>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </section>
                    </form>
                </mat-step>
                <mat-step [stepControl]="organisation_form"  >
                    <ng-template matStepLabel>Organisation Structure</ng-template>
                    <form [formGroup]="organisation_form" *ngIf="!isViewClient" >
                        <div class="example-container pd-left">
                            <mat-form-field appearance="outline" class="example-full-width" 
                            *ngIf="!selectedFunction">
                                <mat-label>Function Name</mat-label>
                                <mat-select (selectionChange)="change($event)" formControlName="functionName">
                                    <mat-option *ngFor="let data of functionNameData"
                                    [ngClass]="data.configName === 'Add New Function' ? 'add-new' : ''"
                                     [value]="data.configName">
                                        {{data.configName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width" style="width: 35vw !important;"
                             *ngIf="selectedFunction">
                                <mat-label>Function Name</mat-label>
                                <input matInput formControlName="functionName" placeholder="Enter Function Name" type="text" maxlength="100" />
                            </mat-form-field>
                            &nbsp; &nbsp;
                            <span (click)="createFunction()" mat-mini-fab *ngIf="selectedFunction" >
                                <mat-icon>add</mat-icon>
                            </span>
                            
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Team Name</mat-label>
                                <input matInput placeholder="Team Name" formControlName="teamName"
                               type="text" maxlength="100" />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label class="label" >FTE Manager Cost</mat-label>
                                <input matInput type="text" min="0" placeholder="FTE Manager Cost"
                                    formControlName="fteManagerCost"  maxlength="16" onlyNumber  />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>FTE Managers</mat-label>
                                <input matInput type="text" min="0" placeholder="FTE Managers"
                                    formControlName="fteManagers" maxlength="7" onlyNumber />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>FTE Member Cost</mat-label>
                                <input matInput type="text" min="0" placeholder="FTE Member Cost"
                                    formControlName="fteMemberCost"  maxlength="16" onlyNumber />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>FTE Members</mat-label>
                                <input matInput type="text" min="0" placeholder="FTE members"
                                    formControlName="fteMembers" maxlength="7" onlyNumber/>
                            </mat-form-field>
                            <mat-form-field id="daysAndHours" appearance="outline" class="example-full-width">
                                <mat-label>Working Days</mat-label>
                                <input matInput type="text" min="0" placeholder="Working days in the year" formControlName="days" 
                                maxlength="5" onlyNumber />
                            </mat-form-field>
                            <mat-form-field id="daysAndHours" appearance="outline" class="example-full-width">
                                <mat-label>Working Hours</mat-label>
                                <input matInput type="text" min="0" placeholder="Working hours in the day" formControlName="hours"
                                maxlength="5" onlyNumber />
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Access Personal</mat-label>
                                <mat-select multiple formControlName="authors">
                                    <mat-option *ngFor="let user of users"
                                        [value]="user.first_name + ' ' + user.last_name">{{user.first_name + " "
                                        + user.last_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            


                              <div style="margin-right: 3em;
                              display: flex;
                              justify-content: flex-end;">
                                  <button class="active-stroke-lrg" *ngIf="isSaveOrganisation" [disabled]="isViewClient" (click)="orgStructureNext()">Save</button>
                                  <button class="active-stroke-lrg" *ngIf="isEditClient && isUpdateOrganisation" (click)="updateOrganisation()">Update</button>
                                </div>
                        </div>
                    </form>
                    <br>
                  
                    <section >
                        <div class="row" id="tabs" style="display:flex; justify-content:space-between " >
                            <div class=" col-2">
                                <label style="font-weight: bold !important; ">Function</label>
                            </div>
                            <div class="col-3" >
                                <label style="font-weight: bold !important; ">Team Name</label>
                            </div>

                            <div class="col-3" >
                                <label style="font-weight: bold !important;">Members</label>
                            </div>

                            <div class="col-4 "  >
                                <label style="font-weight: bold !important;">Members Cost</label>
                            </div>
                        </div>
                        <div class="row" *ngFor="let organisation of reStructured_Org_data" >
                            <hr>
                             <div class="col-4 " style="text-align: center;" id="tabs">
                                <div class="tab-function">
                                    <p>{{organisation.type}}</p>
                                </div>
                             </div>
                            <div class="col-8" style="margin-top:-4rem;" id="tabs">
                                <div class="tab-function">
                                </div>
                                <div class="tabs-team">
                                    <div class="tabs-team-a" style="margin-bottom: 3em; " *ngFor="let team of organisation.value">
                                        <p class="p-1 ">{{ team.teamName }}</p>
                                    </div>
                                </div>

                                <div class="tabs-members">
                                    <div class="tabs-team-a" *ngFor="let team of organisation.value">
                                        <p class="p-1">Team Leader <br><br>Team Members</p>
                                        <p class="p-2">{{ team.fteManagers }}<br><br> {{ team.fteMembers }}</p>
                                    </div>
                                </div>

                                <div class="tabs-members">
                                    <div class="tabs-team-a" *ngFor="let team of organisation.value">
                                        <p class="p-1">Team Leader <br><br>Team Members</p>
                                        <p class="p-2">{{ team.fteManagers * team.fteManagerCost }}<br><br>{{ team.fteMembers * team.fteMemberCost }}</p>
                                        &nbsp; &nbsp; <br><i style="margin-top: 45px;" *ngIf="isEditClient" mat-ripple class="fa-regular fa-pen-to-square"
                                        (click)="editOrganisation(organisation,team)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <hr>
                    <br>
                    <section class="clientab">
                        <div class="basic-container">
                            <mat-card class="custom-pad">
                                <mat-card-content>
                                    <div class="example-container">
                                        <div class="three-buttons">
                                            <!-- &nbsp;
                                            <button class="active-solid-lrg" matStepperPrevious> Back</button>
                                            &nbsp; -->
                                            <!-- <a class="saveDraft">Save</a> -->
                                            &nbsp;
                                            <button class="active-solid-lrg"  (click)="save_next()" > Next</button>
                                            &nbsp;
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </section>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Modules</ng-template>
                    <app-modules></app-modules>
                </mat-step>
            </mat-horizontal-stepper>

        </div>
    </section>
    <br>
</div>