import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsComponent } from '@app/projects/projects.component';
import { SharedModule } from '@app/shared/shared.module';
import { AVAEVAComponent } from '@app/projects/ava-eva/ava-eva.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EvaComponent } from './eva/eva.component';
import { CoreModule } from '@app/core/core.module';
import { ResponsesManagementComponent } from './responses-management/responses-management.component';
import { ProjectsRoutingModule } from './projects-routing.module';

@NgModule({
  declarations: [
    ProjectsComponent,
    AVAEVAComponent,
    EvaComponent,
    ResponsesManagementComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    ProjectsRoutingModule,
    CommonModule,
    SharedModule,
    CoreModule,
    NgApexchartsModule
  ]
})
export class ProjectsModule { }
