<mat-dialog-content class="popup">
<!-- <h2>Data Table</h2> -->
<!-- <table class="data-table">
  <thead>
    <tr>
      <th>Category</th>
      <th>Subcategory</th>
      <th>Lens</th>
      <th>Question</th>
      <th>Answer</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of datasource">
      <td>{{ item.category }}</td>
      <td>{{ item.subCategory }}</td>
      <td>{{ item.lens }}</td>
      <td>{{ item.question }}</td>
      <td><input type="number" [(ngModel)]="item.answer" /></td>
    </tr>
  </tbody>
</table> -->

<!-- MI-FG -->
<table mat-table [dataSource]="datasource" *ngIf="Mi_Fg">
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
  </ng-container>
  <ng-container matColumnDef="subCategory">
    <th mat-header-cell *matHeaderCellDef> SubCategory </th>
    <td mat-cell *matCellDef="let element"> {{element?.subCategory ?? '-' }} </td>
  </ng-container>

  <ng-container matColumnDef="lens">
    <th mat-header-cell *matHeaderCellDef> Lens </th>
    <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
  </ng-container>

  <ng-container matColumnDef="question">
    <th mat-header-cell *matHeaderCellDef> Question </th>
    <td mat-cell *matCellDef="let element"> {{element.question}} </td>
  </ng-container>

  <ng-container matColumnDef="Answer">
    <th mat-header-cell *matHeaderCellDef> Answer </th>
    <td mat-cell *matCellDef="let element"> 
      <!-- <input type="number" [(ngModel)]="element.answer" /> -->
      <mat-form-field appearance="outline" style="width: 5vw;">
        <mat-label>Answers*</mat-label>
        <input matInput type="number" min="1" max="5" maxlength="1" [(ngModel)]="element.answer">
      </mat-form-field>
      <br>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="dc"></tr>
  <tr mat-row *matRowDef="let row; columns: dc;"></tr>
</table>

<!-- AVA-EVA -->

<mat-tab-group (selectedTabChange)="tabChange($event.index)" *ngIf="Survey" >
  <mat-tab label="Ava">
    <table mat-table [dataSource]="datasource" style="width: 70vw;">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef> SubCategory </th>
        <td mat-cell *matCellDef="let element"> {{element?.subCategory ?? '-' }} </td>
      </ng-container>
    
      <ng-container matColumnDef="lens">
        <th mat-header-cell *matHeaderCellDef> Lens </th>
        <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
      </ng-container>
    
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef> Question </th>
        <td mat-cell *matCellDef="let element"> {{element.question}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Answer">
        <th mat-header-cell *matHeaderCellDef> Answer </th>
        <td mat-cell *matCellDef="let element"> 
          <!-- <input type="number" [(ngModel)]="element.answer" /> -->
          <mat-form-field appearance="outline" style="width: 5vw;">
            <mat-label>Answers*</mat-label>
            <input matInput #myinput type="number" min="1" max="5" maxlength="1" [(ngModel)]="element.Answer">
          </mat-form-field>
          <br>
        </td>
      </ng-container>
    
    
      <tr mat-header-row *matHeaderRowDef="dc"></tr>
      <tr mat-row *matRowDef="let row; columns: dc;"></tr>
    </table>
  </mat-tab>
  <mat-tab label="Eva">
    <table mat-table [dataSource]="datasource">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef> SubCategory </th>
        <td mat-cell *matCellDef="let element"> {{element?.subCategory ?? '-' }} </td>
      </ng-container>
    
      <ng-container matColumnDef="lens">
        <th mat-header-cell *matHeaderCellDef> Lens </th>
        <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
      </ng-container>
    
      <ng-container matColumnDef="question">
        <th mat-header-cell *matHeaderCellDef> Question </th>
        <td mat-cell *matCellDef="let element"> {{element.question}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Answer">
        <th mat-header-cell *matHeaderCellDef> Answer </th>
        <td mat-cell *matCellDef="let element"> 
          <!-- <input type="number" [(ngModel)]="element.answer" /> -->
          <mat-form-field appearance="outline" style="width: 5vw;">
            <mat-label>Answers*</mat-label>
            <input matInput type="number" min="1" max="5" maxlength="1" [(ngModel)]="element.answer">
          </mat-form-field>
          <br>
        </td>
      </ng-container>
    
    
      <tr mat-header-row *matHeaderRowDef="dc"></tr>
      <tr mat-row *matRowDef="let row; columns: dc;"></tr>
    </table>
  </mat-tab>
</mat-tab-group>

<!-- POI-->

<div *ngIf="POI">
  <mat-card class="heading">
    <span>Sub Modules of Process Owner Interviews</span>
  </mat-card>
  <br>
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
      *ngFor="let data of datasource2; let i = index;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{data.subModule}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table mat-table [dataSource]="data.values" class="tb" *ngIf="data.values.length > 0">

        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef> Question </th>
          <td mat-cell *matCellDef="let element"> {{element?.question ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element"><span>{{element.description  ? element.description : '--'}}</span>
          </td>
          <!-- <td mat-cell *matCellDef="let element"><span *ngIf="element.description === ''">{{'N/A'}}</span></td> -->
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Answer </th>
          <td mat-cell *matCellDef="let element">
            <!-- {{element.answer}}
            <span></span> -->
            <br>
            <mat-form-field appearance="outline" class="size" *ngIf="element.options && element.type ==='string'">
              <mat-label>Select</mat-label>
              <mat-select [(ngModel)]="element.answer" required>
                <span *ngFor="let value of element.options">
                  <span *ngIf="value.textAnswer == 'None'">
                    <mat-option [value]="value.answer"> {{ value.textAnswer }}
                    </mat-option>
                  </span>
                  <span *ngIf="value.answer && value.textAnswer != 'None'">
                    <mat-option [value]="value.answer"> {{ value.textAnswer }}
                    </mat-option>
                  </span>
                  <span *ngIf="!value.answer && value.textAnswer != 'None'">
                    <mat-option [value]="value.textAnswer"> {{ value.textAnswer }}
                    </mat-option>
                  </span>

                  <!-- <mat-option [value]="value.textAnswer" *ngIf="!value.answer"> {{ value.textAnswer }}
                  </mat-option>

                  <mat-option [value]="value.answer"> {{ value.textAnswer }}
                  </mat-option> -->
                </span>
              </mat-select>
            </mat-form-field>
            <br *ngIf="element.options && element.type ==='string'">
            <mat-form-field appearance="outline" style="width: 6vw;" *ngIf="!element.type">
              <input matInput type="number" [(ngModel)]="element.answer">
            </mat-form-field>
            <br  *ngIf="!element.type">
            <mat-form-field appearance="outline" style="width: 6vw" *ngIf="element.type === 'number'">
              <input matInput type="number" [(ngModel)]="element.answer">
            </mat-form-field>
            <br *ngIf="element.type === 'number'"><br *ngIf="element.type === 'number'">
            <mat-form-field appearance="outline" style="width: 12vw;" *ngIf="!element.options && element.type ==='string'">
              <input matInput type="text" [(ngModel)]="element.answer">
            </mat-form-field>
            <br *ngIf="!element.options && element.type ==='string'">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="dc2; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: dc2;"></tr>
      </table>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="button-container">
  <button mat-button (click)="onSubmit()">Submit</button>
  <button mat-button (click)="onNoClick()">Cancel</button>
</div>
</mat-dialog-content>