<h1 mat-dialog-title>Request History</h1>
<table mat-table [dataSource]="dataSource" style="width: 100%;">

    <ng-container matColumnDef="questions">
      <th mat-header-cell *matHeaderCellDef>Questions</th>
      <td mat-cell *matCellDef="let element"> {{element.question}} </td>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>
  
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef> Comments </th>
      <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> 

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>