
<br>
<section class="db_manager_interview">
    <!-- <H2>Database Manager Interview</H2> -->

    <br>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="tabChange($event.index)" >
        <mat-tab label="Approved" ></mat-tab>
        <mat-tab label="Draft" ></mat-tab>
    </mat-tab-group>
    <br>

  <mat-card>
    <div class="row filters">
      <div class="col-sm-4 input-container">
        <mat-label>Category</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedCat" (selectionChange)="templateChange($event.value,'category')">
            <mat-option *ngFor="let item of questionCategories" [value]="item.category">
              {{item.category}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="col-sm-4 input-container">
        <mat-label>Sub Category</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedSubCat" (selectionChange)="templateChange($event.value,'subcategory')">
            <mat-option *ngFor="let item of selectedCategoryData?.subCategory" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="col-sm-4 input-container">
        <mat-label>Lens</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedLens" (selectionChange)="templateChange($event.value,'lens')">
            <mat-option *ngFor="let item of lens" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row filters">
          <div class="col-sm-12 input-container">
              <mat-label>Select Template</mat-label>
              <mat-form-field appearance="outline" style="font-size: 14.5px;">
                  <mat-select [(ngModel)]="SelectedTemplate" (selectionChange)="templateChange($event.value,'template')">
                      <mat-option *ngFor="let template of templates" [value]="template.templateName"> {{
                          template.templateName }} </mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
      </div>
  
  </mat-card>
<br>
<div class="end">
    <button class="active-solid-lrg clearBtn" (click)="clearFilters()">
        Clear Filters
    </button>
    <button class="active-solid-lrg w-11" (click)="openDialog('create','dummy element')">
        Add New
    </button>
</div>
<br>

  <section class="table">
    <table mat-table [dataSource]="datasource2" class="step-1table">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category</th>
        <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="sub-category">
        <th mat-header-cell *matHeaderCellDef>Sub Category</th>
        <td mat-cell *matCellDef="let element"> {{element?.subCategory ?? '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="lens">
        <th mat-header-cell *matHeaderCellDef>Lens</th>
        <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
      </ng-container>

        <ng-container matColumnDef="questionid">
          <th mat-header-cell *matHeaderCellDef>  Question Id</th>
          <td mat-cell *matCellDef="let element"> {{element?.questionId ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="questionorder">
          <th mat-header-cell *matHeaderCellDef>   Question Order</th>
          <td mat-cell *matCellDef="let element"> {{element?.questionOrder ?? '-'}} </td>
        </ng-container> 

        <ng-container matColumnDef="question" class="truncate">
          <th mat-header-cell *matHeaderCellDef> Question</th>
          <td mat-cell *matCellDef="let element"> {{element?.question  ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="state" class="truncate">
          <th mat-header-cell *matHeaderCellDef> Status</th>
          <td mat-cell *matCellDef="let element"> {{element?.state  ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
              <mat-icon (click)="openDialog('edit',element)" *ngIf="!element?.isDefault" class="icon playPause">mode_edit</mat-icon> &nbsp;
              <mat-icon (click)="deletePreview(element)" *ngIf="!element?.isDefault" class="icon bulb" >delete</mat-icon>
              <mat-icon *ngIf="!element?.isDefault" (click)="openHistory(element)"  class="icon bulb" >info</mat-icon>

          </td>
      </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="dc2"></tr>
        <tr mat-row *matRowDef="let row; columns: dc2;"></tr>
      </table>
      <br>
      <mat-card *ngIf="isEmpty" style="text-align: center;">No Data Available!</mat-card>
      <mat-paginator
      class="pagination"
        #paginator
        (page)="getPageEvent($event)"
        [length]="totalLength"
        [pageIndex]="(questionPagination.page || 1) - 1"
        [pageSize]="questionPagination.perPage"
        [pageSizeOptions]="questionPagination.paginationSizes"
        [showFirstLastButtons]="true"
        *ngIf="!isEmpty"
      >
      </mat-paginator>
</section>

</section>
<div *ngIf="spinner" class="overlay" >
  <mat-spinner class="spinner"></mat-spinner>
</div>