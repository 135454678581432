import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MasterDataService } from "../master-data.service";
import { UsePagination } from "@app/core/classes/use-pagination";
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { QUESTION_STATES_ObJ, Modules } from "@app/resources/question-state";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SurveyService } from "@app/survey/survey.service";

@Component({
  selector: "app-template-list",
  templateUrl: "./template-list.component.html",
  styleUrls: ["./template-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateListComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = ["template", "module", "action"];
  masterDataList: any = [];
  totalLength = 0;
  rowEdited: any = [];
  clientsPagination = new UsePagination({
    page: 0,
    perPage: 50,
    total: this.totalLength,
  });
  search = new FormControl("");
  searchText: string = "";
  searchModule = new FormControl("");
  selectedModuleName: string = "";
  searchCategory = new FormControl("");
  selectedCategoryName: string = "";
  moduleList: any = [];
  categoryList: any = [];

  constructor(
    private masterDataService: MasterDataService,
    private snackBar: MatSnackBar,
    private onboardingService: OnboardingService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private surveyService: SurveyService
  ) {}

  ngOnInit(): void {
    this.getModuleData();
    this.getTemplates();
    sessionStorage.setItem("CLIENTID", "empty");
  }

  getTemplates() {
    console.log("called templates ---------------");
    this.masterDataService
      .getTemplates(
        this.clientsPagination.page,
        this.clientsPagination.perPage,
        this.selectedModuleName
      )
      .subscribe(
        (response: any) => {
          this.masterDataList = response.data;
          this.dataSource = response.data;
          this.cdr.markForCheck();
          console.log("template data", response.data, this.dataSource);
          this.totalLength =
            response.metadata && response.metadata.length
              ? response.metadata[0].total
              : this.totalLength;
          this.cdr.markForCheck();
        },
        (error: any) => {
          console.log("-- error Master --", error);

          this.masterDataList = [];
          this.dataSource = [];
          this.totalLength = 0;
          this.cdr.markForCheck();
        }
      );
  }

  getModuleData() {
    this.onboardingService.getModuleData("REFDATA_MODULE").subscribe(
      (response) => {
        this.moduleList = response;
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("Error on get module list: ", error);
      }
    );
  }

  getPageEvent(pageData: PageEvent) {
    this.clientsPagination.doPagination(pageData);
    this.getTemplates();
    this.cdr.markForCheck();
  }

  onModuleSelect(selection: any) {
    this.selectedModuleName = selection.value;
    this.getTemplates();
    this.cdr.markForCheck();
  }

  clearFilters() {
    this.search.patchValue("");
    this.searchModule.patchValue("");
    this.searchCategory.patchValue("");
    this.searchText = "";
    this.selectedModuleName = "";
    this.selectedCategoryName = "";
    this.getTemplates();
  }

  getModuleName(module: string) {
    let allModule: any = Modules;
    const moduleName = Object.keys(allModule).find(
      (key) => allModule[key] === module
    );
    return moduleName;
  }

  getSurveyData(element: any) {
    console.log(element);
    let surveyQuery: object = { templateId: element._id };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        console.log(surveyResponse);
        if (surveyResponse.data.length > 0) {
          this.snackBar.open(
            "Surveys present with this Template, cannot delete it",
            "close"
          );
        } else {
          this.deletePreview(element);
        }
      },
      (error) => {
        console.log("Error while getting survey data: ", error);
      }
    );
  }

  deletePreview(element: any) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Deactivate the Template ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("res", res, element);
      if (res) {
        this.masterDataService.deleteTemplate(element._id).subscribe({
          next: (res: any) => {
            this.snackBar.open("Template Deleted Successfully", "close");
            this.getTemplates();
            this.cdr.markForCheck();
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      }
    });
  }

  editTemplateName(element: any, index: number) {
    this.rowEdited[index] = true;
    console.log(index);
  }

  onCancelFullCost(_t71: any, index: any, $event: MouseEvent) {
    this.rowEdited[index] = false;
  }

  save(data: any, index: number) {
    console.log(data);
    let payload: Object = {
      templateName: data.templateName,
      module: data.module,
      questions: data.questions,
    };
    console.log("load question payload", payload);

    this.masterDataService
      .updateTemplate(data._id, payload)
      .subscribe((updateTemplateRes) => {
        this.snackBar.open("Template Updated Successfully", "close");
        this.rowEdited[index] = false;
        this.cdr.markForCheck();
      });
  }
}
