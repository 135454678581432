<H2 mat-dialog-title class="font" >{{
    action == 'create' ? 'Create Interview' : 
    action == 'edit' ? 'Update Interview' : 
    action == 'createQuestion' ? 'Create Question' : 'Edit Question'
    }}</H2>

<section *ngIf="action == 'create' || action == 'edit' " mat-dialog-content class="createInterview" >
  <form [formGroup]="interviewCreateForm">
    <div class="col-sm-11">
        <mat-form-field appearance="outline" class="w">
            <mat-label>Name</mat-label>
           <input type="text" matInput formControlName="interviewName">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w">
            <mat-label>Function</mat-label>
            <mat-select (selectionChange)="functionSelection($event.value)"  formControlName="clientFunction">
                    <mat-option  *ngFor="let item of functions" [value]="item" > 
                     {{item?.functionName }} 
                  </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-sm-11">
      
        <mat-form-field appearance="outline" class="w">
            <mat-label>Team</mat-label>
            <mat-select  formControlName="teamName">
                    <mat-option  *ngFor="let item of selectedFunction?.teams " [value]="item.teamName"> 
                     {{item?.teamName }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role">
                    <mat-option  *ngFor="let item of role"  [value]="item"> 
                     {{item}} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-sm-11">
        <mat-form-field appearance="outline" class="w" >
            <mat-label>Date</mat-label> 
             <input matInput readonly="true" [matDatepicker]="picker" formControlName="scheduledDate" >
             <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
             <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w" >
            <mat-label>Interviewer</mat-label>
            <mat-select formControlName="interviewer">
               <mat-option  *ngFor="let item of owners"  [value]="item"> 
                {{item}} 
           </mat-option>
       </mat-select>
        </mat-form-field>
    </div>
  </form>
</section>

<section *ngIf="action == 'create' || action == 'edit' " class="buttons">
   <button  class="active-stroke-lrg btnwidth" color="warn" mat-dialog-close 
    (click)="dialogRef.close()" >Cancel </button>

<button (click)=" save((action == 'create') ? 'create' : 'update') " class="active-solid-lrg btnwidth"  
  > {{(action == 'create') ? 'Create' : 'Update'}}
</button>
</section>

<section *ngIf="action == 'createQuestion' || action == 'editQuestion' " class="createInterview" >
    <form [formGroup]="questionCreateForm" >
          <mat-form-field appearance="outline" >
              <mat-label>Template </mat-label>
             <input  type="text" matInput placeholder="Template" formControlName="template">
          </mat-form-field>
          <mat-form-field appearance="outline" >
              <mat-label>Category</mat-label>
              <mat-select [value]="selectedCategory" (selectionChange)="onFilterChange($event.value, 'subcategory')" formControlName="category">
                      <mat-option  *ngFor="let item of categories " [value]="item"> 
                       {{item.category }} 
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" >
              <mat-label>Sub Category</mat-label>
              <mat-select (selectionChange)="onChangeSubCategory($event.value)" formControlName="subCategory">
                      <mat-option  *ngFor="let item of selectedCategory?.subCategory " [value]="item"> 
                       {{item }} 
                  </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" >
              <mat-label>Lens</mat-label>
              <mat-select formControlName="lens">
                      <mat-option  *ngFor="let item of lens" [value]="item" > 
                       {{item }} 
                    </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="questioninput" >
              <mat-label>Question</mat-label>
            <input type="text" matInput placeholder="Question" formControlName="question" maxlength="150">
          </mat-form-field>
          <mat-error *ngIf="invalidTemplate"> Template name already exists for this module. </mat-error> 
         
    </form>
  </section>

  <section *ngIf="action == 'createQuestion' || action == 'editQuestion' " class="buttons">
    <button class="active-stroke-lrg btnwidth" mat-dialog-close 
     (click)="dialogRef.close()" >Cancel </button>

 <button *ngIf="isDefaultTemplate || action == 'editQuestion' " (click)=" action == 'editQuestion' ? editTemplate() : newTemplate(); isNewTemplate = true " 
 class="active-solid-lrg btnwidth"> Save
 </button>

 <button *ngIf="!isDefaultTemplate && action !== 'editQuestion' " 
  mat-raised-button color="primary" class="active-stroke-lrg btnwidth"  [matMenuTriggerFor]="menu" 
   > Save As&nbsp;<mat-icon>keyboard_arrow_down</mat-icon>
 </button>
 
<mat-menu #menu="matMenu">
 <button mat-menu-item (click)="currentTemplate(); isNewTemplate = false"  [disabled]="isDefaultTemplate">In Current Template</button>
 <button mat-menu-item (click)="newTemplate(); isNewTemplate = true" >New Template</button>
</mat-menu>
 </section>
<!-- <div *ngIf="spinner" class="spinner-container">
    <mat-spinner></mat-spinner>
</div> -->
