<br>
<table mat-table [dataSource]="details" class="detailsTable">

    <ng-container matColumnDef="Client">
        <th mat-header-cell *matHeaderCellDef >Client Name</th>
        <td mat-cell *matCellDef="let element">
                {{element.clientName}}
        </td>
    </ng-container>

    <ng-container matColumnDef="Details">
        <th mat-header-cell *matHeaderCellDef> Details </th>
        <td mat-cell *matCellDef="let element"> {{element.details}} </td>
    </ng-container>

    <ng-container matColumnDef="Sector">
        <th mat-header-cell *matHeaderCellDef> Sector </th>
        <td mat-cell *matCellDef="let element"> {{element.sector}} </td>
    </ng-container>

    <ng-container matColumnDef="Start Date">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let element"> {{element && element.startDate ? (element.startDate | date:'dd-MM-YYYY') : 'N/A' }} </td>
    </ng-container>

    <ng-container matColumnDef="End Date">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let element"> {{element && element.endDate ? (element.endDate | date:'dd-MM-YYYY') : 'N/A' }} </td>
    </ng-container>

    <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon (click)="viewProjectInfo()" matTooltip="View Client">insert_chart_outlined</mat-icon>
            &nbsp;
            <mat-icon (click)="editProjectInfo()" matTooltip="Edit Client">edit</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
</table>

<br>
<mat-card>
    <table mat-table [dataSource]="clientModulesData">

        <ng-container matColumnDef="Module">
            <th mat-header-cell *matHeaderCellDef >Module</th>
            <td mat-cell *matCellDef="let element">
                <button (click)="goTo(element.moduleId)" class="active-solid-lrg module" ngClass={{element.color}}>
                    {{element.moduleId}}</button>
                <br> &nbsp;
            </td>
        </ng-container>

        <ng-container matColumnDef="Workflow">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element" class="Status" 
                ngClass="{{element.status == 'In progress' ? 'Pending' : element.status == 'Not Setup' ? 'not-started' : element.status}}"> 
                {{element.status}} 
            </td>
        </ng-container>

        <ng-container matColumnDef="Owner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let element">
                 {{element.owners.slice(0,1) || 'Alex'}} &nbsp;
                <span *ngIf="element.owners.length > 1" class="owner-bck" [matTooltip]="element.owners"> +{{element.owners.length -1}} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Deadline">
            <th mat-header-cell *matHeaderCellDef> Due Date </th>
            <td mat-cell *matCellDef="let element"> {{element && element.endDate ? (element.endDate | date:'dd-MM-YYYY') : 'N/A' }} </td>
        </ng-container>

        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element" class="t-a">
                &nbsp;
                <mat-icon *ngIf="element.status === 'Not Setup'" [routerLink]="element.setupLink"> settings </mat-icon>
                <mat-icon *ngIf="element.status !== 'Not Setup'" [routerLink]="element.outputLink"> insert_chart_outlined </mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <br>
</mat-card>

<div *ngIf="spinner" class="overlay" >
    <mat-spinner class="spinner"></mat-spinner>
</div>