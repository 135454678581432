export const data = [
  {
    team: "Team-A",
    total: 10,
    functionName: "Risk",
    manager: true,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-A",
    total: 10,
    functionName: "Risk",
    manager: false,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-B",
    functionName: "Risk",
    total: 5,
    manager: true,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-B",
    functionName: "Risk",
    total: 15,
    manager: false,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-C",
    total: 5,
    functionName: "Center",
    manager: true,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-C",
    total: 15,
    functionName: "Center",
    manager: false,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-D",
    functionName: "Back",
    total: 5,
    manager: true,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
  {
    team: "Team-D",
    functionName: "Back",
    total: 15,
    manager: false,
    questions: [
      {
        Activity: "Complaince",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "First Aid",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 10,
      },
      {
        Activity: "Following Standard Processes",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 15,
      },
      {
        Activity: "Core work",
        Type: "Core",
        TimeCategory: "Core Activity",
        Answer: 16,
      },
      {
        Activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 5,
      },
      {
        Activity: "Archiving",
        Type: "Non Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 15,
      },
      {
        Activity: "Archiving",
        Type: "Meetings",
        TimeCategory: "Value Add",
        Answer: 10,
      },
      {
        Activity: "Archiving",
        Type: "Reports",
        TimeCategory: "Value Add",
        Answer: 15,
      },
      {
        Activity: "Team Manager Support - Cover",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 4,
      },
      {
        Activity: "Team Manager Support - Other",
        Type: "Value Add",
        TimeCategory: "Non Core Activity",
        Answer: 13,
      },
    ],
  },
];
