import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AVAEVARoutingModule } from './ava-eva-routing.module';
import { SetupComponent } from './setup/setup.component';
import { ResponseManagementComponent } from './response-management/response-management.component';
import { AvaOutputsComponent } from './ava-outputs/ava-outputs.component';
import { EvaOutputsComponent } from './eva-outputs/eva-outputs.component';
import { SharedModule } from '@app/shared/shared.module';
import { AvaEvaComponent } from './ava-eva.component';
import { VaTablesComponent } from './va-tables/va-tables.component';
import {  RouterModule, Routes } from '@angular/router';
import { AvaEvaOutputsComponent } from './ava-eva-outputs/ava-eva-outputs.component';
import { BuildFromDatabaseAvaComponent } from './build-from-database-ava/build-from-database-ava.component';
import { CoreModule } from '@app/core/core.module';

 const routes:Routes = [
   {
    path:'',
    component : AvaEvaComponent,
    children:[
      {
        path : 'setup',
        component : SetupComponent
      },
      {
        path : 'response',
        component : ResponseManagementComponent
      },
      {
        path : 'output',
        component : AvaEvaOutputsComponent,
        children:[
          {
            path : 'avaoutput',
            component : AvaOutputsComponent
          },
          {
            path : 'evaoutput',
            component : AvaOutputsComponent
          },
        ]
      },
     
    ]
   }
 ]

@NgModule({
  declarations: [
    AvaEvaComponent,
    SetupComponent,
    ResponseManagementComponent,
    AvaOutputsComponent,
    EvaOutputsComponent,
    VaTablesComponent,
    AvaEvaOutputsComponent,
    BuildFromDatabaseAvaComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    AVAEVARoutingModule,
    RouterModule.forChild(routes)
  ],
  exports: [],
})
export class AVAEVAModule { }
