
<br>

<div class="evacontent">

    <!-- <section >
        <span class="title">&nbsp; Database Eva</span>
    </section> -->
    <br>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="tabChange($event.index)" >
        <mat-tab label="Approved" ></mat-tab>
        <mat-tab label="Draft" ></mat-tab>
    </mat-tab-group>

    <br>

    <mat-card>
        <div class="row filters" >
            <div class="col-sm-4 input-container">
                <mat-label>Category</mat-label>
                <mat-form-field appearance="outline" style="font-size: 14.5px;">
                    <mat-select  [value]="selectedCategory" (selectionChange)="onFilterChange($event.value, 'category')">
                        <mat-option *ngFor="let type of questionCategories" [value]="type"> {{ type.category }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div class="col-sm-4 input-container">
                <mat-label>Sub Category</mat-label>
                <mat-form-field appearance="outline" style="font-size: 14.5px;">
                    <mat-select [value]="selectedSubCategory" (selectionChange)="onFilterChange($event.value, 'subcategory')">
                        <mat-option *ngFor="let subCategory of selectedCategory?.subCategory" [value]="subCategory"> {{ subCategory }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-4 input-container">
                <mat-label>Lens</mat-label>
                <mat-form-field appearance="outline" style="font-size: 14.5px;">
                    <mat-select [value]="questionSelcLens" (selectionChange)="onFilterChange($event.value, 'lens')" >
                        <mat-option *ngFor="let category of questionLens" [value]="category"> {{ category }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row filters">
            <div class="col-sm-12 input-container">
                <mat-label>Select Template</mat-label>
                <mat-form-field appearance="outline" style="font-size: 14.5px;">
                    <mat-select [value]="selectedTemplate" (selectionChange)="onTemplateSelect($event)">
                        <mat-option *ngFor="let template of templateList" [value]="template.templateName"> {{
                            template.templateName }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </mat-card>
    <br>
    <div class="end">
        <button class="active-solid-lrg clearBtn" (click)="clearFilters()">
            Clear Filters
        </button>
        <button class="active-solid-lrg w-11" (click)="openDialog('create','dummy element')">
            Add New
        </button>
    </div>
    <br>
   
        <table mat-table [dataSource]="dataSource" style="width: 70vw;margin-top: 20px;">
            <ng-container matColumnDef="activity">
                <th mat-header-cell *matHeaderCellDef> Question </th>
                <td mat-cell *matCellDef="let element"> {{element?.question ?? '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element"> {{element?.category ??'-'}} </td>
            </ng-container>
            <ng-container matColumnDef="subcategory">
                <th mat-header-cell *matHeaderCellDef> Sub Category </th>
                <td mat-cell *matCellDef="let element"> {{element?.subCategory ??'-'}} </td>
            </ng-container>
            <ng-container matColumnDef="lens">
                <th mat-header-cell *matHeaderCellDef> Lens </th>
                <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element?.state ?? '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="!element?.isDefault" (click)="openDialog('edit',element)"  class="icon playPause">mode_edit</mat-icon> &nbsp;
                    <mat-icon *ngIf="!element?.isDefault" (click)="deletePreview(element)"  class="icon bulb" >delete</mat-icon>
                    <mat-icon *ngIf="!element?.isDefault" (click)="openHistory(element)"  class="icon bulb" >info</mat-icon>

                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator
        class="pagination"
          #paginator
          (page)="getPageEvent($event)"
          [length]="totalLength"
          [pageIndex]="(questionPagination.page || 1) - 1"
          [pageSize]="questionPagination.perPage"
          [pageSizeOptions]="questionPagination.paginationSizes"
          [showFirstLastButtons]="true"
          *ngIf="!isEmpty"
        >
        </mat-paginator>
</div>


<div *ngIf="spinner" class="overlay" >
    <mat-spinner class="spinner"></mat-spinner>
</div>