import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagerInteriewsComponent } from './manager-interiews.component';
import {  RouterModule, Routes } from '@angular/router';
import { InterviewSetupComponent } from './interview-setup/interview-setup.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { ResponseManagementComponent } from './response-management/response-management.component';
import { OutputsComponent } from './outputs/outputs.component';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';
import { InterviewPopupComponent } from './interview-setup/interview-popup/interview-popup.component';
import { MiDeepDiveComponent } from './mi-deep-dive/mi-deep-dive.component';

 const route:Routes = [
  {
      path: '',
      component : ManagerInteriewsComponent,
      canActivate: [AuthGuard],
      children : [
       {
        path:'',
        component : InterviewSetupComponent
       },
       {
        path:'setup',
        component : InterviewSetupComponent
       },
       {
        path:'setup2',
        component : InterviewSetupComponent
       },
       {
        path:'responses',
        component : ResponseManagementComponent
       },
       {
        path:'outputs',
        component : OutputsComponent
       },
       {
        path:'mi-deep-dive',
        component : MiDeepDiveComponent
       },
        
      ]
   
  }
]

@NgModule({
  declarations: [
    ManagerInteriewsComponent,
    InterviewSetupComponent,
    ResponseManagementComponent,
    OutputsComponent,
    InterviewPopupComponent,
    MiDeepDiveComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule.forChild(route)
  ],
  exports : [
    RouterModule
  ]
})
export class ManagerInteriewsModule { }