import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from '@app/core/classes/helper';
import { InterviewSetupComponent } from '@app/projects-v2/manager-interview/interview-setup/interview-setup.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationDialogComponent implements OnInit {
  message: string = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  isFromApproval: boolean= false;
  // comments = new FormGroup('',Validators.required)
  commentsForm: any = new FormGroup(
    {
      comments: new FormControl(null, Validators.required),
    },
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<InterviewSetupComponent>
  ) {
    if (data) {
      this.isFromApproval = data.from == 'approval' ? true : false
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  ngOnInit(): void {}

  onConfirmClick(): void {
 
    if ( this.isFromApproval   ) {
    const { emptyFields } = Helper.getEmptyFields(this.commentsForm);
      if(emptyFields.length === 0){
        this.commentsForm.markAsTouched()
        if(this.commentsForm.valid){
           this.dialogRef.close({event:'approval',data:this.commentsForm.value.comments})
        }
      }
     } 
     else{
      this.dialogRef.close(true);
    }
  }
}
