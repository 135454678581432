import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsV2Component } from './projects-v2.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { OverviewComponent } from './overview/overview.component';
import { ConstructionComponent } from './construction/construction.component';



const routes: Routes = [
  {
    path: '',
    component: ProjectsV2Component,
    children: [
      { path: 'overview', component: OverviewComponent, pathMatch: 'full' },
      {
        path:'construction',
        component : ConstructionComponent,
      },
      {
        path: 'op-maturity',
        loadChildren: () =>
          import('./op-maturity/op-maturity.module').then(
            (m) => m.OpMaturityModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'ava-eva',
        loadChildren: () =>
          import('./ava-eva/ava-eva.module').then(
            (m) => m.AVAEVAModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'manager-interview',
        loadChildren: () =>
          import('./manager-interview/manager-interiews.module').then(
            (m) => m.ManagerInteriewsModule
          ),
        canActivate: [AuthGuard],
        
      },
      {
        path: 'op-maturity',
        loadChildren: () =>
          import('./op-maturity/op-maturity.module').then(
            (m) => m.OpMaturityModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'focus-groups',
        loadChildren: () =>
          import('./focus-groups/focus-groups.module').then(
            (m) => m.FocusGroupsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'power-bi',
        loadChildren: () =>
          import('./power-bi/power-bi.module').then(
            (m) => m.PowerBIModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'process-interviews',
        loadChildren: () =>
          import('./process-interviews/process-interviews.module').then(
            (m) => m.ProcessInterviewsModule
          ),
        canActivate: [AuthGuard],
      },

    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsV2RoutingModule { }
