import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-op-maturity',
  templateUrl: './op-maturity.component.html',
  styleUrls: ['./op-maturity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpMaturityComponent implements OnInit {
  render:boolean = false;


  constructor(private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  tabChange(event: any) {

    if (event.index == 1) {
      this.render = true;
    } 
    else{
      this.render = false;
    }
  }

}
