import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from '@app/onboarding/onboarding.component';
import { SharedModule } from '@app/shared/shared.module';
import { ModulesComponent } from '@app/onboarding/modules/modules.component';
import { OnlyNumberDirective } from '@app/core/directives/onlyNumber.directive';
@NgModule({
  declarations: [
    OnboardingComponent,
    ModulesComponent,
    OnlyNumberDirective
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class OnboardingModule { }
