import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeModule } from "./home/home.module";
import { DragScrollModule } from "ngx-drag-scroll";
import { OAuthModule } from "angular-oauth2-oidc";
import { OnboardingModule } from "./onboarding/onboarding.module";
import { LoginComponent } from "./login/login.component";
import { MasterdataModule } from "./masterdata/masterdata.module";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from "./core/guards/auth.guard";
import { AdminAccessGuard } from "./core/guards/admin-access.guard";
import { TokenInterceptor } from "./core/interceptors";
import { WorkflowTemplateModule } from "./workflow-template/workflow-template.module";
import { SurveyModule } from "./survey/survey.module";
import { DatePipe } from "@angular/common";
import { ProjectsModule } from "./projects/projects.module";
import { DatabaseModule } from "./database/database.module";
import { NgApexchartsModule } from "ng-apexcharts";
import { SurveyLandingPageComponent } from "./survey-landing-page/survey-landing-page.component";
import { ProjectsV2Module } from "./projects-v2/projects-v2.module";
import { AVAEVAModule } from "./projects-v2/ava-eva/ava-eva.module";
import { MiSurveyPageComponent } from "./mi-survey-page/mi-survey-page.component";
import { FocusGroupsSurveyPageComponent } from "./focus-groups-survey-page/focus-groups-survey-page.component";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from "@angular/material/snack-bar";
import { PoiSurveyPageComponent } from "./poi-survey-page/poi-survey-page.component";
import { NumericOnlyDirective } from "./core/directives/numericOnly.directive";
import { SigninCallbackComponent } from "./signin-callback/signin-callback.component";
import { FooterComponent } from './footer/footer.component';

// import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu'; // (Multiple Menu module for sidebar)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SurveyLandingPageComponent,
    MiSurveyPageComponent,
    FocusGroupsSurveyPageComponent,
    PoiSurveyPageComponent,
    SigninCallbackComponent,
    NumericOnlyDirective,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // NgMaterialMultilevelMenuModule, // (Multiple Menu module for sidebar)
    HomeModule,
    DragScrollModule,
    CoreModule,
    SharedModule,
    OnboardingModule,
    MasterdataModule,
    WorkflowTemplateModule,
    SurveyModule,
    ProjectsModule,
    DatabaseModule,
    ProjectsV2Module,
    AVAEVAModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgApexchartsModule,
    MatSnackBarModule,
  ],
  providers: [
    // MultilevelMenuService
    AuthGuard,
    AdminAccessGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
