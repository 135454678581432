<br>
<div class="content">
    <div class="">
    
        <section class="master-data">
            <mat-card>
                <div class="row filters" >
                    <div class="col-sm-4 input-container">
                        <mat-label>Search</mat-label>
                        <mat-form-field appearance="outline" style="font-size: 12.5px;">
                            <input matInput #input [formControl]="search" (input)="onSearchChange($event)" />
                        </mat-form-field>
                    </div>
                    
                    <div class="col-sm-4 input-container">
                        <mat-label>Select Module</mat-label>
                        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                            <mat-select [formControl]="searchModule" (selectionChange)="onModuleSelect($event)">
                    
                                <mat-option *ngFor="let module of moduleList" [value]="module.value"> {{ module.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div class="col-sm-4 input-container">
                        <mat-label>Select Category</mat-label>
                        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                            <mat-select [formControl]="searchCategory" (selectionChange)="onCategorySelect($event)">
                                <mat-option *ngFor="let category of categoryList" [value]="category.name"> {{ category.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </mat-card>
            <br>
            <div class="end">
                <button class="active-solid-lrg clearBtn" (click)="clearFilters()">
                    Clear Filters
                </button>
            </div>
            <br>
            <table mat-table [dataSource]="dataSource" class="w-100">

            
               
              
        
                <!-- <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef>State</th>
                  <td mat-cell *matCellDef="let element">{{element.state}}</td>
                </ng-container>-->

              
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let element">{{element?.category}}</td>
                </ng-container> 

                <ng-container matColumnDef="module">
                  <th mat-header-cell *matHeaderCellDef>Module</th>
                  <td mat-cell *matCellDef="let element">{{ getModuleName(element.module)}}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let element" [ngClass]="getQuestionState(element)">
                    {{ getQuestionState(element)}}
                </td>
                </ng-container>

                <ng-container matColumnDef="question">
                  <th mat-header-cell *matHeaderCellDef>Question</th>
                  <td mat-cell *matCellDef="let element">{{element?.question}}</td>
                </ng-container>
              
                <!-- action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="display: flex;gap: 1em;justify-content: center;margin-top: 1em;">Actions</th>
                  <td mat-cell *matCellDef="let element">
                    <div style="display: flex;gap: 1em;justify-content: center;margin-top: 1em;">
                        <mat-icon (click)="confirm('Approve', element)">check_circle_outline</mat-icon>
                        <mat-icon (click)="confirm('Reject',element)">highlight_off</mat-icon>
                    </div>

                </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                ></tr>
              </table>
              <br>
            <mat-paginator (page)="getPageEvent($event)"
                [length]="totalLength"
                [pageIndex]="(clientsPagination.page || 1) - 1"
                [pageSize]="clientsPagination.perPage"
                [pageSizeOptions]="clientsPagination.paginationSizes"
                showFirstLastButtons
                aria-label="Select page">
            </mat-paginator>
        </section>
        <br><br>
    </div>

</div>
