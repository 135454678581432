import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '@app/home/home/home.service';
import { OnboardingService } from '@app/onboarding/onboarding.service';

const ELEMENT_DATA: any[] = [
  {position: 1, name: 'Operational Maturity', weight: 1.0079, symbol: 'H',color:'green',status:'complete'},
  {position: 2, name: 'Manager Interviews', weight: 4.0026, symbol: 'He',color:'',status:'Workflow status'},
  {position: 3, name: 'AVA', weight: 6.941, symbol: 'Li',color:'red',status:'overdue'},
  {position: 4, name: 'EVA', weight: 9.0122, symbol: 'Be',color:'',status:'Workflow status'},
  {position: 5, name: 'Process Owner Interviews', weight: 10.811, symbol: 'B',color:'yellow',status:'Workflow status'},
];

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  edit:boolean = true;
  displayedColumns: string[] = ['Module', 'Workflow', 'Owner','Deadline'];
  dataSource = ELEMENT_DATA;



  clientId: string;

  clientData:any;
  organisationData:any;
  totalFTE: number = 0;

  selected_AVA_EVA:any = 0;
  toggleTo:boolean = false;

  constructor(
    private homeService:HomeService,
    private onboardingService:OnboardingService,
    private route: ActivatedRoute,
    private sb: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id") || "";
    this.getClientById();
    this.getOrganisationByClientId();
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('ava/eva-index => ', tabChangeEvent.index);
    this.selected_AVA_EVA = tabChangeEvent.index;
    
  }

  toggle() {
    this.toggleTo = !this.toggleTo;
    let message = ''
    console.log(this.toggleTo);
    if(this.toggleTo){
      message = "Switching To EVA .."
    }else{
      message = "Switching To AVA .."
    }
    this.sb.open(message, 'close',{
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    
  }

  getClientById(){
    this.homeService.getClients(this.clientId).subscribe(res=>{
      this.clientData = res[0]
    })
  }

  getOrganisationByClientId(){
    this.onboardingService.getOrganisationByClientId(this.clientId).subscribe(organisationData=>{

      /* TODO: Display the organisation multiple team name and FTE count */
      this.organisationData = organisationData[0];
      this.totalFTE = this.organisationData?.teams[0].fteManagers + this.organisationData?.teams[0].fteMembers;
    })
  }

}
