<br>
<div class="content">
    <section class="arr-buttons">
        <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
        <a class="next round"><i class="fa-solid fa-chevron-right"></i></a> &nbsp;
        <span class="title">&nbsp; Create Workflow</span>
    </section>
</div>
<br>

<mat-tab-group class="invisible-tabs" [(selectedIndex)]="TabIndex">
    <mat-tab label="Add">
      <!-- <mat-card class="input">
        <mat-form-field appearance="outline" class="size-select">
          <mat-label>Enter Template Name</mat-label>
          <input matInput placeholder="">
        </mat-form-field>
      
        <mat-form-field appearance="outline" class="size-select">
          <mat-label>Modules</mat-label>
          <mat-select (selectionChange)="onModuleSelect($event)">
            <mat-option *ngFor="let module of clientModuleData" [value]="module">
              {{module}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card> -->
      <br>
        <ul class="flex-container">
          <li class="flex-item questions">
            <mat-card style="font-size: 15px;
            padding-bottom: 0;
            width: 30em;
            margin: 0;">
              <mat-form-field appearance="outline" class="size-select">
                <mat-label>Enter Template Name</mat-label>
                <input matInput [formControl]="templateNameControl" placeholder="">
              </mat-form-field>
            </mat-card>
            <br>
              <mat-card>
                <mat-form-field appearance="outline"  class="search">
                  <mat-label>Search</mat-label>
                  <input matInput type="text">
                  <!-- <button  matSuffix mat-icon-button aria-label="Clear">
                    <mat-icon>close</mat-icon>
                  </button> -->
                </mat-form-field>
                <br>
                <mat-card style="height: 38vh;border: 2px dashed #3F51B5;"
                *ngIf="referenceData.length === 0">
                  <span class="empty">
                    Select Modules to display questions
                  </span>
                </mat-card>
                <div cdkDropList #questionList="cdkDropList" [cdkDropListData]="referenceData"
                  [cdkDropListConnectedTo]="[tag1,tag2,tag3,tag4]" class="movie-list" (cdkDropListDropped)="onDrop($event)">
                  <div class="movie-block" *ngFor="let data of referenceData" cdkDrag>{{data.question}}</div>
                </div>
              </mat-card>
          </li>
          <li class="flex-item">
            <mat-card style="font-size: 15px;
            padding-bottom: 0;
            width: 37.5em;
            margin-left: 11.5em;">
              <mat-form-field appearance="outline" class="size-module">
                <mat-label>Modules</mat-label>
                <mat-select (selectionChange)="onModuleSelect($event)">
                  <mat-option *ngFor="let module of clientModuleData" [value]="module.name">
                    {{module.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card>
            <br>
            <ul class="flex-container">
              <li class="flex-container" style="width: 45vw;">
              <div class="column">
                <mat-card 
                cdkDropList #tag1="cdkDropList" [cdkDropListData]="Tag1" 
                [cdkDropListConnectedTo]="[questionList]" class="effect" 
                (cdkDropListDropped)="onDrop($event)"
                matBadge="{{Tag1.length }}">
                  <section>
                    <div class="tag">
                      AVA
                    </div>
                  </section>
                </mat-card>
              </div>
              <div class="column">
                <mat-card
                cdkDropList #tag2="cdkDropList" [cdkDropListData]="Tag2" 
                [cdkDropListConnectedTo]="[questionList]" class="effect"  (cdkDropListDropped)="onDrop($event)"
                matBadge="{{Tag2.length }}">
                  <section>
                    <div class="tag">
                      EVA
                    </div>
                  </section>
                </mat-card>
              </div>
              <div class="column">
                <mat-card
                cdkDropList #tag3="cdkDropList" [cdkDropListData]="Tag3"
                [cdkDropListConnectedTo]="[questionList]" class="effect" (cdkDropListDropped)="onDrop($event)"
                matBadge="{{Tag3.length }}">
                  <section>
                    <div class="tag">
                      FreeText
                    </div>
                  </section>
                </mat-card>
              </div>
              <div class="column">
                <mat-card
                cdkDropList #tag4="cdkDropList" [cdkDropListData]="Tag4"
              [cdkDropListConnectedTo]="[questionList]" class="effect" (cdkDropListDropped)="onDrop($event)"
                matBadge="{{Tag4.length }}">
                  <section>
                    <div class="tag">
                      ManagersOnly
                    </div>
                  </section>
                </mat-card>
              </div>
              </li>
            </ul>
          </li>
        </ul>
    </mat-tab>
    <mat-tab label="Preview">
      <app-preview></app-preview>
    </mat-tab>
</mat-tab-group>
<br>
<section class="clientab" *ngIf="TabIndex === 0">
  <div class="basic-container">
      <div class="custom-pad">
          <!-- <mat-card-content> -->
              <div class="example-container">

                
                  <div class="three-buttons">
                    <button *ngIf="TabIndex === 1" class="active-solid-lrg" (click)="TabIndex = 0" >Back</button>
                      <button class="active-stroke-lrg cancel">Save as Draft</button> &nbsp;
                      <button *ngIf="TabIndex == 0" class="active-solid-lrg" (click)="onSave()" >Next</button>
                  </div>
              </div>
          <!-- </mat-card-content> -->
        </div>
  </div>
</section>
