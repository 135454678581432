import { Component, OnInit, inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../core/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { SnackBarService } from "@app/core/services/snackbar.service";
import { HttpResponse } from "@angular/common/http";
import { first } from "rxjs";

@Component({
  selector: "app-signin-callback",
  templateUrl: "./signin-callback.component.html",
  styleUrls: ["./signin-callback.component.scss"],
})
export class SigninCallbackComponent implements OnInit {
  loginForm: any;

  private readonly authService = inject(AuthService)

  constructor(
    private router: Router,
    public snackBar: SnackBarService
  ) {
  }
    ngOnInit(): void {
        this.authService.userManager.signinCallback().finally(() => {
            console.log("logged in");
            let id_token = "";
            const length = sessionStorage.length;
            for(let i = 0; i < length ; i++) {
              const key = sessionStorage.key(i);
              const value = sessionStorage.getItem(key != null ? key : "");
              id_token = JSON.parse(value ? value : "{}")['id_token']
            }
            if(id_token  && id_token != "") {
              localStorage.setItem("id_token", id_token);
            }
            this.authService.authorize().pipe(first()).subscribe((response : HttpResponse<any>) => {
              localStorage.setItem("encodedRole",response.body);
              this.router.navigate(["/home"]);
            }, (error : any)  => {
              this.snackBar.open("Error logging in, Please contact Administrator", "close");
              this.router.navigate([""]);
            });
        })
    }
}
