
<!-- <mat-tab-group class="tabGroup" mat-align-tabs="left" >
    <mat-tab label="Setup 1" ></mat-tab>
    <mat-tab label="Setup 2" ></mat-tab>
</mat-tab-group> -->
<!-- <mat-chip-list aria-label="Fish selection">
    <mat-chip color="primary" selected>Setup 1</mat-chip>
    <mat-chip color="accent" selected>Setup 2</mat-chip>
</mat-chip-list> -->
<div class="stepbtns" >
  <!-- <div class="center">
      <button (click)="step1 = true; steps()" mat-flat-button >
          <i *ngIf="step1" class="fa-solid fa-circle-check fa-xl"></i>
          Step 1 
      </button>
      <button (click)="step1 = false; steps()" mat-flat-button >
          <i *ngIf="!step1" class="fa-solid fa-circle-check fa-xl"></i>
          Step 2
      </button>
  </div> -->
  
</div>

<mat-card *ngIf="step1">
  <form [formGroup]="interviewCreateForm">
    <div class="row">
      <div class="col-sm-4 input-container">
        <mat-label>Name</mat-label>
        <mat-form-field appearance="outline">
          <input type="text" matInput formControlName="interviewName">
        </mat-form-field>
      </div>

      <div class="col-sm-4 input-container">
        <mat-label>Function</mat-label>
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="assignFunction($event.value)" formControlName="clientFunction">
            <mat-option *ngFor="let item of functions" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4 input-container">
        <mat-label>Team</mat-label>
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="assignTeam($event.value)" formControlName="teamName">
            <mat-option *ngFor="let item of teams " [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4 input-container">
        <mat-label>Role</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="role">
            <mat-option *ngFor="let item of role" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-4 input-container">
        <mat-label>Date</mat-label>
        <mat-form-field appearance="outline" >
          <input readonly="true" matInput [matDatepicker]="picker" formControlName="scheduledDate" [min]="todayDate" >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-4 input-container">
        <mat-label>Interviewer</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="interviewer">
            <mat-option *ngFor="let item of owners" [value]="item">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

  </form>
</mat-card>
<br>

<section class="two-butns" *ngIf="step1">
  <button class="active-solid-lrg btnwidth siz" *ngIf="type == 'create'" (click)="save('create')"> Create Interview</button>
  <button class="active-solid-lrg btnwidth siz" *ngIf="type == 'update'"  (click)="save('update')"> Update Interview</button>
</section>
<br *ngIf="step1">

<section *ngIf="step1"  class="step-1">
<table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="datasource1" class="step-1table">
    <ng-container matColumnDef="candidate">
      <th mat-header-cell mat-sort-header="candidate" *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"> {{element?.interviewName ?? '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="team">
      <th mat-header-cell mat-sort-header="team"  *matHeaderCellDef> Team </th>
      <td mat-cell *matCellDef="let element"> {{element?.teamName ?? '-' }} </td>
    </ng-container>

    <ng-container matColumnDef="function">
      <th mat-header-cell mat-sort-header="function" *matHeaderCellDef> Function</th>
      <td mat-cell *matCellDef="let element"> {{element?.clientFunction ?? '-'}} </td>
    </ng-container>

      <!-- <ng-container matColumnDef="timespent">
        <th mat-header-cell *matHeaderCellDef> Time Spent </th>
        <td mat-cell *matCellDef="let element"> {{element.time}} </td>
      </ng-container> -->

    <ng-container matColumnDef="team-manager">
      <th mat-header-cell mat-sort-header="team-manager" *matHeaderCellDef> Role</th>
      <td mat-cell *matCellDef="let element"> {{element?.role ?? '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header="date" *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.scheduledDate | date:'dd-MM-YYYY' }} </td>
    </ng-container>
  
    <ng-container matColumnDef="interviewer">
      <th mat-header-cell mat-sort-header="interviewer" *matHeaderCellDef> Interviewer </th>
      <td mat-cell *matCellDef="let element"> {{element?.interviewer ?? '-'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="template">
      <th mat-header-cell mat-sort-header="template" *matHeaderCellDef> Template </th>
      <td mat-cell *matCellDef="let element"> {{element?.templateDetail?.templateName ?? 'Not Created'}} </td>
    </ng-container>
  
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" >
        <mat-icon *ngIf="element.status != 'Complete'" class="icon playPause" (click)="patchValue(element)" >mode_edit</mat-icon>
        <mat-icon *ngIf="element.status != 'Complete'" class="icon bulb" (click)="openDeleteDialog(element)" >delete</mat-icon>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row *matRowDef="let row; columns: dc;"></tr>
  </table>
  <br>
  <!-- <div class="two-butns">
    <button *ngIf="step1" class="active-solid-lrg siz" (click)="openPopup('create','create')">
      Create Interview
    </button>
  </div> -->
</section>
<br *ngIf="step1">
<section class="two-butns" *ngIf="step1">
  <button class="active-stroke-lrg btnwidth siz" (click)="clearForm()">Cancel </button>

  <button matTooltip="To add questions" [ngClass]="{'val': !canClick}" *ngIf="step1" class="active-solid-lrg siz" 
   (click)="step1 = false; steps()">
    Next
  </button>
</section>

<section *ngIf="!step1">
<mat-card class="the-flex">
  <div class="col-sm-6 input-container">
    <mat-label>Interviews</mat-label>
    <mat-form-field appearance="outline">
      <mat-select multiple [formControl]="interviewSelected" [(ngModel)]="selectedInterview" [compareWith]="comparer">
        <mat-option *ngFor="let item of interviews" [value]="item">
          {{item?.interviewName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-6 input-container">
    <mat-label>Build from Template</mat-label>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="templateSelected" 
      (selectionChange)="templateChange($event.value)">
        <mat-option *ngFor="let item of templates" [value]="item">
          {{item?.templateName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>

<br>
<div class="two-butns">
  <button class="active-solid-lrg white siz" (click)="step1 = true; steps()" matTooltip="To Create Interviews">Back</button>
  <button class="active-solid-lrg siz" (click)="buildfromDb('managerInterviews')">Build from Database</button>
  <button (click)="opendialog('create','dummy1','dummy2')" class="active-solid-lrg siz" >
    Add Question
</button>
<button class="active-solid-lrg siz" (click)="preview()">Preview</button>
</div>

</section >
<br>

<section  *ngIf="!step1" class="step-1">
  <table matSort (matSortChange)="sortRefData($event)" mat-table [dataSource]="datasource2" class="step-1table">
    <ng-container matColumnDef="category">
      <th mat-header-cell mat-sort-header="category" *matHeaderCellDef> Category</th>
      <td mat-cell *matCellDef="let element"> {{element?.category ?? '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="sub-category">
      <th mat-header-cell mat-sort-header="sub-category" *matHeaderCellDef>Sub Category</th>
      <td mat-cell *matCellDef="let element"> {{element?.subCategory ?? '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="lens">
      <th mat-header-cell mat-sort-header="lens" *matHeaderCellDef>Lens</th>
      <td mat-cell *matCellDef="let element"> {{element?.lens ?? '-'}} </td>
    </ng-container>

      <ng-container matColumnDef="questionid">
        <th mat-header-cell *matHeaderCellDef>  Question Id</th>
        <td mat-cell *matCellDef="let element"> {{element?.questionId ?? '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="questionorder">
        <th mat-header-cell *matHeaderCellDef>   Question Order</th>
        <td mat-cell *matCellDef="let element"> {{element?.questionOrder ?? '-'}} </td>
      </ng-container> 

      <ng-container matColumnDef="question" class="truncate">
        <th mat-header-cell mat-sort-header="question" *matHeaderCellDef> Question</th>
        <td mat-cell *matCellDef="let element"> {{element?.question  ?? '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element; let i = index" >
          <mat-icon (click)="opendialog('edit',element,i)" *ngIf="!isDefaultTemplate" class="icon playPause" >mode_edit</mat-icon>
          <mat-icon (click)="deletePreview(element, i)" *ngIf="!isDefaultTemplate" class="icon bulb"  >delete</mat-icon>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="dc2"></tr>
      <tr mat-row *matRowDef="let row; columns: dc2;"></tr>
    </table>
</section>

<section *ngIf="!step1" class="footerbtns">
<div >
  <!-- <button  class="largebtn" mat-raised-button color="accent" (click)="preview()" >
    <i class="fa-regular fa-regular fa-eye fa-lg"></i>
    Preview
</button> -->

  <!-- <button class="active-solid-lrg" style="text-decoration: underline;" (click)="preview()">Survey Preview</button> -->

</div>
<!-- <div>
  <button  class="largebtn" mat-raised-button color="primary"  >
    <i class="far fa-calendar-plus fa-lg"></i>
    Create 
</button>
</div> -->

</section >

<!-- <div class="spinner-container" >
<mat-spinner></mat-spinner>
</div> -->
