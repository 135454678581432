import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from '@app/survey/survey.component';
import { SurveyAddComponent } from '@app/survey/survey-add/survey-add.component';
import { SharedModule } from '@app/shared/shared.module';



@NgModule({
  declarations: [
    SurveyComponent,
    SurveyAddComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class SurveyModule { }
