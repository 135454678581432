import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})

// Note :This gaurd will redirect to login page if not user is not Admin
export class AdminAccessGuard implements CanActivate {
  constructor(private router: Router, private authservice: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const token = localStorage.getItem("encodedRole");
    if (token) {
      if (!this.authservice.checkAdminRights(`${token}`)) {
        localStorage.removeItem("token");
        this.router.navigateByUrl("");
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
