import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from "@angular/core";

@Component({
  selector: "app-circle-chart",
  templateUrl: "./circle-chart.component.html",
  styleUrls: ["./circle-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleChartComponent implements OnInit {
  constructor() {}

  private _chartText: any;
  @Input() get chartText() {
    return this._chartText;
  }
  set chartText(value: any) {
    this._chartText = value;
  }

  private _chartValue: number;
  @Input() get chartValue() {
    return this._chartValue;
  }
  set chartValue(value: any) {
    this._chartValue = value;
  }

  hideProgressBar: boolean = false;

  ngOnInit() {
    this.hideProgressBar = this.chartValue ? false : true;
  }
}
