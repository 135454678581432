<!-- <div mat-dialog-title>
    Confirmation
    <mat-icon
      mat-dialog-close
      class="close-button mat-dialog-close"
      svgIcon="appClose"
    ></mat-icon>
  </div> -->
  <mat-dialog-content style="display: flex; flex-direction: column; margin: 0em;" >
    <h4 class="text-center">
      {{ message }}
    </h4>
    <form [formGroup]="commentsForm">
      <mat-form-field appearance="outline" *ngIf="isFromApproval" class="comments-textarea" >
        <input matInput formControlName ="comments" type="text" name="comments" id="comments"
         placeholder="Comments" autocomplete="off" >
     </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <section class="cb">
      <button
      mat-dialog-close
      tabindex="-1"
      class="active-solid-lrg white fit" 
    >
      {{ cancelButtonText }}
    </button>
    <button
      (click)="onConfirmClick()"
      tabindex="1"
      class="active-solid-lrg red fit" 
    >
      {{ confirmButtonText }}
    </button>
    </section>
  </mat-dialog-actions>
  