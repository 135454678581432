export const QUESTION_STATES = [
    { name: "DRAFT"},
    { name: "SUBMITTED"},
    { name: "APPROVED"},
    { name: "REJECTED"}
];

export const TAGS = [
    { name: "AVA", value: "AVA"},
    { name: "EVA", value: "EVA"},
    { name: "FreeText", value: "FREETEXT"},
    { name: "ManagersOnly", value: "MANAGERSONLY"}
];

export enum Modules {
AVA = "AVA",
EVA = "EVA",
MANAGERINTERVIEWS = "managerInterviews",
FOCUSGROUP = "focusGroup",
OPMATURITY = "opMaturity",
PROCESSOWNERINTERVIEW = "processOwners",
}

export const TAGS_ObJ = Object.fromEntries( TAGS.map(obj => [obj.value, obj.name]) );
export const QUESTION_STATES_ObJ = Object.fromEntries( QUESTION_STATES.map(obj => [obj.name, obj.name]) );


export const SURVEY_DETAIL_DATA = [
    {
      "_id": "6478754be6a80d882facb422",
      "clientFunction": "Credit/Lending",
      "clientId": "6476a5b63fe44a2f0609a24e",
      "isManager": false,
      "surveyId": "6478754b6bcc706e76f01ac8",
      "teamName": "testing",
      "__v": 0,
      "createdAt": "2023-06-01T10:39:07.885Z",
      "link": "http://localhost:4200/ava/survey-page/6478754b6bcc706e76f01ac8",
      "updatedAt": "2023-06-01T10:39:07.885Z"
    },
    {
      "_id": "6478754be6a80d882facb423",
      "clientFunction": "Credit/Lending",
      "clientId": "6476a5b63fe44a2f0609a24e",
      "isManager": true,
      "surveyId": "6478754b6bcc706e76f01ac8",
      "teamName": "testing",
      "__v": 0,
      "createdAt": "2023-06-01T10:39:07.885Z",
      "link": "http://localhost:4200/ava/survey-page/6478754b6bcc706e76f01ac8",
      "updatedAt": "2023-06-01T10:39:07.885Z"
    }
  ];

export const FG_RESPONSE_DATA = [

    {
      "_id": "648ae2cd4ad1e44ab4e41c4f",
      "clientId": "647dba3941826b3aa21cc9e2",
      "isManager": true,
      "surveyData": {
        "templates": [
          {
            "templateId": "64741118a66d862ec8defbbd",
            "module": "focusGroup"
          }
        ],
        "startDate": "2023-06-15T10:07:08.935Z",
        "endDate": "2023-06-15T10:07:08.935Z",
        "user": "64741111809eea89388fd603"
      },
      "createdAt": "2023-06-15T10:07:09.001Z",
      "link": "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      "updatedAt": "2023-06-15T10:07:09.031Z",
      "module": "focusGroup",
      "clicks": 4,
      "focusGroupData": {
        "_id" : "64940330ec53a0a873e655d6",
        "focusGroupName" : "Focus Group 001",
        "status" : "Pending",
        "scheduledDate" : "2023-06-22T00:00:00.000Z",
        "interviewer" : "moderator moderator",
        "clientId" : "647495f40d6f7c0a19a8495b",
        "templateId": "6483329cdb4af57abeb73d68"
      }
    },
    {
      "_id": "648ae2cd4ad1e44ab4e41c4f",
      "clientId": "647dba3941826b3aa21cc9e2",
      "isManager": false,
      "surveyData": {
        "templates": [
          {
            "templateId": "64741118a66d862ec8defbbd",
            "module": "focusGroup"
          }
        ],
        "startDate": "2023-06-15T10:07:08.935Z",
        "endDate": "2023-06-15T10:07:08.935Z",
        "user": "64741111809eea89388fd603"
      },
      "createdAt": "2023-06-15T10:07:09.001Z",
      "link": "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      "updatedAt": "2023-06-15T10:07:09.031Z",
      "module": "focusGroup",
      "clicks": 0,
      "focusGroupData": {
        "_id" : "64940330ec53a0a873e655d6",
        "focusGroupName" : "Focus Group 002",
        "status" : "Pending",
        "scheduledDate" : "2023-06-22T00:00:00.000Z",
        "interviewer" : "moderator moderator",
        "clientId" : "647495f40d6f7c0a19a8495b",
        "templateId": "6483329cdb4af57abeb73d68"
      }
    },
    {
      "_id": "648ae2cd4ad1e44ab4e41c4f",
      "clientId": "647dba3941826b3aa21cc9e2",
      "isManager": true,
      "surveyData": {
        "templates": [
          {
            "templateId": "64741118a66d862ec8defbbd",
            "module": "focusGroup"
          }
        ],
        "startDate": "2023-06-15T10:07:08.935Z",
        "endDate": "2023-06-15T10:07:08.935Z",
        "user": "64741111809eea89388fd603"
      },
      "createdAt": "2023-06-15T10:07:09.001Z",
      "link": "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      "updatedAt": "2023-06-15T10:07:09.031Z",
      "module": "focusGroup",
      "clicks": 1,
      "focusGroupData": {
        "_id" : "64940330ec53a0a873e655d6",
        "focusGroupName" : "Focus Group 003",
        "status" : "Pending",
        "scheduledDate" : "2023-06-22T00:00:00.000Z",
        "interviewer" : "moderator moderator",
        "clientId" : "647495f40d6f7c0a19a8495b",
        "templateId": "6483329cdb4af57abeb73d68"
      }
    }
]