import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "@app/core/layout/layout.component";
// import { HomeComponent } from "@app/home/home.component"
import { OnboardingComponent } from "@app/onboarding/onboarding.component";
import { LoginComponent } from "@app/login/login.component";
import { MasterdataComponent } from "@app/masterdata/masterdata.component";
import { ApprovalComponent } from "@app/masterdata/approval/approval.component";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { AdminAccessGuard } from "@app/core/guards/admin-access.guard";
import { CreateTemplateComponent } from "@app/workflow-template/create-template/create-template.component";
import { PreviewComponent } from "@app/workflow-template/preview/preview.component";
import { SurveyComponent } from "@app/survey/survey.component";
import { SurveyAddComponent } from "@app/survey/survey-add/survey-add.component";
import { ProjectsComponent } from "@app/projects/projects.component";
// import { DatabaseComponent } from "@app/database/database.component";
// import { DbOpMaturityComponent } from "@app/database/db-op-maturity/db-op-maturity.component";
// import { DbAvaComponent } from "@app/database/db-ava/db-ava.component";
// import { DbEvaComponent } from "@app/database/db-eva/db-eva.component";
import { SurveyLandingPageComponent } from "./survey-landing-page/survey-landing-page.component";
import { MiSurveyPageComponent } from "./mi-survey-page/mi-survey-page.component";
import { FocusGroupsSurveyPageComponent } from "./focus-groups-survey-page/focus-groups-survey-page.component";
import { TemplateListComponent } from "./masterdata/template-list/template-list.component";
import { PoiSurveyPageComponent } from "./poi-survey-page/poi-survey-page.component";
import { RoleGuard } from "./core/guards/role-guard.guard";
import { SigninCallbackComponent } from "./signin-callback/signin-callback.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "signin-callback",
    component: SigninCallbackComponent,
  },
  {
    path: "submit-survey/:surveyDetailId",
    component: SurveyLandingPageComponent,
  },
  {
    path: "project/:id/add-survey",
    // component: SurveyAddComponent,
    component: SurveyLandingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mi-survey/preview",
    component: MiSurveyPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "mi-submit-survey/:surveyDetailId",
    component: MiSurveyPageComponent,
  },
  {
    path: "fg-survey/preview",
    component: FocusGroupsSurveyPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fg-submit-survey/:surveyDetailId",
    component: FocusGroupsSurveyPageComponent,
  },
  {
    path: "poi-survey/preview",
    component: PoiSurveyPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "poi-submit-survey/:surveyDetailId",
    component: PoiSurveyPageComponent,
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "project-list",
        loadChildren: () =>
          import("./project-list/project-list.module").then(
            (m) => m.ProjectListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "onboard",
        component: OnboardingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "onboard/:type",
        component: OnboardingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "master-data",
        component: MasterdataComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "approve-data",
        component: MasterdataComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            component: ApprovalComponent,
            canActivate: [AdminAccessGuard],
          },
          {
            path: "approval",
            component: ApprovalComponent,
            canActivate: [AdminAccessGuard],
          },
          {
            path: "template-list",
            component: TemplateListComponent,
            canActivate: [AdminAccessGuard],
          },
        ],
      },
      {
        path: "template",
        component: CreateTemplateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ":type/:id/preview",
        component: PreviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "survey",
        component: SurveyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "project/:id",
        loadChildren: () =>
          import("./projects-v2/projects-v2.module").then(
            (m) => m.ProjectsV2Module
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "database",
        loadChildren: () =>
          import("./database/database.module").then((m) => m.DatabaseModule),
        canActivate: [AuthGuard],
      },
      {
        path: "dashboard/:id",
        loadChildren: () =>
          import("./client-dashboard/client-dashboard.module").then(
            (m) => m.ClientDashboardModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
