import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionAddDialogComponent } from "@app/masterdata/question-add-dialog/question-add-dialog.component";
import { TAGS_ObJ } from "@app/resources/question-state";
import { SurveyService } from "@app/survey/survey.service";
import { WorkflowTemplateService } from "../workflow-template.service";
@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {
  dialog: any;
  surveyId: string = "";
  templateId: string = "";
  page: string = "";
  tag: any = TAGS_ObJ;
  templateDetail: any = {};
  surveyDetails: any;
  clientSelectedTemplateId: any;

  Tag1 = [];

  Tag2 = [];
  Tag3 = [];
  Tag4 = [];
  routeType: any;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private workflowTemplateService: WorkflowTemplateService,
    private surveyService: SurveyService
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      this.routeType = params.get("type");
    });

    this.surveyId = this.activeRoute.snapshot.paramMap.get("id") || "";
    this.page = this.activeRoute.snapshot.paramMap.get("type") || "";
    this.templateId = this.activeRoute.snapshot.paramMap.get("id") || "";


    if (this.page === "survey") {
      this.getSurveyBySurveyId();
    }

    if (this.page === "template") {
      this.getTemplateData();
    }
  }

  getTemplateData() {
    this.workflowTemplateService
      .getTemplateByQuery("id", this.templateId)
      .subscribe(
        (response) => {
          this.templateDetail = response.data[0];
        },
        (error) => {
          console.log("=== ERROR template response ===", error);
        }
      );
  }

  goTo() {
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: "70em",
      // height: '450px',
      disableClose: false,
      data: {
        from: this.routeType,
        templateId: this.templateId,
      },
    });

    this.dialog.afterClosed().subscribe((submit: any) => {});
  }

  back() {
    this.router.navigateByUrl("/template");

    //this.router.navigate([`/client/${this.clientId}/${this.page}`]);
  }

  delete() {
    const dialogRef = this.matDialog.open(DialogOverview, {
      width: "",
      data: {},
    });
    this.dialog.afterClosed().subscribe((submit: any) => {});
  }

  getSurveyBySurveyId() {
    this.surveyService.getSurveyById(this.surveyId).subscribe((res) => {
      this.surveyDetails = res.data[0];
      this.templateId = res.data[0].templateId;

      this.getTemplateData();
    });
  }

  updateTemplate() {
    const payload = {
      templateName: this.surveyDetails.templateName,
      moduleId: this.surveyDetails.moduleId,
      tags: ["string"],
      questions: [
        {
          tag: "string",
          refId: "string",
        },
      ],
    };

    this.workflowTemplateService
      .updateTemplate("templateId", payload)
      .subscribe((res) => {
        console.log(res);
      });
  }
}

@Component({
  selector: "confirm-dialog",
  templateUrl: "confirm.html",
})
export class DialogOverview {
  constructor(
    public dialogRef: MatDialogRef<DialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
