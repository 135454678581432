import { Component, OnInit } from "@angular/core";;

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
