<mat-card style="display: none;">
  <div class="row" style="display: flex;">
    <div class="col-sm-6">
      <mat-form-field appearance="outline" class="filter">
        <mat-label>Team</mat-label>
        <mat-select (selectionChange)="teamFilter($event.value)" [(ngModel)]="teamFilterOption">
          <mat-option *ngFor="let cat of filters" [value]="cat.Value">
            {{cat.Value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <br>

      <div id="chart">
        <apx-chart [series]="radialChartOptions.series" [chart]="radialChartOptions.chart"
          [plotOptions]="radialChartOptions.plotOptions" [labels]="radialChartOptions.labels"
          [legend]="radialChartOptions.legend" [colors]="radialChartOptions.colors"
          [responsive]="radialChartOptions.responsive"></apx-chart>
      </div>
    </div>

    <div class="col-sm-6">



      <br>
      <section>
        <div id="chart">
          <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions" [xaxis]="chartOptions.xaxis"></apx-chart>
        </div>

      </section>
    </div>
  </div>
</mat-card>

<!-- New -->
<!-- Single select below -->
<!-- <div class="row space filterr">
  <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
    <mat-form-field appearance="outline">
      <mat-label>Function</mat-label>
      <mat-select placeholder="Function" [(ngModel)]="selectedFunction" (selectionChange)="changeFunction($event.value)">
        <mat-option *ngFor="let function of functions" [value]="function">
          {{function}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    

    <mat-form-field appearance="outline">
      <mat-label>Team</mat-label>
      <mat-select placeholder="Team" [(ngModel)]="selectedTeam" (selectionChange)="changeTeam($event.value)">
        <mat-option *ngFor="let team of teams" [value]="team">
          {{team}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-6" style="justify-content: end;display: inline-flex;">
    <button class="active-solid-lrg ex">Export data</button>
    <div style="margin-top: 0.65em;">
                <button matTooltip="Clear All Filters" (click)="clearFilters()" class="active-solid-med size">
                  <mat-icon>clear</mat-icon></button>
        </div>
  </div>
</div> -->

<!-- Multiple select below -->

<mat-tab-group class="tabgroup" [selectedIndex]="selectedTab" (selectedTabChange)="tabChange($event.index)">
  <mat-tab label="DeepDive">
  <app-mi-deep-dive></app-mi-deep-dive>
  </mat-tab>

  <mat-tab label="Overview" class="tab2">
    <br>
    <div class="row space filterr">
      <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
        <!-- <mat-form-field appearance="outline">
          <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunction"
            (selectionChange)="changeFunction($event.value)">
            <mat-option *ngFor="let function of functions" [value]="function">
              {{function}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
    
        <mat-form-field appearance="outline">
          <mat-label>Function</mat-label>
          <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
            (selectionChange)="changeFunction($event.value)">
            <mat-option *ngFor="let function of functions" [value]="function">
              {{function}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="outline">
          <mat-label>Team</mat-label>
          <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
            <mat-option *ngFor="let team of teams" [value]="team">
              {{team}}
            </mat-option>
          </mat-select>
        </mat-form-field>
    
        <!-- <mat-form-field appearance="outline">
          <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeam" (selectionChange)="changeTeam($event.value)">
            <mat-option *ngFor="let team of teams" [value]="team">
              {{team}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <!-- <div class="export-btn-div">
          <button class="export-btn" (click)="downloadData()">Export data</button>
        </div> -->
      </div>
      <div class="col-sm-6 to-end">
        <div>
          <button class="active-solid-lrg ex" (click)="downloadData()">Export data</button>
        </div>
      </div>
    </div>
    
    <br>
    <div>
      <div class="row" style="display: flex;">
        <div class="col-sm-2">
          <mat-card class="fg-cards align avg" style="height: 39.2vh;">
            <span class="head">Operational Maturity Score</span>
            <span class="avg-chart-content" style="padding-left: 6.1em !important;font-size: 20px !important;">{{SinglechartOptions.name}}</span>
                <div id="single">
                  <apx-chart
                    [series]="SinglechartOptions.series"
                    [chart]="SinglechartOptions.chart"
                    [plotOptions]="SinglechartOptions.plotOptions"
                    [colors]="SinglechartOptions.colors"
                    [responsive]="SinglechartOptions.responsive"
                  ></apx-chart>
                </div>
          </mat-card>
        </div>
        <div class="col-sm-9">
         <app-fte-counts></app-fte-counts>
          <!-- <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
            <mat-card class="col-sm-2 fg-cards side align" *ngFor="let chartData of chartDataArray">
              <span class="head">{{chartData.name}}</span>
              <apx-chart [series]="chartData.series" [chart]="chartData.chart" [plotOptions]="chartData.plotOptions"
                [colors]="chartData.colors" [responsive]="chartData.responsive"></apx-chart>
            </mat-card>
          </div> -->
          <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
            <mat-card class="col-sm-2 fg-cards side align" *ngFor="let chartData of chartDataArray">
              <span class="head ellipsis">{{chartData.name}}</span>
              <br>
              <app-circle-chart [chartText]="'N/A'"
              [chartValue]="100" *ngIf="chartData.series == '0.0'" class="n-a"></app-circle-chart>
              <app-circle-chart [chartText]="chartData.series"
              [chartValue]="(chartData.series / this.possiblePoints) * 100" *ngIf="chartData.series != '0.0'"></app-circle-chart>
            </mat-card>
          </div>
        </div>
      </div>
      <br>

        <mat-card >
          <table class="my-table">
            <tr>
              <ng-container *ngFor="let header of displayedColumns; let i = index">
                <th>{{ header }}</th>
              </ng-container>
            </tr>
            <ng-container *ngFor="let row of dataTable; let i = index">
              <tr (click)="onRowClick(row)" [ngClass]="{'selected-row': isRowSelected(row)}">
                <ng-container *ngFor="let header of displayedColumns; let j = index">
                   <td> <div *ngIf="header !== 'Maturity Assessment Score'">
                    {{ row[header] }}
                   </div>
                    <!-- <div *ngIf="header === 'Maturity Score'" class="bar" [style.background]="getBarColor(row[header])">
                      {{ row[header] }}
                    </div>  -->
                    <div class="instyle" *ngIf="header === 'Maturity Assessment Score'">   
                      <mat-progress-bar [value]="row[header]*20"></mat-progress-bar>
                      <div style="position: absolute; padding: 10px; color: white;width: 6vw!important;">  {{ row[header] }}</div>
                  </div>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </table>
        </mat-card>

        <!-- <table mat-table [dataSource]="dataTable" class="col-sm-12" style="margin-left: 1.5em;">
    
          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef> Team </th>
            <td mat-cell *matCellDef="let element" mat-ripple> {{element.name}} </td>
          </ng-container>
    
          <ng-container matColumnDef="function">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let element" mat-ripple> {{element.function}} </td>
          </ng-container>
    
          <ng-container matColumnDef="fte">
            <th mat-header-cell *matHeaderCellDef> Total FTE </th>
            <td mat-cell *matCellDef="let element" mat-ripple> {{element.fte}} </td>
          </ng-container>
    
          <ng-container matColumnDef="ftc">
            <th mat-header-cell *matHeaderCellDef> Total FLC </th>
            <td mat-cell *matCellDef="let element" mat-ripple> {{element.ftc}} </td>
          </ng-container>
    
          <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef> Maturity Assesment Score </th>
            
            <td mat-cell *matCellDef="let element" mat-ripple> 
              <div class="instyle">   
                  <mat-progress-bar [value]="65" class="sizing"></mat-progress-bar>
                  <div style="position: absolute; padding: 10px; color: white;width: 6vw!important;"> 
                    {{element.avg.toFixed(1)}}
                  </div>
              </div>  
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          [ngClass]="{'selected-row': selectedRows.indexOf(row) !== -1}" (click)="toggleSelection(row)"></tr>
        </table> -->
        
      <br>
      <div class="row" style="display: flex;">
        <div class="col-sm-5">
          {{'Sub Categories'}}
          <br>
          <br>
          <mat-card *ngIf="render">
            <apx-chart
            [series]="Radar.series"
            [chart]="Radar.chart"
            [xaxis]="Radar.xaxis"
            [yaxis]="Radar.yaxis"
          ></apx-chart>
          </mat-card>
        </div>
        <div class="col-sm-7">
          {{'Lens Performance'}}
          <br>
          <br>

          <!-- <table mat-table [dataSource]="lensTable" class="col-sm-12" style="margin-left: 1.5em;" >
            <ng-container [matColumnDef]="column" *ngFor="let column of lensColumns">
              <th *ngIf="lensCheck(header)" mat-header-cell *matHeaderCellDef> {{column}} </th>
              <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="lensColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: lensColumns;"></tr>
          </table> -->

          <div class="table-cont">

            <table class="lens-table">
              <tr>
                <ng-container *ngFor="let header of lensColumns; let i = index">
                  <th *ngIf="lensCheck(header)">{{ header }}</th>
                </ng-container>
              </tr>
              <ng-container *ngFor="let row of lensTable; let i = index">
                <tr>
                  <ng-container *ngFor="let header of lensColumns; let j = index">
                     <td [style.color] ="getRowColor(row[header])" *ngIf="lensCheck(header)"> <div>
                      {{ row[header] }}
                     </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </table>
          </div>

        </div>
      
      </div>
      <br><br>
    </div>
  </mat-tab>
</mat-tab-group>

