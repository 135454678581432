import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private httpClient: HttpClient) {}

  clientUrl: string = environment.CLIENT_API_URL;
  apiUrl: string = environment.STATIC_API_URL;
  authUrl: string = environment.AUTH_API_URL;

  getClients(id: string = "") {
    let url = `${this.clientUrl}client`;
    if (id) url = `${url}?id=${id}`;

    return this.httpClient.get<any>(url);
  }

  getModuleData(type: string = "") {
    return this.httpClient.get<any>(`${this.apiUrl}category?type=${type}`);
  }

  getOrganisationByClientId(clientId: string) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/functions?clientId=${clientId}&size=100&pageNumber=0`
    );
  }

  getModulesByClientId(clientId: string) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/modules?clientId=${clientId}&size=100&pageNumber=0`
    );
  }

  getClientCredentials(clientId: string) {
    return this.httpClient.get<any>(
      `${this.authUrl}auth/clientCredentials?clientId=${clientId}`
    );
  }
}
