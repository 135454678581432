import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomService } from "@app/core/services/custom.service";
import { DatabaseService } from "@app/database/database.service";

@Component({
  selector: "app-req-history",
  templateUrl: "./req-history.component.html",
  styleUrls: ["./req-history.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReqHistoryComponent implements OnInit {
  displayedColumns: string[] = ["questions", "status", "comments"];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataSource: any,
    public dialogRef: MatDialogRef<ReqHistoryComponent>,
    private customService: CustomService,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {}
}
