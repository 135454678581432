<br>
<mat-card>
  <div class="row space">
    <div class="col-sm-8 titleCard" style="display: flex;gap: 1.5em;">
      <div class="col-sm-2">
        <span class="head">Interviews</span>
      </div>
      <div class="col-sm-2">
        <span class="head">Functions</span>
      </div>
      <div class="col-sm-2">
        <span class="head">Teams</span>
      </div>
    </div>
    <div class="col-sm-4 titleCard" style="display: flex;gap: 1.5em;">
      <div class="col-sm-2" style="width: 8vw;">
        <span class="head">Complete</span>
      </div>
      <div class="col-sm-2" style="width: 8vw;">
        <span class="head">Incomplete</span>
      </div>
    </div>
  </div>
  
  <div class="row space">
    <mat-card class="col-sm-8 mat-elevation-z0" style="display: flex;gap: 1.5em;">
      <mat-card class="col-sm-2 small mat-elevation-z0">
        <span>{{interviewCount}}</span>
      </mat-card>
      <mat-card class="col-sm-2 small mat-elevation-z0">
        <span>{{functionsCount}}</span>
      </mat-card>
      <mat-card class="col-sm-2 small mat-elevation-z0">
        <span>{{teamsCount}}</span>
      </mat-card>
    </mat-card>
    <mat-card class="col-sm-4 mat-elevation-z0" style="display: flex;gap: 1.5em;">
      <mat-card class="col-sm-2 complete mat-elevation-z0">
        <span>{{completedCount}}</span>
      </mat-card>
      <mat-card class="col-sm-2 incomplete mat-elevation-z0">
        <span style="color: white !important;">{{inCompleteCount}}</span>
      </mat-card>
    </mat-card>
  </div>
</mat-card>
<br>
<section>

    <table mat-table [dataSource]="datasource1" matSort (matSortChange)="sortData($event)" >
        <ng-container matColumnDef="candidate">
          <th mat-header-cell mat-sort-header="candidate" *matHeaderCellDef>Team Leader Name</th>
          <td mat-cell *matCellDef="let element"> {{element?.interviewName ?? '-'}} </td>
        </ng-container>
        <ng-container matColumnDef="team">
          <th mat-header-cell mat-sort-header="team" *matHeaderCellDef> Team Name </th>
          <td mat-cell *matCellDef="let element"> {{element?.teamName ?? '-' }} </td>
        </ng-container>
      
        <ng-container matColumnDef="interviewer">
          <th mat-header-cell mat-sort-header="interviewer" *matHeaderCellDef> Interviewer </th>
          <td mat-cell *matCellDef="let element"> {{element?.interviewer ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <th mat-header-cell mat-sort-header="dueDate" *matHeaderCellDef> Due </th>
          <td mat-cell *matCellDef="let element"> {{getDueDate(element?.scheduledDate.substring(0,10) ?? '-')}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header="status" *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element?.status ?? '-'}} </td>
        </ng-container>

        <ng-container matColumnDef="action" >
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.status != 'Complete'">
              <mat-icon (click)="goToSurveySubmit(element)" matTooltip="Run Interview">play_circle_outline</mat-icon>  
            </span>
            <span *ngIf="element.status != 'Complete'">
              <mat-icon matRipple style="margin-left: 5px;" (click)="copyLink(element.link)" matTooltip="Copy Link">link</mat-icon>  
            </span>
            <span *ngIf="element.status == 'Complete'">
              <mat-icon matRipple style="margin-left: 5px;" (click)="editResponse(element)" matTooltip="Edit Response">edit</mat-icon>  
            </span>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="dc"></tr>
        <tr mat-row *matRowDef="let row; columns: dc;"></tr>
      </table>

</section>

