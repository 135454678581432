import { Component, OnInit, Input } from '@angular/core';
import * as apex from "ng-apexcharts";
import { SurveyService } from '@app/survey/survey.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-responses-management',
  templateUrl: './responses-management.component.html',
  styleUrls: ['./responses-management.component.scss']
})
export class ResponsesManagementComponent implements OnInit {

  displayedColumns2: any[] = [
    "function",
    "team",
    "role",
    "link",
    "responses",
  ];

  response = [
    { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
    { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
    { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
    { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  ];

  series_dn: apex.ApexNonAxisChartSeries;
  chart_dn: apex.ApexChart;
  responsive_dn: apex.ApexResponsive[];
  labels: any;
  @Input() surveyId: string = "";
  surveyDetailList: any = [];
  clientId: string = "";
  surveyData: any = {};

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id") || "";
    this.initializeDoughnutChartOptions();
    this.getSurveyData();
  }

  getSurveyData(){

    let surveyQuery: object = {clientId: this.clientId, module: "ava-eva"};
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe((surveyResponse)=>{
      this.surveyData = surveyResponse['data'][0];
      this.surveyId = this.surveyData['_id'];
      this.getSurveyDetails()
    }, (error) => {
      console.log('Error while getting survey data: ', error);
      
    });
  }

  getSurveyDetails(){
    this.surveyService.getSurveyDetails('surveyId', this.surveyId, this.clientId).subscribe((surveyDetails) => {
      this.surveyDetailList = surveyDetails;
    }, (error) => {
      console.log('Get surveyDetails Error:', error);
    });
  }

  private initializeDoughnutChartOptions(): void {
    this.series_dn = [44, 55, 13, 43, 22];

    this.chart_dn = {
      type: "donut",
    };

    (this.labels = ["Team A", "Team B", "Team C", "Team D", "Team E"]),
      (this.responsive_dn = [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ]);
  }

  getResponseCount(surveyDetailId: string){
    this.surveyService
  }


  goToSurveySubmit(link: string){
    window.open(link, "_blank");
  }
}
