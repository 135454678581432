import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MasterDataService {
  baseUrl: string = environment.STATIC_API_URL;
  constructor(private httpClient: HttpClient) {}

  getTemplates(
    pageNumber: number = 0,
    pagePerItem: number = 5,
    module: string
  ) {
    let url1 = `${this.baseUrl}template?size=${pagePerItem}&pageNumber=${pageNumber}`;
    let url2 = `${this.baseUrl}template?module=${module}&size=${pagePerItem}&pageNumber=${pageNumber}`;

    pageNumber = pageNumber ? pageNumber - 1 : pageNumber;
    let url = module == "" ? url1 : url2;
    return this.httpClient.get<any>(url);
  }

  updateTemplate(templateId: string, payload: any) {
    return this.httpClient.put<any>(
      `${this.baseUrl}template/${templateId}`,
      payload
    );
  }

  deleteTemplate(templateId: string) {
    return this.httpClient.delete<any>(`${this.baseUrl}template/${templateId}`);
  }

  getMasterData(
    pageNumber: number = 0,
    pagePerItem: number = 5,
    searchText: string = "",
    moduleName: string = "",
    categoryName: string = "",
    questionState: string = ""
  ) {
    pageNumber = pageNumber ? pageNumber - 1 : pageNumber;
    // console.log('pageNumber: ', pageNumber, ' pagePerItem: ', pagePerItem, ' searchText: ', searchText);
    // return this.httpClient.get<any>(`${this.baseUrl}refData?size=${pagePerItem}&pageNumber=${pageNumber}&text=${searchText}&category=${categoryName}&module=${moduleName}`)
    let url = `${this.baseUrl}refData?size=${pagePerItem}&pageNumber=${pageNumber}`;
    if (searchText) url = `${url}&text=${searchText}`;
    if (categoryName) url = `${url}&category=${categoryName}`;
    if (moduleName) url = `${url}&module=${moduleName}`;
    // if(questionType) url = `${url}&questionType=${questionType}`;
    if (questionState) url = `${url}&state=${questionState}`;

    return this.httpClient.get<any>(url);
  }

  getMasterDataByQuery(propName: string = "", propValue: string = "") {
    let url = `${this.baseUrl}refData?${propName}=${propValue}`;
    return this.httpClient.get<any>(url);
  }

  getMasterDataById(referenceId: string = "") {
    return this.httpClient.get<any>(`${this.baseUrl}refData?id=${referenceId}`);
  }

  createMasterData(payload: Object = {}) {
    return this.httpClient.post<any>(`${this.baseUrl}refData`, payload);
  }

  updateMasterData(referenceId: string, payload: Object = {}) {
    return this.httpClient.put<any>(
      `${this.baseUrl}refData/${referenceId}`,
      payload
    );
  }
}
