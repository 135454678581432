<h2 mat-dialog-title> 
  {{
   this.type == 'AVA-Ref-Create' ? 'Create AVA Question' 
     : this.type  == 'AVA-Ref-Edit' ? 'Edit AVA Question' :
     this.type == 'EVA-Ref-Create' ? 'Create EVA Question' 
     : this.type  == 'EVA-Ref-Edit' ? 'Edit EVA Question' : 
     this.type == 'MI-Ref-Create' ? 'Create Manager Interview Question' 
     : this.type  == 'MI-Ref-Edit' ? 'Edit Manager Interview Question' : 
     this.type == 'FG-Ref-Create' ? 'Create Focus Group Question' 
     : this.type  == 'FG-Ref-Edit' ? 'Edit Focus Group Question' : 
     this.type == 'POI-Ref-Create' ? 'Create Process Owner Question' 
     : this.type  == 'POI-Ref-Edit' ? 'Edit Process Owner Question' : ''
  }}
</h2>

<mat-dialog-content *ngIf="this.type == 'AVA-Ref-Create' || this.type == 'AVA-Ref-Edit'  " >
<!-- <mat-card>
 <mat-card-content> -->
     <div class="example-container">
         <form [formGroup]="avaRefQuestionform">

         
           <mat-form-field appearance="outline">
             <mat-label>Category</mat-label>
             <mat-select formControlName="category" >
               <mat-option *ngFor="let category of questionCategories" [value]="category">
                 {{category}}
               </mat-option>
             </mat-select>
           </mat-form-field>
         
           <mat-form-field appearance="outline">
             <mat-label>Question Type</mat-label>
             <mat-select formControlName="type" >
               <mat-option *ngFor="let type of questionTypes" [value]="type">
                 {{type}}
               </mat-option>
             </mat-select>
           </mat-form-field>

               <mat-form-field appearance="outline" >
                 <mat-label> Answer type</mat-label>
                 <mat-select [(value)]="selected" (selectionChange)="Selection()" formControlName="questionType">
                   <mat-option value="FREETEXT">Text</mat-option>
                   <mat-option value="RATING">Rating</mat-option>
                   <mat-option value="NUMBER">Number</mat-option>
                   <mat-option value="PERCENTAGE">Percentage</mat-option>
                 </mat-select>
               </mat-form-field>

               <mat-form-field appearance="outline" class="custom-w">
                 <mat-label>Question</mat-label>
                 <input matInput type="text" maxlength="100" formControlName="question" placeholder="Enter Question">
               </mat-form-field>
             
             <!-- <mat-form-field appearance="outline" class="custom-w">
               <mat-label>Note</mat-label>
               <textarea matInput formControlName="note" placeholder="Note"></textarea>
             </mat-form-field> -->
          
           
         </form>
     </div>
 <!-- </mat-card-content>
</mat-card> -->
</mat-dialog-content>

<mat-dialog-content *ngIf="this.type == 'EVA-Ref-Create' || this.type == 'EVA-Ref-Edit'  " >
<!-- <mat-card>
<mat-card-content> -->
  <div class="example-container">
      <form [formGroup]="evaRefQuestionform">
             
        <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
          <mat-label>Category</mat-label>
          <mat-select  formControlName="category" (selectionChange)="evaCategoryChange($event)" >
              <mat-option *ngFor="let type of questionCategories" [value]="type"> {{ type.category }} </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
          <mat-label>Sub Category</mat-label>
          <mat-select formControlName="subCategory" (selectionChange)="evaSubCategoryChange($event.value)" >
              <mat-option *ngFor="let subCategory of evaSelectedCategory?.subCategory" [value]="subCategory"> {{ subCategory }} </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;width: 20em">
          <mat-label>Lens</mat-label>
          <mat-select formControlName="lens" >
              <mat-option *ngFor="let category of questionLens" [value]="category"> {{ category }} </mat-option>
          </mat-select>
      </mat-form-field>



            <mat-form-field appearance="outline" >
              <mat-label> Answer type</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="Selection()" formControlName="questionType">
                <mat-option value="FREETEXT">Text</mat-option>
                <mat-option value="RATING">Rating</mat-option>
                <mat-option value="NUMBER">Number</mat-option>
                <mat-option value="PERCENTAGE">Percentage</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-w">
              <mat-label>Question</mat-label>
              <input matInput type="text" maxlength="100" formControlName="question" placeholder="Enter Question">
            </mat-form-field>
          
          <!-- <mat-form-field appearance="outline" class="custom-w">
            <mat-label>Note</mat-label>
            <textarea matInput formControlName="note" placeholder="Note"></textarea>
          </mat-form-field> -->
       
        
      </form>
  </div>
<!-- </mat-card-content>
</mat-card> -->
</mat-dialog-content>

<mat-dialog-content *ngIf="this.type == 'MI-Ref-Create' || this.type == 'MI-Ref-Edit'  " >
<!-- <mat-card>
<mat-card-content> -->
  <div class="example-container">
      <form [formGroup]="miRefQuestionform">

        <mat-form-field appearance="outline" 
        style="font-size: 12.5px;margin-left: 1em;">
          <mat-label>Category</mat-label>
          <mat-select  formControlName="category" (selectionChange)="miCategoryChange($event)" >
              <mat-option *ngFor="let type of questionCategories" [value]="type"> {{ type.category }} </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
          <mat-label>Sub Category</mat-label>
          <mat-select (selectionChange)="miSubCategoryChange($event.value)" formControlName="subCategory" >
              <mat-option *ngFor="let cat of miSelectedCategory?.subCategory" [value]="cat"> {{ cat }} </mat-option>
          </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;width: 20em">
          <mat-label>Lens</mat-label>
          <mat-select formControlName="lens" >
              <mat-option *ngFor="let category of questionLens" [value]="category"> {{ category }} </mat-option>
          </mat-select>
      </mat-form-field>



            <mat-form-field appearance="outline" >
              <mat-label> Answer type</mat-label>
              <mat-select [(value)]="selected" (selectionChange)="Selection()" formControlName="questionType">
                <mat-option value="FREETEXT">Text</mat-option>
                <mat-option value="RATING">Rating</mat-option>
                <mat-option value="NUMBER">Number</mat-option>
                <mat-option value="PERCENTAGE">Percentage</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="custom-w">
              <mat-label>Question</mat-label>
              <input matInput formControlName="question" placeholder="Enter Question">
            </mat-form-field>
          
          <!-- <mat-form-field appearance="outline" class="custom-w">
            <mat-label>Note</mat-label>
            <textarea matInput formControlName="note" placeholder="Note"></textarea>
          </mat-form-field> -->
       
        
      </form>
  </div>
<!-- </mat-card-content>
</mat-card> -->
</mat-dialog-content>

<mat-dialog-content *ngIf="this.type == 'FG-Ref-Create' || this.type == 'FG-Ref-Edit'  " >
  <!-- <mat-card>
  <mat-card-content> -->
    <div class="example-container">
        <form [formGroup]="fgRefQuestionform">
               
          <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;">
            <mat-label>Category</mat-label>
            <mat-select  formControlName="category" (selectionChange)="fgCategoryChange($event)" >
                <mat-option *ngFor="let type of questionCategories" [value]="type"> {{ type.category }} </mat-option>
            </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;">
            <mat-label>Sub Category</mat-label>
            <mat-select (selectionChange)="fgSubCategoryChange($event.value)" formControlName="subCategory" >
                <mat-option *ngFor="let subCategory of fgSelectedCategory?.subCategory" [value]="subCategory"> {{ subCategory }} </mat-option>
            </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;width: 20em">
            <mat-label>Lens</mat-label>
            <mat-select formControlName="lens" >
                <mat-option *ngFor="let category of questionLens" [value]="category"> {{ category }} </mat-option>
            </mat-select>
        </mat-form-field>
  
  
  
              <mat-form-field appearance="outline" >
                <mat-label> Answer type</mat-label>
                <mat-select [(value)]="selected" (selectionChange)="Selection()" formControlName="questionType">
                  <mat-option value="FREETEXT">Text</mat-option>
                  <mat-option value="RATING">Rating</mat-option>
                  <mat-option value="NUMBER">Number</mat-option>
                  <mat-option value="PERCENTAGE">Percentage</mat-option>
                </mat-select>
              </mat-form-field>
  
              <mat-form-field appearance="outline" class="custom-w">
                <mat-label>Question</mat-label>
                <input matInput type="text" maxlength="100" formControlName="question" placeholder="Enter Question">
              </mat-form-field>
            
            <!-- <mat-form-field appearance="outline" class="custom-w">
              <mat-label>Note</mat-label>
              <textarea matInput formControlName="note" placeholder="Note"></textarea>
            </mat-form-field> -->
         
          
        </form>
    </div>
  <!-- </mat-card-content>
  </mat-card> -->
  </mat-dialog-content>


  <mat-dialog-content *ngIf="this.type == 'POI-Ref-Create' || this.type == 'POI-Ref-Edit'  " >
    <!-- <mat-card>
    <mat-card-content> -->
      <div class="example-container">
          <form [formGroup]="poiRefQuestionForm">
                 
            <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;">
              <mat-label>Category</mat-label>
              <mat-select  formControlName="category" (selectionChange)="poiCategoryChange($event)" >
                  <mat-option *ngFor="let type of questionCategories" [value]="type"> {{ type.category }} </mat-option>
              </mat-select>
          </mat-form-field>
    
          <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;">
              <mat-label>Sub Category</mat-label>
              <mat-select (selectionChange)="poiSubCategoryChange($event.value)" formControlName="subCategory" >
                  <mat-option *ngFor="let subCategory of poiSelectedCategory?.subCategory" [value]="subCategory"> {{ subCategory }} </mat-option>
              </mat-select>
          </mat-form-field>
    
          <mat-form-field appearance="outline" style="font-size: 14.5px;margin-left: 1em;width: 20em">
              <mat-label>Lens</mat-label>
              <mat-select formControlName="lens" >
                  <mat-option *ngFor="let category of questionLens" [value]="category"> {{ category }} </mat-option>
              </mat-select>
          </mat-form-field>
    
    
    
                <!-- <mat-form-field appearance="outline" >
                  <mat-label> Answer type</mat-label>
                  <mat-select [(value)]="selected" (selectionChange)="Selection()" formControlName="questionType">
                    <mat-option value="FREETEXT">Text</mat-option>
                    <mat-option value="RATING">Rating</mat-option>
                    <mat-option value="NUMBER">Number</mat-option>
                    <mat-option value="PERCENTAGE">Percentage</mat-option>
                  </mat-select>
                </mat-form-field> -->
    
                <mat-form-field appearance="outline" class="custom-w">
                  <mat-label>Question</mat-label>
                  <input matInput type="text" maxlength="100" formControlName="question" placeholder="Enter Question">
                </mat-form-field>
              
              <!-- <mat-form-field appearance="outline" class="custom-w">
                <mat-label>Note</mat-label>
                <textarea matInput formControlName="note" placeholder="Note"></textarea>
              </mat-form-field> -->
           
            
          </form>
      </div>
    <!-- </mat-card-content>
    </mat-card> -->
    </mat-dialog-content>

<div style="display: flex;justify-content: end;gap: 1em;">
  <button class="active-stroke-lrg" style="width: 12em !important;" (click)="closeDialog()">Cancel</button>
  <button class="active-stroke-lrg" style="width: 12em !important;" *ngIf="!editQuestion" (click)="onSaveDraft()">Save
    as Draft</button>
  <button class="active-solid-lrg" style="width: 12em !important;" (click)="submit()">
    Submit
  </button>
</div>
<!-- <mat-dialog-actions>
<button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions> -->


