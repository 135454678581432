import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ClientDashboardService {
  clientURL: string = environment.CLIENT_API_URL;
  surveyURL: string = environment.SURVEY_API_URL;
  staticURL: string = environment.STATIC_API_URL;

  constructor(private http: HttpClient) {}

  getClientInfo(clientId: string) {
    let url = `${this.clientURL}insight/info/${clientId}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  getClientInsight(clientId: string) {
    let url = `${this.clientURL}insight/${clientId}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  createClientInfo(clientId: any, payload: any) {
    let url = `${this.clientURL}insight/info/${clientId}`;
    return this.http.post<any>(url, payload, { observe: "response" });
  }

  createClientInsight(clientId: any, payload: any) {
    let url = `${this.clientURL}insight/${clientId}`;
    return this.http.post<any>(url, payload, { observe: "response" });
  }

  editClientInfo(id: string, clientId: string, payload: any) {
    let url = `${this.clientURL}insight/info/${clientId}/${id}`;
    return this.http.put<any>(url, payload, { observe: "response" });
  }

  editClientInsight(id: string, clientId: string, payload: any) {
    let url = `${this.clientURL}insight/${clientId}/${id}`;
    return this.http.put<any>(url, payload, { observe: "response" });
  }

  deleteClientInsight(id: string, clientId: string) {
    let url = `${this.clientURL}insight/${clientId}/${id}`;
    return this.http.delete<any>(url, { observe: "response" });
  }

  getClientCount(clientId: string, module: string) {
    let url = `${this.clientURL}${module}/${clientId}`;
    return this.http.get<any>(url, { observe: "response" });
  }
}
