<br>
<div class="space filterr" style="display: flex;">
    <div class="col-sm-6" style="gap: 1em; display: inline-flex;">    
    <mat-form-field appearance="outline">
      <mat-label>Interview Name</mat-label>
        <mat-select multiple placeholder="Interview" [(ngModel)]="selectedInterviews" (selectionChange)="changeInterview($event.value)">
            <mat-option *ngFor="let interview of interviews" [value]="interview">
                {{interview}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Function</mat-label>
        <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
          (selectionChange)="changeFunction($event.value)">
          <mat-option *ngFor="let function of functions" [value]="function">
            {{function}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="outline">
        <mat-label>Team</mat-label>
        <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
          <mat-option *ngFor="let team of teams" [value]="team">
            {{team}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
<br>
<table mat-table [dataSource]="dataSource2"  class="tb-size">
    <ng-container matColumnDef="lead">
        <th mat-header-cell *matHeaderCellDef> Team Lead Name </th>
        <td mat-cell *matCellDef="let element"> {{element?.interviewName }} </td>
    </ng-container>
    
    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef> Team </th>
        <td mat-cell *matCellDef="let element"> {{element?.teamName }} </td>
    </ng-container>

    <ng-container matColumnDef="function">
        <th mat-header-cell *matHeaderCellDef> Function </th>
        <td mat-cell *matCellDef="let element"> {{element?.clientFunction }} </td>
    </ng-container>
    
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date Due </th>
        <td mat-cell *matCellDef="let element"> {{element?.scheduledDate | date:'dd-MM-YYYY' }}  </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" class="Status" ngClass="{{element.status}}"> {{element?.status ?? '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;let i = index;">
            <mat-icon matTooltip="Initiate Output"> insert_chart_outlined</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></tr>
    <tr  [ngClass]="{ 'selected-mi-row' : selectedRowIndex == i }"
    (click)="showData(i,row)"
     mat-row *matRowDef="let row; columns: displayedColumns2;let i = index;" 
     ></tr>
  </table>
  <br>
<section *ngIf="canShow">
  <div class="row f">
    <div class="col-sm-7">
        <table mat-table [dataSource]="dataSource">
        
            <ng-container  matColumnDef="names">
              <th mat-header-cell *matHeaderCellDef> Questions </th>
              <td mat-cell *matCellDef="let element" class="truncate"> {{element.question}} </td>
            </ng-container>
            <ng-container matColumnDef="lines">
              <th mat-header-cell *matHeaderCellDef> 
                <div class=labels>
                    <p>Strongly Disagree</p>
                    <p> Disagree</p>
                    <p>Neither</p>
                    <p>Agree</p>
                    <p>Strongly Agree</p>
                </div> </th>
              <td mat-cell *matCellDef="let element"> 
              <!-- Timeline -->

                        <section class="whole">
                            <!-- <ngx-mat-timeline position="start" orientation="horizontal">
                                <ngx-mat-timeline-item *ngFor="let ans of element.rating; let idx = index"
                                    style="min-width: 78px; max-width: 78px" [icon]="ans.length > 0 ? 'circle' : idx == 5 ? 'star' : '' ">
                                </ngx-mat-timeline-item>
                            </ngx-mat-timeline> -->

                            <div class="rating-line">
                              <div *ngFor="let ans of element.rating ; let idx = index">
                                 <div class="circleStyles c_fix" *ngIf="ans.length > 0" 
                                  [ngStyle]="{'margin-left': calculateMargin(ans, idx)}">
                                {{getAnswer(ans)}}</div>
                              </div>
                          </div>
                        </section>
              </td>
            </ng-container>
            <ng-container  matColumnDef="average">
                <th mat-header-cell *matHeaderCellDef> Average </th>
                <td mat-cell *matCellDef="let element"> 
                    <div style="margin-bottom: 4rem;" >
                        {{element.average}}
                    </div>
                     </td>
              </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr  mat-row *matRowDef="let row; columns: displayedColumns;let i = index" 
            [ngClass]="{ 'highlighted' : selectedIndex == i }" (click)="highlight(i,row)"
            ></tr>
          </table>
    </div>
    <div class="col-sm-5">
        <mat-card class="fg-deep-card">
            <p class="question"> {{selectedRow?.question}}</p>
            <br>
            <!-- Timeline -->
            <section class="timeline-container1">
                <div>
                    <div class=labels2>
                        <p>Strongly Disagree</p>
                        <p> Disagree</p>
                        <p>Neither</p>
                        <p>Agree</p>
                        <p>Strongly Agree</p>
                    </div>
                    <div >
                        <!-- <ngx-mat-timeline position="start" orientation="horizontal">
                            <ngx-mat-timeline-item *ngFor="let ans of selectedRow.rating; let idx = index" 
                            style="min-width: 68px; max-width: 68px; width: 96%;"
                              [icon]="ans.length > 0 ? 'circle' : idx == 5 ? 'circle' : '' " 
                              [ngClass]="{ 'average': idx == 5, 'selected-icon': (idx  ==  selectedHoverIndex && idx != 5 ),'default-icon' : (idx  !==  selectedHoverIndex && idx != 5 ) }"
                              (mouseenter)="showContent(idx,ans)" (mouseleave)="hideContent(ans)"
                              >
                            </ngx-mat-timeline-item>
                          </ngx-mat-timeline> -->
                          <!-- <app-rating-timeline></app-rating-timeline> -->

                          <div class="rating-line2">
                            <div *ngFor="let ans of selectedRow.rating; let idx = index">
                               <div class="circleStyles circle" *ngIf="ans.length > 0"  [ngStyle]="{'margin-left': calculateMargin(ans, idx)}"
                                (click)="showContent(idx,ans)">
                              {{getAnswer(ans)}}</div>
                            </div>
                        </div>

                    </div>
                </div>
              </section>



            <p class="avg"> Average - {{selectedRow.average}}</p>
            
            <!-- <mat-label class="input-label">Free Text Responses</mat-label> -->
            <div *ngFor="let item of hoverContent" >
                
            <section class="dd-style"  >
                <!-- <mat-label class="input-label"> {{item?.team ?? '-'}}</mat-label>
                <span>
                    <mat-icon (click)="open('add')">add</mat-icon>
                    <mat-icon (click)="open('edit')">edit</mat-icon>
                </span> -->
            </section>

            <div class="comment-div">
              <p style="padding: 5px">{{getCommentData(item)}}</p>
            </div>
<!-- 
            <mat-form-field appearance="outline" class="tb-size">
                <textarea matInput
                [value]="item?.comments == '' ? 'No Comments' : item?.comments "></textarea>
            </mat-form-field> -->
        </div>
            <!-- <br>
            <mat-form-field appearance="outline" class="tb-size" [style.opacity]="this.hover === true ? 0.5 : ''">
                <textarea matInput cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="5"
                value="TextTextTextTextTextTextTextTextTextTextText"></textarea>
            </mat-form-field> -->
        </mat-card>
    </div>
  </div>
</section>

<section *ngIf="!canShow" style="display: flex;justify-content: center;">
    {{"No Data is received or selected"}}
</section>