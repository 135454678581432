<br>
<section>
    <mat-card class ='user-details'>
    
        <div class="contents">
            <div>
                <span class="name">PwC Admin</span>
            </div>
            <div class="icons">
                <span><i class="fa-regular fa-user"></i></span>
                <span><i class="fa-regular fa-bell"></i></span>
                <span><i class="fa-solid fa-gear"></i></span>
            </div>
        </div>
    
    </mat-card>
</section>

