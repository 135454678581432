<h2 mat-dialog-title>Template</h2>
<mat-dialog-content>
    <div class="example-container">
        <form [formGroup]="avaTemplateAddForm" *ngIf="isAvaTemplate">

            <mat-form-field *ngIf="newTemplate" appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Template Name</mat-label>
                <input [readonly]="qus_Edit" matInput  formControlName="templateName" placeholder="Enter Template Name"/>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Activity</mat-label>
                <input matInput formControlName="activity" type="text" maxlength="100" placeholder="Enter Activity"/>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Type</mat-label>
                <!-- <input matInput formControlName="type" placeholder="Enter Type"/> -->
                <mat-select  formControlName="type">
                    <mat-option *ngFor="let type of avaType" [value]="type" >
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Time Category</mat-label>
                <!-- <input matInput formControlName="category" placeholder="Enter Category"/> -->
                <mat-select  formControlName="category">
                    <mat-option *ngFor="let item of avaTimeCategory" [value]="item" >
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- TODO: Changes the logic for display error on ts file -->
            <!-- <mat-error *ngIf="invalidTemplate"> Template name already exists for this module. </mat-error>  -->
        </form>

        <form [formGroup]="evaTemplateAddForm" *ngIf="!isAvaTemplate">

            <mat-form-field *ngIf="newTemplate" appearance="outline" class="example-full-width" style="padding-right: 20px;" >
                <mat-label>Template Name</mat-label>
                <input [readonly]="qus_Edit" matInput formControlName="templateName" placeholder="Enter Template Name"/>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Category</mat-label>
                <!-- <input matInput formControlName="category" placeholder="Enter Category"/> -->
                <mat-select [value]="selectedCategory" (selectionChange)="onFilterChange($event.value, 'category')" formControlName="category">
                    <mat-option   *ngFor="let item of evaCategory" [value]="item" >
                        {{item.category}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Sub Category</mat-label>
                <!-- <input matInput formControlName="subCategory" placeholder="Enter Sub Category"/> -->
                <mat-select (selectionChange)="onChangeSubCategory($event.value)" formControlName="subCategory">
                    <mat-option *ngFor="let item of selectedCategory?.subCategory" [value]="item" >
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Lens</mat-label>
                <!-- <input matInput formControlName="lens" placeholder="Enter Lens"/> -->
                <mat-select  formControlName="lens">
                    <mat-option *ngFor="let item of evaLens" [value]="item" >
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
<!-- 
            <mat-form-field appearance="outline" class="example-full-width" style="padding-right: 20px;">
                <mat-label>Question Id</mat-label>
                <input matInput formControlName="questionId" placeholder="Enter Question Id"/>
            </mat-form-field> -->

            <mat-form-field appearance="outline" class="example-full-width" class="questioninput" style="padding-right: 20px;">
                <mat-label>Question</mat-label>
                <input matInput formControlName="question" type="text" maxlength="100" placeholder="Enter Question"/>
            </mat-form-field>

            <!-- TODO: Changes the logic for display error on ts file -->
            <!-- <mat-error *ngIf="invalidTemplate"> Template name already exists for this module. </mat-error>  -->
        </form>
    </div>

</mat-dialog-content>
<div *ngIf="!qus_Edit" style="display: flex;justify-content: center;gap: 2em;">

    <button class="active-stroke-lrg" style="width: 12em !important;" mat-dialog-close  (click)="dialogRef.close()"
         >Cancel</button>

   <button class="active-solid-lrg tmp-save" style="width: 12em !important;" 
        *ngIf="isDefaultTemplate" (click)="saveAsNewTemplate()">Save </button>

    <button mat-button class="active-stroke-sml" 
        style="width: 12em !important;" [matMenuTriggerFor]="menu" 
        *ngIf="!isDefaultTemplate">Save As &nbsp;<mat-icon>keyboard_arrow_down</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="saveOnTemplate()" [disabled]="isDefaultTemplate">In Current Template</button>
        <button mat-menu-item (click)="saveTemplate()">New Template</button>
    </mat-menu>
</div>
<div *ngIf="qus_Edit" style="display: flex;justify-content: end;gap: 1em;">

   <button class="active-stroke-lrg btnwidth" mat-dialog-close  (click)="dialogRef.close()"
         >Cancel </button>

    <button class="active-stroke-lrg btnwidth"  (click)="updateQuestion()"
        >Update 
    </button>

   
</div>