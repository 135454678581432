import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowTemplateService } from '@app/workflow-template/workflow-template.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from "@app/core/classes/helper";
import { DatabaseService } from '@app/database/database.service';
import { CustomService } from '@app/core/services/custom.service';

@Component({
  selector: 'app-template-add',
  templateUrl: './template-add.component.html',
  styleUrls: ['./template-add.component.scss']
})
export class TemplateAddComponent implements OnInit {
  qus_Edit: boolean = false
  avaType: any;
  avaTimeCategory: any;
  evaCategory: any;
  evaSubCategory: any;
  evaLens: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private workflowTemplateService: WorkflowTemplateService,
    public dialogRef: MatDialogRef<TemplateAddComponent>,
    private snackBar: MatSnackBar,
    private cdr : ChangeDetectorRef,
    private databaseService : DatabaseService,
    private customService : CustomService
  ) { }

  isAvaTemplate: boolean = false;
  isDefaultTemplate: boolean = false;
  selectedTemplateDetail: any = {};
  avaTemplateAddForm: any = new FormGroup({
    templateName: new FormControl('', [Validators.required,  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    activity: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required)
  });

  evaTemplateAddForm: any = new FormGroup({
    templateName: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
    subCategory: new FormControl(null, Validators.required),
    lens: new FormControl(null, Validators.required),
    // questionId: new FormControl(null, Validators.required),
    question: new FormControl(null, Validators.required)
  });
  newTemplate: boolean = true;
  invalidTemplate: boolean = false;
  selectedCategory:any
  ngOnInit(): void {
    this.isAvaTemplate = this.data.from === 'ava-template' ? true : false;
    this.qus_Edit = this.data?.edit_qus === true ? true : false;
    this.isDefaultTemplate = this.data.isDefaultTemplate;
    this.selectedTemplateDetail = this.data.templateDetail;
    console.log( '====== this.data.from ======', this.data );
    console.log( 'isAvaTemplate', this.isAvaTemplate,this.qus_Edit );

    if(this.isAvaTemplate){
      this.getCategories()
      this.getAVATemplates()
      this.getType()
      if(this.qus_Edit){
          this.editQuestion() 
       }

    } else{
      this.getEvaCategories()
      this.getEVATemplates()
    } 


    if(this.qus_Edit){
     return 
    }

    if(!this.isDefaultTemplate){
      this.avaTemplateAddForm.controls['templateName'].setValue(this.selectedTemplateDetail['templateName']);
      this.avaTemplateAddForm.controls['templateName'].disable();
      this.evaTemplateAddForm.controls['templateName'].setValue(this.selectedTemplateDetail['templateName']);
      this.evaTemplateAddForm.controls['templateName'].disable();
    } else{
      this.newTemplate = true;
    }
  }

  getType(){
    this.databaseService.getDropDown('ACTIVITY','ava', "type").subscribe({
      next:(res:any)=>{ if(res.length > 0)this.avaType = res.map((x:any) => x.name)},
      error:(error:any)=>{console.log('type dropdown',error);}
    })
  }

  getCategories(){
    this.databaseService.getDropDown('ACTIVITY', 'ava').subscribe({
      next:(res:any)=>{ if(res.length > 0) {
        this.avaTimeCategory = res.map((x:any) => x.name)}
      },
      error:(error:any)=>{console.log('type dropdown',error);}
    })
  }
  getEvaCategories(){
    this.databaseService.getDropDown('CATEGORY', 'eva' ).subscribe({
      next:(res:any)=>{ if(res.length > 0) {
          this.evaCategory = res
          this.cdr.markForCheck()
          if(this.qus_Edit){
            this.editEVA_Question()
         }
        } 
      },
      error:(error:any)=>{console.log('type dropdown',error);}
    })
  }

  onFilterChange(event:any, type:any){

    this.selectedCategory = event;
    this.evaLens = [];
  }

  onChangeSubCategory(event:any){
    this.evaLens = this.selectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }


  public getAVATemplates() {
    this.workflowTemplateService.getTemplateByQuery("module", "AVA").subscribe(
      (response) => {

       let  avaDropdownData = response.data[0];
        // let avaType = avaDropdownData.questions.map((x:any) => x.type)
        // let avaTimeCategory = avaDropdownData.questions.map((x:any) => x.timeCategory)
        // this.avaType = this.removeDuplicates(avaType)
        // this.avaTimeCategory = this.removeDuplicates(avaTimeCategory)
        console.group('avaDropdownData',avaDropdownData)
        console.log('avaType',this.avaType);
        console.log('avaTimeCategory',this.avaTimeCategory);
      },
      (error) => {
        console.log("---- Get all ava template Error ----", error);
      }
    );
  }

  public getEVATemplates(){
    this.workflowTemplateService.getTemplateByQuery("module", "EVA").subscribe((response) =>{
      console.log('---- Get all eva template list ----', response);
      let evaDropdownData = response.data[0];
     
      console.group('evaDropdownData',evaDropdownData)
    }, (error) =>{
      console.log('---- Get all eva template Error ----', error);
    });
  }

  // avaTemplateAddForm: any = new FormGroup({
  //   templateName: new FormControl(null, Validators.required),
  //   activity: new FormControl(null, Validators.required),
  //   type: new FormControl(null, Validators.required),
  //   category: new FormControl(null, Validators.required)
  // });
  editQuestion(){
    console.log('ava edit question called');
    
    this.avaTemplateAddForm.patchValue({
      templateName : this.selectedTemplateDetail?.selectedTemp ,
      activity : this.selectedTemplateDetail?.question,
      type : this.selectedTemplateDetail?.type,
      category : this.selectedTemplateDetail?.category,
    })
    
  }

  editEVA_Question(){

    let selectedCatIndex :number = 0
    let selectedSubCatIndex :number = 0
    console.log('evaCategory',this.evaCategory);
    
    this.evaCategory.forEach( (x:any, i:any)=>{
      if(x.category == this.selectedTemplateDetail.category){
        selectedCatIndex = i
        return this.selectedCategory = x
      }
    });

    this.evaLens = this.evaCategory[selectedCatIndex].lens.find((lensDetail: any) => lensDetail.subCategory == this.selectedTemplateDetail.subCategory).lens;
    this.evaTemplateAddForm.patchValue({
      templateName: this.selectedTemplateDetail.selectedTemp,
      category: this.evaCategory[selectedCatIndex],
      subCategory: this.selectedTemplateDetail.subCategory,
      lens: this.selectedTemplateDetail.lens,
      question: this.selectedTemplateDetail.question
    });
  }

  removeDuplicates(element:any){
    let array:[] = element.reduce((acc: any[], current: any) => {
      if(!acc.includes(current)) acc.push(current)
      return acc
    }, [])
    return array
  }

  updateQuestion(){
    this.avaTemplateAddForm.markAllAsTouched()

    if(this.avaTemplateAddForm.valid && this.isAvaTemplate ){

      let question: any = this.avaTemplateAddForm.value.activity;
      this.customService.checkQuestion("AVA", [question], this.data.templateDetail._id).subscribe((response): any =>{

        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        } else{

          this.dialogRef.close({event:'editsubmitAVA', data : this.avaTemplateAddForm.value});
          this.cdr.markForCheck()
        }
      });
    }
   else if(this.evaTemplateAddForm.valid && !this.isAvaTemplate){

      let question: any = this.evaTemplateAddForm.value.question;
      this.customService.checkQuestion("EVA", [question], this.data.templateDetail._id).subscribe((response): any =>{

        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        } else{

          this.dialogRef.close({event:'editsubmitEVA', data : this.evaTemplateAddForm.value});
          this.cdr.markForCheck()
        }
      });
    }
  }

  saveOnTemplate(){
    this.newTemplate = true;

    this.avaTemplateAddForm.controls['templateName'].setValue(this.selectedTemplateDetail['templateName']);
    this.avaTemplateAddForm.controls['templateName'].disable();
    this.evaTemplateAddForm.controls['templateName'].setValue(this.selectedTemplateDetail['templateName']);
    this.evaTemplateAddForm.controls['templateName'].disable();


    if(!this.isAvaTemplate){

      this.evaTemplateAddForm.markAllAsTouched();
      if (this.evaTemplateAddForm.invalid) {
        return false;
      }
      let payload = this.evaTemplateAddForm.value;
      console.log('eva form value',this.evaTemplateAddForm.value);
      
      this.customService.checkQuestion("EVA", [payload["question"]]).subscribe((response): Boolean =>{

        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        }

        let updateData: any = {
          module: "EVA", 
          question: payload["question"], 
          lens: payload["lens"], 
          category: payload["category"]['category'],
          subCategory: payload["subCategory"],
          min : 0,
          max : 0,
          questionState : 'SUBMITTED',
        }
        this.onUpdateTemplate(updateData);
        return true;
      });
      return true;

    } else{

      this.avaTemplateAddForm.markAllAsTouched();
      if (this.avaTemplateAddForm.invalid) {
        return false;
      }
      let payload = this.avaTemplateAddForm.value;
      this.customService.checkQuestion("AVA", [payload["activity"]]).subscribe((response): Boolean =>{


        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        }

        let  setData:any = {
          module: "AVA", 
          question: payload["activity"], 
          type: payload["type"], 
          category: payload["category"],
          min : 0,
          max : 0,
          questionState : 'SUBMITTED',
        };
  
        this.onUpdateTemplate(setData);
        return true;
      });
      return true;
    }
  }

  public saveTemplate(){
    
    let formTemplateName = this.isAvaTemplate ? this.avaTemplateAddForm.controls['templateName'].value : this.evaTemplateAddForm.controls['templateName'].value;
    if(this.selectedTemplateDetail.templateName === formTemplateName){

      this.newTemplate = true;
      this.avaTemplateAddForm.controls['templateName'].setValue('');
      this.avaTemplateAddForm.controls['templateName'].enable();
      this.evaTemplateAddForm.controls['templateName'].setValue('');
      this.evaTemplateAddForm.controls['templateName'].enable();
    }
    this.cdr.markForCheck();
    this.saveAsNewTemplate();
  }

  saveAsNewTemplate(){
    if(!this.isAvaTemplate){
      this.onSaveEvaTemplate();
    } else{

      this.onSaveAvaTemplate();
    }
  }

  onSaveAvaTemplate(){
    const { emptyFields } = Helper.getEmptyFields(this.avaTemplateAddForm);
    let payload = this.avaTemplateAddForm.value;
    console.log('>>>>>>>>>.',payload);
    
    this.avaTemplateAddForm.markAllAsTouched();
    if (this.avaTemplateAddForm.valid && emptyFields.length == 0) {
        
      this.customService.checkQuestion("AVA", [payload["activity"]]).subscribe((response) =>{

        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        }

        payload = {
          module: "AVA", 
          question: payload["activity"], 
          type: payload["type"], 
          category: payload["category"],
          min : 0,
          max : 0,
          questionState : 'SUBMITTED',
        };

        console.log('--- AVA payload ----', payload);
        this.onSaveTemplate(payload);
        return true;
      });

    } else{
      this.avaTemplateAddForm.markAllAsTouched();
    }
  }

  onSaveEvaTemplate(){

    const { emptyFields } = Helper.getEmptyFields(this.evaTemplateAddForm);
    this.evaTemplateAddForm.markAllAsTouched();
    if (this.evaTemplateAddForm.valid && emptyFields.length == 0) {

      let payload = this.evaTemplateAddForm.value;
      this.customService.checkQuestion("EVA", [payload["question"]]).subscribe((response) =>{

        if(response){
          this.snackBar.open("Question Already Exist", 'close');
          return false;
        }

        this.cdr.markForCheck();
        payload = { 
          module: "EVA", 
          question: payload["question"],
          category: payload.category?.category, 
          subCategory: payload["subCategory"], 
          lens: payload["lens"], 
          min : 1,
          max : 5,
          questionState : 'SUBMITTED',
        };

        console.log('--- EVA payload ----', payload);
        this.onSaveTemplate(payload);
        return true;
      });

    } else{
      this.evaTemplateAddForm.markAllAsTouched();
    }
  }

  onSaveTemplate(refDataPayload: any = {}){

    console.log('create temp payload',refDataPayload);

    let template :any = this.isAvaTemplate ? this.avaTemplateAddForm.controls.templateName.value : this.evaTemplateAddForm.controls.templateName.value
    let payload: any = {
      module: refDataPayload['module'],
      templateName: template,
      questions: [
        refDataPayload
      ]
    }

    console.log('create temp payload',payload);

    /*  */
    this.workflowTemplateService.createTemplate(payload).subscribe({
      next: (response) => {

        console.log('---- Template Added response -----', response);
        this.snackBar.open("Template Created and Question Sent for Approval Successfully", 'close');
        this.dialogRef.close();
      },
      error: (err) => {
        console.log(`Create template Error:`);
        console.log(err);
        console.log(err.ok);
        if(!err.ok){
          this.invalidTemplate = true;
        }
        let message = err.error.message;
        this.snackBar.open(message, 'close');
      },
      complete: () => {},
    });
    /*  */

    

  
  }


  onUpdateTemplate(refDataPayload: any = {}){

    console.log('create temp payload', refDataPayload);

    let template :any = this.isAvaTemplate ? this.avaTemplateAddForm.controls.templateName.value : this.evaTemplateAddForm.controls.templateName.value
  
    let payload: any = {
      module: refDataPayload.module,
      templateName: template,
      questions: [
        refDataPayload
      ]
    }
    console.log('temp create payload',payload)
    let templateId: string = this.selectedTemplateDetail['_id'];
    this.workflowTemplateService.updateTemplate(templateId, payload)
    .subscribe((updateTemplateRes) => {

        this.snackBar.open("Question Sent for Approval Successfully", 'close');
        this.dialogRef.close();
    });
    

  

  }

 
}