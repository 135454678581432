import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class ManagerInterviewService {
  // STATIC_API_URL: 'http://localhost:3000/',
  // AUTH_API_URL: 'http://localhost:3001/',
  // CLIENT_API_URL: 'http://localhost:3002/',
  // SURVEY_API_URL: 'http://localhost:3003/',
  clientURL: string = environment.CLIENT_API_URL;
  surveyURL: string = environment.SURVEY_API_URL;
  staticURL: string = environment.STATIC_API_URL;
  constructor(private http: HttpClient) {}

  getInterview(clientId: string, interviewID?: string) {
    let queryString = "";
    if (interviewID) {
      queryString = `?id=${interviewID}&size=100&pageNumber=0`;
    } else {
      queryString = "?size=100&pageNumber=0";
    }

    let url = `${this.clientURL}managerInterview/${clientId}${queryString}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  createInterview(payload: any) {
    let url = `${this.clientURL}managerInterview`;
    return this.http.post<any>(url, payload, { observe: "response" });
  }

  editInterview(interviewId: string, payload: any) {
    let url = `${this.clientURL}managerInterview/${interviewId}`;
    return this.http.put<any>(url, payload, { observe: "response" });
  }

  deleteInterview(interviewId: string) {
    let url = `${this.clientURL}managerInterview/${interviewId}`;
    return this.http.delete<any>(url, { observe: "response" });
  }

  getTemplatesByModule(module: string) {
    return this.http.get<any>(`${this.staticURL}template?module=${module}`);
  }

  createTemplate(payload: Object = {}) {
    return this.http.post<any>(`${this.staticURL}template`, payload);
  }

  updateTemplate(templateId: string, payload: any) {
    return this.http.put<any>(
      `${this.staticURL}template/${templateId}`,
      payload
    );
  }
  // getTemplatesByTemplateId(templateId: any) {
  //   return this.http.get<any>(
  //     `${this.staticURL}template?id=${templateId}&size=100&pageNumber=0`
  //   );
  // }

  getManagerInterviewResponse(module: string, clientId: string = "") {
    let url: string = `${this.surveyURL}survey/surveyDetail?module=${module}`;
    if (clientId) url = `${url}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }

  getMI(module: string, clientId: string = "") {
    let url: string = `${this.surveyURL}survey/surveyDetail?module=${module}`;
    if (clientId) url = `${url}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }

  getOutput(module: any, clientId: any) {
    let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}`;
    return this.http.get<any>(url);
  }

  getOutput1(module: any, clientId: any, Id: any) {
    let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}&interviewId=${Id}`;
    return this.http.get<any>(url);
  }
}
