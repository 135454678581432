<mat-card class="logocard">
    <img width="65px" height="18px" src="../../assets/icons/logo.svg" alt="pwc">
</mat-card>
<div class="survey-land" *ngIf="isSurveyActive">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected" >
        <mat-tab>
            <section class="ava" >
                <div class="sticky">
                    <mat-card  *ngIf="!isReadOnly" class="right">
                        <span class="surveyhead flex">Welcome to the Activity Value Analyser questionnaire!</span>
                        <p class="para">To understand how your work is balanced in a given day your organisation
                            would like you to complete the following survey.</p>
                        <p class="para">
                            <span style="color: black;font-weight: 600;"> Your answers will be anonymous </span>
                            and will take approximately <span style="color: black;font-weight: 600;">10 minutes</span> to complete
                            and consists of <span style="color: black;font-weight: 600;"> three parts.</span>
                        </p>
                    
                        <br>
                        <div class="" *ngIf="!isReadOnly">
                            <br>
                            <mat-form-field appearance="outline" style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                                <mat-label>Select Function</mat-label>
                                <mat-select (selectionChange)="assignFunction($event)">
                                    <mat-option *ngFor="let func of functions" [value]="func"> {{ func }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br>
                    
                            <mat-form-field appearance="outline" style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                                <mat-label>Select Team</mat-label>
                                <mat-select (selectionChange)="assignTeam($event.value)">
                                    <mat-option *ngFor="let team of teams" [value]="team"> {{ team }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br>
                            <mat-form-field appearance="outline" style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                                <mat-label>Role</mat-label>
                                <mat-select (selectionChange)="assignRole($event.value)">
                                    <mat-option *ngFor="let role of role" [value]="role"> {{ role }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-card>
                    <br>
                    <div class="flex">
                        <button class="active-solid-lrg " *ngIf="isReadOnly" style="width: 35rem;" (click)="selected = 1">
                            View Preview
                        </button>
                        <button class="active-solid-lrg " *ngIf="!isReadOnly" style="width: 35rem;" (click)="begin()">
                            Begin Questionnaire
                        </button>
                    </div>
                </div>
            </section>
        </mat-tab>

        <mat-tab label="AVA" *ngIf="moduleType === 'ava'" >
<!-- <mat-card  >
    <mat-card-content> -->
            <section class="ava" >
                <div class="sticky">
                    <span class="para part" [ngClass]="moduleType == 'ava'? 'bold' : 'para'">Part 1</span>
                    <span class="para part">Part 2</span>
                    <!-- <span class="para part" [ngClass]="selected == 3 ? 'bold' : 'para'">Part 3</span> -->
                    <mat-card class="right">
                        <span class="surveyhead flex">PART-1 : Activity Value Analyser</span>
                        <p class="para">Please estimate how much of your day/week is occupied by each activity.
                            <span style="color: #6D0606;font-weight: 600;">The total time must equal 100%</span>
                        </p>
                    </mat-card>
                    <br>
                    <mat-card class="right">
                        <mat-card
                            style="background-color: #F4F4F8; box-shadow: none;border-radius: 6px;height: 15px;border: 2px solid #e5e5f2;">
                            <!-- <div> -->
                            <p class="para flex-center" style="font-weight: 500;">Time Allocated : {{avaSum}}%</p>
                            <!-- </div> -->
                        </mat-card>
                    </mat-card>
                </div>
                <br>

                <div *ngFor="let item of grouped_AVAQuestions " style="width: 30vw;">
                    <!-- <h1 class="h">{{item.type}}</h1> -->
                    <div *ngFor="let element of item?.questions" >
                        <mat-card style="padding: 10px; margin-right: 10px; margin-left: 10px;">
                            <div class="m-card">
                                <span class="question">{{element?.question}}</span>
                                <span class="">{{element?.displayValue}}%</span>
                            </div>
                            <span class="m-second m-card" *ngIf="surveyDetailId">
                                <mat-slider *ngIf="surveyDetailId" [value]="element.answer" [disabled]="(avaSum >= 100 && element.answer == 0) ? true : false"  [displayWith]="formatLabel"
                                      (change)="answer($event ,element)" tickInterval="1000" step="1000" min="0"
                                    max="100000" aria-label="units"></mat-slider>
                                    &nbsp;
                                    <div>
                                        <span class="arrow"  (click)="decreaseNumber(element)"><i class="fa-solid fa-caret-left"></i></span>
                                        <span class="arrow" [class.disabled]="avaSum === 100" (click)="increaseNumber(element)">
                                            <i class="fa-solid fa-caret-right"></i>
                                        </span>
                                    </div>
                            </span>
                            <span class="m-second m-card" *ngIf="!surveyDetailId">
                                <mat-slider *ngIf="!surveyDetailId" [disabled]="isReadOnly" thumbLabel [displayWith]="formatLabel"
                                    [value]="element?.answer" (change)="answer($event ,element)" tickInterval="1000" step="1000" min="0"
                                    max="100000" aria-label="units"></mat-slider>
                                    &nbsp;
                                    <div [ngClass]="isReadOnly ? 'rating-disabled' : ''">
                                        <span class="arrow" (click)="decreaseNumber(element)"><i class="fa-solid fa-caret-left"></i></span>
                                        <span class="arrow" (click)="increaseNumber(element)"><i class="fa-solid fa-caret-right"></i></span>
                                    </div>
                            </span>
                        </mat-card>
                       
                        <br>
                            
<!-- 
                        <mat-slider *ngIf="!surveyDetailId" [disabled]="isReadOnly" thumbLabel [displayWith]="formatLabel" 
                        [value]="element?.answer" (change)="answer($event ,element)"
                            tickInterval="1000" step="1000" min="0" max="100000" aria-label="units"></mat-slider>
                        <br> -->
                    </div>
                </div>

                <mat-card class="buttons sticky">
                    <button class="active-solid-lrg but-style" *ngIf="!surveyDetailId" (click)="goToProject()">Back</button>
                    <button class="active-solid-lrg but-style" *ngIf="surveyDetailId"  (click)="selected = 0">Back</button>
                    <button class="active-stroke-lrg but-style" *ngIf="!surveyDetailId"  (click)="nextPreview()">Next</button>
                    <button class="active-stroke-lrg but-style" *ngIf="surveyDetailId"  (click)="onConfirmAva()">Next</button>
                </mat-card>
            </section>

            <app-footer></app-footer>

        <!-- </mat-card-content>
    </mat-card> -->
        </mat-tab>

        <mat-tab label="EVA" *ngIf="moduleType === 'eva'">
            <!-- <mat-card>
                <mat-card-content> -->
            <section class="eva">
                <!-- <div class="row center sticky" >
                      <div class="col-sm-3"> <h1>EVA Survey</h1> </div>
                      <div class="col-sm-9 center descStars" >
                        <div  *ngFor="let star of descstars" style="margin: 0 0.3em; width:8em;">
                            <i class="fa fa-star " style="color: rgb(21, 8, 216);" ></i>
                            <span  color="warn" > {{star}} </span>
                        </div>
                      </div>
                </div>
                
                <br> -->
                <div class="sticky">
                    <span class="para part">Part 1</span>
                    <span class="para part" [ngClass]="moduleType == 'eva' ? 'bold' : 'para'">Part 2</span>
                    <!-- <span class="para part" [ngClass]="selected == 3 ? 'bold' : 'para'">Part 3</span> -->
                    <mat-card class="right">
                        <span class="surveyhead flex">PART-2 : Engagement Value Analyser</span>
                        <p class="para">Please answer the following questions about x</p>
                    </mat-card>
                    <br>

                    <mat-card class="right" style="height: 10vh;">
                        <div class="col-sm-11 center descStars">
                            <div class="desc">
                                <div class="">
                                    <section class='alignDescStars'>
                                        <div class="star-container">
                                            <div class="star-text">Strongly Disagree</div>
                                            <div class="star-text"> Disagree</div>
                                            <div class="star-text">Neither</div>
                                            <div class="star-text">Agree</div>
                                            <div class="star-text">Strongly Agree</div>
                                        </div>
                                        <div class="star-container" >
                                            <i *ngFor="let item of timeline" class="fa fa-star str star"></i>
                                        </div>
                                     
                                    </section>
                                   
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <br>
                <div *ngFor="let item of evaQuestions; let i = index" style="width: 30vw;">
                    <mat-card class="eva-card">
                        <p class="question"> <b> {{i + 1}} </b> . {{item?.question}}</p>
                        <!-- This is to display the selected rating value -->
                        <!-- <h3  class="starSelected"> <b> {{item?.answer}}* </b> </h3> -->
                    
                        <div *ngIf="isReadOnly">
                                <ul class="list-inline rating-list " [ngClass]="isReadOnly ? 'rating-disabled' : ''"
                                    *ngFor="let star of stars" style="display: inline-block">
                                    <li (click)="countStar(item,star)">
                                        <i class="fa fa-star str "></i>
                                    </li>
                                </ul>
                        </div>
                        <div *ngIf="!isReadOnly">
                            <ul class="list-inline rating-list " [ngClass]="isReadOnly ? 'rating-disabled' : ''"
                                *ngFor="let star of stars" style="display: inline-block">
                                <li (click)="countStar(item,star)" [ngClass]="{'selected': (star <= item?.answer)}">
                                    <i class="fa fa-star str "></i>
                                </li>
                            </ul>
                    </div>
                    </mat-card>
                    <br>
                </div>

                <mat-card class="buttons sticky">
                    <button class="active-solid-lrg but-style" (click)="goToAva()">Back</button>
                    <button class="active-stroke-lrg but-style" *ngIf="!surveyDetailId" (click)="updateActivityAndCreate()">Create</button>
                    <button class="active-stroke-lrg but-style" *ngIf="surveyDetailId" (click)="evaSelection()">Submit</button>
                </mat-card>
            </section>
            <app-footer></app-footer>
    <!-- </mat-card-content>
        </mat-card> -->
        </mat-tab>

        <!-- <mat-tab>
            <section class="ava" >
                <div class="sticky">
                    <span class="para part">Part 1</span>
                    <span class="para part">Part 2</span>
                    <mat-card class="right">
                        <span class="surveyhead flex">PART-3 : Long Form Questions</span>
                        <p class="para">Questions about x in 100 words or fewer.</p>
                    </mat-card>
                </div>
                    <br>
                    <div class="right">
                        <span class="para">Questions</span>
                        <br><br>
                        <div *ngFor="let item of evaQuestions; let i = index;" >
                            <mat-card class="eva-card">
                                <p class="question"> <b>{{i + 1}}</b>. {{item?.question}}</p>                        
                                <div class="">
                                    <div class="">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>Text </mat-label>
                                            <textarea matInput  [(ngModel)]="item.textareaValue" (input)="onTextareaInput(item)" ></textarea>
                                            <mat-hint>{{ item.remainingWords }} words remaining of 100</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-card>
                            <br>
                        </div>
                    </div>
                    <br>
                    <mat-card class="buttons sticky">
                        <button class="active-solid-lrg but-style" (click)="selected = 1">Back</button>
                        <button class="active-stroke-lrg but-style" *ngIf="surveyDetailId" (click)="onSubmitSurvey()">Finish Survey</button>
                    </mat-card>
              
            </section>

        </mat-tab> -->

        <mat-tab>
            <section class="ava" >
                <div class="sticky">
                    <mat-card class="right">
                        <span class="surveyhead flex">Thank you!</span>
                        <p class="para">You have completed all of the questions in this questionnaire. You can now close the survey</p>
                    </mat-card>
                    <br>
                    <div class="flex" style="gap: 2em;margin-right: 10px;">
                        <!-- <button class="active-stroke-lrg "(click)="goToAva();selected = 1">
                            Back
                        </button> -->
                        <button class="active-solid-lrg "(click)="close()">
                            Close Tab
                        </button>
                    </div>
                </div>
            </section>
        </mat-tab>
    </mat-tab-group>
</div>
<br>
<mat-card class="right" *ngIf="!isSurveyActive">
    "Survey is Completed"
</mat-card>
<!-- <div *ngIf="!isSurveyActive">
"Survey is Completed"</div> -->