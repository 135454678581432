import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessInterviewsService {

  clientURL:string = environment.CLIENT_API_URL
  surveyURL:string = environment.SURVEY_API_URL
  staticURL:string = environment.STATIC_API_URL

  private subject = new Subject<any>();
  constructor(
    private http : HttpClient
  ) { }

  getProcessInterview(clientId:string){
    let url = `${this.clientURL}processOwner/${clientId}?size=100&pageNumber=0`
    return this.http.get<any>(url, {observe: 'response'});
  }

  createProcessInterview(payload:any){
    let url = `${this.clientURL}processOwner`
    return this.http.post<any>(url , payload, {observe: 'response'})
  }

  editProcessInterview(processOwnerId:string,payload:any){
    let url = `${this.clientURL}processOwner/${processOwnerId}`
    return this.http.put<any>(url , payload, {observe: 'response'})
  }

  deleteProcessInterview(processOwnerId:string){
    let url = `${this.clientURL}processOwner/${processOwnerId}`
    return this.http.delete<any>(url ,  {observe: 'response'})
  }

  getTemplatesByModule(module: string) {
    return this.http.get<any>(
      `${this.staticURL}template?module=${module}&size=1000&pageNumber=0`
    );
  }

  updateTemplate(templateId: string, payload: any) {
    return this.http.put<any>(
      `${this.staticURL}template/${templateId}`,
      payload
    );
  }

  getPoiResponse(module:string, clientId: string = ""){
    let url: string = `${this.surveyURL}survey/surveyDetail?module=${module}`;
    if(clientId) url = `${url}&clientId=${clientId}`;
    return this.http.get<any>( url );
  }

  sendData(value: any) {
    this.subject.next({ value });
  }

  receiveData() {
    return this.subject.asObservable();
  }

// Weightage

  getWeightage(clientId:string){
    let url = `${this.clientURL}processOwner/weightage/${clientId}?size=100&pageNumber=0`
    return this.http.get<any>(url, {observe: 'response'});
  }

  createWeightage(payload:any){
    let url = `${this.clientURL}processOwner/weightage`
    return this.http.post<any>(url , payload, {observe: 'response'})
  }

  editWeightage(processOwnerId:string,payload:any){
    let url = `${this.clientURL}processOwner/weightage/${processOwnerId}`
    return this.http.put<any>(url , payload, {observe: 'response'})
  }

  deleteWeightage(processOwnerId:string){
    let url = `${this.clientURL}processOwner/weightage/${processOwnerId}`
    return this.http.delete<any>(url ,  {observe: 'response'})
  }

  // getPOI(clientId: string = ""){
  //   let url: string = `${this.surveyURL}survey/surveyDetail?module=${module}`;
  //   if(clientId) url = `${url}&clientId=${clientId}`;
  //   return this.http.get<any>( url );
  // }

  // getOutput(module:any,clientId:any){
  //   let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}`
  //   return this.http.get<any>(url)
  // }

  // getOutput1(module:any,clientId:any,focusGroupId:any){
  //   let url = `${this.surveyURL}surveyResponse/surveyResults?module=${module}&clientId=${clientId}&focusGroupId=${focusGroupId}`
  //   return this.http.get<any>(url)
  // }
}

