import { PageEvent } from "@angular/material/paginator";

export interface Pagination {
  page: number;
  perPage: number;
  total: number;
}

/**
 * @description
 *  A reusable pagination class to pagiantor. and maintains
 *  the pagination data.
 *
 * @example
 * ``` typescript
 * public clientsPagination: UsePagination = new UsePagination({
 *   page: 1,
 *   perPage: 50
 * });
 * ```
 *
 * public getPatientList(clientId: string, pagination: RecordsPagination, order: string, sortBy: string, searchStr = null, excludeIO = false,fieldSet?:string) {
 *   const { perPage, page } = pagination;
 *   let url = `${this.config.envSettings['API_ENDPOINT']}patients?page=${page}&perPage=${perPage}`;
 *   const headers = new HttpHeaders({ 'hide-loader': 'hide-loader' });
 *   return this.http.get(url, {headers});
 *  }
 *
 *  ```
 * ```html
 * <mat-paginator (page)="getPageEvent($event)"
 *                [disabled]="!dataSource?.data?.length"
 *                [length]="clientsPagination?.total || 0"
 *                [pageIndex]="(clientsPagination?.page || 1) - 1"
 *                [pageSize]="clientsPagination?.perPage"
 *                [pageSizeOptions]="clientsPagination?.paginationSizes"
 *                [showFirstLastButtons]="true">
 * </mat-paginator>
 * ```
 */
export class UsePagination implements Pagination {
  page = 0;
  perPage = 25;
  total = 0;
  paginationSizes = [5, 10, 20];

  constructor(recordsPagination: Pagination) {
    this.page = recordsPagination.page;
    this.perPage = recordsPagination.perPage;
    this.total = recordsPagination?.total;
    if (this.page == 1) this.page = 0;
  }

  public isPageSizeReachedTotal() {
    if (this.total) {
      return this.page >= this.total;
    }
    return this.page * this.perPage >= this.total;
  }

  // call this while search or filter
  public resetPagination() {
    this.page = 1;
    this.perPage = 10;
    this.total = 0;
  }

  public moveNextPage() {
    this.page += 1;
  }

  // bind this on page event
  public doPagination(event: PageEvent) {
    // if (event.previousPageIndex != event.pageIndex) {
    //   this.page = event.pageIndex;
    //   this.perPage = event.pageSize;
    // }
    this.page = event.pageIndex;
    this.perPage = event.pageSize;
    // if (event.pageIndex == 1 || event.pageIndex == 0) this.page = 0;
    // else this.page = event.pageIndex + 1;
  }

  // update it from list response
  public updateTotal(total: number) {
    this.total = total;
    this.setPaginationSizes(total);
  }

  // use this for
  public get value() {
    return {
      page: this.page,
      perPage: this.perPage,
      total: this.total,
    };
  }

  public set value(pagination: Pagination) {
    this.page = pagination.page;
    this.perPage = pagination.perPage;
    this.total = pagination.total;
  }

  public setPaginationSizes(total: number) {
    if (total <= 25) {
      this.paginationSizes = [25];
    } else {
      this.paginationSizes = [5, 10, 25, 50];
    }
  }
}
