import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "@app/core/guards/auth.guard";
import { AvaEvaComponent } from "./ava-eva.component";
import { SetupComponent } from "./setup/setup.component";
import { ResponseManagementComponent } from "./response-management/response-management.component";
import { AvaEvaOutputsComponent } from "./ava-eva-outputs/ava-eva-outputs.component";

const routes: Routes = [
  {
    path: "",
    component: AvaEvaComponent,
    children: [
      { path: "setup", component: SetupComponent },
      { path: "setup-II", component: SetupComponent },
      { path: "responses", component: ResponseManagementComponent },
      { path: "outputs", component: AvaEvaOutputsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AVAEVARoutingModule {}
