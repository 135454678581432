
<!-- <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged1($event)"> -->


      <!-- <mat-card style="display: none;">
        <form [formGroup]="form" (ngSubmit)="submit()" style="font-size: 14px;display: flex;justify-content: center;">
          &nbsp;
          <mat-form-field appearance="outline">
            <mat-select formControlName="filter" (selectionChange)="filterChart($event.value)">
              <mat-option *ngFor="let cat of filters" [value]="cat.Value">
                {{cat.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="outline" >
            <mat-select placeholder="Member by Manager" (selectionChange)="filterTeam($event.value)">
              <mat-option value="all">All</mat-option>
              <mat-option value="member">Team Member</mat-option>
              <mat-option value="isManager">Team Manager</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="outline">
            <mat-select placeholder="Team by Function" (selectionChange)="changexType($event.value)">
              <mat-option value="teamName">Team</mat-option>
              <mat-option value="clientFunction">Function</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <br>  <br>            
            <div *ngIf="chartLoad"> <apx-chart
              id="chart2"  
              [series]="newChartOptions.series"
              [chart]="newChartOptions.chart"
              [dataLabels]="newChartOptions.dataLabels"
              [plotOptions]="newChartOptions.plotOptions"
              [responsive]="newChartOptions.responsive"
              [xaxis]="newChartOptions.xaxis"
              [colors]="newChartOptions.colors" 
              [legend]="newChartOptions.legend"
              [fill]="newChartOptions.fill"
            ></apx-chart></div>
            <div >
              <apx-chart id="barLongChart"
                [series]="barLongChartOptions.series"
                [chart]="barLongChartOptions.chart"
                [dataLabels]="barLongChartOptions.dataLabels"
                [plotOptions]="barLongChartOptions.plotOptions"
                [xaxis]="barLongChartOptions.xaxis"
              ></apx-chart>
            </div>
      </mat-card> -->

      <section class="newDesign">
        <div class="row space filterr">
          <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
            <mat-form-field appearance="outline">
              <mat-label>Function</mat-label>
              <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
                (selectionChange)="changeFunction($event.value)">
                <mat-option *ngFor="let function of functions" [value]="function">
                  {{function}}
                </mat-option>
              </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="outline">
              <mat-label>Team</mat-label>
              <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
                <mat-option *ngFor="let team of teams" [value]="team">
                  {{team}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <br>
        <div class="switch">
          <span class="head">Overview</span>
          <div style="display: flex;justify-content:end;">
            <!-- <span>Team&nbsp;</span>
            <mat-slide-toggle color="grey" [(ngModel)]="functionSwitch" (change)="onFunctionChange($event)">
            </mat-slide-toggle>
            <span>&nbsp;Function</span> -->

              <span>Team&nbsp;</span>
              <div class="toggle-container2">
            
                <div class="toggle-button" [style.background-color]="functionSwitch ? 'white' : 'black' "
                  (click)="onFunctionChange('team')"></div>
                <div class="toggle-button" [style.background-color]="functionSwitch ? 'black' : 'white' "
                  (click)="onFunctionChange('function')"></div>
            
              </div>
              <span>&nbsp;Function</span>
           
            <span class="pd-right"></span>
            <span>&nbsp;Member</span>
            <div class="toggle-container">
              <div class="toggle-button" [style.background-color]="getDivColour('member')" (click)="handleMemberClick('member')"></div>
              <div class="toggle-button" [style.background-color]="getDivColour('all')" (click)="handleMemberClick('all')"></div>
              <div class="toggle-button" [style.background-color]="getDivColour('manager')" (click)="handleMemberClick('manager')"></div>
            </div>
            <!-- <mat-radio-group class="tw-toggle" [(ngModel)]="memberSelect">
              <mat-radio-button class="toggle toggle-yes" (click)="populateBarData()" value="member" ></mat-radio-button>            
              <mat-radio-button class="toggle toggle-yes" (click)="populateBarData()" value="all">
              </mat-radio-button>
              <mat-radio-button class="toggle toggle-yes" (click)="populateBarData()" value="manager">
              </mat-radio-button>
            </mat-radio-group> -->
            
            <br>&nbsp;&nbsp;&nbsp;
            <span>Manager&nbsp;</span>
            <br>
          </div>
        </div>
        <br>
        <div class="centered content scrolls">
          <mat-card class="mat-elevation-z0 selection" *ngFor="let teams of selectionTeams" (click)="getByTeam(teams.teamName,teams)"
          [ngClass]="{'selected-card': selectedRow.indexOf(teams) !== -1}">
           <span class="name">{{teams.teamName}}</span>
            <mat-card class="small nonValue mat-elevation-z0" matTooltip="Non Value">
              <span>{{teams.nonValueAdd.toFixed()}}</span>
            </mat-card>
            <mat-card class="small value mat-elevation-z0" matTooltip="Value Add">
              <span>{{teams.valueAdd.toFixed()}}</span>
            </mat-card>
            <mat-card class="small core mat-elevation-z0" matTooltip="Core">
              <span>{{teams.core.toFixed()}}</span>
            </mat-card>
            <mat-card class="mat-elevation-z0" style="text-align: center;">
              <span>{{teams.fte}}&nbsp;{{"FTE"}}</span>
            </mat-card>
          </mat-card>
          <br>
        </div>

        <div class="row centered">
          <mat-card class="col-sm-5">
            <span class="head">Detailed Breakdown</span>
            <br><br>
            <div class="scrolls" *ngIf="chartLoad">
              <apx-chart [series]="newChartOptions.series" [chart]="newChartOptions.chart" [dataLabels]="newChartOptions.dataLabels"
                [plotOptions]="newChartOptions.plotOptions" [responsive]="newChartOptions.responsive" [xaxis]="newChartOptions.xaxis"
                [yaxis]="newChartOptions.yaxis" [colors]="newChartOptions.colors" [legend]="newChartOptions.legend"
                [fill]="newChartOptions.fill">
              </apx-chart>
            </div>
          </mat-card>
          <mat-card class="col-sm-7 activity">
            <!-- <span class="head">All Activities</span> -->
            <br>
          <div class="scroll">
            <div class="table-container">
              <table mat-table [dataSource]="activityTable2" class="custom">
                <ng-container matColumnDef="activity">
                  <th mat-header-cell *matHeaderCellDef> All Activities</th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
            
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef> Time Spent </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="instyle" *ngIf="element.spent !== 0">
                      <mat-progress-bar *ngIf="element.colorType === 'core'" [value]="element.spent " class="style1"></mat-progress-bar>
                      <mat-progress-bar *ngIf="element.colorType === 'non-value'" [value]="element.spent"  class="style2"></mat-progress-bar>
                      <mat-progress-bar *ngIf="element.colorType === 'value'" [value]="element.spent " class="style3"></mat-progress-bar>
                      <!-- <mat-progress-bar  [value]="100" class="style1"></mat-progress-bar>
                      <mat-progress-bar *ngIf="element.colorType === 'non-value'" [value]="100" class="style2"></mat-progress-bar>
                      <mat-progress-bar *ngIf="element.colorType === 'value'" [value]="100" class="style3"></mat-progress-bar> -->
                      <div style="position: absolute; padding: 10px; color: black;width: 6vw!important;">
                        {{element.spent | number: "1.0-0" }}%
                      </div>
                    </div>
                    <div class="instyle" *ngIf="element.spent === 0">
                      &nbsp;&nbsp;&nbsp;{{element.spent}}% 
                    </div>
                  </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="atColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: atColumns;"></tr>
              </table>
            </div>
          </div>
          </mat-card>
        </div>
        <br>
        <div class="row space">
          <div class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
            <div class="keys">
              <span class="tle">{{"Key:"}}</span>
            </div>
            <mat-card mat-ripple class="keys mat-elevation-z0" (click)="getType('All')">
              <span class="key-text">{{"All"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys core mat-elevation-z0" (click)="getType('Core')">
              <span class="key-text">{{"Core"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys value mat-elevation-z0" (click)="getType('Value Add')">
              <span class="key-text">{{"Value Add"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys non-value mat-elevation-z0" (click)="getType('Non Value Add')">
              <span class="key-text">{{"Non Value Add"}}</span>
            </mat-card>
          </div>
        </div>
      </section>
      <br><br><br>
  <!-- </mat-tab-group> -->
  <br>
  <br><br>

