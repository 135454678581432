import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsV2RoutingModule } from './projects-v2-routing.module';
import { ProjectsV2Component } from './projects-v2.component';
import { SharedModule } from '@app/shared/shared.module';
import { OverviewComponent } from './overview/overview.component';
import { ManagerInteriewsModule } from './manager-interview/manager-interiews.module';
import { OpMaturityModule } from './op-maturity/op-maturity.module';
import { ConstructionComponent } from './construction/construction.component';



@NgModule({
  declarations: [
    ProjectsV2Component,
    OverviewComponent,
    ConstructionComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    ProjectsV2RoutingModule,
    ManagerInteriewsModule,
    OpMaturityModule,
    SharedModule,
    CommonModule
  ]
})
export class ProjectsV2Module { }
