export const OVERVIEW_OP_MATURITY_HEATMAP = [
  {
    teamName: "Team A",
    functionName: "Accounting/Finance",
    FTE: 20,
    totalFLC: 10000,
    ava: {
      core: 50,
      valueAdd: 20,
      nonValueAdd: 30,
    },
    eva: 2.3,
    teamLeaderInterview: 2.3,
    focusGroup: 4.2,
    poi: {
      diagnosticPotential: 3.9,
      potentialSavings: 15000,
    },
    maturityScore: 4.3,
  },
  {
    teamName: "Team B",
    functionName: "Customer Service",
    FTE: 30,
    totalFLC: 2000,
    ava: {
      core: 20,
      valueAdd: 50,
      nonValueAdd: 30,
    },
    eva: 1.5,
    teamLeaderInterview: 1.7,
    focusGroup: 3.7,
    poi: {
      diagnosticPotential: 4.6,
      potentialSavings: 25000,
    },
    maturityScore: 1.3,
  },
  {
    teamName: "Team C",
    functionName: "Customer Service",
    FTE: 30,
    totalFLC: 2000,
    ava: {
      core: 25,
      valueAdd: 25,
      nonValueAdd: 50,
    },
    eva: 3.6,
    teamLeaderInterview: 4.9,
    focusGroup: 3.7,
    poi: {
      diagnosticPotential: 3.7,
      potentialSavings: 290000,
    },
    maturityScore: 3.8,
  },
];

export const OP_MATURITY_SCORING = [
  {
    teamName: "Team A",
    functionName: "Accounting/Finance",
    FTE: 20,
    totalFLC: 10000,
    categoryScores: [
      {
        category: "Customer & Purpose",
        score: 1.3,
      },
      {
        category: "People & Capability",
        score: 2.7,
      },
      {
        category: "Performance",
        score: 1.7,
      },
      {
        category: "Problem Solving",
        score: 1.7,
      },
      {
        category: "Intelligent Automation and Process",
        score: 4.1,
      },
    ],
    maturityScore: 4.9,
  },
  {
    teamName: "Team B",
    functionName: "Customer Service",
    FTE: 70,
    totalFLC: 19000,
    categoryScores: [
      {
        category: "Customer & Purpose",
        score: 1.3,
      },
      {
        category: "Performance",
        score: 1.7,
      },
      {
        category: "Intelligent Automation and Process",
        score: 4.1,
      },
      {
        category: "Problem Solving",
        score: 1.7,
      },
      {
        category: "People & Capability",
        score: 2.7,
      },
    ],
    maturityScore: 3.4,
  },
  {
    teamName: "Team ABC",
    functionName: "Project Management/Analysis",
    FTE: 70,
    totalFLC: 19000,
    categoryScores: [
      {
        category: "Customer & Purpose",
        score: 2.3,
      },
      {
        category: "Performance",
        score: 4.7,
      },
      {
        category: "Intelligent Automation and Process",
        score: 3.1,
      },
      {
        category: "Problem Solving",
        score: 3.7,
      },
      {
        category: "People & Capability",
        score: 2.7,
      },
    ],
    maturityScore: 3.4,
  },
];

export const OP_MATURITY_TOTAL_RESPONSES = {
  totalResponse: 570,
  moduleResponses: [
    {
      module: "AVA",
      responseCount: 125,
    },
    {
      module: "POI",
      responseCount: 171,
    },
    {
      module: "Team Leader Interviews",
      responseCount: 80,
    },
    {
      module: "Engagement",
      responseCount: 114,
    },
    {
      module: "Focus Group",
      responseCount: 80,
    },
  ],
};

export const OP_MATURITY_LENS_PERFORMANCE = [
  {
    lens: "Customer Journeys",
    score: 3.6,
  },
  {
    lens: "Understanding customer value",
    score: 4.7,
  },
  {
    lens: "Customer feedback",
    score: 1.5,
  },
  {
    lens: "Team Purpose",
    score: 3.6,
  },
  {
    lens: "Improvement plans",
    score: 2.8,
  },
  {
    lens: "Voice of business",
    score: 4.7,
  },
  {
    lens: "KPIs",
    score: 1.9,
  },
  {
    lens: "Targets",
    score: 4.0,
  },
  {
    lens: "MI",
    score: 1.1,
  },
  {
    lens: "Quality management",
    score: 3.0,
  },
  {
    lens: "Visual management",
    score: 3.1,
  },
  {
    lens: "Visual management  Huddles - Occurrence",
    score: 3.2,
  },
  {
    lens: "Huddles - Content & Facilitation",
    score: 3.2,
  },
  {
    lens: "Tiered system",
    score: 3.3,
  },
  {
    lens: "Structured Problem Solving",
    score: 2.5,
  },
  {
    lens: "Triggers",
    score: 2.6,
  },
  {
    lens: "Escalation process",
    score: 2.7,
  },
  {
    lens: "Failure demand / rework",
    score: 3.6,
  },
  {
    lens: "AVA",
    score: 3.6,
  },
  {
    lens: "Visibility of processes and ownership",
    score: 3.6,
  },
  {
    lens: "Process design",
    score: 3.6,
  },
  {
    lens: "Inbound quality",
    score: 3.6,
  },
  {
    lens: "Systems enablement / workflow",
    score: 3.6,
  },
  {
    lens: "Risk management",
    score: 3.6,
  },
  {
    lens: "Selection criteria and process for new automation opportunities",
    score: 3.6,
  },
  {
    lens: "RPA & workflow Implementation status",
    score: 3.6,
  },
  {
    lens: "AI and advanced technology implementation status",
    score: 3.6,
  },
  {
    lens: "Automation supplier relationships",
    score: 3.6,
  },
  {
    lens: "Automation supplier relationships",
    score: 3.6,
  },
  {
    lens: "Use of standards",
    score: 3.6,
  },
  {
    lens: "Knowledge management of standards and process confirmation",
    score: 3.6,
  },
  {
    lens: "Process confirmation and adherence",
    score: 3.6,
  },
  {
    lens: "Work organisation",
    score: 3.6,
  },
  {
    lens: "Forecast",
    score: 3.6,
  },
  {
    lens: "Control",
    score: 3.6,
  },
  {
    lens: "Review",
    score: 3.6,
  },
  {
    lens: "Continuous improvement culture",
    score: 3.6,
  },
  {
    lens: "Continuous improvement governance",
    score: 3.6,
  },
  {
    lens: "Deskside coaching",
    score: 3.6,
  },
  {
    lens: "Coaching / 1-1s",
    score: 3.6,
  },
  {
    lens: "Awareness & visibility of skills",
    score: 3.6,
  },
  {
    lens: "Training & development plans",
    score: 3.6,
  },
  {
    lens: "Demand and capacity management",
    score: 3.6,
  },
  {
    lens: "Successes captured",
    score: 3.6,
  },
  {
    lens: "Working styles",
    score: 3.6,
  },
  {
    lens: "Team building",
    score: 3.6,
  },
  {
    lens: "Colleague engagement",
    score: 3.6,
  },
  {
    lens: "Programme sponsorship",
    score: 3.6,
  },
  {
    lens: "Middle management visibility",
    score: 3.6,
  },
  {
    lens: "Senior management visibility",
    score: 1.3,
  },
  {
    lens: "Role models and behaviours",
    score: 1.1,
  },
];

export const MI_TEAMS = [
  {
    teamName: "Team A",
    functionName: "Accounting/Finance",
    FTE: 20,
    totalFLC: 10000,
    ava: {
      core: 50,
      valueAdd: 20,
      nonValueAdd: 30,
    },
    eva: 2.3,
    teamLeaderMaturityScore: 3.1,
  },
  {
    teamName: "Team B",
    functionName: "Enterprise Services/Facilities Management",
    FTE: 50,
    totalFLC: 150000,
    ava: {
      core: 30,
      valueAdd: 25,
      nonValueAdd: 45,
    },
    eva: 3.9,
    teamLeaderMaturityScore: 4.0,
  },
  {
    teamName: "Team C",
    functionName: "Legal",
    FTE: 70,
    totalFLC: 480000,
    ava: {
      core: 70,
      valueAdd: 30,
      nonValueAdd: 20,
    },
    eva: 2.6,
    teamLeaderMaturityScore: 3.0,
  },
];

export const MI_AVA_TEAM_LEADER = [
  {
    activity: "Compliance",
    score: 20,
  },
  {
    activity: "First Aid",
    score: 19,
  },
  {
    activity: "Following Standard Processes",
    score: 41,
  },
  {
    activity: "Core work",
    score: 41,
  },
  {
    activity:
      "Escalations (e.g. Any problems that require escalating for support)",
    score: 14,
  },
  {
    activity: "Archiving",
    score: 15,
  },
  {
    activity: "Meetings",
    score: 16,
  },
  {
    activity: "Reports",
    score: 41,
  },
  {
    activity: "Team Manager Support - Cover",
    score: 12,
  },
  {
    activity: "Team Manager Support - Other",
    score: 41,
  },
  {
    activity: "Work Allocation",
    score: 32,
  },
  {
    activity: "Chasing/ Rework / Fixing Issues",
    score: 33,
  },
  {
    activity: "Stepping In To Execute Activities Assigned To Your Team",
    score: 34,
  },
  {
    activity: "Covering For Other Managers",
    score: 41,
  },
  {
    activity: "Emails / Admin",
    score: 41,
  },
  {
    activity: "IT / Technical Issues",
    score: 37,
  },
  {
    activity: "Reactively Addressing Issues / Firefighting",
    score: 41,
  },
  {
    activity: "Internal / External Audit",
    score: 38,
  },
  {
    activity: "Other Non Core Activities",
    score: 41,
  },
  {
    activity: "Ad Hoc Meetings",
    score: 39,
  },
  {
    activity: "Other",
    score: 41,
  },
  {
    activity: "Recognition",
    score: 41,
  },
  {
    activity: "Performance 1-2-1 and Development",
    score: 41,
  },
  {
    activity: "Supporting The Workload Of Other Teams Besides Your Home Team",
    score: 41,
  },
  {
    activity: "Process Guide Review / Updating Procedures",
    score: 41,
  },
  {
    activity: "Project - Meetings",
    score: 41,
  },
  {
    activity: "Project - SME",
    score: 41,
  },
  {
    activity: "Project - Testing",
    score: 41,
  },
  {
    activity:
      "Training (Including training of new starters, formalised, classroom based)",
    score: 41,
  },
  {
    activity: "Problem Solving and Process Improvement Activities",
    score: 41,
  },
  {
    activity:
      "Personal Development Time For You Rather Than Developing Your Team Members",
    score: 41,
  },
  {
    activity: "Customer Facing Time",
    score: 41,
  },
  {
    activity: "Coaching",
    score: 41,
  },
  {
    activity:
      "Huddles / Stand-ups (Quick 15-20 min daily meetings with your team)",
    score: 41,
  },
  {
    activity: "Capacity Planning (Daily, weekly, monthly)",
    score: 41,
  },
];

export const MI_AVA_TEAM_MEMBER = [
  {
    activity: "Compliance",
    score: 1.3,
  },
  {
    activity: "First Aid",
    score: 4.1,
  },
  {
    activity: "Following Standard Processes",
    score: 1.4,
  },
  {
    activity: "Core work",
    score: 1.5,
  },
  {
    activity:
      "Escalations (e.g. Any problems that require escalating for support)",
    score: 4.0,
  },
  {
    activity: "Archiving",
    score: 1.6,
  },
  {
    activity: "Meetings",
    score: 4.2,
  },
  {
    activity: "Reports",
    score: 1.7,
  },
  {
    activity: "Team Manager Support - Cover",
    score: 3.8,
  },
  {
    activity: "Team Manager Support - Other",
    score: 1.4,
  },
  {
    activity: "Work Allocation",
    score: 2.0,
  },
  {
    activity: "Chasing/ Rework / Fixing Issues",
    score: 4.1,
  },
  {
    activity: "Stepping In To Execute Activities Assigned To Your Team",
    score: 4.1,
  },
  {
    activity: "Covering For Other Managers",
    score: 4.1,
  },
  {
    activity: "Emails / Admin",
    score: 4.1,
  },
  {
    activity: "IT / Technical Issues",
    score: 4.1,
  },
  {
    activity: "Reactively Addressing Issues / Firefighting",
    score: 4.1,
  },
  {
    activity: "Internal / External Audit",
    score: 4.1,
  },
  {
    activity: "Other Non Core Activities",
    score: 4.1,
  },
  {
    activity: "Ad Hoc Meetings",
    score: 4.1,
  },
  {
    activity: "Other",
    score: 4.1,
  },
  {
    activity: "Recognition",
    score: 4.1,
  },
  {
    activity: "Performance 1-2-1 and Development",
    score: 4.1,
  },
  {
    activity: "Supporting The Workload Of Other Teams Besides Your Home Team",
    score: 4.1,
  },
  {
    activity: "Process Guide Review / Updating Procedures",
    score: 4.1,
  },
  {
    activity: "Project - Meetings",
    score: 4.1,
  },
  {
    activity: "Project - SME",
    score: 4.1,
  },
  {
    activity: "Project - Testing",
    score: 4.1,
  },
  {
    activity:
      "Training (Including training of new starters, formalised, classroom based)",
    score: 4.1,
  },
  {
    activity: "Problem Solving and Process Improvement Activities",
    score: 4.1,
  },
  {
    activity:
      "Personal Development Time For You Rather Than Developing Your Team Members",
    score: 4.1,
  },
  {
    activity: "Customer Facing Time",
    score: 4.1,
  },
  {
    activity: "Coaching",
    score: 4.1,
  },
  {
    activity:
      "Huddles / Stand-ups (Quick 15-20 min daily meetings with your team)",
    score: 4.1,
  },
  {
    activity: "Capacity Planning (Daily, weekly, monthly)",
    score: 4.1,
  },
  {
    activity: "Project Work Outside of Standard Processes",
    score: 4.1,
  },
  {
    activity: "Team Briefings",
    score: 4.1,
  },
  {
    activity: "Reading Important Information On Process/ Policy Changes",
    score: 4.1,
  },
];

export const MI_EVA_RESPONSE = [
  {
    question:
      "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 4.5,
  },
  {
    question:
      "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
    score: {
      one: "31%",
      two: "10%",
      three: "20%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 4.1,
  },
  {
    question: "I am clear about the purpose and value of our work.",
    score: {
      one: "3%",
      two: "10%",
      three: "30%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 3.2,
  },
  {
    question:
      "We regularly use customer feedback to reflect on how to improve the quality of our work.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 4.0,
  },
  {
    question: "I understand clearly how my actions impact customer service.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.8,
  },
  {
    question: "We resolve customer problems quickly and effectively.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "Handovers between our team and other teams are smooth and there is minimal rework required.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I am able to use modern digital technology to improve the way I work.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "Forecasting is accurate and there is a clear feedback loop to better inform resource planning.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "Everyone in my team follows the same steps and methods, completing tasks by following best practice.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I would recommend my employer as a great place to work and would go beyond what is expected to ensure the business' success.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question: "I am recognised in a way that motivates me.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question: "Local leadership are visible and good workplace role models.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I receive regular support and coaching that helps me improve my performance.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "This last year, I have had opportunities at work to learn and grow.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question: "I am totally satisfied with my working life.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I am encouraged to participate in activities that enhance departmental productivity.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "My line manager and I have actively shaped my role so that it contributes to my long term development objectives.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 3.7,
  },
  {
    question:
      "I can see the link between my personal goals and the direction of the business.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I can see the link between my goals and the direction of the business.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 3.8,
  },
  {
    question:
      "Team performance and feedback is visible to me and looked at regularly.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "My team collaborates well with each other, other teams, and are committed to doing quality work.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 3.0,
  },
  {
    question:
      "My manager effectively balances workload and equips the team with the correct materials to work effectively.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 1.1,
  },
  {
    question:
      "In my team, we communicate openly with each other about problems and concerns.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 2.1,
  },
  {
    question:
      "I use metrics that help me understand the performance of the work I deliver.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 1.7,
  },
  {
    question:
      "My supervisor, or someone at work, seems to care about me as a person and encourages my development.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 1.0,
  },
  {
    question:
      "In the last seven days, I have received recognition or praise for doing good work.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 1.6,
  },
  {
    question: "At work, I have the opportunity to do what I do best every day.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 1.3,
  },
  {
    question: "I have a best friend at work.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 4.1,
  },
  {
    question:
      "The mission or purpose of my company makes me feel my job is important.",
    score: {
      one: "3%",
      two: "10%",
      three: "60%",
      four: "3%",
      five: "20%",
    },
    maturityScore: 3.5,
  },
];
