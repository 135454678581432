import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ava-eva',
  templateUrl: './ava-eva.component.html',
  styleUrls: ['./ava-eva.component.scss']
})
export class AvaEvaComponent implements OnInit {
  selected:any;
  
  @Input() surveyId: string = "";
  surveyData: any;

  clientId: string = "";
  constructor(
    private router :Router
  ) { }

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem('clientId') || "";
  }



 

}
