<br *ngIf="isSetupModules">


<p style="text-align: center;margin: 0em;">Please select the modules you will be carrying out during your diagnostic</p>
<table mat-table [dataSource]="modules_data_source" class="mat-elevation-z8 demo-table" *ngIf="isSetupModules" style="display: none;">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="vertical-align: initial;" >{{element.position}}</td>
    </ng-container>
  
    <ng-container matColumnDef="module">
        <th style="text-align: center" mat-header-cell id="module_container" *matHeaderCellDef>Module</th>
        <button style="margin-left: 2em;" class="active-stroke-lrg" *matCellDef="let element"
            [ngClass]="{'activeButton':element.is_selected || element.selected,'inActiveButton':!element.selected && !element.is_selected}"
            (click)="selectModule(element); module(element)">{{element.name}}</button>
    </ng-container>
  
    <ng-container  *ngIf="!isViewClient" matColumnDef="description">
        <th style="text-align: center" mat-header-cell *matHeaderCellDef>Description</th>
        <td style="text-align: center" mat-cell *matCellDef="let element">
            <p class="descrip">{{element.description}}</p>
            <!-- <mat-form-field appearance="outline" style="width:20em; " >
                <mat-label>Description</mat-label >
                <input [(ngModel)]="element.description" type="text" [disabled]=" !(element.selected || element.is_selected)"
                   matInput maxLength="300" noSpace
                  (input)="descModify( element, $event)" >
            </mat-form-field> -->
        </td>
      </ng-container>

    <ng-container *ngIf="isViewClient" matColumnDef="description">
        <th style="text-align: center" mat-header-cell *matHeaderCellDef>Description</th>
        <td style="text-align: center" mat-cell *matCellDef="let element">
          <p class="descrip">{{element.description}}</p>
            <!-- <mat-form-field appearance="outline" style="width:40em; " >
                <mat-label>Description</mat-label >
                <input [(ngModel)]="element.description" type="text" [disabled]=" true"
                   matInput maxLength="300" noSpace
                  (input)="descModify( element, $event)" >
            </mat-form-field> -->
        </td>
      </ng-container>
  
    <tr   mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
     style="margin: 2em 0em;"
        mat-row
        (click)="clickedRows.add(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns;"
    ></tr>
  </table>  


  

  <table mat-table [dataSource]="modules_data_source" class="mat-elevation-z8 demo-table" *ngIf="isSetupModules">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="vertical-align: initial;" >{{element.position}}</td>
    </ng-container>
  
    <ng-container matColumnDef="module">
        <th style="text-align: center" mat-header-cell id="module_container" *matHeaderCellDef>Module</th>
        <button style="margin-left: 2em;" class="active-stroke-lrg" *matCellDef="let element"
          [ngClass]="{'activeButton':element.mandatory || element.checked }"
            (click)="onChangeModule(element);">{{element.name}}</button>
    </ng-container>
  
    <ng-container  *ngIf="!isViewClient" matColumnDef="description">
        <th style="text-align: center" mat-header-cell *matHeaderCellDef>Description</th>
        <td style="text-align: center" mat-cell *matCellDef="let element">
            <p class="descrip">{{element.description}}</p>
        </td>
      </ng-container>

    <ng-container *ngIf="isViewClient" matColumnDef="description">
        <th style="text-align: center" mat-header-cell *matHeaderCellDef>Description</th>
        <td style="text-align: center" mat-cell *matCellDef="let element">
          <p class="descrip">{{element.description}}</p>
        </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
     style="margin: 2em 0em;"
        mat-row
        (click)="clickedRows.add(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns;"
    ></tr>
  </table> 


<p style="margin-bottom:10px ;" *ngIf="!isSetupModules">Activity Value Analyser Setup</p>
<mat-card *ngIf="!isSetupModules">
    <mat-card-content>
        <div id="example-container" class="example-container">
            <form [formGroup]="modules_details_form">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Team activities: Time Categories</mat-label>
                    <input matInput formControlName="teamActivities" placeholder="+ add new activity" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Allocation</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="allocation1" formControlName="allocation1">
                      <mat-option *ngFor="let allocation1 of allocations1" [value]="allocation1.value">
                        {{allocation1.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Questions</mat-label>
                    <input matInput formControlName="question" placeholder="+ add new question" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Input Type</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="inputType1" formControlName="inputType1">
                      <mat-option *ngFor="let inputType1 of inputTypes1" [value]="inputType1.value">
                        {{inputType1.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Manager only activites: time categories</mat-label>
                    <input matInput formControlName="managerActivities" placeholder="+ add new activity" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Allocation</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="allocation2" formControlName="allocation2">
                      <mat-option *ngFor="let allocation2 of allocations2" [value]="allocation2.value">
                        {{allocation2.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Manager only questions</mat-label>
                    <input matInput formControlName="managerOnlyQuestions" placeholder="+ add new question" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Input Type</mat-label>
                    <mat-select [(ngModel)]="selectedValue" name="inputType2" formControlName="inputType2">
                      <mat-option *ngFor="let inputType2 of inputTypes2" [value]="inputType2.value">
                        {{inputType2.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </form>
        </div>
    </mat-card-content>
</mat-card>

<br>
<section class="clientab">
    <div class="basic-container">
        <mat-card class="custom-pad">
            <mat-card-content >
                <div class="example-container">
                    <div class="three-buttons">
                        <button class="active-solid-lrg" matStepperPrevious>Back</button>
                        <button  [disabled]="isViewClient" class="active-solid-lrg"
                        (click)="isEditClient ? editModule() : createMoudle()  " *ngIf="isSetupModules">Set up Modules</button> &nbsp;
                        <button class="active-solid-lrg"(click)="selectParticipants()" *ngIf="!isSetupModules">Select participants</button> &nbsp;
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</section>