import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionAddDialogComponent } from '@app/masterdata/question-add-dialog/question-add-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { PageEvent } from '@angular/material/paginator';
import { UsePagination } from '@app/core/classes/use-pagination';

@Component({
  selector: 'app-db-op-maturity',
  templateUrl: './db-op-maturity.component.html',
  styleUrls: ['./db-op-maturity.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class DbOpMaturityComponent implements OnInit {

  constructor(
    private databaseService: DatabaseService,
    private onboardingService: OnboardingService,
    private matDialog: MatDialog,
  ) { }
  expandedElement: PeriodicElement | null;
  dataSource: any = [];
  displayedColumns: string[] = ['expand', 'category', 'subCategory', 'lens', 'question', 'action'];
  // displayedColumns: string[] = ['category', 'subCategory', 'lens', 'question', 'notInPlace', 'designedYetNotInPlace', 'inPlaceAndNotAddingValue', 'inPlaceAndAddingValue', 'InPlaceAddingValueAndContinuouslyReviewed'];
  categoryList: any = [];
  dialog: any;
  totalLength: number = 0;
  questionPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });

  ngOnInit(): void {
    this.getCategoryData();
    this.dataSource = new MatTableDataSource(this.databaseService.getOpMaturityQuestions());
  }
  
  openDialog() {
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: '70em',
      disableClose: true,
      data: {from: 'data-create'},
    });
  }

  isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
    return (buttonToggleGroup.value || []).indexOf(id) !== -1;
  }
  
  getCategoryData(){
    this.onboardingService.getModuleData('CATEGORY').subscribe((response) => {
      this.categoryList = response;
    },
    (error)=>{
      console.log('Error on get Category list: ', error);

    });
  }

  getPageEvent(pageData: PageEvent) {
    // this.clientsPagination.doPagination(pageData);
  }

}

export interface PeriodicElement {
  category: string;
  activity: string;
  type: string;
}