<!-- <br>
<mat-tab-group class="tabgroup" (selectedTabChange)="tabChange($event)">
  <mat-tab label="Op Maturity-Scoring">
    <app-op-maturity-scoring></app-op-maturity-scoring>
  </mat-tab>

  <mat-tab label="Outputs">
    <app-op-maturity-outputs *ngIf="render"></app-op-maturity-outputs>
  </mat-tab>
</mat-tab-group> -->
<router-outlet></router-outlet>