import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTemplateComponent } from '@app/workflow-template/create-template/create-template.component';
import { WorflowTemplateComponent } from '@app/workflow-template/worflow-template.component';
import { SharedModule } from '@app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PreviewComponent } from '@app/workflow-template/preview/preview.component';
import { TemplateAddComponent } from './template-add/template-add.component';



@NgModule({
  declarations: [
    CreateTemplateComponent,
    WorflowTemplateComponent,
    PreviewComponent,
    TemplateAddComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule
  ]
})
export class WorkflowTemplateModule { }
