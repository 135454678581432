import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  constructor(private httpClient: HttpClient) {}

  surveyUrl: string = environment.SURVEY_API_URL;
  clientUrl: string = environment.CLIENT_API_URL;
  staticUrl: string = environment.STATIC_API_URL;

  getSingleClient(id: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client?id=${id}&fullSearch=false&size=100&pageNumber=0`
    );
  }

  getClientFunctions(id: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/functions?clientId=${id}`
    );
  }

  createSurvey(payload: any) {
    return this.httpClient.post<any>(`${this.surveyUrl}survey`, payload);
  }

  getSurveyById(id: string) {
    return this.httpClient.get<any>(
      `${this.surveyUrl}survey?id=${id}&size=100&pageNumber=0`
    );
  }

  getSurveyByQuery(query: object) {
    let entries = Object.entries(query);
    let data = entries.map(([key, value]) => {
      return `${key}=${value}`;
    });
    let finalQuery = data.join("&");
    let url = `${this.surveyUrl}survey?${finalQuery}`;
    return this.httpClient.get<any>(url);
  }

  getSurveyDetails(
    propName: string = "",
    propValue: string = "",
    clientId: string = ""
  ) {
    let url = `${this.surveyUrl}survey/surveyDetail?${propName}=${propValue}`;

    if (clientId) url = `${url}&clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  createSurveyResponse(payload: any = {}) {
    let url = `${this.surveyUrl}surveyResponse`;
    return this.httpClient.post<any>(url, payload);
  }

  getSurveyResponses(surveyDetailId: string) {
    let url = `${this.surveyUrl}surveyResponse?surveyDetailId=${surveyDetailId}`;
    return this.httpClient.get<any>(url);
  }

  getSurveyResponsesCount(clientId: string) {
    let url = `${this.surveyUrl}surveyResponse/getSurveyCounts?clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  getSelectedOwner(clientid: any) {
    // let url = `${this.clientUrl}client`
    let url = `${this.clientUrl}client?id=${clientid}&fullSearch=false&size=100&pageNumber=0`;
    return this.httpClient.get<any>(url);
  }

  getSurveyStats(surveyId: string) {
    let url = `${this.surveyUrl}survey/getStats?surveyId=${surveyId}`;
    return this.httpClient.get<any>(url);
  }

  // Get Survey Output for their modules
  getSurveyOutputs(module: any, clientId: any) {
    let url = `${this.surveyUrl}surveyResponse/surveyResults?module=${module}&clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  //Manager Interviews survey (landing) page

  getMiTemplate(tempId: any) {
    let url =
      `${this.staticUrl}template?id=${tempId}&module=managerInterviews` +
      `&size=100&pageNumber=0`;
    return this.httpClient.get<any>(url);
  }

  getFocusGroupTemplate(tempId: any) {
    let url =
      `${this.staticUrl}template?id=${tempId}&module=focusGroup` +
      `&size=100&pageNumber=0`;
    return this.httpClient.get<any>(url);
  }

  getPoiTemplate(tempId: any) {
    let url =
      `${this.staticUrl}template?id=${tempId}&module=processOwners` +
      `&size=100&pageNumber=0`;
    return this.httpClient.get<any>(url);
  }

  createMiSurvey(payload: any) {
    let url = `${this.surveyUrl}survey`;
    return this.httpClient.post<any>(url, payload);
  }

  submitMiSurvey(payload: any) {
    let url = `${this.surveyUrl}surveyResponse`;
    return this.httpClient.post<any>(url, payload);
  }

  //Focus Group Get teams
  getFocusGroupTeams(surveyDetailId: string = "", clientId: string = "") {
    let url = `${this.clientUrl}client/teams`;
    if (surveyDetailId) url = `${url}?surveyDetailId=${surveyDetailId}`;
    if (clientId) url = `${url}?clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  //FG get current question index
  getFGQuestionIndex(surveyDetailId: any) {
    let url = `${this.surveyUrl}survey/surveyDetail/${surveyDetailId}`;
    return this.httpClient.get<any>(url);
  }

  getAllSurveyDetails(clientId: any) {
    let url = `${this.surveyUrl}survey/surveyDetail?clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  postSurveyDetail(payload: any) {
    let url = `${this.surveyUrl}survey/surveyDetail`;
    return this.httpClient.post<any>(url, payload);
  }

  //FG increase current question index
  increaseQuestionIndex(payload: any, surveyDetailId: any) {
    let url = `${this.surveyUrl}survey/manageFocusGroup/${surveyDetailId}`;
    return this.httpClient.post<any>(url, payload);
  }

  //Get Functions
  getFunctions(clientId: any) {
    let url = `${this.clientUrl}client/functions?clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  //Get Teams
  getTeams(clientId: any) {
    let url = `${this.clientUrl}client/teams?clientId=${clientId}`;
    return this.httpClient.get<any>(url);
  }

  downloadData(clientId: string, module: string, surveyDetailId?: string) {
    let url = `${this.surveyUrl}surveyResponse/download/${clientId}?module=${module}`;
    if (surveyDetailId) {
      url = url + `&surveyDetailId=${surveyDetailId}`;
    }

    const headers = new HttpHeaders({ "Content-Type": "application/json" });

    return this.httpClient.get(url, { responseType: "blob", headers });
  }
}
