<br>
<div class="content">
    <div class="">
    
        <section class="master-data">
            <mat-card>
                <div class="row filters" >
<!-- commented for later use -->
                    <!-- <mat-form-field appearance="outline"  style="font-size: 12.5px;width: 45%;">
                        <mat-label>Search</mat-label>
                        <input matInput #input [formControl]="search" (input)="onSearchChange($event)"/>
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                        <mat-label>Select Category</mat-label>
                        <mat-select [formControl]="searchCategory" (selectionChange)="onCategorySelect($event)">

                            <mat-option *ngFor="let category of categoryList" [value]="category.name"> {{ category.name }} </mat-option>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                        <mat-label>Select Module</mat-label>
                        <mat-select [formControl]="searchModule" (selectionChange)="onModuleSelect($event)">
                          
                          <mat-option *ngFor="let module of moduleList" [value]="module.value"> {{ module.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <button class="clearBtn" (click)="clearFilters()" mat-raised-button color="warn"  >
                        <i class="fa-solid fa-eraser fa-lg"></i>
                       Clear Filters
                    </button> -->

                    <div class="span2"></div>

                    <button class="active-solid-lrg2 clearBtn" (click)="clearFilters()">
                        Clear Filters
                    </button>

                </div>

            </mat-card>
            <br>
            <table mat-table [dataSource]="dataSource" class="w-100">

            
               
              
        
                <!-- <ng-container matColumnDef="state">
                  <th mat-header-cell *matHeaderCellDef>State</th>
                  <td mat-cell *matCellDef="let element">{{element.state}}</td>
                </ng-container>-->

              
                <ng-container matColumnDef="template">
                  <th mat-header-cell *matHeaderCellDef>Template</th>
                  <td mat-cell *matCellDef="let element; let i=index">
                    <span *ngIf="rowEdited[i]">
                        <mat-form-field  style="font-size: 12px;">
                            <input [(ngModel)]="element.templateName" matInput required>
                        </mat-form-field>
                        </span>
                    <span *ngIf="!rowEdited[i]">{{element.templateName}}</span>
                 </td>
                </ng-container> 

                <ng-container matColumnDef="module">
                  <th mat-header-cell *matHeaderCellDef>Module</th>
                  <td mat-cell *matCellDef="let element">{{ getModuleName(element.module)}}</td>
                </ng-container>

               
              
                <!-- action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="display: flex;gap: 1em;justify-content: center;margin-top: 1em;">Actions</th>
                  <td mat-cell *matCellDef="let element;let i = index">
                    <div style="display: flex;gap: 1em;justify-content: center;margin-top: 1em;">
                        <mat-icon (click)="editTemplateName(element, i)" *ngIf="!rowEdited[i] && element.templateName != 'default'" matTooltip="Edit"> mode_edit </mat-icon> &nbsp;
                        <mat-icon (click)="getSurveyData(element)" *ngIf="!rowEdited[i] && element.templateName != 'default'" matTooltip="Edit"> delete </mat-icon>
                        <mat-icon (click)="save(element, i)" matTooltip="Save" *ngIf="rowEdited[i]"> save </mat-icon>
                        <mat-icon *ngIf="rowEdited[i]" matTooltip="Cancel" (click)="onCancelFullCost(element, i, $event)"> cancel </mat-icon>
                    </div>
                </td>
                </ng-container>

            
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                ></tr>
              </table>
              <br>
            <mat-paginator (page)="getPageEvent($event)"
                [length]="totalLength"
                [pageIndex]="(clientsPagination.page || 1) - 1"
                [pageSize]="clientsPagination.perPage"
                [pageSizeOptions]="clientsPagination.paginationSizes"
                showFirstLastButtons
                aria-label="Select page">
            </mat-paginator>
        </section>
        <br><br>
    </div>

</div>
