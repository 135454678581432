<mat-toolbar class="lg">
    <div class="navbar-logo-div">
        <a class="navbar-logo-link lg-space">
            <img width="60px" height="24px" src="../../assets/icons/logo.svg" alt="pwc">
        </a>
    </div></mat-toolbar>

<br>
<div class="survey-land">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected">
        <mat-tab>
            <mat-card class="right">
                <span class="surveyhead flex">Team Leader Questions</span>
                <div *ngIf="surveyDetailId">
                    <p class="para" style="text-align: center;" >Welcome Team Leader, please answer the following questions.</p>
                </div>
                <br>
                <section class="butns">
                    <button class="active-solid-med " style="width: 33rem;" *ngIf="!surveyDetailId"  (click)="selected = 1">
                        Preview Interview Questions
                    </button>
                    <button class="active-solid-med " style="width: 33rem;" *ngIf="surveyDetailId"  (click)="selected = 1">
                        Begin Interview
                    </button>
                </section>
            </mat-card>
        </mat-tab>
        <mat-tab label="">
            <!-- <mat-card>
                <mat-card-content> -->
            <section class="card2">
                <mat-card>
                    <div class="row center sticky">
                        <div class="col">
                            <span class="surveyhead flex">Team Leader Questions</span>
                        </div>
                        <!-- <div class="col-sm-9 center descStars" >
                                        <div  style="margin: 0 0.3em; width:8em;">
                                            <i class="fa fa-star " style="color: rgb(21, 8, 216);" ></i>
                                            <span  color="warn" > {{'star'}} </span>
                                        </div>
                                      </div> -->
                    </div>
                </mat-card>
                <br>
                <mat-card>
                    <div class="row">
                        <div class="right" style="height: 10vh;">
                            <div class="col-sm-11 center descStars">
                                <div class="desc">
                                    <div class="">
                                        <section class='alignDescStars'>
                                            <div class="star-container">
                                                <div class="star-text">Strongly Disagree</div>
                                                <div class="star-text"> Disagree</div>
                                                <div class="star-text">Neither</div>
                                                <div class="star-text">Agree</div>
                                                <div class="star-text">Strongly Agree</div>
                
                                            </div>
                                            <div class="star-container">
                                                <i *ngFor="let item of timeline" class="fa fa-star str star"></i>
                                            </div>
                
                                        </section>
                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <br>
                <mat-card>
                    <div *ngFor="let item of managerInterviews; let i = index;">
                        <div class="m-card">
                            <p class="question">{{i+1}} . {{item?.question}}</p>
                            <!-- This is to display the selected rating value -->
                            <!-- <h3  class="starSelected"> <b> {{item?.answer}}* </b> </h3> -->
                        </div>
                        <div class="">
                            <div class="">
                                <ul class="list-inline rating-list " [ngClass]="preview ? 'rating-disabled' : ''"
                                    *ngFor="let star of stars" style="display: inline-block">
                                    <li (click)="countStar(item,star)" [ngClass]="{'selected': (star <= item?.answer)}">
                                        <i class="fa fa-star "></i>
                                    </li>
                                    <br>
                                </ul>
                                <br>
                                <mat-form-field appearance="outline" style="width:  95%;margin-left: 0.65em;"
                                    [ngClass]="preview ? 'rating-disabled' : ''">
                                    <mat-label>Comments</mat-label>
                                    <textarea matInput (keyup)="getInput($event,item)" [(ngModel)]="item.comments"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <br>
                <div class="buttons">
                    <button class="active-solid-lrg but-style" *ngIf="!surveyDetailId"  (click)="back()">Back</button>
                    <button class="active-solid-lrg but-style" *ngIf="!surveyDetailId" (click)="createTemplateMI()" >Create</button>
                </div>
                <div class="submit" *ngIf="surveyDetailId">
                    <button class="active-solid-lrg but-style"  (click)="submit()" >Submit</button>
                </div>
                <app-footer></app-footer>
            </section>
        <!-- </mat-card-content>
        </mat-card> -->
        </mat-tab>

        <mat-tab>
            <section class="" >
                <div class="mi-sticky">
                    <mat-card class="mi-right">
                        <span class="surveyhead flex">Thank you!</span>
                        <p class="para">You have completed all of the questions in this questionnaire. You can now close the survey</p>
                    </mat-card>
                    <br>
                    <div class="flex" style="gap: 2em;margin-right: 10px;">
                        <button [routerLink]=" '/project/'+ ClientId + '/manager-interview/responses' "
                         class="active-solid-lrg "(click)="close()">
                            Close Tab
                        </button>
                    </div>
                </div>
            </section>
        </mat-tab>
    </mat-tab-group>
    <br>
</div>
