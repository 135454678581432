<div class="container-fluid">
    <div>
        <section class="login">
            <section class="login__cont">
                <div class="login__cont-logo">
                    <a routerLink="/">
                    <img width="65px" height="24px" src="../assets/icons/logo.svg" alt="pwc">
                    </a>
                </div>
                
                <div class="login__msg">                
                    <section class="login__cont-msg">

                        <span class="login__cont-act-msg">Better Diagnostic and Benchmarking the future.</span>              
                        <div style="margin-top: 20px;">

                            <form [formGroup]="signinForm" class="signin-frm">
                                <!-- <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Enter email</mat-label>
                                    <input matInput formControlName="email" placeholder="Email" />
                                </mat-form-field>
                        
                                <mat-form-field appearance="outline">
                                    <mat-label>Enter your password</mat-label>
                                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
                                
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field> -->
                                <button mat-button class="active-stroke-sml login__btn" type="submit" (click)="onSignIn()"> Login Using SSO </button> 
                            </form>
                        </div>
                        <!-- <mat-divider></mat-divider> --> <!-- Need to add Horizon line -->
                        <!-- <a class="login__google-btn" href="javascript:void(0)">
                            <img class="login__google-logo" src="../assets/images/google-logo.png" alt="Google Logo">
                            <span class="login__google-link"> Continue with Google </span>
                        </a> -->
                    </section>
                </div>
            </section>
            <section class="login__banner">
                <img  class="login__banner-im" src="../assets/images/login-banner.png" alt="Login Banner Image">
            </section>
        </section>
    </div>
</div>