import { F } from "@angular/cdk/keycodes";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { CustomService } from "@app/core/services/custom.service";
import { DatabaseService } from "@app/database/database.service";
import { SurveyService } from "@app/survey/survey.service";

interface DataObject {
  [key: string]: number | string;
}

@Component({
  selector: "app-op-maturity-weightage",
  templateUrl: "./op-maturity-weightage.component.html",
  styleUrls: ["./op-maturity-weightage.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpMaturityWeightageComponent implements OnInit {
  data: any;
  initial = true;

  clientId: any;

  functions: any[] = [];
  teams: any[] = [];

  teamMap = new Map();
  functionMap = new Map();

  selectedFunction: String = "All";
  selectedTeam: String = "All";

  selectedFunctions: any[] = ["All"];
  selectedTeams: any[] = ["All"];
  previousFunctions: any[] = ["All"];
  previousTeams: any[] = ["All"];

  responseTable: DataObject[] = [];
  functionTable: DataObject[] = [];
  teamTable: DataObject[] = [];

  lensSet: any = new Set();

  reponseHeaders = [
    "Team",
    "Function",
    "Number FTE",
    "AVA",
    "POI",
    "Team Lead Interviews",
    "Engagement",
    "Focus Groups",
    "Op Maturiy Status",
  ];

  functionHeaders = [
    "Function",
    "Number FTE",
    "Weighting",
    "Overall Diagnostic Score",
  ];

  teamHeaders = [
    "Team",
    "Function",
    "Number FTE",
    "Weighting",
    "Overall Diagnostic Score",
  ];

  spinner:boolean = true;

  constructor(
    private surveyService: SurveyService,
    private customService: CustomService,
    private databaseService: DatabaseService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";

    this.getSurveyStats();
  }

  changeFunction(event: any) {
    console.log("----changeFunction ------", event);
    this.selectedFunctions = [];

    if(!event.length){
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;

    this.processTableData();
  }

  changeTeam(event: any) {
    console.log("----changeTeam ------", event);
    this.selectedTeams = [];

    if(!event.length){
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.processTableData();
  }

  getSurveyStats() {
    this.surveyService.getSurveyOutputs("opMaturity", this.clientId).subscribe(
      (res: any) => {
        this.data = res;
        this.getFunctionsAndTeams();
        this.getAllLens();
        this.spinner = false;
      },
      (error: any) => {
        console.log("Error from Output API : ", error);

        let check = " RetryAfterMs";
        this.spinner = false;
        console.log("error", error);
        if (error.error.message.includes(check)) {
          this.spinner = true;
          this.cdr.markForCheck();
          setTimeout(() => {
            this.getSurveyStats();
          }, 2000);
        }
      }
    );
  }

  getFunctionsAndTeams() {
    this.functions = [];
    this.functions.push("All");
    this.teams.push("All");
    for (let d of this.data) {
      if (!this.functions.includes(d.clientFunction)) {
        this.functions.push(d.clientFunction);
      }
      this.teams.push(d.teamName);
    }
  }

  getAllLens() {
    this.databaseService.getDistinctLens().subscribe(
      (res: any) => {
        for (let r of res) {
          let key = r.category + "-" + r.subCategory + "-" + r.lens;
          this.lensSet.add(key);
        }
        this.overAllCounts();
      },
      (error) => {
        console.log("Error from Distinct Lens API : ", error);
      }
    );
  }

  overAllCounts() {
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        console.log(res);
        for (let r of res) {
          let fnObject: any;
          if (this.functionMap.has(r.functionName)) {
            fnObject = this.functionMap.get(r.functionName);
          } else {
            fnObject = {};
            fnObject.total = 0;
            fnObject.teams = [];
          }
          for (let x of r.teams) {
            let object: any = {};
            object.functionName = r.functionName;
            object.teamName = x.teamName;
            object.fte = x.fteMembers + x.fteManagers;
            fnObject.total = fnObject.total + object.fte;
            fnObject.teams.push(x.teamName);
            this.teamMap.set(object.teamName, object);
          }
          this.functionMap.set(r.functionName, fnObject);
        }
        this.processTableData();
      },
      error: (error: any) => {
        console.log("get counts error", error);
      },
    });
  }

  processTableData() {
    let teamDataMap = new Map();
    for (let teamData of this.data) {
      let processThis = false;

      if (
        (this.selectedFunctions.includes(teamData.clientFunction) ||
          this.selectedFunctions.includes("All")) &&
        (this.selectedTeams.includes(teamData.teamName) ||
          this.selectedTeams.includes("All"))
      ) {
        processThis = true;
      }

      if (processThis) {
        let object: any = {};
        object.teamName = teamData.teamName;
        object.clientFunction = teamData.clientFunction;
        let opMap = new Map();
        for (let module of teamData.modules) {
          if (module.module == "opMaturity") {
            for (let len of module.lens) {
              let key = len.category + "-" + len.subCategory + "-" + len.lens;
              opMap.set(key, len.answer);
            }
          }
        }
        for (let module of teamData.modules) {
          if (module.module == "managerInterviews") {
            object.miCount = module.lens.length > 0 ? module.lens[0].count : 0;
          } else if (module.module == "processOwners") {
            object.poiCount = module.lens.length > 0 ? module.lens[0].count : 0;
          } else if (module.module == "focusGroup") {
            object.fgCount = module.lens.length > 0 ? module.lens[0].count : 0;
          } else if (module.module == "ava-eva") {
            object.avaCount = module.lens.length > 0 ? module.lens[0].count : 0;
          }
          let overAllScore = 0;
          let overAllCount = 0;
          for (let len of module.lens) {
            let key = len.category + "-" + len.subCategory + "-" + len.lens;

            let answer = opMap.has(key)
              ? parseFloat(opMap.get(key))
              : parseFloat(len.answer);

            if (!opMap.has(key)) {
              opMap.set(key, answer);
            }

            overAllScore = overAllScore + answer / parseInt(len.count);
            overAllCount = overAllCount + 1;
          }

          object.allLens = true;
          for (let ls of this.lensSet) {
            if (!opMap.has(ls)) {
              object.allLens = false;
            }
          }
          object.average = overAllScore / overAllCount;
        }
        teamDataMap.set(teamData.teamName, object);
      }
    }

    //Process Response Functions
    let total = 0;
    this.responseTable = [];
    for (let key of teamDataMap.keys()) {
      let obj: any = {};
      let data = teamDataMap.get(key);
      let fteData = this.teamMap.get(key);
      data.fte = fteData.fte;
      total = total + data.fte;
      obj["Team"] = key;
      obj["Function"] = data.clientFunction;
      obj["Number FTE"] = data.fte;
      obj["AVA"] = data.avaCount;
      obj["POI"] = data.poiCount;
      obj["Team Lead Interviews"] = data.miCount;
      obj["Engagement"] = data.avaCount;
      obj["Focus Groups"] = data.fgCount;
      obj["Op Maturiy Status"] = data.allLens;
      this.responseTable.push(obj);
    }

    // Process Team Data
    let aggObject: any = {};
    let weighting = 0;
    let totalFte = 0;
    let avg = 0;
    this.teamTable = [];
    let loopFunctions = new Set();

    for (let key of teamDataMap.keys()) {
      let object: any = {};
      let data = teamDataMap.get(key);
      let fteData = this.teamMap.get(key);
      console.log(fteData);
      object["Team"] = key;
      object["Function"] = data.clientFunction;
      loopFunctions.add(data.clientFunction);
      object["Number FTE"] = fteData.fte;
      object["Weighting"] = ((data.fte / total) * 100).toFixed(2);
      object["Overall Diagnostic Score"] = data.average.toFixed(2);
      weighting = weighting + (data.fte / total) * 100;
      totalFte = fteData.fte + totalFte;
      avg = avg + data.average * (data.fte / total);
      console.log(object);
      this.teamTable.push(object);
    }

    aggObject["Team"] = "Total Weighted Score";
    aggObject["Function"] = "";
    aggObject["Number FTE"] = totalFte;
    aggObject["Weighting"] = weighting.toFixed(2);
    aggObject["Overall Diagnostic Score"] = avg.toFixed(2);

    this.teamTable.push(aggObject);
    this.teamTable.sort();
    console.log(this.teamTable);

    //Process Function Data
    aggObject = {};
    weighting = 0;
    totalFte = 0;
    avg = 0;
    this.functionTable = [];
    for (let key of loopFunctions) {
      let fnObject: any = {};

      let data = this.functionMap.get(key);
      console.log(data);

      let score = 0;
      let f2total = 0;
      let tot = 0;
      for (let t of data.teams) {
        let data = teamDataMap.get(t);
        if (data) {
          score = score + data.average;
          f2total = f2total + 1;
          tot = tot + data.fte;
        }
      }

      fnObject["Function"] = key;
      fnObject["Number FTE"] = tot;
      fnObject["Weighting"] = ((tot / total) * 100).toFixed(2);
      fnObject["Overall Diagnostic Score"] = (score / f2total).toFixed(2);

      weighting = weighting + (tot / total) * 100;
      totalFte = tot + totalFte;
      avg = avg + score * (tot / total);

      this.functionTable.push(fnObject);
    }

    aggObject["Function"] = "Total Weighted Score";
    aggObject["Number FTE"] = totalFte;
    aggObject["Weighting"] = weighting.toFixed(2);
    aggObject["Overall Diagnostic Score"] = avg.toFixed(2);
    this.functionTable.push(aggObject);
    this.functionTable.sort();
    this.cdr.markForCheck();
  }

  getTrafficLightColor(value: number | string) {
    if (typeof value === "string") {
      if (value == "true") {
        return "#82c96b";
      } else {
        return "red";
      }
    } else {
      if (value) {
        return "#82c96b";
      } else {
        return "red";
      }
    }
  }

  getBackground(header: string) {
    if (
      header == "AVA" ||
      header == "POI" ||
      header == "Team Lead Interviews" ||
      header == "Engagement" ||
      header == "Focus Groups"
    ) {
      return "#33FFE9";
    } else {
      return "white";
    }
  }
}
