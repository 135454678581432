import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { DatabaseService } from '@app/database/database.service';
import { Observable, map,  } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class CustomService {
    staticServiceUrl = environment.AUTH_API_URL;
    clientServiceUrl = environment.CLIENT_API_URL;
    questionList: any = [];

    constructor(
        private databaseService: DatabaseService,
        private httpClient: HttpClient
    ) { }

    checkQuestion(module: string, questions: [string], questionId: string = ""): Observable<any> {

      return this.databaseService.getRefDataByQuery(module).pipe(map(
        (response) => {

          if(questions && questions.length && response && response.length){
            if(questionId){
              
              return questions.some(question => response.find((q: any) => q.question === question && q._id != questionId));
            } else{

              return questions.some(question => response.find((q: any) => q.question === question));
            }
          }
          return false;
        }
      ))
    }

    checkPOIQuestion(questions: [string], templateDetail: any = {}, questionIndex: number = 0): Observable<any> {

      return this.databaseService.getRefDataByQuery('processOwners').pipe(map(
        (response) => {
          
          if(questions && questions.length && response && response.length){
            
            let duplicateQuestion: boolean = questions.some(question => response.find((q: any) => q.question === question));
            if(templateDetail && Object.keys(templateDetail).length !== 0){

              let opMaturityQuestions = templateDetail.questions.find((questionData: any) => questionData.subModule == "Op maturity");
              for (const question of questions) {
                
                let changeQuestion = opMaturityQuestions.values[questionIndex];
                if(question == changeQuestion['question'] ){
                  duplicateQuestion =  false;
                }
              }
            }

            return duplicateQuestion;
          }
          return false;
        }
      ))
    }

  getClientFunctions(clientId: any) {
    return this.httpClient.get<any>(`${this.clientServiceUrl}client/functions?clientId=${clientId}`);
  }
}
