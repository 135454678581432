<br>
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Set Up">
          <br>
          <mat-card>
            <mat-card-header>
              <span class="info">Survey Set Up &nbsp;
                <i mat-ripple class="fa-regular fa-pen-to-square"></i></span><br> &nbsp;
        
            </mat-card-header>
            <mat-card-content>
              <div class="container">
                <div class="row" style="display: flex;height: 15vh;">
                  <div class="col-sm-6">
                    <div style="display: flex;gap: 3em;margin-left: 3em;">
                      <div>
                        <p class="project-info">Start Date</p>
                        <p class="project-info">End Date</p>
                        <p class="project-info">Teams</p>
                      </div>
                      <div>
                        <p class="project-info" *ngIf="surveyData">:&nbsp;&nbsp;{{surveyData?.startDate | date:"dd-MM-YYYY"}}</p>
                        <p class="project-info" *ngIf="!surveyData">:&nbsp;&nbsp;{{ "N/A"}}</p>
                        <p class="project-info" *ngIf="surveyData">:&nbsp;&nbsp;{{surveyData?.endDate | date:"dd-MM-YYYY"}}</p>
                        <p class="project-info" *ngIf="!surveyData">:&nbsp;&nbsp;{{"N/A"}}</p>
                        <p class="project-info" *ngIf="surveyData">:&nbsp;&nbsp;{{surveyDetail[0]?.teamName}}</p>
                        <p class="project-info" *ngIf="!surveyData">:&nbsp;&nbsp;{{ "N/A" }}</p>
                      </div>
              
                    </div>
                  </div>
                  <div class="col-sm-6" style="margin-top: 2.5em;">
                    <div class="container" style="width: 35vw;height: 15vh;">
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <section class="main-timeline-section">
                            <div class="timeline-start"></div>
                            <div class="conference-center-line"></div>
                            <div class="conference-timeline-content">
                              <div class="timeline-article timeline-article-top">
                                <div class="content-date">
                                  <span *ngIf="surveyData">{{surveyData?.endDate | date:"dd-MM-YYYY"}}</span>
                                  <span *ngIf="!surveyData">{{'Not started'}}</span>
                                </div>
                                <div class="meta-date"></div>
              
                              </div>
                              <div class="timeline-article timeline-article-bottom">
                                <div class="content-date">
                                  <!-- <span>10-09-2017</span> -->
                                </div>
                                <div class="meta-date"></div>
              
                              </div>
                              <div class="timeline-article timeline-article-top">
                                <div class="content-date">
                                  <!-- <span>10-09-2017</span> -->
                                </div>
                                <div class="meta-date"></div>
              
                              </div>
                              <div class="timeline-article timeline-article-bottom">
                                <div class="content-date">
                                  <span *ngIf="surveyData">{{surveyData?.startDate | date:"dd-MM-YYYY"}}</span>
                                  <span *ngIf="!surveyData">{{'Not started'}}</span>
                                </div>
                                <div class="meta-date"></div>
              
                              </div>
                            </div>
                            <div class="timeline-end"></div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

              <br>
              <!-- ava filter -->
              <mat-card>
                

                <div class="row" style="padding: 0.5em;display: flex;justify-content: space-between;">
                  <mat-card-title style="margin-top: 0.8em;font-size: 18px;
                                    font-weight: 500;"> <i class="fa-regular fa-file-lines"></i>&nbsp;<i class="fa-solid fa-a"></i><i
                      class="fa-solid fa-v"></i><i class="fa-solid fa-a"></i></mat-card-title>
                  <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                    <mat-label>Build from Template</mat-label>
                    <mat-select [formControl]="searchModule"  value="{{AvaDefaultTemplate.templateName}}" (selectionChange)="onTemplateSelect($event)">
                      <mat-option *ngFor="let template of AvaTemplateList" [value]="template.templateName"> {{ template.templateName }} </mat-option>
                    </mat-select>
                  </mat-form-field>
            
                  <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                    <mat-label>Build from database</mat-label>
                    <mat-select [formControl]="searchModule">
                      <mat-option *ngFor="let module of moduleList" [value]="module.name"> {{ module.name }} </mat-option>
                    </mat-select>
                  </mat-form-field>
    
                  <!-- <mat-button-toggle-group #group="matButtonToggleGroup" (change)="toggle($event)" style="font-size: 14.5px;height: 3.15em;">
                    <mat-button-toggle value="AVA" style="width: 5vw;">
                      <i class="fa-regular fa-file-lines"></i>&nbsp;<i class="fa-solid fa-a"></i><i class="fa-solid fa-v"></i><i class="fa-solid fa-a"></i>
                    </mat-button-toggle>
                    <mat-button-toggle value="EVA" style="width: 5vw;">
                      <i class="fa-regular fa-file-lines"></i>&nbsp;<i class="fa-solid fa-e"></i><i class="fa-solid fa-v"></i><i class="fa-solid fa-a"></i>
                    </mat-button-toggle>
                  </mat-button-toggle-group> -->
                  
                  <div>
                    &nbsp;<button class="active-stroke-sml w" (click)="openDialog()">
                      Add New &nbsp; <i class="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </div>
            
              </mat-card>
              <br>
              <!-- ava table -->
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="activity">
                  <th mat-header-cell *matHeaderCellDef> Activity </th>
                  <td mat-cell *matCellDef="let element"> {{element.activity}} </td>
                </ng-container>
            
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                </ng-container>
            
                <ng-container matColumnDef="timeCategory">
                  <th mat-header-cell *matHeaderCellDef> Time Category </th>
                  <td mat-cell *matCellDef="let element"> {{element.timeCategory}} </td>
                </ng-container>
    
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon class="icon playPause" *ngIf="!isDefaultTemplate">mode_edit</mat-icon>
                    <mat-icon class="icon bulb" *ngIf="!isDefaultTemplate" (click)="onRemoveQuestion(element)">delete</mat-icon>
                  </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>



          <mat-card align="center">
            <button class="active-solid-lrg" style="text-decoration: underline;" (click)="addSurvey()">Survey Preview</button>
          </mat-card>

        
        </mat-tab>
        <mat-tab label="Responses Management">
          <br>
          <app-responses-management [surveyId]="surveyData?._id"></app-responses-management>



          <!-- <mat-card>
            <div id="dn" align="center">
              <apx-chart
              [series]="series_dn"
              [chart]="chart_dn"
              [labels]="labels"
              [responsive]="responsive_dn"
            ></apx-chart>
            </div>
          </mat-card>
          <br>
          <table mat-table [dataSource]="response" class="mat-elevation-z8">
        
            <ng-container matColumnDef="division">
              <th mat-header-cell *matHeaderCellDef> Division </th>
              <td mat-cell *matCellDef="let element"> {{'Division 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="function">
              <th mat-header-cell *matHeaderCellDef> Function </th>
              <td mat-cell *matCellDef="let element"> {{'Function 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="team">
              <th mat-header-cell *matHeaderCellDef> Team </th>
              <td mat-cell *matCellDef="let element"> {{'Team 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef> Role </th>
              <td mat-cell *matCellDef="let element"> {{'Team Member'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="responses">
              <th mat-header-cell *matHeaderCellDef> Responses </th>
              <td mat-cell *matCellDef="let element"> {{'15'}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table> -->
        


        </mat-tab>
        <mat-tab label="Outputs">
          <br>
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected1" (selectedTabChange)="tabChanged1($event)">
            <mat-tab label="Overview">
              <br>
              <!-- <mat-card style="display: flex;">
                <p>List & Chart</p>
              </mat-card> -->
              <br>
            
              <table mat-table [dataSource]="overview" class="custom">
                <ng-container matColumnDef="activity">
                  <th mat-header-cell *matHeaderCellDef> Activity</th>
                  <td mat-cell *matCellDef="let element"> {{element.activity}} </td>
                </ng-container>

                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef> Time Spent </th>
                  <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                </ng-container>

    
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef> Time Spent % </th>
                  <td mat-cell *matCellDef="let element"> {{element.spent}} </td>
                </ng-container>

                <ng-container matColumnDef="timespent">
                  <th mat-header-cell *matHeaderCellDef> Time Spent </th>
                  <td mat-cell *matCellDef="let element"> {{element.time}} </td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                  <th mat-header-cell *matHeaderCellDef> Capacity</th>
                  <td mat-cell *matCellDef="let element"> {{element.capacity}} </td>
                </ng-container>

                <ng-container matColumnDef="$$">
                  <th mat-header-cell *matHeaderCellDef> $$ </th>
                  <td mat-cell *matCellDef="let element"> {{element.$$}} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="dc"></tr>
                <tr mat-row *matRowDef="let row; columns: dc;"></tr>
              </table>
              
            </mat-tab>
            <mat-tab label="Breakdown">
              <mat-card>
                <form [formGroup]="form" (ngSubmit)="submit()" style="font-size: 14px;display: flex;justify-content: center;">
                  <!-- <mat-form-field appearance="outline">
                    <mat-select formControlName="chart" (selectionChange)="onChartChange($event)">
                      <mat-option *ngFor="let cat of type" [value]="cat.viewValue">
                        {{cat.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  &nbsp;
                  <mat-form-field appearance="outline">
                    <!-- <mat-select placeholder="Sub Category" (selectionChange)="filterChart($event.value)">
                      <mat-option value="Activity">Activity</mat-option>
                      <mat-option value="Type">Type</mat-option>
                      <mat-option value="TimeCategory">Time Category</mat-option>
                    </mat-select> -->
                    <mat-select formControlName="filter" (selectionChange)="filterChart($event.value)">
                      <mat-option *ngFor="let cat of filters" [value]="cat.Value">
                        {{cat.Value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field appearance="outline" >
                    <mat-select placeholder="Member by Manager" (selectionChange)="filterTeam($event.value)">
                      <mat-option value="all">All</mat-option>
                      <mat-option value="member">Team Member</mat-option>
                      <mat-option value="manager">Team Manager</mat-option>
                    </mat-select>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Team by Function" (selectionChange)="changexType($event.value)">
                      <mat-option value="team">Team</mat-option>
                      <mat-option value="functionName">Function</mat-option>
                    </mat-select>
                  </mat-form-field>

                <!-- Radar Chart -->
                <!-- <div>
                  <apx-chart id="chart" *ngIf="selectedOption === 'Radar Chart'" [series]="series" [chart]="chart"
                    [xaxis]="xaxis"></apx-chart>
                </div>
                <br> -->
                </form>
                <br>  <br>            
                <!-- <div> 
                  <div> <apx-chart
                    id="chart2"  
                    [series]="newChartOptions.series"
                    [chart]="newChartOptions.chart"
                    [dataLabels]="newChartOptions.dataLabels"
                    [plotOptions]="newChartOptions.plotOptions"
                    [responsive]="newChartOptions.responsive"
                    [xaxis]="newChartOptions.xaxis"
                    [legend]="newChartOptions.legend"
                    [fill]="newChartOptions.fill"
                    (click)="onChartClick($event)"
                  ></apx-chart></div>              
                </div>
                <br>

                <div >
                  <apx-chart id="barLongChart"
                    [series]="barLongChartOptions.series"
                    [chart]="barLongChartOptions.chart"
                    [dataLabels]="barLongChartOptions.dataLabels"
                    [plotOptions]="barLongChartOptions.plotOptions"
                    [xaxis]="barLongChartOptions.xaxis"
                  ></apx-chart>
                </div> -->

                <div class="row centered">
                  <div class="col-sm-6">
                    <div> <apx-chart
                      id="chart2"  
                      [series]="newChartOptions.series"
                      [chart]="newChartOptions.chart"
                      [dataLabels]="newChartOptions.dataLabels"
                      [plotOptions]="newChartOptions.plotOptions"
                      [responsive]="newChartOptions.responsive"
                      [xaxis]="newChartOptions.xaxis"
                      [legend]="newChartOptions.legend"
                      [fill]="newChartOptions.fill"
                      (click)="onChartClick($event)"
                    ></apx-chart></div>
                  </div>
                  <div class="col-sm-6">
                    <div >
                      <apx-chart id="barLongChart"
                        [series]="barLongChartOptions.series"
                        [chart]="barLongChartOptions.chart"
                        [dataLabels]="barLongChartOptions.dataLabels"
                        [plotOptions]="barLongChartOptions.plotOptions"
                        [xaxis]="barLongChartOptions.xaxis"
                      ></apx-chart>
                    </div>
                  </div> 
                </div>
                
              </mat-card>
              <br>

            </mat-tab>
          </mat-tab-group>
              
        </mat-tab>
    </mat-tab-group>