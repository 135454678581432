import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ava-eva-outputs',
  templateUrl: './ava-eva-outputs.component.html',
  styleUrls: ['./ava-eva-outputs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvaEvaOutputsComponent implements OnInit {

  clientId: string = "";
  ava:boolean;
  constructor(
    private router :Router,private cdr:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem('clientId') || "";
    this.Output_TabChange(0)
  }

  Output_TabChange(index:any){
    
    if(index == 0){
      this.ava = true
      // this.router.navigateByUrl(`project/${this.clientId}/ava-eva/avaoutput`)
    }
    if(index == 1){
      this.ava = false
      // this.router.navigateByUrl(`project/${this.clientId}/ava-eva/evaoutput`)
    }
    this.cdr.markForCheck();
  }

}
