import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CustomService } from '../services/custom.service';

@Component({
  selector: 'app-fte-counts',
  templateUrl: './fte-counts.component.html',
  styleUrls: ['./fte-counts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FteCountsComponent implements OnInit {
  clientId: any = sessionStorage.getItem("clientId") || "";
  @Input() inputData: any = ''

  functionsCount: any;
  teamsCount: any;
  managersCount: any;
  membersCount: any;

  constructor(
    private customService:CustomService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.overAllCounts();
    console.log('app-fte-counts',this.inputData);
  }

  overAllCounts(){
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        console.log("overallCounts :----->", res);
        let functionsCount:any = []
        let teamsCount:any = 0;
        let fteManagers:any = 0;
        let fteMembers:any = 0;

        res.forEach((ele:any) => {
          console.log(ele);
          functionsCount.push(ele.functionName)
          teamsCount = teamsCount + ele.teams.length

          //Managers & Members Count
          ele.teams.forEach((data:any) => {
            fteManagers = fteManagers + data.fteManagers
            fteMembers = fteMembers + data.fteMembers
          });
        });
        functionsCount = [...new Set(functionsCount)];
        console.log(functionsCount,functionsCount.length);
        console.log(teamsCount);
        console.log('Man,Mem',fteManagers,fteMembers);

        this.functionsCount = functionsCount.length;
        this.teamsCount = teamsCount;
        this.managersCount = fteManagers;
        this.membersCount = fteMembers;
        
        this.cdr.markForCheck();
       
      },
      error: (error: any) => {
        console.log("get counts error", error);
      },
    });
  }

}
