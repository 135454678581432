import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-worflow-template',
  templateUrl: './worflow-template.component.html',
  styleUrls: ['./worflow-template.component.scss']
})
export class WorflowTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
