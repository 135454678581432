<br>
<!-- <mat-spinner style="z-index: 10;margin: 0 auto;"></mat-spinner> -->
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Set Up">
          <br>
          <mat-card>
            <mat-card-header>
              <span class="info">Survey Set Up &nbsp;
                <i mat-ripple class="fa-regular fa-pen-to-square"></i></span><br> &nbsp;
        
            </mat-card-header>
            <mat-card-content style="display: flex;gap: 3em;margin-left: 3em;">
              <div>
                <p class="project-info">Start Date</p>
                <p class="project-info">End Date</p>
                <p class="project-info">Teams</p>
              </div>
              <div>
                <p class="project-info">:&nbsp;&nbsp;{{startDate}}</p>
                <p class="project-info">:&nbsp;&nbsp;{{endDate}}</p>
                <p class="project-info">:&nbsp;&nbsp;{{organisationData?.teams[0].teamName}}</p>
              </div>
        
            </mat-card-content>
          </mat-card>
          <br>
          <mat-card>
          
            <div class="row" style="padding: 0.5em;display: flex;justify-content: space-between;">
              <mat-card-title style="margin-top: 0.8em;font-size: 18px;
                            font-weight: 500;"> <i class="fa-regular fa-file-lines"></i>&nbsp;<i class="fa-solid fa-e"></i><i
                  class="fa-solid fa-v"></i><i class="fa-solid fa-a"></i></mat-card-title>
              <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                <mat-label>Build from Template</mat-label>
                <mat-select [formControl]="searchModule" value="{{evaDefaultTemplate.templateName}}" (selectionChange)="onTemplateSelect($event)">
                  <mat-option *ngFor="let template of evaTemplateList" [value]="template.templateName"> {{ template.templateName }} </mat-option>
                </mat-select>
              </mat-form-field>
        
              <mat-form-field appearance="outline" style="font-size: 12.5px;margin-left: 1em;">
                <mat-label>Build from database</mat-label>
                <mat-select [formControl]="searchModule">
                  <mat-option *ngFor="let module of moduleList" [value]="module.name"> {{ module.name }} </mat-option>
                </mat-select>
              </mat-form-field>
              <div>
                &nbsp;<button class="active-stroke-sml w" (click)="openDialog()">
                  Add New &nbsp; <i class="fa-solid fa-plus"></i>
                </button>
                <!-- &nbsp;<button class="active-stroke-sml w">
                  Remove &nbsp; <i class="fa-solid fa-trash"></i>
                </button>
                &nbsp;<button class="active-stroke-sml w">
                  Edit &nbsp; <i class="fa-solid fa-minus"></i>
                </button> -->
              </div>
            </div>
        
          </mat-card>
          <br>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef> Category </th>
              <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>
        
            <ng-container matColumnDef="sub-category">
              <th mat-header-cell *matHeaderCellDef> Sub Category </th>
              <td mat-cell *matCellDef="let element"> {{element.subCategory}} </td>
            </ng-container>
        
            <ng-container matColumnDef="lens">
              <th mat-header-cell *matHeaderCellDef> Lens </th>
              <td mat-cell *matCellDef="let element"> {{element.lens}} </td>
            </ng-container>
        
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef> Role </th>
              <td mat-cell *matCellDef="let element"> {{'Manager'}} </td>
            </ng-container>
        
            <!-- <ng-container matColumnDef="function">
              <th mat-header-cell *matHeaderCellDef> Function </th>
              <td mat-cell *matCellDef="let element"> {{''}} </td>
            </ng-container> -->
        
            <!-- <ng-container matColumnDef="team">
              <th mat-header-cell *matHeaderCellDef> Team </th>
              <td mat-cell *matCellDef="let element"> {{''}} </td>
            </ng-container> -->
        
            <ng-container matColumnDef="subteam">
              <th mat-header-cell *matHeaderCellDef> Sub-team </th>
              <td mat-cell *matCellDef="let element"> {{'Team 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="ID">
              <th mat-header-cell *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.questionId}} </td>
            </ng-container>
        
            <ng-container matColumnDef="score">
              <th mat-header-cell *matHeaderCellDef> MA Score </th>
              <td mat-cell *matCellDef="let element"> {{'4'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="question">
              <th mat-header-cell *matHeaderCellDef> Question </th>
              <td mat-cell *matCellDef="let element"> {{ element.question }} </td>
            </ng-container>
        
            <!-- <ng-container matColumnDef="freetext">
              <th mat-header-cell *matHeaderCellDef> Freetext </th>
              <td mat-cell *matCellDef="let element"> {{''}} </td>
            </ng-container> -->

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element">
                <mat-icon class="icon playPause" *ngIf="!isDefaultTemplate">mode_edit</mat-icon>
                <mat-icon class="icon bulb" *ngIf="!isDefaultTemplate" (click)="onRemoveQuestion(element)">delete</mat-icon>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <br>
          <mat-card align="center">
            <!-- <a style="text-decoration: underline;">Survey Link & Preview</a> &nbsp;&nbsp; -->
            <button class="active-solid-lrg" style="text-decoration: underline;" (click)="addSurvey()">Survey Preview</button>
          </mat-card>
        
        </mat-tab>
        <mat-tab label="Responses Management">
          <br>
          <mat-card>
            <div id="dn" align="center">
              <!-- <mat-card><p>Timeline</p></mat-card> -->
              <apx-chart
              [series]="series_dn"
              [chart]="chart_dn"
              [labels]="labels"
              [responsive]="responsive_dn"
            ></apx-chart>
            </div>
          </mat-card>
          <br>
          <table mat-table [dataSource]="response" class="mat-elevation-z8">
        
            <ng-container matColumnDef="division">
              <th mat-header-cell *matHeaderCellDef> Division </th>
              <td mat-cell *matCellDef="let element"> {{'Division 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="function">
              <th mat-header-cell *matHeaderCellDef> Function </th>
              <td mat-cell *matCellDef="let element"> {{'Function 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="team">
              <th mat-header-cell *matHeaderCellDef> Team </th>
              <td mat-cell *matCellDef="let element"> {{'Team 1'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef> Role </th>
              <td mat-cell *matCellDef="let element"> {{'Team Member'}} </td>
            </ng-container>
        
            <ng-container matColumnDef="responses">
              <th mat-header-cell *matHeaderCellDef> Responses </th>
              <td mat-cell *matCellDef="let element"> {{'15'}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
          </table>
        
        </mat-tab>
        <mat-tab label="Outputs">
          <br>
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="selected1" (selectedTabChange)="tabChanged1($event)">
            <mat-tab label="Overview">
              <br>
              <mat-card style="display: flex;">
                <p>List & Chart</p>
              </mat-card>
              <br>
            
              <table mat-table [dataSource]="dataSource2" class="custom">

                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef> No. </th>
                  <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
 
                <ng-container matColumnDef="weight">
                  <th mat-header-cell *matHeaderCellDef> Weight </th>
                  <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>
    
                <ng-container matColumnDef="time">
                  <th mat-header-cell *matHeaderCellDef> Time Spent % </th>
                  <td mat-cell *matCellDef="let element"> {{element.time}} </td>
                </ng-container>

                <ng-container matColumnDef="timespent">
                  <th mat-header-cell *matHeaderCellDef> Time Spent </th>
                  <td mat-cell *matCellDef="let element"> {{element.timespent}} </td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                  <th mat-header-cell *matHeaderCellDef> Capacity</th>
                  <td mat-cell *matCellDef="let element"> {{element.capacity}} </td>
                </ng-container>

                <ng-container matColumnDef="$$">
                  <th mat-header-cell *matHeaderCellDef> $$ </th>
                  <td mat-cell *matCellDef="let element"> {{element.$$}} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="dc"></tr>
                <tr mat-row *matRowDef="let row; columns: dc;"></tr>
              </table>
              
            </mat-tab>
            <mat-tab label="Breakdown">
              <mat-card>
                <form [formGroup]="form" (ngSubmit)="submit()" style="font-size: 14px;display: flex;justify-content: center;">
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="chart" (selectionChange)="onChartChange($event)">
                      <mat-option *ngFor="let cat of type" [value]="cat.viewValue">
                        {{cat.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Category">
                      <!-- <mat-option>None</mat-option> -->
                      <mat-option value="bar">Team</mat-option>
                      <mat-option value="radar">Function Name</mat-option>
                    </mat-select>
                  </mat-form-field>
                  &nbsp;
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Sub Category">
                      <!-- <mat-option>None</mat-option> -->
                      <mat-option value="bar">Activity</mat-option>
                      <mat-option value="radar">Type</mat-option>
                      <mat-option value="pie">Time Category</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
                <br>
                <div id="chart" *ngIf="selectedOption === 'Bar Chart'">
                  <!-- <apx-chart
                                  [series]="series"
                                  [chart]="chart"
                                  [dataLabels]="dataLabels"
                                  [plotOptions]="plotOptions"
                                  [responsive]="responsive"
                                  [xaxis]="xaxis"
                                  [legend]="legend"
                                  [fill]="fill"
                                ></apx-chart> -->
                
                  <apx-chart [series]="series_bar" [chart]="chart_bar" [xaxis]="xaxis_bar" [title]="title_bar"></apx-chart>
                </div>
                <br>
                <div>
                  <!-- Radar Chart -->
                  <apx-chart id="chart" *ngIf="selectedOption === 'Radar Chart'"
                   [series]="series" [chart]="chart" [xaxis]="xaxis" [title]="title"></apx-chart>
                </div>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
    