import { Component, OnInit } from '@angular/core';
import { HomeService } from '@app/home/home/home.service';

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.scss']
})
export class MasterdataComponent implements OnInit {

  constructor(
    private homeService : HomeService
  ) { }

  ngOnInit(): void {
    sessionStorage.setItem('CLIENTID','empty')
    
  }

}
