<br>
<!-- <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tab($event)">
    <mat-tab label="Detailed Breakdown"> -->


        <section class="newDesign">
          <div class="row space filterr">
            <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
              <!-- <mat-form-field appearance="outline">
                <mat-label>Function</mat-label>
                <mat-select placeholder="Team by Function" (selectionChange)="changeFunction($event.value)">
                  <mat-option *ngFor="let function of functions" [value]="function">
                    {{function}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          
              <mat-form-field appearance="outline">
                <mat-label>Team</mat-label>
                <mat-select placeholder="Team by Function" (selectionChange)="changeTeam($event.value)">
                  <mat-option *ngFor="let team of teams" [value]="team">
                    {{team}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <div class="row space filterr">
                <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
                  <mat-form-field appearance="outline">
                    <mat-label>Function</mat-label>
                    <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
                      (selectionChange)="changeFunction($event.value)">
                      <mat-option *ngFor="let function of functions" [value]="function">
                        {{function}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              
                  <mat-form-field appearance="outline">
                    <mat-label>Team</mat-label>
                    <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
                      <mat-option *ngFor="let team of teams" [value]="team">
                        {{team}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-6" style="justify-content: end;display: inline-flex;"> -->
              <!-- <button class="active-solid-lrg ex">Export data</button> -->
              <!-- <div style="margin-top: 0.65em;" *ngIf="clearAll">
                          <button matTooltip="Clear All Filters" (click)="clearFilters()" class="active-solid-med size">
                            <mat-icon>clear</mat-icon></button>
                  </div> -->
            <!-- </div> -->
          </div>

          <div class="row">
            <div class="col-sm-2">
              <mat-card class="fg-cards align" style="height: 42.2vh;">
                <span class="head">Average Engagement score</span>
                <span class="avg-chart-content" style="padding-left: 6.1em !important;font-size: 20px !important;">{{SinglechartOptions.name}}</span>
                <div id="single">
                  <apx-chart
                    [series]="SinglechartOptions.series"
                    [chart]="SinglechartOptions.chart"
                    [plotOptions]="SinglechartOptions.plotOptions"
                    [colors]="SinglechartOptions.colors"
                    [responsive]="SinglechartOptions.responsive"
                  ></apx-chart>
                </div>
              </mat-card>
            </div>
            <div class="col-sm-9">
              <mat-card class="fg-cards" style="display: none;">
                <div class="row space">
                  <div class="col-sm-12 titleCard" style="display: flex">
                    <div class="col-sm-3">
                      <span class="head">Functions</span>
                    </div>
                    <div class="col-sm-3">
                      <span class="head">Teams</span>
                    </div>
                    <div class="col-sm-3">
                      <span class="head">Managers</span>
                    </div>
                    <div class="col-sm-3">
                      <span class="head">Members</span>
                    </div>
                  </div>
                </div>
          
                <div class="row space">
                  <mat-card class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
                    <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{functionsCount}}</span>
                    </mat-card>
                    <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{teamsCount}}</span>
                    </mat-card>
                    <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{managerCount}}</span>
                    </mat-card>
                    <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{memberCount}}</span>
                    </mat-card>
                  </mat-card>
                </div>
              </mat-card>
              <app-fte-counts></app-fte-counts>
              <br>
              <!-- <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
                <mat-card class="col-sm-2 fg-cards side align" *ngFor="let chartData of chartDataArray">
                  <span class="head">{{chartData.name}}</span>
                  <apx-chart
                  [series]="chartData.series"
                  [chart]="chartData.chart"
                  [plotOptions]="chartData.plotOptions"
                  [colors]="chartData.colors"
                  [responsive]="chartData.responsive"
                ></apx-chart>
                </mat-card>
              </div> -->
              <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
                <mat-card class="col-sm-2 fg-cards side align" *ngFor="let chartData of chartDataArray">
                  <span class="head ellipsis">{{chartData.name}}</span>
                  <br>
                  <app-circle-chart [chartText]="'N/A'"
                  [chartValue]="100" *ngIf="chartData.series == '0'" class="n-a"></app-circle-chart>
                  <app-circle-chart [chartText]="chartData.series"
                  [chartValue]="(chartData.series / this.possiblePoints) * 100" *ngIf="chartData.series != '0'"></app-circle-chart>
                </mat-card>
              </div>
              <!-- <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
                <mat-card class="col-sm-2 fg-cards side align" *ngFor="let cat of overallCategories;let i=index">
                  <span class="head ellipsis">{{cat.name}}</span>
                  <br>
                  <app-circle-chart [chartText]="cat.value"
                  [chartValue]="(cat.value / this.possiblePoints) * 100"></app-circle-chart>
                </mat-card>
              </div> -->
            </div>
          </div>
          <br><br>
        
          <div class="row f">
            <div class="col-sm-4">
              <div class="switch">
                <span class="head">Team Overview</span>
                <div class="row f" style="justify-content: end;">
                  <span>Team&nbsp;</span>
              <div class="toggle-container2">

                <div class="toggle-button" [style.background-color]="isFunction ? 'white' : 'black' " (click)="onFunctionChange('team')"></div>
                <div class="toggle-button" [style.background-color]="isFunction ? 'black' : 'white' " (click)="onFunctionChange('function')"></div>

              </div>
              <span>&nbsp;Function</span> 
                </div>
              </div>
              <br>
              <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">
          
                <ng-container matColumnDef="team">
                  <th mat-header-cell *matHeaderCellDef> 
                  <span *ngIf="isFunction == false">Team</span>  
                  <span *ngIf="isFunction">Function</span> 
                  </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
          
                <ng-container matColumnDef="sd">
                  <th mat-header-cell *matHeaderCellDef> 1 </th>
                  <td mat-cell *matCellDef="let element"> 
                  {{element.one }}
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="d">
                  <th mat-header-cell *matHeaderCellDef> 2 </th>
                  <td mat-cell *matCellDef="let element"> 
                  {{element.two}}
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="n">
                  <th mat-header-cell *matHeaderCellDef> 3 </th>
                  <td mat-cell *matCellDef="let element"> 
                   {{element.three}} 
                  </td>
                </ng-container>
          
                <ng-container matColumnDef="agree">
                    <th mat-header-cell *matHeaderCellDef> 4 </th>
                    <td mat-cell *matCellDef="let element"> 
                      {{element.four}} 
                    </td>
                  </ng-container>
                
                  <ng-container matColumnDef="sa">
                    <th mat-header-cell *matHeaderCellDef> 5 </th>
                    <td mat-cell *matCellDef="let element"> 
                       {{element.five}}
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="average">
                    <th mat-header-cell *matHeaderCellDef> Average </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="avg" [ngClass]="setColor+element.avg.toFixed()">{{element.avg.toFixed(1)}}</span> 
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="ds2"></tr>
                <tr mat-row *matRowDef="let row; columns: ds2;"></tr>
              </table>
            </div>
            <div class="col-sm-8">
             <!-- <div id="response">
              <apx-chart [series]="Response.series" [chart]="Response.chart" [dataLabels]="Response.dataLabels" [yaxis]="Response.yaxis"
                [plotOptions]="Response.plotOptions" [stroke]="Response.stroke" [fill]="Response.fill" [xaxis]="Response.xaxis"></apx-chart>
             </div> -->
             <div class="switch">

              <span class="head">Engagement Responses</span>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <span>Team Member &nbsp;</span>
              <div class="toggle-container">

                <div class="toggle-button" [style.background-color]="getDivColour('member')" (click)="handleMemberClick('member')"></div>
                <div class="toggle-button" [style.background-color]="getDivColour('all')" (click)="handleMemberClick('all')"></div>
                <div class="toggle-button" [style.background-color]="getDivColour('manager')" (click)="handleMemberClick('manager')"></div>

              </div>
              <span>&nbsp;Team Manager</span> 

            </div>
            <br>
            <div class="table-container">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef> Questions </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
  
                <ng-container matColumnDef="sd">
                  <th mat-header-cell *matHeaderCellDef> Results </th>
                  <td mat-cell *matCellDef="let element;let i = index;"> 
                    <div id="chart">
                      <apx-chart
                        [series]="element.chart.series"
                        [chart]="element.chart.chart"
                        [plotOptions]="element.chart.plotOptions"
                        [xaxis]="element.chart.xaxis"
                        [stroke]="element.chart.stroke"
                        [colors]="element.chart.colors"
                        [fill]="element.chart.fill"
                        [tooltip]="element.chart.tooltip"
                        [legend]="element.chart.legend"
                      ></apx-chart>
                    </div>
                  </td>
                </ng-container>
              
               <ng-container matColumnDef="d">
                  <th mat-header-cell *matHeaderCellDef> Disagree </th>
                  <td mat-cell *matCellDef="let element"> 
                    <span class="d-style">{{element.two.toFixed()}}% </span>  
                  </td>
                </ng-container>
              
                <ng-container matColumnDef="n">
                  <th mat-header-cell *matHeaderCellDef> Neutral </th>
                  <td mat-cell *matCellDef="let element"> 
                    <span class="n-style">{{element.three.toFixed()}}%  </span>   
                  </td>
                </ng-container>
          
                <ng-container matColumnDef="agree">
                    <th mat-header-cell *matHeaderCellDef> Agree </th>
                    <td mat-cell *matCellDef="let element"> 
                      <span class="agree-style"> {{element.four.toFixed()}}%  </span>  
                    </td>
                  </ng-container>
                
                  <ng-container matColumnDef="sa">
                    <th mat-header-cell *matHeaderCellDef> Strongly Agree </th>
                    <td mat-cell *matCellDef="let element"> 
                      <span class="s-agree"> {{element.five.toFixed()}}% </span>  
                    </td>
                  </ng-container>
          
                  <ng-container matColumnDef="average">
                    <th mat-header-cell *matHeaderCellDef> Average </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="average-style"> {{element.avg.toFixed(1)}} </span></td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTable2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTable2;"></tr>
              </table>
            </div>
          </div>
          </div>
        </section>
        <br>
        <div class="row space">
          <div class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
            <div class="keys">
              <span class="tle">{{"Key:"}}</span>
            </div>
            <mat-card mat-ripple class="keys mat-elevation-z0" (click)="getType('all')">
              <span class="key-text">{{"All"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys sd mat-elevation-z0" (click)="getType('one')">
              <span class="key-text">{{"Strongly Disagree"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys d mat-elevation-z0" (click)="getType('two')">
              <span class="key-text">{{"Disagree"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys n mat-elevation-z0" (click)="getType('three')">
              <span class="key-text">{{"Neither"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys agree mat-elevation-z0" (click)="getType('four')">
              <span class="key-text">{{"Agree"}}</span>
            </mat-card>
            <mat-card mat-ripple class="keys strong mat-elevation-z0" (click)="getType('five')">
              <span class="key-text">{{"Strongly Agree"}}</span>
            </mat-card>
          </div>
        </div>
    <!-- </mat-tab>
</mat-tab-group>  -->



