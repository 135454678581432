import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DatabaseComponent } from "./database.component";
import { DbAvaComponent } from "./db-ava/db-ava.component";
import { DbEvaComponent } from "./db-eva/db-eva.component";
import { DbManagerInterviewComponent } from "./db-manager-interview/db-manager-interview.component";
import { DbFocusGroupComponent } from "./db-focus-group/db-focus-group.component";
import { DbPoiComponent } from "./db-poi/db-poi.component";

const routes: Routes = [
  {
    path: "",
    component: DatabaseComponent,
    children: [
      //{ path: 'database', component: DatabaseComponent, pathMatch: 'full' },
      { path: "ava", component: DbAvaComponent },
      { path: "eva", component: DbEvaComponent },
      { path: "manager-interview", component: DbManagerInterviewComponent },
      { path: "focus-group", component: DbFocusGroupComponent },
      { path: "process-interview", component: DbPoiComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DatabaseRoutingModule {}
