<br>
<div class="content">
    <section class="arr-buttons">
        <!-- <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
        <a class="next round"><i class="fa-solid fa-chevron-right"></i></a>  -->
        <i class="fa-sharp fa-solid fa-sheet-plastic previous round" style="font-size: 25px;"></i>
        &nbsp;
        <span class="title">&nbsp; Project {{clientData?.clientName}}</span>
    </section>
    <br>
    <section class="projectab">
        <div class="">
              <mat-tab-group mat-align-tabs="center">
                <mat-tab label="Overview">
                    <mat-card style="margin-top: 10px;" *ngIf="!edit">
                        <mat-card-content style="height: 15vh;margin-left: 2em;" >
                            <span class="info">Project Information</span><br> &nbsp;
                            <form>
                                <!-- <div class="col-md-10"> -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>Client</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                    &nbsp;
                                    
                                <!-- </div> -->
                                <!-- <div class="col-md-10"> -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>FTE</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                    &nbsp;
                                <!-- </div> -->
                                <!-- <div class="col-md-10"> -->
                                    <mat-form-field appearance="outline">
                                        <mat-label>Teams</mat-label>
                                        <input matInput>
                                    </mat-form-field>
                                    &nbsp;
                                <!-- </div> -->
                            </form>
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card *ngIf="edit">
                            <!-- <div align="end">
                                <button mat-mini-fab style="background-color: wheat;">
                                    <i class="fa-regular fa-pen-to-square"></i>
                                </button>
                            </div> -->
                        <mat-card-header>
                            <span class="info">Project Information &nbsp;
                                <i mat-ripple class="fa-regular fa-pen-to-square"></i></span><br> &nbsp;&nbsp;
                           
                        </mat-card-header>
                        <mat-card-content style="display: flex;gap: 3em;margin-left: 3em;">
                            <div>
                                <p class="project-info">Client</p>
                                <p class="project-info">FTE</p>
                                <p class="project-info">Teams</p>
                            </div>
                            <div>
                                <p class="project-info">:&nbsp;&nbsp;{{clientData?.clientName}}</p>
                                <p class="project-info">:&nbsp;&nbsp;{{totalFTE}}</p>
                                <p class="project-info">:&nbsp;&nbsp;{{organisationData?.teams[0].teamName}}</p>
                            </div>
 
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-card>
                        <table mat-table [dataSource]="dataSource">
                    
                            <ng-container matColumnDef="Module">
                                <th mat-header-cell *matHeaderCellDef >Module</th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="active-stroke-lrg module" ngClass={{element.color}}>
                                        {{element.name}}</button>
                                    <br> &nbsp;
                                </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="Workflow">
                                <th mat-header-cell *matHeaderCellDef> Workflow Status </th>
                                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="Owner">
                                <th mat-header-cell *matHeaderCellDef> Owner </th>
                                <td mat-cell *matCellDef="let element"> {{'Alex'}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="Deadline">
                                <th mat-header-cell *matHeaderCellDef> Deadline </th>
                                <td mat-cell *matCellDef="let element"> {{'18/05/2023'}} </td>
                            </ng-container>
                    
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <br>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Operational Maturity">
                    <p></p>
                </mat-tab>
                <mat-tab label="AVA/EVA">
                    <div class="float" mat-ripple (click)="toggle()">
                        <i class="fa-solid fa-left-right" style="margin-top: 12px;font-size: 1.5em;"></i>
                    </div>
                    <!-- <div class="fixedContainer">
                        This is experimental
                    </div> -->
                    <app-ava-eva *ngIf='toggleTo === false'></app-ava-eva>
                    <app-eva *ngIf='toggleTo === true'></app-eva>
                </mat-tab>
                <!-- <mat-tab label="EVA">
                    <app-eva></app-eva>
                </mat-tab> -->
                <mat-tab label="Manager Interviews">
                    <p>14</p>
                </mat-tab>
                <mat-tab label="Process Interviews">
                    <p>15</p>
                </mat-tab>
                <mat-tab label="Focus Groups">
                    <p>16</p>
                </mat-tab>
            </mat-tab-group>
        </div>
    </section>
</div>
