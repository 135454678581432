import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeService } from '@app/home/home/home.service';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { ProjectService } from '@app/projects/project.service';

const ELEMENT_DATA: any[] = [
  {position: 1, name: 'Operational Maturity', weight: 1.0079, symbol: 'H',color:'green',status:'complete'},
  {position: 2, name: 'Manager Interviews', weight: 4.0026, symbol: 'He',color:'',status:'Workflow status'},
  {position: 3, name: 'AVA', weight: 6.941, symbol: 'Li',color:'red',status:'overdue'},
  {position: 4, name: 'EVA', weight: 9.0122, symbol: 'Be',color:'',status:'Workflow status'},
  {position: 5, name: 'Process Owner Interviews', weight: 10.811, symbol: 'B',color:'yellow',status:'Workflow status'},
];

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  spinner: boolean = false;
  edit:boolean = true;
  displayedColumns: string[] = ['Module', 'Deadline','Owner','Workflow','Action'];
  displayedColumns2: string[] = ['Client', 'Details','Sector','Start Date','End Date','Action'];
  dataSource = ELEMENT_DATA;
  details:any;

  clientId: any;

  clientData:any;
  organisationData:any;
  totalFTE: number = 0;
  clientModulesData:any;

  modulesList:any = {
    "MANAGER INTERVIEWS": false,
    "PROCESS OWNER INTERVIEWS": false ,
    "FOCUS GROUPS": false ,
    "AVA & ENGAGEMENT SURVEYS": false ,
    "POWER BI":false
}

  constructor(
    private projectService : ProjectService,
    private onboardingService:OnboardingService,
    private route: ActivatedRoute,
    private sb: MatSnackBar,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id")
    ? this.route.snapshot.paramMap.get("id")
    : sessionStorage.getItem("clientId");

    this.getClientById();
    this.getOrganisationByClientId();
    this.getModulesByClientId();
  }

  getClientById(){
    this.spinner = true;
    this.projectService.getClients(this.clientId).subscribe(res=>{
      this.clientData = res[0];
      this.details = res;
      
    },
    (error) => {
      console.log(error);
      this.spinner = false;
    }
    )
  }

  getOrganisationByClientId(){
    this.projectService.getOrganisationByClientId(this.clientId).subscribe(organisationData=>{

      /* TODO: Display the organisation multiple team name and FTE count */
      this.organisationData = organisationData;
      this.organisationData.map((data:any)=>{
        data.teams.map((team:any)=>{
          this.totalFTE += team.fteManagers + team.fteMembers
        })
      })
    })
  }

  getModulesByClientId(){
    this.projectService.getModulesByClientId(this.clientId).subscribe((res) => {
      res.map((modules:any)=>{

        if(modules.moduleId === "Op Maturity"){
          modules.setupLink = `/project/${this.clientId}/op-maturity/outputs`;
          modules.outputLink = `/project/${this.clientId}/op-maturity/outputs`;
        }

        if(modules.moduleId === "AVA & ENGAGEMENT SURVEYS"){
          modules.setupLink = `/project/${this.clientId}/ava-eva/setup`;
          modules.outputLink = `/project/${this.clientId}/ava-eva/outputs`;
        }

        if(modules.moduleId === "MANAGER INTERVIEWS"){
          modules.setupLink = `/project/${this.clientId}/manager-interview/setup2`;
          modules.outputLink = `/project/${this.clientId}/manager-interview/outputs`;
        }

        if(modules.moduleId === "FOCUS GROUPS" ){
          modules.setupLink = `/project/${this.clientId}/focus-groups/setup`;
          modules.outputLink = `/project/${this.clientId}/focus-groups/outputs`;
        }
        
        if(modules.moduleId === "PROCESS OWNER INTERVIEWS"){
          modules.setupLink = `/project/${this.clientId}/process-interviews/setup`;
          modules.outputLink = `/project/${this.clientId}/process-interviews/process-outputs`;
        }

        if(modules.moduleId === "Data Diagnostic"){
          modules.setupLink = `/project/${this.clientId}/power-bi`;
          modules.outputLink = `/project/${this.clientId}/power-bi`;
        }
      });
      this.clientModulesData = res;
      console.log(this.clientModulesData);
      this.spinner = false;
      
    },
    (error) => {
      console.log(error);
      this.spinner = false;
    }
    );
  }

  editProjectInfo(){
    this.router.navigate(["onboard/edit"], {
      queryParams:
        {
          id: this.clientId,
        },
    });  
  }

  viewProjectInfo(){
    this.router.navigate(["onboard/view"], {
      queryParams:
        {
          id: this.clientId,
        },
    });  
  }

  goTo(data: any) {
    if (data === 'Team Leader Interviews') this.router.navigateByUrl("/project/" + this.clientId + "/manager-interview/setup")
    if (data === 'Process Owner Interviews') this.router.navigateByUrl("/project/" + this.clientId + "/process-interviews/setup")
    if (data === 'Surveys') this.router.navigateByUrl("/project/" + this.clientId + "/ava-eva/setup")
    if (data === 'Focus Groups') this.router.navigateByUrl("/project/" + this.clientId + "/focus-groups/setup")
    if (data === 'Operational Maturity') this.router.navigateByUrl("/project/" + this.clientId + "/op-maturity/scoring")
    if (data === 'Data Diagnostic') this.router.navigateByUrl("/project/" + this.clientId + "/power-bi")
  }
}
