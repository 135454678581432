import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import * as d3 from "d3";

@Component({
  selector: "app-rating-timeline",
  templateUrl: "./rating-timeline.component.html",
  styleUrls: ["./rating-timeline.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingTimeline implements OnInit {
  data: number[] = [1, 2, 3, 3, 4];

  constructor() {}

  ngOnInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const chartElement = document.getElementById("chart");

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 500;
    canvas.height = 300;

    chartElement?.appendChild(canvas);

    const xScale = canvas.width / (this.data.length - 1);
    const yScale = canvas.height / (Math.max(...this.data) - 1);

    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(0, canvas.height - this.data[0] * yScale);

      this.data.forEach((value, index) => {
        ctx.lineTo(index * xScale, canvas.height - value * yScale);
      });

      ctx.strokeStyle = "steelblue";
      ctx.lineWidth = 2;
      ctx.stroke();

      this.data.forEach((value, index) => {
        ctx.beginPath();
        ctx.arc(
          index * xScale,
          canvas.height - value * yScale,
          5,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = "steelblue";
        ctx.fill();

        ctx.font = "12px Arial";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(
          value.toString(),
          index * xScale,
          canvas.height - value * yScale - 10
        );
      });
    }
  }
}
