<br>
<div *ngIf="spinner" class="overlay" >
    <mat-spinner class="spinner"></mat-spinner>
</div>
<div class="contents">

    <section class="arr-buttons">
        <!-- <a class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
        <a class="next round"><i class="fa-solid fa-chevron-right"></i></a>  -->
        <i class="fa-sharp fa-solid fa-house previous round"></i>
        &nbsp;
        <span class="title">Home</span>
    </section>

    <br>
    <span class="heading">Active Clients</span> 
    <!-- it's temporary code in future wll remove once design finalized -->
    <div class="projects" *ngIf="false">
        <drag-scroll class="project-card" (dsInitialized)="onDragScrollInitialized()" (indexChanged)="onIndexChanged($event)"
            (reachesLeftBound)="leftBoundStat($event)" (reachesRightBound)="rightBoundStat($event)"
            [drag-scroll-y-disabled]="yDisabled"
            (snapAnimationFinished)="onSnapAnimationFinished()" #nav>
            
                <mat-card drag-scroll-item class="example-card" *ngFor="let data of clients">
                    <mat-card-title>
                        <!-- <img class="small-logo" src="../../assets/icons/std.jpg" alt="logo"> -->
                        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-left: 12.5em;
                        min-width: 0;
                        width: 0;
                        height: 0;
                        line-height: normal;
                        padding: 0px;">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item>
                                <span>View</span>
                            </button>
                            <button mat-menu-item>
                                <span>Edit</span>
                            </button>
                            <button mat-menu-item>
                                <span routerLink="/template">Create Template</span>
                            </button>
                        </mat-menu> -->
                    </mat-card-title>
                    <mat-card-content>
                        <mat-grid-list cols="2" rowHeight="2:1">
                            <mat-grid-tile>
                                <p class="card-title-sml mb">Client Name</p>
                                <p class="card-title-content mt">{{data.clientName}}</p>
                            </mat-grid-tile>
                            <mat-grid-tile>
                                <p class="card-title-sml mb">Diagnostic Name</p>
                                <p class="card-title-content mt">{{data.diagnosticName}}</p>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <mat-grid-list cols="1">
                            <mat-grid-tile>
                                <a class="card-title-sml p0">Active Projects</a>
                                <a href="" target="_blank" class="pd">Onboarding Process</a>
                                <a href="" target="_blank" class="pd">Perform Plus Diagnostic</a>
                                <a class="card-title-sml p0">Past Projects</a>
                                <a href="" target="_blank" class="pd">Automation Review Diagnostic</a>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <section class="card-button-section">
                            <button class="active-stroke-sml" (click)="editClient(true,data)">Edit</button>
                            <button class="active-solid-med w-50" (click)="viewClient(false,data)">View Client</button>
                            <button class="active-solid-med w-50" (click)="viewProject(data)">Module</button>
                        </section>
                    </mat-card-content>
                </mat-card>
            

            <mat-card drag-scroll-item class="example-card">
                <mat-card-title>
               
                </mat-card-title>
                <mat-card-content>
                    <section>
                        <br><br> <br><br> <br><br> <br><br>
                        <i routerLink="/onboard" class="fa-regular fa-plus plus-card"></i>
                        <br><br> <br><br> <br><br> <br>
                      
                    </section>
                </mat-card-content>
            </mat-card>
        </drag-scroll>
    </div>
    <br>

    <section class="card"  >
        <mat-card class="example-card" *ngFor="let data of clients">
            <!-- <mat-card-title>
                <img class="small-logo" src="../../assets/icons/std.jpg" alt="logo">
            </mat-card-title> -->
            <mat-card-content>
                <mat-grid-list cols="2" rowHeight="2:1">
                    <mat-grid-tile>
                        <p class="card-title-sml mb">Client Name</p>
                        <span [matTooltip]="data.clientName" matTooltipClass="tooltip"
                         class="card-title-content mt truncate">{{data.clientName}}</span>
                    </mat-grid-tile>
                    <mat-grid-tile class="margin-top" >
                        <p class="card-title-sml mb">Project Lead</p>
                        <p [matTooltip]="data?.authors[0] ?? 'none' " matTooltipClass="tooltip" 
                        class="card-title-content mt truncate">{{data?.authors[0] ?? '--'}}</p>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <p class="card-title-sml mb">Start Date</p>
                        <p class="card-title-content mt">{{data?.startDate | date:('dd-MM-yyyy') }}</p>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <p class="card-title-sml mb">End Date</p>
                        <p class="card-title-content mt">{{data?.endDate | date:('dd-MM-yyyy') }}</p>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <a class="card-title-sml p0">Active Projects</a>
                        <p [matTooltip]="data.diagnosticName" matTooltipClass="tooltip"
                         (click)="viewProject(data)" class="pd truncate">{{data.diagnosticName}}</p>
                    </mat-grid-tile>
                </mat-grid-list>
               
            </mat-card-content>
            <mat-card-actions class="footer_btns" >
                <button *ngIf="role!='client'" class="active-stroke-sml" (click)="editClient(true,data)">Edit</button>
                <button *ngIf="role!='client'" class="active-solid-med w-50" (click)="viewClient(false,data)">View Client</button>
                <button class="active-solid-med w-50" (click)="viewProject(data)">Module</button>
            </mat-card-actions>
        </mat-card>
    </section>
</div>