import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { QuestionAddDialogComponent } from "@app/masterdata/question-add-dialog/question-add-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DatabaseService } from "../database.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UsePagination } from "@app/core/classes/use-pagination";
import { FormControl } from "@angular/forms";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Helper } from "@app/core/classes/helper";
import { QUESTION_STATES_ObJ } from "@app/resources/question-state";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReqHistoryComponent } from "@app/masterdata/req-history/req-history.component";
@Component({
  selector: "app-db-eva",
  templateUrl: "./db-eva.component.html",
  styleUrls: ["./db-eva.component.scss"],
})
export class DbEvaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  spinner: boolean = true;
  isEmpty: boolean = true;
  Pagination: any = {
    length: 0,
    pageIndex: 0,
    pageSize: 5,
    previousPageIndex: 0,
  };
  selectedType: string = "";
  questionLens: any;
  questionCategories: any;
  filteredQuestions: any = [];
  approvedQuestions: any = [];
  selectedTab: number = 0;
  state: string = QUESTION_STATES_ObJ["APPROVED"];
  questionSelcLens: any;
  constructor(
    private onboardingService: OnboardingService,
    private workflowTemplateService: WorkflowTemplateService,
    private matDialog: MatDialog,
    private databaseService: DatabaseService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar
  ) {}
  dataSource: any = [];
  displayedColumns: string[] = [
    "category",
    "subcategory",
    "lens",
    "activity",
    "state",
    "action",
  ];
  categoryList: any = [];
  templateList: any = [];
  dialog: any;
  totalLength: number = 0;
  questionPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });

  selectedTemplate: any;
  selectedCategory: any;
  selectedSubCategory: any;

  selected: any = "";
  searchCtrl = new FormControl();
  filtered: any;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;

  ngOnInit(): void {
    this.tabChange(0);
    this.getCategories();
    this.getTemplateData();
  }

  getCategories() {
    this.databaseService.getDropDown("CATEGORY", "eva").subscribe({
      next: (res: any) => {
        if (res.length > 0) this.questionCategories = res;
      },
      error: (error: any) => {
        console.log("type dropdown", error);
      },
    });
  }

  ngAfterViewInit() {}

  getApprovedQuestions() {
    this.databaseService
      .getRefData(
        this.questionPagination.perPage,
        this.questionPagination.page,
        "EVA",
        this.state
      )
      .subscribe({
        next: (res: any) => {
          this.spinner = false;
          console.log("approved EVA question", res);
          this.approvedQuestions = [];
          if (res.data.length == 0) {
            this.isEmpty = true;
            this.dataSource = [];
          } else {
            // this.approvedQuestions = res.data
            // if(this.state == 'APPROVED'){
            //   this.dataSource = res.data.filter((x:any)=>  x.module == 'EVA')
            //   console.log('dataSource',this.dataSource);

            // }else{
            //   this.dataSource = res.data.filter((x:any)=> x.state == 'DRAFT')
            // }
            // this.approvedQuestions = res.data.filter((x:any)=>  x.module == 'EVA')
            this.dataSource = res.data;
            this.approvedQuestions = this.dataSource;
            this.totalLength = res.metadata[0].total;

            console.log("type", this.questionLens);

            this.isEmpty = false;
          }
        },
        error: (error: any) => {
          this.spinner = false;
          console.log(error);
        },
      });
  }

  openDialog(type: string, element: any) {

    if(typeof element === 'object') {

      element.comment = "";
      let categoryIndex = this.questionCategories.findIndex((categoryData: any) => categoryData.category == element.category);
      this.questionLens = this.questionCategories[categoryIndex].lens.find((lensDetail: any) => lensDetail.subCategory == element.subCategory).lens;
    }
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: "70em",
      disableClose: true,
      data: {
        from: type == "create" ? "EVA-Ref-Create" : "EVA-Ref-Edit",
        category: this.questionCategories,
        lens: this.questionLens,
        question: element,
        state: this.state,
      },
    });

    this.dialog.afterClosed().subscribe((res: any) => {
      console.log("res", res.event);

      if (
        res.event == "avaQusCreate" ||
        res.event == "avaQusEdit" ||
        res.event == "avaDraft"
      ) {
        this.getApprovedQuestions();
      }
    });
  }

  tabChange(tabIndex: any) {
    this.spinner = true;
    console.log("tab", tabIndex);
    this.selectedTab = tabIndex;
    if (tabIndex == 0) {
      this.state = QUESTION_STATES_ObJ["APPROVED"];
    } else {
      this.state = QUESTION_STATES_ObJ["DRAFT"];
    }
    this.getApprovedQuestions();
    this.clearFilters();
  }

  clearFilters() {
    this.selectedCategory = "";
    this.selectedSubCategory = "";
    this.questionSelcLens = "";
    this.selectedTemplate = "";
    this.filteredQuestions = [];
    this.getApprovedQuestions();
    //this.dataSource = this.approvedQuestions
  }

  getCategoryData() {
    this.onboardingService.getModuleData("CATEGORY").subscribe(
      (response) => {
        this.categoryList = response.filter(
          (category: any) => category.isParent
        ); /* Here display only the parent categories. */
      },
      (error) => {
        console.log("Error on get Category list: ", error);
      }
    );
  }

  getTemplateData() {
    this.databaseService.getTemplateByModule("EVA").subscribe(
      (response) => {
        this.templateList = response["data"];
      },
      (error) => {
        console.log("Error on get template list: ", error);
      }
    );
  }

  getTemplateByQuery() {
    this.databaseService
      .getTemplateByQuery(this.selectedTemplate, "EVA")
      .subscribe((res: any) => {
        this.dataSource = res["data"][0].questions;
      });
  }

  onTemplateSelect(selectedEvent: any) {
    this.selectedTemplate = selectedEvent.value;

    let res = this.templateList.findIndex(
      (template: any) => template.templateName === selectedEvent.value
    );
    console.log(this.templateList[res]);
    this.dataSource = this.templateList[res].questions;
    this.approvedQuestions = this.dataSource;

    this.selectedCategory = "";
    this.selectedSubCategory = "";
    this.questionSelcLens = "";
    this.filteredQuestions = [];
    // this.getTemplateByQuery();
  }

  onFilterChange(event: any, type: string) {
    console.log("cat", event, "type-", type);

    if (type == "category") {
      this.selectedCategory = event;

      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (x: any) => x.category == event.category
        );
      } else {
        this.filteredQuestions = this.approvedQuestions.filter(
          (x: any) => x.category == event.category
        );
      }
      //this.filteredQuestions = this.approvedQuestions.filter((x:any)=> x.category == event.category)
      this.dataSource = this.filteredQuestions;
      this.questionLens = [];
      this.cdr.markForCheck();
    }
    if (type == "subcategory") {
      this.selectedSubCategory = event;
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (x: any) => x.subCategory == event
        );
      } else {
        this.filteredQuestions = this.approvedQuestions.filter(
          (x: any) => x.subCategory == event
        );
      }
      this.dataSource = this.filteredQuestions;
      this.questionLens = this.selectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
      this.cdr.markForCheck();
    }
    if (type == "lens") {
      this.questionSelcLens = event;
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (x: any) => x.lens == event
        );
      } else {
        this.filteredQuestions = this.approvedQuestions.filter(
          (x: any) => x.lens == event
        );
      }
      this.dataSource = this.filteredQuestions;
      this.cdr.markForCheck();
    }
  }

  getPageEvent(pageData: PageEvent) {
    this.spinner = true;
    // this.Pagination = pageData
    console.log("pageData", pageData);
    this.questionPagination.doPagination(pageData);
    setTimeout(() => {
      this.getApprovedQuestions();
    }, 2000);
  }

  clearSelection() {
    this.selected = "";
    this.filtered = [];
  }

  onSelected() {
    this.selected = this.selected.question;
  }

  displayWith(value: any) {
    return value?.Title;
  }

  serachQuestion() {
    this.searchCtrl.valueChanges
      .pipe(
        filter((res) => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filtered = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http.get("http://localhost:3000/refData?text=" + value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        this.filtered = data.data;
      });
  }

  deletePreview(element: any) {
    console.log("element", element);

    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Deactivate the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("res", res, element);
      if (res) {
        let payload = { ...element };
        delete payload._id;
        delete payload.state;
        delete payload.updatedUser;
        payload.module = "EVA";
        payload.isActive = false;
        payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
        payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

        this.databaseService.editRefData(element._id, payload).subscribe({
          next: (res: any) => {
            this.snackbar.open("Question Deactivated Successfully", "close");
            this.getApprovedQuestions();
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      }
    });
  }

  openHistory(data: any) {
    this.databaseService.getQuestionHistory(data._id).subscribe(
      (res) => {
        let obj: any = {
          question: data.question,
          author: data.user,
          status: data.state,
          comments: data.comment,
        };
        let result: any = [];
        result.push(obj);
        for (let r of res) {
          let newObj: any = {
            question: r.question,
            status: r.state,
            comments: r.comment,
          };
          result.push(newObj);
        }
        let dialog = this.matDialog.open(ReqHistoryComponent, {
          width: "95em",
          // height: '750px',
          disableClose: false,
          data: result,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
