import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatChipsModule } from "@angular/material/chips";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatBadgeModule } from "@angular/material/badge";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatExpansionModule } from "@angular/material/expansion";

import { NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgApexchartsModule } from "ng-apexcharts";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { NgxMatTimelineModule } from "ngx-mat-timeline";
import { PowerBIEmbedModule } from "powerbi-client-angular";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { OptionsScrollDirective } from "@app/core/directives/options-scroll.directive";

const module = [
  CommonModule,
  MatIconModule,
  FormsModule,
  ReactiveFormsModule,
  MatCardModule,
  MatInputModule,
  MatDividerModule,
  MatTabsModule,
  MatFormFieldModule,
  MatSelectModule,
  NgxMatSelectSearchModule,
  MatTableModule,
  MatButtonModule,
  MatDatepickerModule,
  MatRippleModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatMenuModule,
  MatSortModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  OverlayModule,
  MatChipsModule,
  MatTooltipModule,
  MatGridListModule,
  MatStepperModule,
  MatBadgeModule,
  MatListModule,
  MatProgressBarModule,
  MatButtonToggleModule,
  NgApexchartsModule,
  NgxMatTimelineModule,
  PowerBIEmbedModule,
  MatSidenavModule,
  MatToolbarModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [ConfirmationDialogComponent, OptionsScrollDirective],
  imports: [module, MatSnackBarModule],
  exports: [module, OptionsScrollDirective],
})
export class SharedModule {}
