<mat-toolbar class="lg">
    <div class="navbar-logo-div">
        <a class="navbar-logo-link lg-space">
            <img width="60px" height="24px" src="../../assets/icons/logo.svg" alt="pwc">
        </a>
    </div></mat-toolbar>

<br>
<!-- New -->
<div class="survey-land" *ngIf="preview">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected">
        <mat-tab *ngIf="preview">
            <section class="ava" *ngIf="show">
                <div class="sticky">
                    <mat-card class="right nill">
                        <span class="surveyhead flex">Welcome to the Focus Groups</span>
                        <p class="para">
                            Click to begin this activity and answer the questions as they come up.
                            Fill in your details below and then click begin to answer the first question.
                        </p>
                        <br>
                        <mat-form-field appearance="outline"
                            style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                            <mat-label>Name</mat-label>
                            <input matInput>
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="outline"
                            style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                            <mat-label>Select Team</mat-label>
                            <mat-select>
                                <mat-option value="one">First option</mat-option>
                              </mat-select>
                        </mat-form-field>

                    </mat-card>
                    <br>
                    <!-- questions -->
                    <mat-card class="nill" *ngFor="let item of focusGroups.slice(0, 1)">
                        <p class="question"> {{item.question}}</p>
                        <br>

                        <section class="timeline-container">
                            <div>
                                <div class=labels>
                                    <p>Strongly Disagree</p>
                                    <p>Disagree</p>
                                    <p>Neither</p>
                                    <p>Agree</p>
                                    <p>Strongly Agree</p>
                                </div>
                                <div class="line-container">
                                    <div class="line"></div>
                                    <div class="circle"></div>
                                </div>
                              
                                
                            </div>
                        </section>



                        <section style="display: flex; justify-content: space-between;">
                            <mat-label class="input-label">Free Text Response</mat-label>
                            <span>
                            </span>
                        </section>
                        <mat-form-field appearance="outline" class="wd">
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                                value=""></textarea>
                        </mat-form-field>
                    </mat-card>
                    <br>
                    <div *ngFor="let item of focusGroups;let i=index">
                        <mat-card>
                            <p class="question b"> {{item?.question}}</p>                 
                        </mat-card>
                    </div>
                    <br>

                    <div class="sticky submit">
                        <button class="active-solid-lrg but-style" (click)="goBack()">Back</button>
                        <button class="active-solid-lrg but-style" (click)="createTemplate()">Create</button>
                    </div>
                </div>
            </section>
        </mat-tab>
        <app-footer></app-footer>
    </mat-tab-group>
</div>
    
    
    <div class="survey-land" *ngIf="!preview">
        <section class="ava" *ngIf="show">
            <mat-card class="right link" *ngIf="!preview">
                <p>Focus Group link : <a class="pd">{{url}}</a></p>
            </mat-card>
            <br>
            <section class="butns" *ngIf="!preview">
                <button class="active-solid-med white" style="width: 33rem;" (click)="close()">
                    Exit
                </button>
                <button class="active-solid-med " style="width: 33rem;" (click)="show = false;">
                    Start Interview
                </button>
            </section>
        </section>
    </div>


<div class="survey-land">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected">
        <mat-tab>
            <!-- Questions -->
            <section class="card eva" *ngIf="!show">
                <mat-card class="right">
                    <span class="surveyhead flex">Welcome to the Focus Groups</span>
                    <p class="para">
                        Click to begin this activity and answer the questions as they come up.
                        Fill in your details below and then click begin to answer the first question.
                    </p>
                    <br>
                    <mat-form-field appearance="outline"
                        style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                        <mat-label>Name</mat-label>
                        <input matInput [formControl]="participantName">
                    </mat-form-field>
                    <br>
                    <mat-form-field appearance="outline"
                        style="width: 98%;font-family: 'Hanken Grotesk', sans-serif !important;">
                        <mat-label>Select Team</mat-label>
                        <mat-select (selectionChange)="assign($event.value)">
                            <mat-option *ngFor="let team of teams" [value]="team.teamName"> {{ team?.teamName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>
                    <section class="butns">
                        <button class="active-solid-med white" style="width: 33rem;" (click)="show = true;">
                            Back
                        </button>
                        <button class="active-solid-med " style="width: 33rem;" (click)="begin()">
                            First Question 
                        </button>
                    </section>

                </mat-card>
            </section>
        </mat-tab>

        <mat-tab *ngFor="let item of focusGroups;let i=index">
            <!-- Submit Screen content below -->
            <div *ngIf="!isAdmin">

                <section class="card eva" *ngIf="!submitted">
                    <div class="sticky">
                        <mat-card class="right">
                            <span class="surveyhead flex">Focus Groups</span>
                            <p class="para">Please answer the following questions & wait for each to proceed further.
                            </p>
                        </mat-card>
                        <br>
                        <mat-card class="right" style="height: 10vh;">
                            <div class="col-sm-11 center descStars">
                                <div class="desc">
                                    <div class="">
                                        <section class='alignDescStars'>
                                            <div class="star-container">
                                                <div class="star-text">Strongly Disagree</div>
                                                <div class="star-text"> Disagree</div>
                                                <div class="star-text">Neither</div>
                                                <div class="star-text">Agree</div>
                                                <div class="star-text">Strongly Agree</div>

                                            </div>
                                            <div class="star-container" >
                                                <i *ngFor="let item of timeline" class="fa fa-star str star"></i>
                                            </div>
                                         
                                        </section>
                                       
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                    <br>
                    <div class="check">
                        <mat-card class="eva-card" *ngIf="i === selected-1">
                            <p class="question">{{item?.question}}</p>
                            <div>
                                <ul class="list-inline rating-list " [ngClass]="preview ? 'rating-disabled' : ''"
                                    *ngFor="let star of stars" style="display: inline-block">
                                    <li (click)="countStar(item,star)" [ngClass]="{'selected': (star <= item?.answer)}">
                                        <i class="fa fa-star str "></i>
                                    </li>
                                </ul>
                                <br>
                                <mat-form-field appearance="outline" style="width: 95%;margin-left: 0.65em;"
                                    [ngClass]="preview ? 'rating-disabled' : ''">
                                    <mat-label>Text</mat-label>
                                    <textarea matInput (keyup)="getInput($event,item)"
                                        [(ngModel)]="item.comments"></textarea>
                                </mat-form-field>
                                <br>
                                <div class="submit" *ngIf="surveyDetailId">
                                    <!-- <button class="active-solid-lrg but-style" (click)="set('back',i)">Back</button> -->
                                    <button *ngIf="!finalSubmit" class="active-solid-lrg but-style"
                                        (click)="set('next',item)">Submit</button>
                                    <button *ngIf="finalSubmit" class="active-solid-lrg but-style"
                                        (click)="set('submit',i)">Submit</button>
                                </div>
                            </div>
                        </mat-card>
                        <br>
                    </div>
                
                    <app-footer></app-footer>
                </section>

                <!-- //Loader  -->
                <div *ngIf="time">
                    <div class="container">
                        <div class="ball" id="ball-1"></div>
                        <div class="ball" id="ball-2"></div>
                        <div class="ball" id="ball-3"></div>
                    </div>
                    <br>
                    <div class="wait">
                        <span class="surveyhead flex">Please Wait for next question!</span>
                        <br>
                        <span class="count">
                            <i class="fa-solid fa-stopwatch"></i>&nbsp;
                            {{countdown}}
                        </span>

                    </div>
                </div>
            </div>

            <!-- Thank Page -->
            <mat-card class="bx-none" *ngIf="submitted">
                <div class="sticky centr">
                    <mat-card class="right mat-elevation-z0">
                        <span class="surveyhead flex">Thank you!</span>
                        <p class="para">You have completed all of the questions in this questionnaire. <br>
                            You can now close the survey</p>
                    </mat-card>
                    <br>
                    <button class="active-solid-lrg " style="width: 25rem;" (click)="close()">
                        Close Tab
                    </button>
                </div>
            </mat-card>
        </mat-tab>
    </mat-tab-group>
</div>

<!-- ADMIN SCREEN -->
<div class="admin" *ngIf="isAdmin">
    <div *ngIf="!over">
        <section *ngFor="let items of adminDataShow;let i=index">
            <mat-card class="fg-deep-card" *ngIf="i === selected-1">
                <p class="questionAD"> {{items?.question}}</p>
                <br>
                <!-- Timeline -->
                <section class="timeline-container">
                    <div>
                        <div class=labels *ngIf="!noResponse">
                            <p>Strongly Disagree</p>
                            <p> Disagree</p>
                            <p>Neither</p>
                            <p>Agree</p>
                            <p>Strongly Agree</p>
                        </div>
                        <div *ngIf="!noResponse">
                            <!-- <ngx-mat-timeline position="start" orientation="horizontal">
                                <ngx-mat-timeline-item *ngFor="let ans of selectedRow.rating; let idx = index"
                                    style="min-width: 92px; max-width: 92px; width: 96%;"
                                    [icon]="ans.length > 0 ? 'circle' : idx == 5 ? 'circle' : '' "
                                    [ngClass]="{ 
                                        'average': idx == 5,
                                        'selected-icon': (idx  ==  selectedHoverIndex && idx != 5 ),
                                        'default-icon' : (idx  !==  selectedHoverIndex && idx != 5 )
                                 }"
                                    (mouseenter)="showContent(idx,ans)" (mouseleave)="hideContent(ans)">
                                </ngx-mat-timeline-item>
                            </ngx-mat-timeline> -->
                            <div class="rating-line" style="width: 26vw !important;">
                                <div *ngFor="let ans of selectedRow.rating;let idx = index">
                                   <div class="circleStyles c_fix" *ngIf="ans.length > 0" [style.margin-left]="calculateMargin(ans)"
                                    (click)="showContent(idx,ans)">
                                  {{getAnswer(ans)}}</div>
                                </div>
                            </div>
                        </div>
                        <mat-card *ngIf="noResponse" class="no">
                                Please wait for people to start answer the questions.
                                <br>
                        </mat-card>
                        
                    </div>
                </section>
    
    
    
                <p class="avg" *ngIf="!noResponse">Response average: {{items.average}}</p>
                <!-- <mat-label class="input-label">Free Text Responses</mat-label> -->
                <!-- <section class="dd-style">
                    <mat-label class="input-label">Free Text Responses</mat-label>
                    <span>
                        <mat-icon (click)="open('add')">add</mat-icon>
                        <mat-icon (click)="open('edit')">edit</mat-icon>
                    </span>
                </section> -->
    
                <div  *ngFor="let data of hoverContent">
                    <!-- <section *ngFor="let data of items.answers;"> -->
                    <mat-form-field appearance="outline" class="tb-size nill">
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                            [value]="data?.comments == '' ? 'No Comments' : data?.comments "></textarea>
                    </mat-form-field>
                    <br>
                    <!-- </section> -->
                </div>
    
                <div class="submit" *ngIf="surveyDetailId" style="display: flex;justify-content: center;">
                    <button class="active-solid-lrg but-style" *ngIf="adminDataShow.length !== selected"
                        (click)="approveToNextQuestion('next')">Next Question</button>
                    <button class="active-solid-lrg but-style" *ngIf="adminDataShow.length === selected"
                        (click)="approveToNextQuestion('final')">Finish</button>
                </div>
            </mat-card>
        </section>
    </div>
    <br>
  
    <mat-card class="bx-none" *ngIf="over">
        <div class="sticky centr">
            <mat-card class="right mat-elevation-z0">
                <span class="surveyhead flex">Survey Completed !</span>
            </mat-card>
            <br>
            <button class="active-solid-lrg " style="width: 25rem;" (click)="close()">
                Close Tab
            </button>
        </div>
    </mat-card>

</div>