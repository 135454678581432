<mat-dialog-content>    
    <table mat-table [dataSource]="datasource" class="sizing">
        <ng-container matColumnDef="response">
            <th mat-header-cell *matHeaderCellDef>Response No.</th>
            <td mat-cell *matCellDef="let element;let i = index"> {{i+1}} </td>
          </ng-container>
      <ng-container matColumnDef="responses">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element;let i = index">
          <span *ngIf="element.focusGroupData"> {{element.focusGroupData.focusGroupName}} </span>
          <span *ngIf="!element.focusGroupData"> {{element.module}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> 
        <mat-icon matTooltip="Click to get the responses" (click)="onSubmit(element)">edit</mat-icon>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="dc"></tr>
      <tr mat-row *matRowDef="let row; columns: dc;"></tr>
    </table>
    <div class="button-container">
      <!-- <button mat-button (click)="onSubmit()">Submit</button> -->
      <button mat-button (click)="onNoClick()">Cancel</button>
    </div>
    </mat-dialog-content>