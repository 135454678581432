<nav class="navbar active">
    <div class="navbar-container">
        <!--logo div-->
        <div class="navbar-logo-div">
            <a class="navbar-logo-link">
                <img width="65px" height="24px" src="../assets/icons/logo.svg" alt="pwc">
            </a>
        </div>
        <!--item list-->
        <ul class="menu-list scrollnav">
            <li class="menu-item">
                <a class="menu-link" (click)="reset()" routerLink="/home" [routerLinkActive]="'active_list'" >
                    <i class="fa-sharp fa-solid fa-house"></i>
                    <span class="menu-link-text">Home</span>
                </a>
            </li>
            
            <!-- <li class="menu-item">
                <a class="menu-link" routerLink="/projects" [routerLinkActive]="'active_list'">
                    <i class="fa-regular fa-folder"></i>
                    <span class="menu-link-text">Project</span>
                </a>
            </li> -->
            <!-- <li class="menu-item">
                <a class="menu-link" class="menu-link">
                    <i class="fa-solid fa-users"></i>
                    <span class="menu-link-text">Clients</span>
                </a>
            </li> -->

            <li class="menu-item" *ngIf="url !=='/home' && role !='client'">
                <a class="menu-link" routerLink="/project-list"  [routerLinkActive]="'active_list'">
                    <i class="fa-sharp fa-solid fa-square-poll-vertical"></i>
                    <span class="menu-link-text">Projects</span>
                </a>
            </li>

            <li class="menu-item" *ngIf="showProject && role!='client'"  >
                <a [routerLink]=" '/project/'+ clientId + '/overview' " class="menu-link" 
                [routerLinkActive]="'active_list'" (click)=" modules = !modules " >
                    <i class="fa-solid fa-database"></i>
                    <span class="menu-link-text"> {{clientData?.clientName}} </span>
                    <i *ngIf="!modules" class="fa-sharp fa-solid fa-caret-left"></i>
                    <i *ngIf="modules" class="fa-sharp fa-solid fa-caret-down"></i>
                </a>
            </li>
          
          

                <ul class="multi  menu-item " *ngIf=" showProject  && modules && role!='client'" >

                    <li class=" menu-item menu-item2"  *ngIf="role!='client'" >
                        <a [routerLink]=" '/project/'+ clientId + '/overview' " class="menu-link" [routerLinkActive]="'active_list'"   >
                            <span  class="menu-link-text">Overview </span>
                        </a>
                    </li>
                    <li class=" menu-item menu-item2"  >
                        <a [routerLink]=" '/dashboard/'+ clientId + '/overview' " class="menu-link" [routerLinkActive]="'active_list'"   >
                            <span *ngIf="role!='client'" class="menu-link-text">Client Dashboard </span>
                            <span *ngIf="role=='client'" class="menu-link-text"> Dashboard </span>

                        </a>
                    </li>
                    <li class="menu-item menu-item2"  *ngIf="role!='client'">
                        <a  class="menu-link" [routerLinkActive]="'active_list-multi'"  (click)=" subModules = !subModules " >
                            <span  class="menu-link-text">Modules </span>
                            <i *ngIf="!subModules" class="fa-sharp fa-solid fa-caret-left"></i>
                            <i *ngIf="subModules" class="fa-sharp fa-solid fa-caret-down"></i>
                        </a>
                    </li>
                    <!-- [class.disabled]="modulesList['Op Maturity'] " -->
                  
                    <ul class="multi modulesList menu-item " *ngIf="subModules"  >
                        <button (click)=" opMaturtiy = !opMaturtiy"  mat-flat-button
                         class="menu-link" [class.is-hidden]="!modulesList['Operational Maturity']" [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Operational Maturity</span>
                        </button>
                        <ul class="multi2 modulesList menu-item " *ngIf="opMaturtiy" >
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/op-maturity/scoring' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Scorecard</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/op-maturity/Weightage' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Weighted Maturity</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button  [routerLink]=" '/project/'+ clientId + '/op-maturity/outputs' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Organisation View</span>
                                </button>
                            </li>
                           
                        </ul>

                        <button (click)="managerInterview = !managerInterview" mat-flat-button 
                         class="menu-link" [class.is-hidden]="!modulesList['Team Leader Interviews']" [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Team Leader Interviews</span>
                        </button>

                            <ul class="multi2 modulesList menu-item " *ngIf="managerInterview" >
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/manager-interview/setup' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Setup</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/manager-interview/responses' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Response Management</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/manager-interview/outputs' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Outputs</span>
                                </button>
                            </li>
                        </ul>
                        
                        <button (click)="processInterview = !processInterview" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['Process Owner Interviews']"
                        [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Process Owner Interviews</span>
                        </button>

                        <ul class="multi2 modulesList menu-item " *ngIf="processInterview" >
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/process-interviews/setup' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Setup</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/process-interviews/weightage' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Cost & Weightage</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/process-interviews/responses' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Response Management</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/process-interviews/process-outputs' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Outputs</span>
                                </button>
                            </li>
                        </ul>

                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['DATA FEED: PRODUCTIVITY']"
                         [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Data Feed : Productivity</span>
                        </button>
                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['DATA FEED: PROCESS']"
                         [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Data Feed : Process</span>
                        </button>
                        <button (click)="focusGroup = !focusGroup" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['Focus Groups']"
                        [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Focus Groups</span>
                        </button>

                        <ul class="multi2 modulesList menu-item " *ngIf="focusGroup" >
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/focus-groups/setup' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Setup</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/focus-groups/responses' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Response Management</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/focus-groups/outputs' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Outputs</span>
                                </button>
                            </li>
                        </ul>

                        <button (click)="AVA = !AVA" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['Surveys']"
                        [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Surveys </span>
                        </button>

                        <ul class="multi2 modulesList menu-item " *ngIf="AVA" >
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/ava-eva/setup' "
                                 mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Setup</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/ava-eva/responses' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Response Management</span>
                                </button>
                            </li>
                            <li class="menu-item">
                                <button [routerLink]=" '/project/'+ clientId + '/ava-eva/outputs' "
                                mat-flat-button class="menu-link"  [routerLinkActive]="'active_list'">
                                    <span class="menu-link-text">Outputs</span>
                                </button>
                            </li>
                        </ul>

                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['PERFORMANCE VARIABILITY ANALYSIS']"
                         [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Performance Variability Analysis </span>
                        </button>
                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['PROCESS DISCOVERY']"
                         [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Process Discovery</span>
                        </button>
                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['DATA AUTOMATION']"
                         [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Data Automation</span>
                        </button>
                        <button (click)="construction()" mat-flat-button class="menu-link" [class.is-hidden]="!modulesList['AI HEAT MAPPING']" 
                        [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">AI Heat Mapping</span>
                        </button>
                        <button [routerLink]=" '/project/'+ clientId + '/power-bi' " mat-flat-button class="menu-link" 
                        [routerLinkActive]="'active_list'" >
                            <span class="menu-link-text">Data Diagnostics</span>
                        </button>
                    </ul>

                   
                </ul>

            <li class="menu-item" *ngIf="isAdmin">
                <a class="menu-link"  [routerLinkActive]="'active_list-multi'" (click)="toggle()">
                    <i class="fa-solid fa-gear"></i>
                    <span class="menu-link-text">Admin Options</span>
                </a>
            </li>
            <ul class="multi" *ngIf="master">
                <!-- <li class="menu-item">
                    <a class="menu-link" routerLink="/master-data" [routerLinkActive]="'active_list'" >
                        <i class="fa-solid fa-database"></i>
                        <span class="menu-link-text">Master</span>
                    </a>
                </li> -->
                <li class="menu-item">
                    <a class="menu-link" routerLink="/approve-data/approval" [routerLinkActive]="'active_list'">
                        <i class="fa-regular fa-square"></i>
                        <span class="menu-link-text">Approval</span>
                    </a>
                </li>
                <li class="menu-item">
                    <a class="menu-link" routerLink="/approve-data/template-list" [routerLinkActive]="'active_list'">
                        <i class="fa-regular fa-square"></i>
                        <span class="menu-link-text">Template</span>
                    </a>
                </li>
                <!-- <li class="menu-item">
                    <a class="menu-link" (click)="openHistory()" [routerLinkActive]="'active_list'">
                        <i class="fa-regular fa-square"></i>
                        <span class="menu-link-text">History</span>
                    </a>
                </li> -->
                <!-- <li class="menu-item">
                    <a class="menu-link" *ngIf="isAdmin" [routerLink]=" '/dashboard/'+ clientId + '/overview' " [routerLinkActive]="'active_list'">
                        <mat-icon>dashboard</mat-icon>
                        <span class="menu-link-text">Dashboard</span>
                    </a>
                </li> -->
            </ul>
            <li *ngIf="role !='client'" class="menu-item">
                <a class="menu-link" [routerLinkActive]="'active_list-multi'" (click)="databaseToggle()">
                    <i class="fa-solid fa-database"></i>
                    <span class="menu-link-text">Database</span>
                </a>
            </li>
            <ul class="multi" *ngIf="databaseMenu">
                <li class="menu-item"  >
                    <a class="menu-link" routerLink="/database/ava" [routerLinkActive]="'active_list'">
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">AVA</span>
                    </a>
                </li>

            


                <li class="menu-item">
                    <a class="menu-link" routerLink="/database/eva" [routerLinkActive]="'active_list'">
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">EVA</span>
                    </a>
                </li>
                <!-- <li class="menu-item" style="pointer-events:none;opacity:0.6;">
                    <a class="menu-link" routerLink="/database/op-maturity" [routerLinkActive]="'active_list'" >
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">Op Maturity</span>
                    </a>
                </li> -->
                <li class="menu-item" >
                    <a class="menu-link" routerLink="/database/manager-interview" [routerLinkActive]="'active_list'" >
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">Team Leader Interviews</span>
                    </a>
                </li>
                <li class="menu-item" >
                    <a class="menu-link" routerLink="/database/focus-group" [routerLinkActive]="'active_list'" >
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">Focus Groups</span>
                    </a>
                </li>
                <li class="menu-item" >
                    <a class="menu-link" routerLink="/database/process-interview" [routerLinkActive]="'active_list'" >
                        <i class="fa-regular fa-file-lines"></i>
                        <span class="menu-link-text">Process Owner Interviews</span>
                    </a>
                </li>

            </ul>
            <br>
            <li *ngIf="role !='client'" class="menu-item">
                <button class="active-stroke-sml new" routerLink="/onboard" >New</button>
            </li>


            <br>

        </ul>
    </div>
    <!--div user info-->
    <div class="user-container">
        <div class="user-info">
            <div class="user-details">
                <span class="user-name" (click)="onSignOut()">Log out</span>
                <br>
            </div>
        </div>
    </div>
</nav>



<!--dashboard-->
<main class="dashboard">
    <mat-toolbar class="toolbar row" >
        <section class="arr-buttons navigation col-sm-6">
            <a (click)="action('back')" class="previous round"><i class="fa-solid fa-chevron-left"></i></a>
            <a (click)="action('front')"  class="next round"><i class="fa-solid fa-chevron-right"></i></a> &nbsp;
            <!-- <span > <b>{{
                displaySelection == 'Home' ? 'home' :
                displaySelection == 'project-list' ? 'Project List' :
                displaySelection == 'approve-data' ? 'Approve Data' :
                displaySelection == 'ava' ? 'AVA' :
                displaySelection == 'eva' ? 'EVA' :
                displaySelection == 'manager-interview' ? 'Manager Interview' : displaySelection
                }}</b> </span> -->
                <span> <b>
                    {{displaySelection}}
                </b> </span>
        </section>
        <!-- <div class="navigation col-sm-6" >
            <i class="fa-solid fa-angle-left fa-xl "></i>
            <i class="fa-solid fa-angle-right fa-xl"></i>
          <h3>Modules</h3>
        </div> -->
        <div class="search col-sm-6 " >
                    <!-- <mat-form-field appearance="outline">
                        <input matInput placeholder="Search">
                      </mat-form-field> -->
        
              
            <!-- <i class="fa-regular fa-bell fa-xl3"></i>
            <i class="fa-solid fa-circle fa-xl2"></i> -->
        </div>
          
        
    
      </mat-toolbar>
    <router-outlet></router-outlet>
    <main>
        <!-- user detail tab below-->
        <!-- <app-user-detail-tab></app-user-detail-tab> -->
    </main>
</main>