<div class="row space filterr">
  <div class="col-sm-6" style="gap: 1em; display: inline-flex;">
    <mat-form-field appearance="outline">
      <mat-label>Function</mat-label>
      <mat-select multiple placeholder="Function" [(ngModel)]="selectedFunctions"
        (selectionChange)="changeFunction($event.value)">
        <mat-option *ngFor="let function of functions" [value]="function">
          {{function}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Team</mat-label>
      <mat-select multiple placeholder="Team" [(ngModel)]="selectedTeams" (selectionChange)="changeTeam($event.value)">
        <mat-option *ngFor="let team of teams" [value]="team">
          {{team}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<br>

<div style="display: flex;">
  <div class="col-sm-2">
    <mat-card class="fg-cards align avg" style="height: 39.2vh;">
      <span class="head">Maturity Score</span>
      <span class="avg-chart-content">{{SinglechartOptions?.name}}</span>
      <div id="single">
        <apx-chart [series]="SinglechartOptions?.series" [chart]="SinglechartOptions?.chart"
          [plotOptions]="SinglechartOptions?.plotOptions" [colors]="SinglechartOptions?.colors"
          [responsive]="SinglechartOptions?.responsive"></apx-chart>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-9">
    <mat-card *ngIf="clientBoard" class="fg-cards">
      <div class="row space">
          <div class="col-sm-12 titleCard" style="display: flex">
              <div class="col-sm-3">
                  <span class="head">Total Responses</span>
              </div>
              <div class="col-sm-3">
                  <span class="head">AVA</span>
              </div>
              <div class="col-sm-3">
                  <span class="head">POI</span>
              </div>
              <div class="col-sm-3">
                  <span class="head">Team Lead Interviews</span>
              </div>
              <div class="col-sm-3">
                <span class="head">Engagement</span>
              </div>
              <div class="col-sm-3">
                <span class="head">Focus Groups</span>
              </div>
          </div>
      </div>

      <div class="row space">
          <mat-card class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
              <mat-card class="col-sm-3 small mat-elevation-z0">
                  <span>{{totalResponse}}</span>
              </mat-card>
              <mat-card class="col-sm-3 small mat-elevation-z0">
                  <span>{{avaCount}}</span>
              </mat-card>
              <mat-card class="col-sm-3 small mat-elevation-z0">
                  <span>{{poiCount}}</span>
              </mat-card>
              <mat-card class="col-sm-3 small mat-elevation-z0">
                  <span>{{miCount}}</span>
              </mat-card>
              <mat-card class="col-sm-3 small mat-elevation-z0">
                <span>{{evaCount}}</span>
            </mat-card>
            <mat-card class="col-sm-3 small mat-elevation-z0">
              <span>{{fgCount}}</span>
          </mat-card>
          </mat-card>
      </div>
  </mat-card>
      <mat-card *ngIf="!clientBoard" class="fg-cards">
          <div class="row space">
              <div class="col-sm-12 titleCard" style="display: flex">
                  <div class="col-sm-3">
                      <span class="head">Functions</span>
                  </div>
                  <div class="col-sm-3">
                      <span class="head">Teams</span>
                  </div>
                  <div class="col-sm-3">
                      <span class="head">Managers</span>
                  </div>
                  <div class="col-sm-3">
                      <span class="head">Members</span>
                  </div>
              </div>
          </div>

          <div class="row space">
              <mat-card class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
                  <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{functionsCount}}</span>
                  </mat-card>
                  <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{teamsCount}}</span>
                  </mat-card>
                  <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{managerCount}}</span>
                  </mat-card>
                  <mat-card class="col-sm-3 small mat-elevation-z0">
                      <span>{{memberCount}}</span>
                  </mat-card>
              </mat-card>
          </div>
      </mat-card>
      <div class="row space" style="margin: 8px 5px 0px 0px; gap: 0em;">
        <mat-card class="col-sm-2 fg-cards side align" *ngFor="let chartData of chartDataArray">
          <span class="head ellipsis">{{chartData.name}}</span>
          <br>
          <app-circle-chart [chartText]="'N/A'"
          [chartValue]="100" *ngIf="chartData.series == '0'" class="n-a"></app-circle-chart>
          <app-circle-chart [chartText]="chartData.series"
          [chartValue]="(chartData.series / this.possiblePoints) * 100" *ngIf="chartData.series != '0'"></app-circle-chart>
        </mat-card>
      </div>
  </div>
</div>
 <br>

<div class="switch">
  <span class="head">Scoring</span>
  <div style="display: flex;justify-content:end;">
    <!-- <span>Team&nbsp;</span>
    <mat-slide-toggle class="example-margin" color="grey" [(ngModel)]="functionSwitch" (change)="onFunctionChange($event)">
    </mat-slide-toggle>
    <span>&nbsp;Function</span>
    <br> -->
    <span>Team&nbsp;</span>
    <div class="toggle-container2">
  
      <div class="toggle-button" [style.background-color]="functionSwitch ? 'white' : 'black' "
        (click)="onFunctionChange('team')"></div>
      <div class="toggle-button" [style.background-color]="functionSwitch ? 'black' : 'white' "
        (click)="onFunctionChange('function')"></div>
  
    </div>
    <span>&nbsp;Function</span>
  </div>
</div>

<br>
<mat-card *ngIf="functionSwitch">
  <table class="my-table">
    <tr>
      <ng-container *ngFor="let header of functionHeaders; let i = index">
        <th>{{ header }}</th>
      </ng-container>
    </tr>
    <ng-container *ngFor="let row of functionTable; let i = index">
      <tr (click)="onRowClick(row)" [ngClass]="{'selected-row': isRowSelected(row)}" #targetElement>
        <ng-container *ngFor="let header of functionHeaders; let j = index">
           <td > <div >
            {{ row[header] }}
           <!-- </div> <div *ngIf="header === 'Op Maturiy Status'">
            <svg height="30" width="30">
              <circle cx="15" cy="15" r="10" [attr.fill]="getTrafficLightColor(row[header])" />
            </svg>-->
           </div>  
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </table>
</mat-card>

<mat-card *ngIf="!functionSwitch">
  <table class="my-table">
    <tr>
      <ng-container  *ngFor="let header of teamHeaders; let i = index">
        <th class="ellipsis">{{ header }}</th>
      </ng-container>
    </tr>
    <ng-container *ngFor="let row of teamTable; let i = index">
      <tr (click)="onRowClick(row)" [ngClass]="{'selected-row': isRowSelected(row)}">
        <ng-container *ngFor="let header of teamHeaders; let j = index">
           <td [style.color] ="getColor(row[header])" > <div *ngIf="header !== 'Maturity Score'">
            {{ row[header] }}
           </div>
            <!-- <div *ngIf="header === 'Maturity Score'" class="bar" [style.background]="getBarColor(row[header])">
              {{ row[header] }}
            </div>  -->
            <div class="instyle" *ngIf="header === 'Maturity Score'">   
              <mat-progress-bar [value]="row[header]*20"></mat-progress-bar>
              <div style="position: absolute; padding:y 10px; color: white;width: 6vw!important; text-align: left; margin-left: 10px;">  {{ row[header] }}</div>
          </div>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </table>
</mat-card>
<br><br>


  <div class="row">
    <div class="col-sm-5">
      <br>
      <h3>Sub Categories</h3>
      <br>
      <mat-card style="height: 600px;">  
        <apx-chart
        [series]="Radar?.series"
        [chart]="Radar?.chart"
        [xaxis]="Radar?.xaxis"
        [yaxis]="Radar?.yaxis"
      ></apx-chart>
      </mat-card>
      <br>
    </div>
    <div class="col-sm-6">
      <br>
      <h3>Lens Performance</h3>
      <br>
      <div class="table-cont">
        <table class="lens-table">
          <tr>
            <ng-container *ngFor="let header of lensHeaders; let i = index">
              <th *ngIf="lensCheck(header)">{{ header }}</th>
            </ng-container>
          </tr>
          <ng-container *ngFor="let row of lensTable; let i = index">
            <tr>
              <ng-container *ngFor="let header of lensHeaders; let j = index">
                 <td [style.color] ="getColor(row[header])" *ngIf="lensCheck(header)"> <div>
                  {{ row[header] }}
                 </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </table>
      </div>
     
    </div>
  
  </div>


  <br>
<br>
<br>

<div *ngIf="spinner" class="overlay" style="left: 0em !important;">
  <mat-spinner class="spinner"></mat-spinner>
</div>