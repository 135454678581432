import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pwc-ui';
  logo = '../'
  appitems = [
    {
        label: 'Item 1 (with Font awesome icon)',
        items: [
            {
                label: 'Item 1.1',
                link: '/item-1-1',
                faIcon: 'fab fa-accusoft'
            },
            {
                label: 'Item 1.2',
                faIcon: 'fab fa-accessible-icon',
                disabled: true,
                items: [
                    {
                        label: 'Item 1.2.1',
                        link: '/item-1-2-1',
                        faIcon: 'fa-allergies' // Font awesome default prefix is fas
                    }
                ]
            }
        ]
    },
    {
        label: 'Item 2',
        icon: 'alarm',
        items: [
        {
            label: 'Item 2.1',
            link: '/item-2-1',
            icon: 'favorite_border',
            activeIcon: 'favorite',
            disabled: true,
        },
        {
            label: 'Item 2.2',
            link: '/item-2-2',
            icon: 'favorite_border',
            activeIcon: 'favorite',
            navigationExtras: {
                queryParams: { order: 'popular', filter: 'new' },
            }
        }
        ]
    }
];

config = {
  paddingAtStart: true,
  interfaceWithRoute: true,
  classname: 'my-custom-class',
  listBackgroundColor: `rgb(208, 241, 239)`,
  fontColor: `rgb(8, 54, 71)`,
  backgroundColor: `rgb(208, 241, 239)`,
  selectedListFontColor: `red`,
  highlightOnSelect: true,
  collapseOnSelect: true,
  useDividers: false,
  rtlLayout: false
};

}
