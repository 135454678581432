<mat-card class="fg-cards">
  <div class="row space">
    <div class="col-sm-12 titleCard" style="display: flex">
      <div class="col-sm-3">
        <span class="head">Functions</span>
      </div>
      <div class="col-sm-3">
        <span class="head">Teams</span>
      </div>
      <div class="col-sm-3">
        <span class="head">Managers</span>
      </div>
      <div class="col-sm-3">
        <span class="head">Members</span>
      </div>
    </div>
  </div>

  <div class="row space">
    <mat-card class="col-sm-12 mat-elevation-z0" style="display: flex;gap: 1.5em;">
      <mat-card class="col-sm-3 small mat-elevation-z0">
        <span>{{functionsCount}}</span>
      </mat-card>
      <mat-card class="col-sm-3 small mat-elevation-z0">
        <span>{{teamsCount}}</span>
      </mat-card>
      <mat-card class="col-sm-3 small mat-elevation-z0">
        <span>{{managersCount}}</span>
      </mat-card>
      <mat-card class="col-sm-3 small mat-elevation-z0">
        <span>{{membersCount}}</span>
      </mat-card>
    </mat-card>
  </div>
</mat-card>






